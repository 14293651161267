import { createContext, useReducer, useContext } from 'react'
import { editedRowsReducer } from './Reducer'

const EditedRows = createContext()

const LineContext = ({ children }) => {
  const [state, dispatch] = useReducer(editedRowsReducer, {
    editArray: []
  })

  return (
    <EditedRows.Provider value={{ state, dispatch }}>
      {children}
    </EditedRows.Provider>
  )
}

export default LineContext

export const EditedRowState = () => {
  return useContext(EditedRows)
}
