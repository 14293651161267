export const vendorSideBarEN = {
  Dashboard: 'Dashboard',
  MyTasks: 'My Tasks',
  MyOpenPOs: 'My Open POs',
  POConfirmOrders: 'PO Confirm Orders',
  POReConfirmOrders: 'PO ReConfirm Orders',
  POChangeRequests: 'PO Change Requests',
  UrgentInquiry : 'Urgent Inquiry',
  Logout: 'Logout',
  DeferOrders: 'Defer Orders',
  DivertOrders: 'Divert Orders',
  ExpediteOrders: 'Expedite Orders'
}
export const VendorSideBarMN = {
  Dashboard: '仪表板',
  MyTasks: '我的任务',
  MyOpenPOs: '我的待处理采购订单',
  POConfirmOrders: '采购订单确认',
  POReConfirmOrders: '采购订单重新确认',
  POChangeRequests: '采购订单变更请求',
  UrgentInquiry : '紧急查询',
  Logout: '登出',
  DeferOrders: '延迟订单',
  DivertOrders: '转移订单',
  ExpediteOrders: '加急订单'
}
