import React, { useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const SideBar = () => {
  const navigation = useNavigate()
  const [sidebarTranslate, setSideBar] = useState({})
  const user = JSON.parse(localStorage.getItem('userData'))
  const { t } = useTranslation()
  const {
    Overview,
    Open_Tasks,
    HFTChangeRequestStatus,
    POList,
    UrgentInquiry ,
    MyReport,
    Logout
  } = t('sidebar')

  // setTimeout(() => {
  //

  // }, 50);
  useLayoutEffect(() => {
    setSideBar(t('sidebar'))
  }, [])
  return (
    <div className='min-w-[220px] flex flex-col gap-5 justify-between min-sidebar-screen items-start h-full sticky top-0 bg-blue-100 overflow-y-auto'>
      <div className='w-full flex flex-col gap-3 h-full'>
        {/* <div className="px-4 py-2 bg-white">
          <img
            src="https://images.harborfreight.com/media/logos/harborfreight-logo.svg"
            width="100%"
            height="100%"
            alt="harbor_freight_logo"
            className="object-contain w-[165px] h-[53px]"
          />
        </div> */}
        <div className='w-full flex flex-col justify-start h-full relative items-start gap-4 border-[#E6EDFF] border-r py-5'style={{ fontWeight: 500 }}>
          <div
            key={'buyer'}
            className={`w-full justify-between flex text-xs items-center cursor-pointer hover:bg-[#0059A8] group ${
              window?.location?.pathname === '/buyer' && 'bg-[#0059A8]'
            }`}
            onClick={() => {
              navigation('/buyer')
            }}
          >
            <p
              className={`text-black p-2 pl-4 group-hover:text-white  ${
                window?.location?.pathname === '/buyer' && 'text-white'
              }`}
            >
              {Overview}
            </p>
          </div>
          {/* <div
            key={'buyer/open_tasks'}
            className={`w-full justify-between flex text-xs items-center cursor-pointer hover:bg-[#0059A8] group ${
              window?.location?.pathname.includes('buyer/open_tasks') &&
              'bg-[#0059A8]'
            } ${user?.userGroupName === "OVERSEAS_ROLE"  && 'hidden'}
            `}
            onClick={() => {
              navigation('/buyer/open_tasks')
            }}
          >
            <p
              className={`text-black p-2 pl-4 group-hover:text-white ${
                window?.location?.pathname.includes('buyer/open_tasks') &&
                'text-white'
              }`}
            >
              {Open_Tasks}
            </p>
          </div> */}
          <div
            key={'buyer/hft_buyer_change_requests'}
            className={`w-full justify-between flex text-xs items-center cursor-pointer hover:bg-[#0059A8] group ${
              window?.location?.pathname.includes(
                'buyer/hft_buyer_change_requests'
              ) && 'bg-[#0059A8]'
            } ${user?.userGroupName === "OVERSEAS_ROLE"  && 'hidden'}`}
            onClick={() => {
              navigation('/buyer/hft_buyer_change_requests')
            }}
          >
            <p
              className={`text-black p-2 pl-4 group-hover:text-white ${
                window?.location?.pathname.includes(
                  'buyer/hft_buyer_change_requests'
                ) && 'text-white'
              }`}
            >
              {HFTChangeRequestStatus}
            </p>
          </div>
          <div
            key={'buyer/po_list'}
            className={`w-full justify-between flex text-xs items-center cursor-pointer hover:bg-[#0059A8] group ${
              window?.location?.pathname.includes('buyer/po_list') &&
              'bg-[#0059A8]'
            } `}
            onClick={() => {
              navigation('/buyer/po_list')
            }}
          >
            <p
              className={`text-black p-2 pl-4 group-hover:text-white ${
                window?.location?.pathname.includes('buyer/po_list') &&
                'text-white'
              }`}
            >
              {POList}
            </p>
          </div>
          <div
            key={'buyer/urgent_orders'}
            className={`w-full justify-between flex text-xs items-center cursor-pointer hover:bg-[#0059A8] group ${
              window?.location?.pathname.includes('buyer/urgent_orders') &&
              'bg-[#0059A8]'
            } ${user?.userGroupName === "OVERSEAS_ROLE"  && 'hidden'}`}
            onClick={() => {
              navigation('/buyer/urgent_orders')
            }}
          >
            <p
              className={`text-black p-2 pl-4 group-hover:text-white ${
                window?.location?.pathname.includes('buyer/urgent_orders') &&
                'text-white'
              }`}
            >
              {UrgentInquiry}
            </p>
          </div>
          <div
            key={'buyer/my_report'}
            className={`w-full justify-between flex text-xs items-center cursor-pointer hover:bg-[#0059A8] group ${
              window?.location?.pathname.includes('buyer/my_report') &&
              'bg-[#0059A8]'
            } ${user?.userGroupName === "OVERSEAS_ROLE"  && 'hidden'}`}
            onClick={() => {
              navigation('/buyer/my_report')
            }}
          >
            <p
              className={`text-black p-2 pl-4 group-hover:text-white ${
                window?.location?.pathname.includes('buyer/my_report') &&
                'text-white'
              }`}
            >
              {MyReport}
            </p>
          </div>
        </div>
      </div>

  
    </div>
  )
}

export default SideBar
