import React, { useEffect, useState } from 'react';
import { handlePODetailsClick } from '../utils/Utils';
import { useNavigate } from 'react-router-dom';

const PopupBanner = ({ isOpen, message, onCancel, text = "", text1 = "", text2 = "", row }) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  // Get role from localStorage
  const role = localStorage.getItem('role');

  // Conditionally render null if component is not open or role is not set
  if (!isOpen || !role) return null;

  // Function to navigate based on role
  const navigateToDetails = () => {
    let path = '';

    if (role === 'buyer') {
      path = `/buyer/hft_buyer_change_requests/request_details/${text1}`;
    } else if (role === 'vendor') {
      path = `/vendor/po_change_requests/request_details/${text1}`;

    } else if (role === 'admin') {
      setErrorMessage('Admins cannot access this page.');
      return;
    } else {
      // Handle unexpected roles if necessary
      console.error('Invalid role:', role);
      setErrorMessage('Invalid role.');
      return;
    }

    navigate(path);
  };
  
  return (
    <div className="modal-overlay fixed text-sm z-30 w-1/3 max-w-xl min-w-min justify-center items-center bg-white shadow-lg shadow-black">
      <div
        className="fixed top-0 left-1/5 w-3/5 h-3.8rem bg-blue-100 text-black flex items-center justify-between p-4 z-50 text-lg"
        style={{ height: '3.8rem' }}
      >
        <div className="flex items-center">
          <span className='text-base'>{message}</span>
          {
            text1?.length === 1 &&
            <span
              className="text-blue-500 underline ml-2 cursor-pointer text-sm"
              onClick={() => handlePODetailsClick(role, text)}
            >
              {text}
            </span>
          }
          {
            text1?.length === 1 &&
            <span
              className="text-blue-500 underline ml-2 cursor-pointer text-sm"
              onClick={() => navigateToDetails(text1, text2)}
            >
              {`${text1} ${text2}`}
            </span>}
        </div>
        <div className="flex justify-end items-center">
          <button
            className="border-2 border-white-300 p-2 rounded-md text-sm"
            onClick={onCancel}
          >
            ❌
          </button>
        </div>
      </div>
      {errorMessage && (
        <div className="fixed top-20 left-1/5 w-3/5 bg-red-100 text-red-700 p-4 rounded-lg shadow-md">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default PopupBanner;
