import React from 'react'
import LoginErrorPage from '../screens/ErrorPage'

const LandingPage = ({ children }) => {
  return (
    <section className='bg-gray-50 dark:bg-gray-900 font-pop'>
      <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0'>
        <p className='flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white'>
          <img
            src='https://images.harborfreight.com/media/logos/harborfreight-logo.svg'
            width='100%'
            height='100%'
            alt='harbor_freight_logo'
            className='object-contain w-[165px] h-[53px]'
          />
        </p>

        <div className='w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 '>
       
          <div className='p-6  sm:p-8 text-center w-full flex gap-5 justify-center'>
            {children}
            {/* <LoginErrorPage/> */}
          </div>
        </div>
        
      </div>
    </section>
  )
}

export default LandingPage
