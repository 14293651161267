import React, { useState, useEffect } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  PaginationState,
  SortingState,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getFacetedRowModel
} from '@tanstack/react-table'
import {
  RankingInfo,
  rankItem,
  compareItems
} from '@tanstack/match-sorter-utils'
import axios from 'axios'

const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

const EditableTable = ({ tableData, columns }) => {
  const [data, setData] = useState(tableData ?? [])
  const [originalData, setOriginalData] = useState(() => [...tableData])
  const [sorting, setSorting] = useState([])
  const [selectedArray, setSelectedArray] = useState([])
  const [showConfirm, setShowConfirm] = useState(false)
  const [globalFilter, setGlobalFilter] = useState('')
  const [columnFilters, setColumnFilters] = useState([])
  const [EditRowData, setEditRowData] = useState({})
  const [rowSelection, setRowSelection] = useState(false)
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
      globalFilter,
      rowSelection
    },
    onRowSelectionChange: setRowSelection,
    meta: {
      EditRowData,
      setEditRowData,
      updateData(rowIndex, colIndex, value) {
        console.log(rowIndex, colIndex, value)
        setData((old) =>
          old?.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [colIndex]: value
              }
            }
            return row
          })
        )
      },
      revertData: (rowIndex, revert) => {
        // if (revert) {
        //   setData((old) =>
        //     old.map((row, index) =>
        //       index === rowIndex ? originalData[rowIndex] : row
        //     )
        //   );
        // } else {
        //   setOriginalData((old) =>
        //     old.map((row, index) => (index === rowIndex ? data[rowIndex] : row))
        //   );
        //
        // }
        if (revert) {
          // setData((prevFields) => ({
          //   ...prevFields,
          //   [rowIndex]: originalData[rowIndex],
          // }));
          return false
        } else return data[rowIndex]
      }
    },

    // filterFns: {
    //   fuzzy: fuzzyFilter,
    // },
    // enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getFacetedRowModel: getFacetedRowModel(),
    debugTable: true
  })

  //   const requiredOutput = () => {
  //     const selectedRowRaw = table?.getSelectedRowModel().flatRows;
  //     const requiredData = [];
  //     selectedRowRaw?.map((row, index) => requiredData.push(row?.original));
  //     selection(requiredData);
  //     return requiredData;
  //   };

  useEffect(() => {
    // console.log("data: ", tableData);
  }, [])

  //   useEffect(() => {
  //     console.log("selected actual data : ", requiredOutput());
  //   }, [rowSelection]);
  return (
    <div className='w-full flex justify-start items-start text-xs flex-col gap-4 max-h-screen'>
      <div className='w-full justify-between flex items-center text-xs'>
        <div>
          <input
            value={globalFilter ?? ''}
            onChange={(e) => setGlobalFilter(e.target.value)}
            className='p-1 py-2 rounded-sm border border-gray-300 outline-none'
            placeholder='Search all columns...'
          />
        </div>

        {selectedArray?.length > 0 && (
          <div className=''>
            <button
              className='bg-[#415da1] px-4 h-[30px] rounded-md outline-none text-white min-w-max'
              onClick={() => setShowConfirm(!showConfirm)}
            >
              Create Change Request
            </button>
          </div>
        )}
        {/*Pagination */}
        <div className='flex items-center gap-2'>
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <span className='min-w-max flex items-center gap-1'>
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </strong>
          </span>
          <span className='min-w-max flex items-center gap-1'>
            | Go to page:
            <input
              type='number'
              id='pagination_input'
              name='pagination_input'
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                table.setPageIndex(page)
              }}
              className='border p-1 rounded w-16'
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50, data?.length].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='w-full overflow-x-auto'>
        <table className='w-full border border-gray-300'>
          <thead className='sticky top-0'>
            {table?.getHeaderGroups()?.map((headerGroup, index) => (
              <tr
                key={headerGroup?.id + index}
                className='divide-x-[1px] bg-[#e0ecfc] divide-gray-300 border-[#ccd9e4] border'
              >
                {headerGroup?.headers?.map((header) => (
                  <th key={header?.id} className='font-medium px-2 py-3'>
                    {header.isPlaceholder ? null : (
                      <div
                        className='w-full flex flex-col items-center justify-center gap-2'
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler()
                        }}
                      >
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>

                        {header.column.getCanSort() ? (
                          <div className='w-full flex justify-center items-center'>
                            {{
                              asc: (
                                <svg
                                  class='w-2 h-3 text-gray-500'
                                  aria-hidden='true'
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 10 14'
                                >
                                  <path
                                    stroke='currentColor'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='M5 13V1m0 0L1 5m4-4 4 4'
                                  />
                                </svg>
                              ),
                              desc: (
                                <svg
                                  class='w-2 h-3 text-gray-500'
                                  aria-hidden='true'
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 10 14'
                                >
                                  <path
                                    stroke='currentColor'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='M5 1v12m0 0 4-4m-4 4L1 9'
                                  />
                                </svg>
                              )
                            }[header.column.getIsSorted()] ?? (
                              <svg
                                class='w-3 h-4 text-gray-500'
                                aria-hidden='true'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 16 20'
                              >
                                <path
                                  stroke='currentColor'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='2'
                                  d='M4 6v13m0 0 3-3m-3 3-3-3m11-2V1m0 0L9 4m3-3 3 3'
                                />
                              </svg>
                            )}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table?.getRowModel().rows?.map((row, index) => (
              <tr
                key={row?.id + index}
                className={`${
                  row?.id % 2 !== 0 ? 'bg-[#ecedf2]' : 'bg-[#ffffff]'
                } text-center divide-x-[1px] divide-gray-300 border border-gray-300`}
              >
                {row?.getVisibleCells()?.map((cell) => (
                  <td className='px-2 py-3'>
                    {flexRender(
                      cell?.column?.columnDef?.cell,
                      cell?.getContext()
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <pre>{JSON.stringify(data, null, "\t")}</pre> */}
    </div>
  )
}

export default EditableTable
