import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  ValidateAPI,
  getPossibleShipWeek,
  getStartDate,
  reasonCodesEN,
  reasonCodesMN,
  updateActionHistory
} from '../../utils/Utils'
import { getWeek } from '../../screens/buyer/NewPoList'
import axios from 'axios'
import { weeksInBetween } from '../../screens/vendor/ConfirmPo'
import Loading from '../Loading'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import PopupModal from '../PopupModal'

const ConfirmActionComp = ({ requestData }) => {
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [showRequestChangeModal, setShowRequestChangeModal] = useState(false)
  const [showAcceptDatePicker, setShowAcceptDatePicker] = useState(false)
  const [reasonArray, setReasonArray] = useState([])
  const [secondReason, setSecondReason] = useState('')
  const [requestSW, setRequestSW] = useState('')
  const [requestDC, setRequestDC] = useState('')
  const [comment, setComment] = useState('')
  // const dc_list = ["CAM", "DAL", "DIL", "MOR", "CHI"];
  const [dc_list, setDCList] = useState(
    JSON.parse(sessionStorage?.getItem('dcList'))
  )
  const [date, setDate] = useState(new Date())
  const selectedRowArray = [requestData]
  const user = JSON.parse(localStorage.getItem('userData'))
  const [submitting, setSubmitting] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [reason_codes, setReasonCode] = useState([])
  const { t } = useTranslation()
  const {
    SelectDC,
    SelectedSW,
    REQSW,
    Comment,
    PleaseselectReasoncodefortheselectedorders,
    Changetype,
    Selectatype,
    Shipweek,
    DC,
    Price,
    Quantity,
    ReasonCode,
    Selectareason,
    RequestChange,
    Cancel,
    Submitting_load
  } = t('ConfirmActionComp')

  const closeConfirmModal = () => {
    setShowConfirmModal(false)
  }

  const openConfirmModal = () => {
    setShowConfirmModal(true)
  }

  const handleConfirmPos = () => {
    const request_payload = []
    selectedRowArray?.map((row) => {
      const payload = {
        id_open_request: row?.id_open_request,
        vendorNumber: Number(row?.vendor_number),
        vendorName: row?.vendor_name,
        createdBy: row?.inventory_analyst,
        current_ship_week: row?.requested_ship_week,
        original_ship_week: row?.requested_ship_week,
        hft_user_id:0,
        supplier_contact_id:user?.vendorContactOrcaleId
      };
      request_payload?.push(payload);
    });
    console.log("Confirm Po payload: ", request_payload);
    setSubmitting(true);
    setShowRequestChangeModal(true)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-confirm-po`,
        request_payload,ValidateAPI
      )
      .then((response) => {
        console.log('response from confirm po: ', response?.data)
        updateActionHistory('Accepted', [requestData])
        setSubmitting(false)
        setShowRequestChangeModal(false)
        window.location?.reload();
        // alert("Orders confirmed successfully!");
        alert(i18next.t('VendorPopUp.Ordersconfirmedsuccessfully'))
        closeConfirmModal()
      })
      .catch((error) => {
        console.log('error while confirming PO: ', error)
        setSubmitting(false)
      }
    )
  }

  const rejectPop = () => {
    const rejectPosubmit = window.confirm(
      i18next.t('VendorPopUp.confirmChangesRequest')
    )
    if (rejectPosubmit) {
      setShowRequestChangeModal(true)
    }
  }

  const editConfirm = () => {
    const req_payload = []
    if (reasonArray?.length > 0) {
      const reasons = reasonArray.join(', ')
      if (requestSW?.length > 0 || requestDC?.length > 0) {
        selectedRowArray?.map((item) => {
          const req_obj = {
            hft_cr_id: item?.id_open_request,
            requested_dc: requestDC,
            reason_code_dc: secondReason === 'dc' ? reasons : '',
            requested_ship_week: item?.requested_ship_week ?? '',
            reason_code_ship_week: secondReason === 'sw' ? reasons : '',
            supplier_response: 'ACCEPTED',
            current_ship_week: requestSW ? requestSW : item?.requested_ship_week,
            status: 'OPEN',
            followups: 1,
            vendor_selected_ship_week: requestSW,
            original_ship_week: requestSW ?requestSW : item?.requested_ship_week,
            ship_week_delay: Math.abs(
              weeksInBetween(requestSW ?? '', item?.requested_ship_week)
            ),
            vendorName: item?.vendor_name,
            createdBy: item?.inventory_analyst,
            requestType: "Confirm",
            hft_user_id:0,
            supplier_contact_id:user?.vendorContactOrcaleId
          };
          return req_payload?.push(req_obj);
        });
        console.log("payload before edit confirm: ", req_payload);
        setSubmitting(true);
        axios
          .put(
            `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-edit-confirm-po`,
            req_payload,ValidateAPI
          )
          .then((response) => {
            console.log('response from editconfirm: ', response?.data)
            setRequestDC('')
            setRequestSW('')
            setSubmitting(false)
            setShowRequestChangeModal(false)
            setSecondReason('')
            setReasonArray([])
            // alert("changes made successfully.");
            alert(i18next.t('VendorPopUp.changesmadesuccessfully'))
            updateActionHistory('Accepted', [requestData])

            window.location.reload()
          })
          .catch((error) => {
            console.log('error while submitting edit confirm: ', error)
            setSubmitting(false)
          })
      } else {
        // alert("Please add new value before submitting.");
        alert(i18next.t('VendorPopUp.Pleaseaddnewvaluebeforesubmitting'))
      }
    } else {
      alert(
        i18next.t(
          'VendorPopUp.PleaseselectReasonCodeChangeTypebeforesubmitting'
        )
      )
    }
  }

  const handleRejectPos = () => {
    const request_payload = []
    const comments_payload = []
    if (!reasonArray?.length > 0)
      return alert(i18next.t('VendorPopUp.Pleaseselectareasoncode'))
    if (!comment)
      return alert(i18next.t('VendorPopUp.Pleaseaddcommentbeforesubmitting'))
    const reasons = reasonArray.join(', ')
    selectedRowArray?.map((row) => {
      const payload = {
        id_open_request: row?.id_open_request,
        vendorNumber: Number(row?.vendor_number),
        reasonCodeShipWeek: reasons,
        vendorName: row?.vendor_name,
        createdBy: row?.inventory_analyst,
        original_ship_week: ''
      }
      const comment_indi = {
        hftcrid: row?.id_open_request,
        po_number: row?.po_number,
        added_by_role_name: user?.roleName?.split('_')?.[0],
        added_by_name: user?.fullName,
        comment: comment,
        type: 'Comment',
        SKU :row?.sku
      }
      request_payload?.push(payload)
      comments_payload?.push(comment_indi)
    })

    console.log('Reject Po payload: ', request_payload)
    console.log('Reject comments payload: ', comments_payload)
    setSubmitting(true)
    setShowRequestChangeModal(true)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-confirm-reject-po`,
        request_payload,ValidateAPI
      )
      .then((response) => {
        console.log('response from reject po: ', response?.data)
        setShowRequestChangeModal(false)
        setReasonArray([])
        setComment('')

        axios
          .post(
            `${process.env.REACT_APP_API_DOMAIN}:8088/api/create-cr-activity`,
            comments_payload,ValidateAPI
          )
          .then((response) => {
            console.log('response from submitting comment: ', response?.data)
            window.location?.reload()
            setSubmitting(false)
            alert(i18next.t('VendorPopUp.Ordersconfirmedsuccessfully'))
          })
          .catch((error) => {
            console.log('error from comments submission: ', error)
            setSubmitting(false)
          })
      })
      .catch((error) => {
        console.log('error while reject PO: ', error)
      })
  }

  const renderBlock = (reason) => {
    if (reason === 'dc') {
      return (
        <div className='flex flex-col space-y-2 text-left'>
          <label
                    htmlFor='dc delect'
                    className='text-xs font-medium'
                  >
                    Please Select DC
                  </label>
        <select
          className='w-full border border-gray-300 p-2 rounded-md text-xs'
          onChange={(e) => setRequestDC(e?.target?.value)}
        >
          <option value='' selected>{SelectDC}</option>
          {dc_list?.map((data, index) => (
            <option value={data?.DC_NAME} key={data + index}>
              {data?.DC_NAME}
            </option>
          ))}
        </select>
         <div className='flex items-center p-2'>
         <p className='text-xs'>REQ DC : </p>
         <p className='text-xs ml-2'>
           <span className='font-medium p-1 px-2 border border-gray-300 rounded-md text-xs'>
           {requestData && requestData.original_dc
}
           </span>
         </p>
       </div>
       </div>
      )
    } else if (reason === 'sw') {
      return (
        <div className='flex flex-col  text-left space-y-2'>
          <label
                    htmlFor='dc delect'
                    className='text-xs font-medium'
                  >
                    Please Select Ship Week
                  </label>
          <DatePicker
            showWeekNumbers
            filterDate={(date) => date.getDay() === 1}
            selected={date}
            onChange={(date) => handleChangeDate(date)}
            className='p-1 px-2 border border-gray-300 rounded-md text-xs'
            calendarStartDay={1}
            showYearDropdown
            minDate={getStartDate(getPossibleShipWeek(requestData.requested_ship_week))}
          />
          {requestSW && (
            <div>
              <p className='text-xs'>
                {SelectedSW} <span className='font-medium'>{requestSW}</span>
              </p>
            </div>
          )}
          <div class='flex  p-2'>
            <p class='text-xs'>{REQSW}: </p>
            <p class='text-xs'>
              <span class='font-medium p-1 px-2 border border-gray-300 rounded-md text-xs'>
                {/* {requestData} */}
                {requestData && requestData.requested_ship_week}
              </span>
            </p>
          </div>
        </div>
      )
    } else {
      return (
        <div className='w-full flex flex-col gap-2 text-left'>
          <label htmlFor='sr_comment' className='text-xs font-medium'>
            {Comment}
          </label>
          <textarea
            id='sr_comment'
            className='w-full border border-gray-300 p-2 rounded-md text-xs'
            placeholder='Type here in detail...'
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      )
    }
  }

  const handleChangeDate = (date) => {
    setDate(date)
    setRequestSW(getWeek(date))
  }

  const handleReasonChange = (selectedReason) => {
    if (!reasonArray.includes(selectedReason)) {
      setReasonArray((prevReasonArray) => [...prevReasonArray, selectedReason])
    }
  }
  useEffect(() => {
    const handleStorage = () => {
      if (window.localStorage?.getItem('language') === 'en') {
        setReasonCode(reasonCodesEN)
      } else {
        setReasonCode(reasonCodesMN)
      }
    }

    window.addEventListener('storage', handleStorage())
    return () => window.removeEventListener('storage', handleStorage())
  }, [window.localStorage?.getItem('language')])
  return (
    <div className='w-full flx flex-col gap-2 justify-center items-center'>
      <div className='flex gap-2 justify-center items-center'>
        <button onClick={openConfirmModal}>
          <svg
            className='w-6 h-6 text-green-600 '
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            viewBox='0 0 20 20'
          >
            <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
          </svg>
        </button>
        <PopupModal
          isOpen={showConfirmModal}
          message={i18next.t('PopUpMessage.Doyouwanttoconfirmtheseorders')}
          onCancel={closeConfirmModal}
          cancelText={i18next.t('AcceptModal.Cancel')}
          onConfirm={handleConfirmPos}
          confirmText={i18next.t('AcceptModal.Confirm')}
        />
        <button onClick={() => setShowRequestChangeModal(true)}>
          <svg
            className='w-6 h-6 text-white bg-black p-1 border border-slate-900 rounded-full font-bold'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
          >
            <path
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='3'
              d='M13.5 9.2 15 7h5m0 0-3-3m3 3-3 3M4 17h4l1.6-2.3M4 7h4l7 10h5m0 0-3 3m3-3-3-3'
            />
          </svg>
        </button>
      </div>

      {/*Request Change Dialog Box */}
      <dialog
        open={showRequestChangeModal}
        className='p-3 inset-0 fixed z-50 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          {submitting ? (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <Loading type={Submitting_load} />
            </div>
          ) : (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <p className='w-full text-center'>
                {PleaseselectReasoncodefortheselectedorders}
              </p>

              <div className='w-full flex flex-wrap justify-center items-center gap-3'>
                {reasonArray?.map((reason, index) => (
                  <div
                    className='rounded-lg bg-[#4472C4] flex gap-2 justify-center items-center p-2 text-xs text-white font-medium'
                    key={index}
                  >
                    <span>{reason}</span>
                    <span
                      className='cursor-pointer'
                      onClick={() =>
                        setReasonArray(reasonArray?.filter((e) => e !== reason))
                      }
                    >
                      X
                    </span>
                  </div>
                ))}
              </div>
              <div className='w-full flex flex-col gap-2 text-left'>
                <label htmlFor='change_type_dd' className='text-xs font-medium'>
                  {Changetype}
                </label>
                <select
                  onChange={(e) => setSecondReason(e?.target?.value)}
                  id='change_type_dd'
                  className='p-2 rounded-md outline-none border border-gray-300 text-xs w-full font-medium'
                  value={secondReason}
                >
                  <option value='' disabled>
                    {Selectatype}
                  </option>
                  <option value='dc'>{DC}</option>
                  <option value='sw'>{Shipweek}</option>
                  <option value='price'>{Price}</option>
                  <option value='qty'>{Quantity}</option>
                </select>
              </div>
              {secondReason?.length > 0 && (
                <div className='w-full flex flex-col items-start gap-2'>
                  <label
                    htmlFor='reason_code_select'
                    className='text-xs font-medium'
                  >
                    {ReasonCode}
                  </label>
                  <select
                    onChange={(e) => handleReasonChange(e.target.value)}
                    name='reason_code_select'
                    id='reason_code_select'
                    className='p-2 rounded-md outline-none border border-gray-300 text-xs w-full font-medium'
                    value=''
                  >
                    <option value='' disabled>
                      {Selectareason}
                    </option>
                    {reason_codes?.map((reason) => (
                      <option key={reason} value={reason}>
                        {reason}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className='w-full'>
                {secondReason?.length > 0 &&
                  reasonArray?.length > 0 &&
                  renderBlock(secondReason)}
              </div>
              <div className='w-full flex justify-center items-center gap-4 mt-4'>
                {secondReason &&
                  (secondReason === 'dc' || secondReason === 'sw' ? (
                    <button
                      className='text-xs font-medium px-2 rounded-md h-[38px] text-white bg-[#415da1] outline-none'
                      onClick={editConfirm}
                    >
                      {RequestChange}
                    </button>
                  ) : (
                    <button
                      className='text-xs font-medium px-2 rounded-md h-[38px] border border-black outline-none'
                      onClick={handleRejectPos}
                    >
                      {RequestChange}
                    </button>
                  ))}
                <button
                  className='text-xs font-medium px-2 rounded-md h-[38px] text-white bg-black outline-none'
                  onClick={() => {
                    setShowRequestChangeModal(false)
                    setReasonArray([])
                    setRequestSW('')
                    setSecondReason('')
                  }}
                >
                  {Cancel}
                </button>
              </div>
            </div>
          )}
        </div>
      </dialog>
    </div>
  )
}

export default ConfirmActionComp
