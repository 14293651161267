import React, { useEffect, useMemo, useRef, useState } from 'react'
import UserProfile from '../../components/UserProfile'
import axios from 'axios'
import {
  handlePODetailsClick,
  checkPd,
  handleCRDetailsClick,
  Filter,
  getMondayDate,
  downloadCSV,
  updateActionHistory,
  MultiSelectColumnFilter,
  reasonCodesEN,
  reasonCodesMN,
  getPresentShipWeek,
  getStartDate,
  getPossibleShipWeek,
  ValidateAPI
} from '../../utils/Utils'
import { IndeterminateCheckbox, getWeek } from '../buyer/NewPoList'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues
} from '@tanstack/react-table'
import DatePicker from 'react-datepicker'
import { weeksInBetween } from './ConfirmPo'
import { handleCRHistoryClick } from '../../utils/Utils'
import Loading from '../../components/Loading'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import PopupModal from '../../components/PopupModal'
import PopupBanner from '../../components/PopupBanner'
import { EditedRowState } from '../../LineContext/Context'
import {
  MessageIconRead,
  MessageIconUnread
} from '../../components/MessageIcon'
import MessageBox from '../../components/MessageBox'
import { useNavigate } from 'react-router'
import Cookies from 'js-cookie'

const FilterTypes = {
  ALL: 'All',
  NO_CR: 'No CR',
  CR: 'CR',
  PAST_DUE: 'Past Due'
}

const MyPos = () => {
  const [showFilters, setShowFilters] = useState(false)
  const [searching, setSearching] = useState(false)
  const [data, setData] = useState([])
  const user = JSON.parse(localStorage.getItem('userData'))
  const vendorNumber = user?.supplierId
  const [loading, setLoading] = useState(false)
  const [sorting, setSorting] = useState([])
  const [rowSelection, setRowSelection] = useState({})
  const [showSelectColumn, setShowSelectColumn] = useState(false)
  const [selectedArray, setSelectedArray] = useState([])
  const [cancelTokenSource, setCancelTokenSource] = useState(null)
  const [showDelayModal, setShowDelayModal] = useState(false)
  const [showCreateLineRequestModal, setShowCreateLineRequestModal] =
    useState(false)
  const [date, setDate] = useState(new Date())
  const [requestSW, setRequestSW] = useState('')
  const [selectedPOForMessageBox, setSelectedPOForMessageBox] = useState(null)
  const [selectedSKUForMessageBox, setSelectedSKUForMessageBox] = useState(null)
  const [messageCounts, setMessageCounts] = useState([])
  const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false)
  const [editedRows, setEditedRows] = useState([])
  const updateEditedRows = (updatedRows) => {
    setEditedRows(updatedRows)
  }
  const [openpoNumberList, setOpenPonumberList] = useState(false)
  const [searchpoNumber, setSearchpoNumber] = useState('')
  const [poNumberlist, setpoNumberList] = useState([])

  const [crIdResponse, setCrIdResponse] = useState([])
  const [columnOrder, setColumnOrder] = useState(
    localStorage.getItem('po_list_order') ?? []
  )
  const [columnVisibility, setColumnVisibility] = useState(
    JSON.parse(localStorage.getItem('toggle_columns')) ?? {}
  )
  const [dcList, setDcList] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [showChangeRequestModal, setShowChangeRequestModal] = useState(false)
  const [requestPayload, setRequestPayload] = useState([])
  const [fields, setFields] = useState({
    poNumber: '',
    sku: '',
    vendorNumber: '',
    upc: '',
    dc: '',
    vendorManager: '',
    inventoryAnalyst: '',
    shipOff: ''
  })
  const navigate = useNavigate()
  //notifybar
  const [showChangeRequestBanner, setShowChangeRequestBanner] = useState(false)
  const [poRow, setPoRow] = useState(null)

  const [rawData, setRawData] = useState([])
  const [dataFilter, setDataFilter] = useState(FilterTypes.ALL)

  const [columnFilters, setColumnFilters] = useState([])
  const [values, setValues] = useState([])
  //delay reason code
  const [selectedReason, setSelectedReason] = useState('')
  const [reasonArray, setReasonArray] = useState([])

  const [reason_codes, setReasonCode] = useState([])

  const [columnResizeMode, setColumnResizeMode] = React.useState('onChange')

  const [columnResizeDirection, setColumnResizeDirection] =
    React.useState('ltr')

  const [ClearState, setClearState] = useState(false)

  const handleReasonChange = (selectedReason) => {
    if (!reasonArray.includes(selectedReason)) {
      setReasonArray((prevReasonArray) => [...prevReasonArray, selectedReason])
    }
    setSelectedReason(selectedReason)
  }

  const handleRemoveReason = (reason) => {
    setReasonArray(reasonArray.filter((e) => e !== reason))
    setSelectedReason('')
  }
  const [lang, setlang] = useState(window.localStorage?.getItem('language'))
  // console.log(lang);

  useEffect(() => {
    const handleStorage = () => {
      if (window.localStorage?.getItem('language') === 'en') {
        setReasonCode(reasonCodesEN)
      } else {
        setReasonCode(reasonCodesMN)
      }
    }

    window.addEventListener('storage', handleStorage())
    return () => window.removeEventListener('storage', handleStorage())
  }, [window.localStorage?.getItem('language')])

  const {
    state: { editArray },
    dispatch
  } = EditedRowState()

  function selectTableRow(rowId) {
    table.getRowById(rowId)?.toggleSelected()
  }

  const EditableSw = ({
    row,
    updatedRows,
    selectTableRow,
    rowSelection,
    updateRowSelection
  }) => {
    const [editing, setEditing] = useState(false)
    const [value, setValue] = useState('')
    const [date, setDate] = useState(new Date())
    const {
      state: { editArray },
      dispatch
    } = EditedRowState()
    const inputRef = useRef(null)

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
          setEditing(false)
        }
      }

      document.body.addEventListener('click', handleClickOutside)

      return () => {
        document.body.removeEventListener('click', handleClickOutside)
      }
    }, [])

    const handleEdit = () => {
      if (
        editArray?.some((c) => c?.SKU === row?.original?.SKU) &&
        editArray?.some((c) => c?.PO_NUMBER === row?.original?.PO_NUMBER)
      ) {
        alert(i18next.t('PopUpMessage.YouvealreadychangedavalueinthisPOLine'))
      } else if (row?.original?.HFT_REQUEST) {
        alert(i18next.t('PopUpMessage.chnagePOLine'))
      } else if (
        row?.original?.CURRENT_SW == null &&
        row?.original?.ORG_SW === null
      ) {
        alert(i18next.t('PopUpMessage.POLinebyvendoryet'))
      } else {
        setEditing(true)
      }
    }

    const handleChangeDate = (date) => {
      setDate(date)
      setValue(getWeek(date))
      handleUpdate(getWeek(date))
      setEditing(false)
    }

    const resetValue = () => {
      setEditing(false)
      setValue(row?.original?.REQ_SW)
      dispatch({
        type: 'REMOVE_FROM_LIST',
        payload: {
          SKU: row?.original?.SKU,
          PO_NUMBER: row?.original?.PO_NUMBER
        }
      })
      updateRowSelection((prevSelection) => ({
        ...prevSelection,
        [row.id]: false
      }))
      setEditing(false)
    }

    const handleUpdate = (date) => {
      console.log('entire row data: ', row)
      if (row?.original?.CURRENT_SW !== date) {
        const updatedRow = { UPDATED_SW: date, ...row.original }
        console.log('updated row: ', updatedRow)
        dispatch({
          type: 'ADD_TO_LIST',
          payload: updatedRow
        })
        const updatedRowId = row.id
        updateRowSelection((prevSelection) => ({
          ...prevSelection,
          [updatedRowId]: !prevSelection[updatedRowId]
        }))
        console.log('is the row selected: ', row?.getIsSelected())
        setEditing(false)
      } else {
        setEditing(false)
      }
    }

    useEffect(() => {
      setValue(row?.original?.CURRENT_SW)
    }, [row?.original?.CURRENT_SW])

    return editing ? (
      <div className='flex flex-col justify-between items-center w-full'>
        <div className='border border-gray-300 rounded-md p-1 w-full relative' >
          <p>CURSW : {row?.original?.CURRENT_SW}</p>
          <DatePicker
            showWeekNumbers
            filterDate={(date) => date.getDay() === 1}
            selected={date}
            onChange={(date) => handleChangeDate(date)}
            className='p-1 z-20 w-full'
            showIcon
            scrollableYearDropdown
            popperClassName='react-datepicker__week-number'
            formatWeekDay={(day) => day.slice(0, 3)}
            calendarStartDay={1}
            showYearDropdown
            minDate={getStartDate(
              getPossibleShipWeek(row?.original?.CURRENT_SW)
            )}
            onBlur={() => {
              setEditing(false)
            }}
            autoFocus
          />
        </div>
        {editArray?.some((c) => c?.SKU === row?.original?.SKU) &&
          editArray?.some((c) => c?.PO_NUMBER === row?.original?.PO_NUMBER) &&
          row?.original?.REQ_SW !== value && (
            <div className='w-full justify-end items-center flex'>
              <button onClick={() => resetValue()}>
                <svg
                  className='w-6 h-6 text-gray-800 dark:text-white'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 24 24'
                >
                  <path
                    fillRule='evenodd'
                    d='M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z'
                    clipRule='evenodd'
                  />
                </svg>
              </button>
            </div>
          )}
      </div>
    ) : (
      <div className='flex justify-center items-center'>
        <p className='cursor-pointer p-2' onClick={handleEdit}>
          {editArray?.some((c) => c?.SKU === row?.original?.SKU) &&
            editArray?.some((c) => c?.PO_NUMBER === row?.original?.PO_NUMBER)
            ? value
            : row?.original?.CURRENT_SW}
        </p>
        {editArray?.some((c) => c?.SKU === row?.original?.SKU) &&
          editArray?.some((c) => c?.PO_NUMBER === row?.original?.PO_NUMBER) &&
          row?.original?.CURRENT_SW !== value && (
            <div>
              <button onClick={() => resetValue()}>
                <svg
                  className='w-6 h-6 text-gray-800'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 24 24'
                >
                  <path
                    fillRule='evenodd'
                    d='M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z'
                    clipRule='evenodd'
                  />
                </svg>
              </button>
            </div>
          )}
      </div>
    )
  }

  const { t } = useTranslation()
  const {
    Filters,
    PO,
    NoDataFound,
    SKU,
    UPC,
    DC,
    InventoryAnalyst,
    VendorManager,
    ShippingOffice,
    SpecialSKUs,
    Loading1,
    ClearFilters,
    Columns,
    ToggleAll,
    Page,
    Gotopage,
    Show,
    Submitting_load,
    HFTCR,
    SelectShipDate,
    SelectedShipWeekValue,
    Submit,
    Cancel,
    CreateDelayRequest,
    Select,
    DelayOrder,
    CreateRequest,
    CURRSW,
    ReasonCode,
    Selectareason
  } = t('POList')
  // console.log(t('POList'))
  const headers = {
    PONUMBER: t('POList.PONUMBER'),
    SKU: t('POList.SKU'),
    DESCRIPTION: t('POList.DESCRIPTION'),
    UPC: t('POList.UPC'),
    MESSAGE: t('POList.MESSAGES'),
    REVNO: t('POList.REVISIONNUMBER'),
    DC: t('POList.DC'),
    REQSW: t('POList.REQSW'),
    ORGSW: t('POList.ORGSW'),
    CURRSW: t('POList.CURRENTSW'),
    ONTIMETARGET: t('POList.ONTIMETARGET'),
    DELTA: t('POList.DELTA'),
    UNITCOST: t('POList.UNITCOST'),
    EXTENDEDCOST: t('POList.EXTENDEDCOST'),
    VENDOR: t('POList.VENDOR'),
    VENDORNAME: t('POList.VENDORNAME'),
    VENDORMANAGER: t('POList.VENDORMANAGER'),
    SHIPOFFICE: t('POList.SHIPPINGOFFICE'),
    SHIPqty: t('POList.SHIPPEDQUANTITY'),
    INVENTORYANALYST: t('POList.INVENTORYANALYST'),
    HEADERDESC: t('POList.POHEADERDESCRIPTION'),
    SPECIALSKU: t('POList.SPECIALSKU'),
    POQUANTITY: t('POList.POQUANTITY'),
    VENDORMESSAGE: t('POList.VENDORMESSAGE'),
    BUYERMESSAGE: t('POList.BUYERMESSAGE'),
    POSKU: t('POList.POSKU'),
    ACTIONTYPE: t('POList.ACTIONTYPE'),
    REASONCODE: t('POList.REASONCODE'),
    DATELASTUPDATED: t('POList.DATELASTUPDATED'),
    ONTARGETSW: 'ON TARGET SW'
  }
  const columns = useMemo(
    () => [
      {
        header: headers.ACTIONTYPE,
        accessorKey: 'REQUEST_TYPE',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.POSKU,
        accessorKey: 'PO_SKU',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
        // size: 250,
      },
      // {
      //   header: headers.ACTIONTYPE,
      //   accessorKey: 'REQUEST_TYPE',
      //   filterFn: (row, id, value) => {
      //     return value.includes(row.getValue(id))
      //   }
      // },
      {
        header: headers.PONUMBER,
        accessorKey: 'PO_NUMBER',
        cell: (info) => {
          return (
            <p
              className='hover:underline cursor-pointer text-blue-500'
              onClick={() => handlePODetailsClick('vendor', info?.getValue())}
            >
              {info?.getValue()}
            </p>
          )
        },
        Filter: MultiSelectColumnFilter,
        filter: 'multiSelect',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },

      {
        header: headers.SKU,
        accessorKey: 'SKU',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.DESCRIPTION,
        accessorKey: 'DESCRIPTION',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.UPC,
        accessorKey: 'UPC',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.BUYERMESSAGE,
        accessorKey: 'BUYER_MESSAGE',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.VENDORMESSAGE,
        accessorKey: 'VENDOR_MESSAGE',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.REVNO,
        accessorKey: 'REV#'
      },
      {
        header: headers.DC,
        accessorKey: 'DC',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.REQSW,
        accessorKey: 'REQ_SW',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.ORGSW,
        accessorKey: 'ORG_SW',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CURRSW,
        accessorKey: 'CURRENT_SW',
        cell: ({ row }) => (
          <EditableSw
            row={row}
            updatedRows={updateEditedRows}
            selectTableRow={selectTableRow}
            rowSelection={rowSelection}
            updateRowSelection={setRowSelection}
          />
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.ONTIMETARGET,
        accessorKey: 'ONTARGET_SHIP_WK',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.DELTA,
        accessorKey: 'DELTA',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.UNITCOST,
        accessorKey: 'UNIT_COST'
      },
      {
        header: headers.EXTENDEDCOST,
        accessorKey: 'EXTENDED_COST'
      },
      {
        header: headers.VENDORMANAGER,
        accessorKey: 'VENDOR_MANAGER',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.SHIPOFFICE,
        accessorKey: 'SHIPPING_OFFICE',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.SHIPqty,
        accessorKey: 'SHIP_QTY'
      },
      {
        header: headers.SPECIALSKU,
        accessorKey: 'SPECIAL_SKUS',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.INVENTORYANALYST,
        accessorKey: 'INVENTORY_ANALYST',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.HEADERDESC,
        accessorKey: 'PO_HEADER_DESC',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.POQUANTITY,
        accessorKey: 'QUANTITY'
      },
      {
        header: headers.REASONCODE,
        accessorKey: 'REASON_CODE',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.DATELASTUPDATED,
        accessorKey: 'DATE_LAST_UPDATED',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      }
    ],
    // [t]
    Object.values(headers)
  )

  // const defaultColumn = {
  //   width: 150, // Default width for all columns
  // };
  const table = useReactTable({
    data,
    columns,
    columnResizeMode,
    columnResizeDirection,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection,
      columnFilters,
      sorting,
      // columnOrder: columnOrder,
      columnVisibility
    },
    enableRowSelection: (row) => row?.original?.HFT_REQUEST === null && row?.original?.CURRENT_SW !== null && row?.original?.ONTARGET_SHIP_WK !== null,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onColumnVisibilityChange: setColumnVisibility,
    // defaultColumn
    // onColumnOrderChange: (newColumnOrder) => {
    //   setColumnOrder(newColumnOrder);
    // },
    // debugTable: true,
  })

  let columnBeingDragged

  const closeCRModal = () => {
    setRequestSW('')
    setDate(new Date())
    setShowDelayModal(false)
    setSelectedArray([])
    setReasonArray([])
    table.resetRowSelection()

  }

  // const handleInputChange = (fieldName, value) => {
  //   setFields((prevFields) => ({
  //     ...prevFields,
  //     [fieldName]: value
  //   }))
  // }
  const getStartDate = (weekString) => {
    // console.log('value received in getstartdate: ', weekString)
    const [week, year] = weekString?.split('.').map(Number)
    const januaryFirst = new Date(year, 0, 1)
    const dayOffset = (8 - januaryFirst.getDay()) % 7
    const calculatedDate = new Date(
      januaryFirst.getTime() +
      (week - 1) * 7 * 24 * 60 * 60 * 1000 +
      dayOffset * 24 * 60 * 60 * 1000
    )
    calculatedDate.setFullYear(year + 2000)
    // console.log('Calculated Date:', calculatedDate)
    return calculatedDate
  }
  const handleClearSearch = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Search canceled by user')
    }
    setFields({
      poNumber: '',
      sku: '',
      upc: '',
      dc: '',
      vendorManager: '',
      inventoryAnalyst: ''
    })
    fetchPoData()
  }

  const closeCreateLineRequestModal = () => {
    setShowCreateLineRequestModal(false)
  }

  const openCreateLineRequestModal = () => {
    setShowCreateLineRequestModal(true)
  }

  const handleCreateLineRequests = (e) => {
    e?.preventDefault()
    const req_payload = []
    editArray?.map((item) => {
      const req_obj = {
        id_po_supplier_portal: 1,
        po_number: Number(item?.PO_NUMBER),
        po_line_number: 1,
        revision_number: Number(item?.['REV#']),
        sku: item?.SKU,
        upc: item?.UPC,
        vendor_number: item?.['VENDOR#'],
        vendor_name: item?.VENDOR_NAME,
        request_type: `Delay`,
        description: item?.DESCRIPTION,
        original_dc: item?.DC,
        requested_dc: '',
        reason_code_dc: '',
        original_ship_week: item?.ORG_SW,
        requested_ship_week: item?.REQ_SW,
        reason_code_ship_week: '',
        current_ship_week: item?.UPDATED_SW,
        original_qty: item?.QUANTITY,
        requested_qty: item?.QUANTITY,
        reason_code_qty: '',
        hft_user_id: 0,
        created_by: item?.VENDOR_NAME,
        supplier_contact_id: user?.vendorContactOrcaleId,
        supplier_response: 'Vendor Initiated',
        status: 'OPEN',
        followups: 1,
        ontimetarget: item?.ONTARGET_SHIP_WK,
        inventory_analayst: item?.INVENTORY_ANALYST,
        shipweekdelay: Math.abs(
          weeksInBetween(item?.ONTARGET_SHIP_WK, item?.UPDATED_SW)
          // weeksInBetween(item?.ONTARGET_SHIP_WK ?? '', requestSW ?? '')
        )
      }
      req_payload?.push(req_obj)
    })
    console.log('payload before change Request: ', req_payload)
    console.log('Array payload: ', req_payload)
    setSubmitting(true)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/create-request`,
        req_payload,
        ValidateAPI
      )
      .then((response) => {
        console.log('response from CR:', response?.data)
        const poRow = requiredOutput()
        console.log('row dc : ', poRow[0]?.PO_NUMBER)
        console.log('selected actual data : ', requiredOutput())
        setPoRow(poRow[0]?.PO_NUMBER || 'NULL')

        if (response?.data) {
          const crIdResponse = response?.data?.map(
            (item) => item.id_open_request
          )
          console.log('crIdResponse:', crIdResponse)
          setCrIdResponse(crIdResponse)
        }
        setSubmitting(false)
        setShowChangeRequestBanner(true) //notify bar
        if (
          fields?.poNumber?.trim() !== '' ||
          fields?.sku?.trim() !== '' ||
          fields?.upc?.trim() !== '' ||
          fields?.dc?.trim() !== '' ||
          fields?.inventoryAnalyst?.trim() !== '' ||
          fields?.vendorManager?.trim() !== '' ||
          fields?.shipOff?.trim() !== ''
        ) {
          handleSearch()
        } else {
          fetchPoData()
        }
        updateActionHistory('Created', response?.data)
        updateActionHistory('Vendor Initiated', response?.data)
        setRowSelection({})
        closeCRModal()
        dispatch({
          type: 'CLEAR_LIST'
        })
      })
      .catch((error) => {
        console.log('error while creating request: ', error)
        alert(i18next.t('VendorPopUp.ErrorwhilecreatingrequestPleasetryagain'))
        setSubmitting(false)
        closeCRModal()
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
    closeCreateLineRequestModal()
  }

  const closePopUpModal = () => {
    setShowChangeRequestBanner(false)
  }

  const closeChangeRequestModal = () => {
    setShowChangeRequestModal(false)
  }

  const handleDelayRequest = (e) => {
    e?.preventDefault()
    if (!selectedReason) {
      alert('Please select a reason code')
      return
    }

    const req_payload = []
    if (requestSW?.length > 0) {
      selectedArray?.map((item) => {
        const req_obj = {
          id_po_supplier_portal: 1,
          po_number: Number(item?.PO_NUMBER),
          po_line_number: 1,
          revision_number: Number(item?.['REV#']),
          sku: item?.SKU,
          upc: item?.UPC,
          vendor_number: item?.['VENDOR#'],
          vendor_name: item?.VENDOR_NAME,
          request_type: `Delay`,
          description: item?.DESCRIPTION,
          original_dc: item?.DC,
          requested_dc: '',
          reason_code_dc: '',
          original_ship_week: item?.ORG_SW,
          requested_ship_week: item?.REQ_SW,
          reason_code_ship_week: selectedReason,
          current_ship_week: requestSW,
          original_qty: item?.QUANTITY,
          requested_qty: item?.QUANTITY,
          reason_code_qty: '',
          hft_user_id: 0,
          created_by: item?.VENDOR_NAME,
          supplier_contact_id: user?.vendorContactOrcaleId,
          supplier_response: 'Vendor Initiated',
          status: 'OPEN',
          followups: 1,
          ontimetarget: item?.ONTARGET_SHIP_WK,
          inventory_analayst: item?.INVENTORY_ANALYST,
          shipweekdelay: Math.abs(
            weeksInBetween(item?.ONTARGET_SHIP_WK, requestSW)
          )
        }
        req_payload?.push(req_obj)
      })
      console.log('payload before change Request: ', req_payload)
      console.log('Array payload: ', req_payload)
      setRequestPayload(req_payload)
      setShowChangeRequestModal(true)
      setShowDelayModal(true)
    } else {
      alert(i18next.t('VendorPopUp.Therearenochangesmadetosubmit'))
    }
  }

  const handleConfirmDelay = () => {
    setSubmitting(true)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/create-request`,
        requestPayload,
        ValidateAPI
      )
      .then((response) => {
        console.log('response from CR:', response?.data)
        const poRow = requiredOutput()
        console.log('row dc : ', poRow[0]?.PO_NUMBER)
        console.log('selected actual data : ', requiredOutput())
        setPoRow(poRow[0]?.PO_NUMBER || 'NULL')

        if (response?.data) {
          const crIdResponse = response?.data?.map(
            (item) => item.id_open_request
          )
          console.log('crIdResponse:', crIdResponse)
          setCrIdResponse(crIdResponse)
        }

        setSubmitting(false)
        setShowDelayModal(false)
        setShowChangeRequestBanner(true) //notify bar
        setReasonArray([])

        if (
          fields?.poNumber?.trim() !== '' ||
          fields?.sku?.trim() !== '' ||
          fields?.upc?.trim() !== '' ||
          fields?.dc?.trim() !== '' ||
          fields?.inventoryAnalyst?.trim() !== '' ||
          fields?.vendorManager?.trim() !== '' ||
          fields?.shipOff?.trim() !== ''
        ) {
          handleSearch()
        } else {
          fetchPoData()
        }
        updateActionHistory('Created', response?.data)
        updateActionHistory('Vendor Initiated', response?.data)
        setRowSelection({})
        closeCRModal()
      })
      .catch((error) => {
        console.log('error while creating request: ', error)
        alert(i18next.t('VendorPopUp.ErrorwhilecreatingrequestPleasetryagain'))
        setSubmitting(false)
        closeCRModal()
      })
    closeChangeRequestModal()
  }

  const fetchPoData = () => {
    setLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/search-oracle-po-data?vendorNumber=${user?.supplierId}`,
        ValidateAPI
      )
      .then((response) => {
        // console.log(`response from po data: `, response?.data)
        setRawData(response?.data)
        setData(response?.data)
        setShowDelayModal(false)
        // setDataFilter(FilterTypes.ALL)
        setLoading(false)
      })
      .catch((error) => {
        console.log('Error from po data: ', error)
        setLoading(false)
        if (error.response.status === 401) {
          alert(error.response.data)
        }
      })
  }

  const handleChangeDate = (date) => {
    setDate(date)
    setRequestSW(getWeek(date))
  }

  const handleSearch = () => {
    if (
      fields?.poNumber?.trim() !== '' ||
      fields?.sku?.trim() !== '' ||
      fields?.upc?.trim() !== '' ||
      fields?.dc?.trim() !== '' ||
      fields?.inventoryAnalyst?.trim() !== '' ||
      fields?.vendorManager?.trim() !== '' ||
      fields?.shipOff?.trim() !== ''
    ) {
      const cancelTokenSource = axios.CancelToken.source()
      setCancelTokenSource(cancelTokenSource)
      setSearching(true)
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}:8088/api/search-oracle-po-data?poNumber=${fields?.poNumber}&sku=${fields?.sku}&vendorNumber=${vendorNumber}&upc=${fields?.upc}&dc=${fields?.dc}&inventoryAnalyst=${fields?.inventoryAnalyst}&vendorManager=${fields?.vendorManager}`,
          ValidateAPI,
          {
            cancelToken: cancelTokenSource.token
          }
        )
        .then((response) => {
          setRawData(response?.data)
          setData(response?.data)
          setSearching(false)
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('Request canceled:', error.message)
          } else {
            console.log('Error while fetching search results: ', error)
          }
          setSearching(false)
        })
    } else {
      alert(i18next.t('VendorPopUp.Pleaseenteratleastonesearchcriteria'))
    }
  }

  const requiredOutput = () => {
    const selectedRowRaw = table?.getSelectedRowModel().flatRows
    console.log('selected raw data now : ', selectedRowRaw?.isRowSelected)
    const requiredData = []
    selectedRowRaw?.map((row, index) => requiredData.push(row?.original))
    setSelectedArray(requiredData)
    return requiredData
  }

  const onDragStart = (e) => {
    columnBeingDragged = Number(e.currentTarget.dataset.columnIndex)
  }

  // const onDrop = (e) => {
  //   e.preventDefault()
  //   const newPosition = Number(e.currentTarget.dataset.columnIndex)
  //   const currentCols = table.getVisibleLeafColumns().map((c) => c.id)
  //   const colToBeMoved = currentCols.splice(columnBeingDragged, 1)

  //   currentCols.splice(newPosition, 0, colToBeMoved[0])
  //   table.setColumnOrder(currentCols)
  // }
  const onDrop = (e) => {
    e.preventDefault()
    const newPosition = Number(e.currentTarget.dataset.columnIndex)
    const currentCols = table.getVisibleLeafColumns().map((c) => c.id)
    if (columnBeingDragged !== newPosition) {
      const colToBeMoved = currentCols.splice(columnBeingDragged, 1)
      currentCols.splice(newPosition, 0, colToBeMoved[0])
      table.setColumnOrder(currentCols)
    }
  }
  //generate csv data
  const generateCSVData = (table) => {
    const data = table?.getFilteredRowModel()?.rows?.map((row) => {
      return row?.original
    })
    return data
  }

  // const SearchPONumber = (value) => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_API_DOMAIN}:8088/notification/searchByPoString?poSearch=${value}`
  //     )
  //     .then((response) => {
  //       console.log(response)

  //       setpoNumberList(response?.data)
  //     })
  //     .catch((error) => {
  //       alert(error?.response?.data)
  //     })
  // }
  useEffect(() => {
    requiredOutput()
  }, [rowSelection])

  useEffect(() => {
    // setShowChangeRequestBanner(true)
  }, [crIdResponse])

  // useEffect(() => {
  //   const savedColumnOrder = sessionStorage.getItem("po_list_order");

  //   if (savedColumnOrder) {
  //     try {
  //       const parsedColumnOrder = JSON.parse(savedColumnOrder);
  //       if (Array.isArray(parsedColumnOrder)) {
  //         setColumnOrder(parsedColumnOrder);
  //       } else {
  //         console.error("Invalid data in sessionStorage. Expected an array.");
  //         // Handle the case of invalid data by setting a default column order
  //         const defaultColumnOrder = columns.map((column) => column.accessor);
  //         setColumnOrder(defaultColumnOrder);
  //       }
  //     } catch (error) {
  //       console.error("Error parsing data from sessionStorage:", error);
  //       // Handle parsing error by setting a default column order
  //       const defaultColumnOrder = columns.map((column) => column.accessor);
  //       setColumnOrder(defaultColumnOrder);
  //     }
  //   } else {
  //     // No saved data, set default column order
  //     const defaultColumnOrder = columns.map((column) => column.accessor);
  //     setColumnOrder(defaultColumnOrder);
  //   }
  // }, [columns]);

  // useEffect(() => {
  //   sessionStorage.setItem("po_list_order", JSON.stringify(columnOrder));
  // }, [columnOrder]);

  useEffect(() => {
    console.log(
      'On column togle: ',
      JSON.stringify(table.getState().columnVisibility)
    )
    localStorage?.setItem(
      'toggle_columns',
      JSON.stringify(table.getState().columnVisibility, null, 2)
    )
  }, [columnVisibility])

  useEffect(() => {
    // fetchPoData();
    console.log('env value ', process.env.REACT_APP_API_DOMAIN)
  }, [])

  // useEffect(() => {
  //   // GetVendorNumber()
  //   const getPONumber = setTimeout(() => {
  //     SearchPONumber(searchpoNumber)
  //   }, 1000)
  //   return () => clearTimeout(getPONumber)
  // }, [])
  const GetDCList = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-dc-list`,
        ValidateAPI
      )
      .then((response) => {
        // console.log(response?.data);
        setDcList(response?.data)
      })
      .catch((error) => {
        // alert(error?.response?.data);
      })
  }
  // Get All DC List
  useEffect(() => {
    GetDCList()
  }, [])

  //Set identifier to 0 to get all data
  const fetchToggleLines = (identifier = 0) => {
    setLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-all-po-data-vendor-toggle?identifier=${identifier}&vendorNumber=${user?.supplierId}`
      )
      .then((response) => {
        setRawData(response?.data)
        setData(response?.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log('Error from fetch toggle lines:', error)
        setLoading(false)
      })
  }

  useEffect(() => {
    const fetchUnreadCounts = async () => {
      const currentPagePOs = table.getRowModel().rows.map((row) => ({
        poNumber: row.original.PO_NUMBER,
        poSku: row.original.SKU
      }))

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_DOMAIN}:8088/api/messages/count`,
          currentPagePOs,
          ValidateAPI
        )
        setMessageCounts(response.data)
      } catch (error) {
        console.error('Error fetching unread message counts:', error)
      }
    }

    fetchUnreadCounts() // Initial fetch
    const intervalId = setInterval(fetchUnreadCounts, 5000) // Fetch every 10 seconds

    return () => clearInterval(intervalId) // Cleanup interval on unmount
  }, [table])

  const handleMessageIconClick = (po, sku) => {
    setSelectedPOForMessageBox(po)
    setSelectedSKUForMessageBox(sku)
    setIsMessageBoxOpen(true)
  }

  const handleCloseMessageBox = () => {
    setSelectedPOForMessageBox(null)
    setSelectedSKUForMessageBox(null)
    setIsMessageBoxOpen(false)
  }

  const getUnreadCount = (po, sku) => {
    const item = messageCounts.find(
      (count) => count.poNumber === po && count.poSku === sku
    )
    return item ? item.unseenForVendors : 0
  }

  useEffect(() => {
    applyFilters()
  }, [dataFilter, rawData])

  const handleFilterChange = (filterName) => {
    setDataFilter(filterName)
  }

  const applyFilters = () => {
    let tempData = [...rawData]

    if (dataFilter === FilterTypes.NO_CR) {
      tempData = tempData.filter((item) => item.HFT_REQUEST === null)
    } else if (dataFilter === FilterTypes.CR) {
      tempData = tempData.filter((item) => item.HFT_REQUEST !== null)
    } else if (dataFilter === FilterTypes.PAST_DUE) {
      tempData = tempData.filter((item) => {
        if (item.CURRENT_SW) {
          const currentSW = item.CURRENT_SW.split('.').map(Number) // CURRENT_SW from data
          const presentSW = getPresentShipWeek().split('.').map(Number) // Today's Shipweek
          return (
            currentSW[1] < presentSW[1] ||
            (currentSW[1] === presentSW[1] && currentSW[0] < presentSW[0])
          )
        }
        return false
      })
    }
    setData(tempData)
  }

  useEffect(() => {
    // fetchToggleLines()
    console.log('Effect called')
    fetchPoData()
  }, [])

  const allPopUp = React.useRef(null)

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (allPopUp.current && !allPopUp.current.contains(event.target)) {
        // Clicked outside the notification, close it
        setOpenPonumberList(false)
        setUnseenBox(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      // Cleanup: Remove the event listener when the component is unmounted
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const cleared = () => {
    const localStorageKey = 'all_selected_values';
    localStorage.removeItem(localStorageKey);
    console.log('localStorage after removal:', localStorage.getItem(localStorageKey));
    console.log('localStorage after removal:', localStorage.getItem('column_filters'));
    setColumnFilters([]);
    console.log('Column filters cleared');
    setClearState(prevState => !prevState); // Toggle the state
  }


  const handleColumnResizeEnd = (header) => {
    const newWidth = header.getSize()
    const storedWidths = JSON.parse(localStorage.getItem('column-widths')) || {}
    storedWidths[header.id] = newWidth
    localStorage.setItem('column-widths', JSON.stringify(storedWidths))
  }

  const loadColumnWidths = (columns) => {
    const storedWidths = JSON.parse(localStorage.getItem('column-widths')) || {}
    columns.forEach((column) => {
      if (storedWidths[column.id]) {
        column.columnDef.size = Number(storedWidths[column.id])
      }
    })
  }

  useEffect(() => {
    loadColumnWidths(table.getAllColumns())
    // localStorage.removeItem('column-widths');
  }, [table])

  useEffect(() => {
    // Event listener to remove item from localStorage on window reload
    const handleBeforeUnload = (event) => {
      // Custom logic to determine if you want to clear localStorage on reload
      localStorage.removeItem('column-widths')
    }
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, []) // Empty dependency array ensures this effect runs only once on mount

  const handlePageChange = (pageIndex) => {
    table.setPageIndex(pageIndex)
  }

  const handlePageSizeChange = (e) => {
    table.setPageSize(Number(e.target.value))
  }

  const handleInputChange = (e) => {
    const page = e.target.value ? Number(e.target.value) - 1 : 0
    handlePageChange(page)
  }

  //get all unseen msg list
  const [unseenList, setunseenlist] = useState([])
  const [openunseenmsgBox, setUnseenBox] = useState(false)
  const getUnseenMessage = () => {
    const role = Cookies.get('role')
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/messages/vendorunseen/count?email=${user?.email}`,
        ValidateAPI
      )
      .then((res) => {
        setunseenlist(res.data)
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }
  useEffect(() => {
    getUnseenMessage()
    const intervalId = setInterval(getUnseenMessage, 5000)
    return () => clearInterval(intervalId)
  }, [])
  const RendorUnreadMessage = (data) => {
    // console.log("message", data.poNumber);
    setColumnFilters([
      {
        id: 'PO_NUMBER',
        value: data.poNumber
      }
    ])
    setUnseenBox(false)

  }
  const getCustomHeaderSize = (header) => {
    const defaultSize = header.getSize();
    if (header?.id === "SKU" || header?.id === "PO_SKU") {
      return 150; // Set your specific width here
    }
    return defaultSize;
  };
  return (
    <div className='w-full flex flex-col gap-4 p-3 max-h-screen'>
      <div className='w-full flex justify-end items-center'>
        {/* <UserProfile /> */}
      </div>
      <div className='w-full flex justify-end  items-start'>
        {/* <p
          className='text-xs font-medium flex gap-2 items-center cursor-pointer'
          onClick={() => setShowFilters(!showFilters)}
        >
          <span className='hover:underline'>{Filters}</span>
          <span>
            <svg
              className='w-3 h-2  text-gray-400'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 14 8'
            >
              <path
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1'
              />
            </svg>
          </span>
        </p> */}
        <div className='flex gap-2 rounded-md bg-blue-100 p-2 items-center justify-center text-xs font-medium'>
          <div className='flex flex-col gap-3'>
            <input
              type='text'
              className='border border-gray-300 outline-none rounded-md p-1 shrink w-60'
              value={fields?.inventoryAnalyst}
              // placeholder='Please Enter Inventory Analyst Name'
              placeholder={i18next.t('PopUpMessage.PleaseEnterInventoryAnalystName')}
              onChange={(e) =>
                handleInputChange('inventoryAnalyst', e.target.value)
              }
            />
          </div>
          <button
            className='bg-[#415da1] px-4 h-[30px] rounded-md outline-none text-white min-w-max'
            disabled={searching}
            onClick={handleSearch}
          >
            {searching ? (
              <div role='status'>
                <svg
                  aria-hidden='true'
                  className='inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300'
                  viewBox='0 0 100 101'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                    fill='currentColor'
                  />
                  <path
                    d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                    fill='currentFill'
                  />
                </svg>
                <span className='sr-only'>{Loading1}</span>
              </div>
            ) : (
              <p>{Filters}</p>
            )}
          </button>
          <button
            // className="h-[30px] px-4 inset-1 border border-[#415da1] rounded-md"
            className=' bg-[#415da1] px-4 h-[30px] rounded-md outline-none text-white min-w-max'
            onClick={handleClearSearch}
          >
            {ClearFilters}
          </button>
          {Object.values(FilterTypes)?.map((buttonName) => {
            return (
              <button
                key={'type' + buttonName}
                className={`p-2 rounded-md min-w-max transition ease-in-out delay-150 outline-none focus:outline-none ${dataFilter === buttonName
                    ? 'bg-blue-400 text-white'
                    : 'bg-white'
                  }`}
                onClick={() => handleFilterChange(buttonName)}
              >
                {buttonName}
              </button>
            )
          })}
          {unseenList.totalCount > 0 && (
            <div className='relative'>
              <button onClick={() => setUnseenBox(!openunseenmsgBox)}>
                <MessageIconRead />
                {unseenList.totalCount > 0 && (
                  <span className='absolute -mt-6 ml-1 rounded-full bg-red-600 px-[0.45em] py-[0.2em] text-[0.6rem] leading-none text-white'>
                    {unseenList?.totalCount}
                  </span>
                )}
              </button>
              {openunseenmsgBox && (
                <div
                  ref={allPopUp}
                  className='absolute z-20 bg-[#e0ecfc] border border-gray-300 rounded-lg shadow-xl right-0 top-7  min-w-[400px] justify-center max-h-[500px] overflow-y-auto '
                >
                  <h1 className='text-lg text-center  pt-2 sticky top-0  bg-[#e0ecfc]'>
                    New Message
                  </h1>
                  {unseenList?.messageCounts?.map((data) => {
                    return (
                      <div
                        className='flex items-center p-2 gap-1 cursor-pointer'
                        onClick={() => RendorUnreadMessage(data)}
                      >
                        <span className='text-red-500'>
                          <MessageIconUnread />
                        </span>
                        <h1 className='text-gray-600'>
                          PO Number : {data.poNumber}
                        </h1>
                        {/* <span className='p-1 rounded-xl bg-red-500 text-white'>{data.count}</span> */}
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className='w-full justify-between flex items-center text-xs'>
        <div className='relative flex justify-start items-center gap-3'>
          <div className='relative flex justify-start items-center'>
            <button
              className='font-medium text-white outline-none bg-[#415da1] w-[90px] rounded-md h-[30px]'
              onClick={() => setShowSelectColumn(!showSelectColumn)}
            >
              {Columns}
            </button>
            {showSelectColumn && (
              <div
                className={`${showSelectColumn ? 'transition opacity-100' : 'opacity-0'
                  } border border-black shadow rounded absolute top-10 h-[250px] w-[230px] overflow-y-auto bg-[#fff] z-20 flex flex-col gap-2`}
              >
                <div className='p-1 px-2 border-b border-black flex gap-2 items-center py-2'>
                  <input
                    type='checkbox'
                    id='column_toggle_all'
                    name='toggle_all_checkbox'
                    checked={table?.getIsAllColumnsVisible()}
                    onChange={table?.getToggleAllColumnsVisibilityHandler()}
                  />
                  <label className='' htmlFor='column_toggle_all'>
                    {ToggleAll}
                  </label>
                </div>
                {table
                  ?.getAllLeafColumns()
                  ?.filter(
                    (column) =>
                      column?.columnDef?.header !== 'PO-SKU' &&
                      column?.columnDef?.header !== 'ACTION TYPE'
                  ) // Filter out the column with the header 'po-sku'
                  ?.map((column, index) => (
                    <div
                      key={column?.id + index}
                      className='px-2 py-1 flex gap-2 items-center'
                    >
                      <input
                        type='checkbox'
                        name='column_name'
                        id={column?.id}
                        checked={column?.getIsVisible()}
                        onChange={column?.getToggleVisibilityHandler()}
                      />
                      <label htmlFor={column?.id}>
                        {column?.columnDef.header}
                      </label>
                    </div>
                  ))}
              </div>
            )}
          </div>

          {selectedArray?.length > 0 && editArray?.length === 0 && (
            <div className=''>
              <button
                className='bg-[#415da1] px-4 h-[30px] rounded-md outline-none text-white min-w-max'
                onClick={() => setShowDelayModal(!showDelayModal)}
              >
                {DelayOrder}
              </button>
            </div>
          )}
          {editArray?.length > 0 && (
            <div className='flex gap-3 items-center justify-start'>
              <button
                className='bg-[#415da1] px-4 h-[30px] rounded-md outline-none text-white min-w-max'
                onClick={openCreateLineRequestModal}
              >
                {DelayOrder}
                {/* Create */}
              </button>
              <PopupModal
                isOpen={showCreateLineRequestModal}
                message={i18next.t(
                  'PopUpMessage.Doyouwanttocreateadelayrequest'
                )}
                onCancel={closeCreateLineRequestModal}
                cancelText={i18next.t('AcceptModal.Cancel')}
                onConfirm={handleCreateLineRequests}
                confirmText={i18next.t('AcceptModal.Confirm')}
              />
              <PopupBanner
                isOpen={showChangeRequestBanner}
                message={i18next.t(
                  'PopUpMessage.Changerequestissubmittedsuccessfully'
                )}
                onCancel={closePopUpModal}
                text={poRow}
                text1={crIdResponse}
              />
            </div>
          )}
        </div>

        <div className='flex items-center gap-2'>
          {columnFilters?.length > 0 && (
            <button
              className='font-medium text-white outline-none bg-[#415da1] w-[90px] rounded-md h-[30px]'
              onClick={() => {
                cleared()
              }}
            >
              {ClearFilters}
            </button>
          )}
          {data?.length !== 0 && downloadCSV('PO_list', generateCSVData(table))}
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => handlePageChange(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => handlePageChange(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <span className='font-medium min-w-max flex items-center gap-1'>
            <div>{Page}</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </strong>
          </span>
          <span className='min-w-max font-medium flex  items-center gap-1'>
            | {Gotopage}
            <input
              type='number'
              id='pagination_input'
              name='pagination_input'
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={handleInputChange}
              className='border p-1 rounded w-16'
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={handlePageSizeChange}
          >
            {[10, 20, 30, 40, 50, data?.length].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>{' '}
        </div>
      </div>
      {!loading ? (
        <div className='w-full overflow-x-auto' style={{ minHeight: '70vh', minWidth: '660px' }}>
          <table className='text-xs border border-gray-300 ' >
            <thead className='top-0 z-10'>
              {table.getHeaderGroups()?.map((headerGroup, index) => (
                <tr
                  key={headerGroup?.id + index}
                  className='divide-x-[1px] bg-[#e0ecfc] divide-gray-300 border-[#ccd9e4] border'
                >
                  <th className='font-medium px-2 py-3  frozen-column1' >
                    <IndeterminateCheckbox
                      checked={table.getIsAllPageRowsSelected()}
                      indeterminate={table.getIsSomePageRowsSelected()}
                      onChange={table.getToggleAllPageRowsSelectedHandler()}
                    />
                  </th>
                  {headerGroup?.headers?.map((header) => (

                    <th
                      style={{ width: getCustomHeaderSize(header) }}
                      key={header?.id}
                      className={`font-medium px-2 py-3  top-0 ${header.id === 'PO_SKU' || header.id === 'REQUEST_TYPE' ? 'frozen-column1' : 'unFrozen'}`}
                      {...{
                        key: header.id,
                        colSpan: header.colSpan,
                        style: {
                          width: header.getSize()
                        }
                      }}
                      draggable={
                        header.id !== 'PO_SKU' &&
                        header.id !== 'REQUEST_TYPE' &&
                        !table.getState().columnSizingInfo.isResizingColumn
                      }
                      data-column-index={header.index ?? -1}
                      onDragStart={
                        header.id !== 'PO_SKU' && header.id !== 'REQUEST_TYPE'
                          ? onDragStart
                          : undefined
                      }
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={
                        header.id !== 'PO_SKU' && header.id !== 'REQUEST_TYPE'
                          ? onDrop
                          : undefined
                      }
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler()
                          }}
                        >
                          <div className='flex justify-center items-center'>
                            {' '}
                            {/* Container with Flexbox */}
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            &nbsp;&nbsp;
                            <div>
                              {{
                                asc: (
                                  <svg
                                    className='w-3 h-4 text-gray-500'
                                    // className="w-4 h-5 text-gray-500" // Increased size with w-4 and h-5
                                    aria-hidden='true'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 10 14'
                                  // viewBox="0 0 14 20" // Adjusted viewBox for larger arrow
                                  >
                                    <path
                                      stroke='currentColor'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      strokeWidth='2'
                                      d='M5 13V1m0 0L1 5m4-4 4 4'
                                    // d="M1 5h12m0 0-4 4m4-4L9 1"
                                    />
                                  </svg>
                                ),
                                desc: (
                                  <svg
                                    className='w-3 h-4 text-gray-500'
                                    // className="w-4 h-5 text-gray-500" // Increased size with w-4 and h-5
                                    aria-hidden='true'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 10 14'
                                  // viewBox="0 0 14 20" // Adjusted viewBox for larger arrow
                                  >
                                    <path
                                      stroke='currentColor'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      strokeWidth='2'
                                      d='M5 1v12m0 0 4-4m-4 4L1 9'
                                    // d="M13 5H1m0 0 4 4m-4-4 3-3m0 6V1m0 0 3 3m-3-3 3 3"
                                    />
                                  </svg>
                                )
                              }[header.column.getIsSorted()] ?? (
                                  <svg
                                    className='w-3 h-4 text-gray-500'
                                    aria-hidden='true'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 16 20'
                                  >
                                    <path
                                      stroke='currentColor'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      strokeWidth='2'
                                      d='M4 6v13m0 0 3-3m-3 3-3-3m11-2V1m0 0L9 4m3-3 3 3'
                                    // d="M4 6h10m0 0-3-3m3 3-3 3m1 8H1m0 0 3-3m-3 3 3 3"
                                    />
                                  </svg>
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                      {header.column.getCanFilter() ? (
                        <div>
                          <Filter
                            column={header.column}
                            table={table}
                            columnFilters={columnFilters}
                            values={values}
                            setValues={setValues}
                            header={header}
                            ClearState={ClearState}
                          />
                        </div>
                      ) : null}
                      {/* <div
                        onDoubleClick={() => header.column.resetSize()}
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={`resizer ${table.options.columnResizeDirection} ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                        style={{
                          transform:
                            columnResizeMode === 'onEnd' && header.column.getIsResizing()
                              ? `translateX(${(table.options.columnResizeDirection === 'rtl' ? -1 : 1) *
                              (table.getState().columnSizingInfo.deltaOffset ?? 0)}px)`
                              : '',
                        }}
                      /> */}

                      {header.id !== 'REQUEST_TYPE' && (
                        <div
                          onDoubleClick={() => header.column.resetSize()}
                          onMouseDown={(e) => {
                            header.getResizeHandler()(e)
                            document.addEventListener(
                              'mouseup',
                              () => handleColumnResizeEnd(header),
                              { once: true }
                            )
                          }}
                          onTouchStart={(e) => {
                            header.getResizeHandler()(e)
                            document.addEventListener(
                              'touchend',
                              () => handleColumnResizeEnd(header),
                              { once: true }
                            )
                          }}
                          className={`resizer ${table.options.columnResizeDirection} ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                          style={{
                            transform:
                              columnResizeMode === 'onEnd' &&
                                header.column.getIsResizing()
                                ? `translateX(${(table.options.columnResizeDirection === 'rtl' ? -1 : 1) * (table.getState().columnSizingInfo.deltaOffset ?? 0)}px)`
                                : ''
                          }}
                        />
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody >
              {table?.getRowModel().rows?.map((row, index) => {
                const rowClassName =
                  index % 2 !== 0 ? 'bg-[#ecedf2]' : 'bg-[#ffffff]'
                const unreadCount = getUnreadCount(
                  row.original?.PO_NUMBER,
                  row.original?.SKU
                )
                return (
                  <tr
                    key={'mypos-tr' + index}
                    className={`${rowClassName} text-center divide-x-[1px] divide-gray-300 border border-gray-300 
                    ${rowClassName} ${row.getIsSelected() ? 'selected' : ''}
                    `}
                  >
                    <td className='px-1.5 py-1.5 frozen-column1'>
  <div className='w-full flex justify-start items-center gap-2 min-w-min' style={{width:'125px'}}>
    <IndeterminateCheckbox
      checked={row.getIsSelected()}
      indeterminate={row.getIsSomeSelected()}
      onChange={row.getToggleSelectedHandler()}
      isdisabled={row?.original?.HFT_REQUEST !== null || row?.original?.CURRENT_SW === null || row?.original?.ONTARGET_SHIP_WK === null}
    />
    <span
      className={`cursor-pointer ${unreadCount > 0 ? 'text-red-500' : 'text-gray-500'}`}
      onClick={() =>
        handleMessageIconClick(
          row.original?.PO_NUMBER,
          row.original?.SKU
        )
      }
      title={
        unreadCount > 0
          ? `Unread messages: ${unreadCount}`
          : 'No unread messages'
      }
    >
      {unreadCount > 0 ? (
        <MessageIconUnread />
      ) : (
        <MessageIconRead />
      )}
    </span>
    <div className='flex gap-2 items-center justify-center'>
      {row.original?.HFT_REQUEST !== null && (
        <div className='flex relative group'>
          <div
            className='relative group inline-block'
            title={`HFT: ${row.original?.HFT_REQUEST}`}
          >
            <svg
              className='w-5 h-5 text-[#ffae42] cursor-pointer'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              viewBox='0 0 20 20'
              onClick={() =>
                handleCRDetailsClick(
                  'vendor',
                  row.original?.HFT_REQUEST
                )
              }
            >
              <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z' />
            </svg>
          </div>
        </div>
      )}
      {row?.original?.LATEST_CR_COUNT > 0 && (
        <div className='w-full flex items-center justify-center'>
          <svg
            className='w-4 h-4 text-gray-900 cursor-pointer'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 20 20'
            onClick={() =>
              handleCRHistoryClick(
                row?.original?.PO_NUMBER,
                row?.original?.SKU
              )
            }
          >
            <path
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M18 5h1v12a2 2 0 0 1-2 2m0 0a2 2 0 0 1-2-2V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v15a2 2 0 0 0 2 2h14ZM10 4h2m-2 3h2m-8 3h8m-8 3h8m-8 3h8M4 4h3v3H4V4Z'
            />
          </svg>
        </div>
      )}
    </div>
    {checkPd(row?.original?.CURRENT_SW ?? row?.original?.REQ_SW) && (
      <div className='w-full flex'>
        <svg
          className='w-4 h-5 text-red-500'
          aria-hidden='true'
          xmlns='http://www.w3.org/2000/svg'
          fill='currentColor'
          viewBox='0 0 20 20'
        >
          <path d='M0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm14-7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4Z' />
        </svg>
      </div>
    )}
  </div>
</td>

                    {row?.getVisibleCells()?.map((cell) => (
                      <td
                        className={`px-1.5 py-1.5  ${cell.column.id === 'PO_SKU' || cell.column.id === 'REQUEST_TYPE' ? 'frozen-column1 ' : ''} ${row.getIsSelected() ? 'bg-blue-200' : ''}`}
                        {...{
                          key: cell.id,
                          style: {
                            width: cell.column.getSize()
                          }
                        }}
                      >
                        {flexRender(
                          cell?.column?.columnDef?.cell,
                          cell?.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
          {isMessageBoxOpen && (
            <MessageBox
              po={selectedPOForMessageBox}
              sku={selectedSKUForMessageBox}
              name={user?.supplierName}
              onClose={handleCloseMessageBox}
            />
          )}
        </div>
      ) : (
        <div className='w-full min-h-[450px] flex flex-col gap-3 justify-center items-center text-xs font-medium'>
          <p>
            <svg
              aria-hidden='true'
              className='inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300'
              viewBox='0 0 100 101'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                fill='currentColor'
              />
              <path
                d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                fill='currentFill'
              />
            </svg>
            <span className='sr-only'>{Loading1}</span>
          </p>
          <p>{Loading1}</p>
        </div>
      )}
      {/*Delay Order Modal */}
      <dialog
        open={showDelayModal}
        className='p-3 inset-0 fixed z-30 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          {submitting ? (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <Loading type={Submitting_load} />
            </div>
          ) : (
            <div className='sm:w-[400px] w-[280px] min-h-[300px] bg-white p-4 rounded-md flex flex-col gap-6 text-xs'>
              <div className='w-full flex justify-end items-center'>
                <button
                  className='border-2 border-gray-300 p-2 rounded-md text-sm'
                  onClick={closeCRModal}
                >
                  ❌
                </button>
              </div>
              <div>
                <p className='font-medium text-base'>{CreateDelayRequest}</p>
                <div className='w-full flex flex-wrap justify-center items-center gap-3'>
                  {reasonArray?.map((reason, index) => (
                    <div
                      className='rounded-lg bg-[#4472C4] flex gap-2 justify-center items-center p-2 text-xs text-white font-medium'
                      key={index}
                    >
                      <span>{reason}</span>
                      <span
                        className='cursor-pointer'
                        onClick={() => handleRemoveReason(reason)}
                      >
                        X
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <form
                onSubmit={handleDelayRequest}
                className='w-full flex flex-col gap-4 justify-between items-center'
              >
                <div className='w-full flex justify-between items-center'>
                  <label className=' font-medium'>{SelectShipDate} </label>
                  <DatePicker
                    showWeekNumbers
                    filterDate={(date) => date.getDay() === 1}
                    selected={date}
                    onChange={(date) => handleChangeDate(date)}
                    className='p-1'
                    calendarStartDay={1}
                    showYearDropdown
                    minDate={
                      selectedArray[0]?.CURRENT_SW &&
                      getStartDate(getPossibleShipWeek(selectedArray[0]?.CURRENT_SW))
                    }
                    weekNumbersClassName='text-blue-800'
                  />
                </div>
                <div className='w-full flex justify-between items-center font-medium'>
                  <p>{SelectedShipWeekValue} </p>
                  <p>{requestSW}</p>
                  {/* <p>{selectedArray[0]?.DELTA}</p> */}
                </div>
                <div className='flex justify-between items-center w-full'>
                  <p className='text-xs'>{CURRSW}</p>
                  <p className='text-xs'>
                    <span className=' p-1 px-2 border border-gray-300 rounded-md font-bold text-xs'>
                      {selectedArray[0]?.CURRENT_SW}
                    </span>
                  </p>
                </div>
                <div className='w-full flex flex-col items-start gap-2'>
                  <label
                    htmlFor='reason_code_select'
                    className='text-xs font-medium'
                  >
                    {ReasonCode}
                  </label>
                  <select
                    onChange={(e) => handleReasonChange(e.target.value)}
                    name='reason_code_select'
                    id='reason_code_select'
                    className='p-2 rounded-md outline-none border border-gray-300 text-xs w-full font-medium'
                    value={selectedReason}
                  >
                    <option value='' disabled>
                      {Selectareason}
                    </option>
                    {reason_codes?.map((reason) => (
                      <option key={reason} value={reason}>
                        {reason}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='w-full flex justify-center items-center gap-6 text-white font-medium mt-5'>
                  <button
                    className='px-4 h-[30px] bg-green-500 rounded-md'
                    type='submit'
                  // disabled={!selectedReason}
                  >
                    {Submit}
                  </button>
                  <button
                    className='px-4 h-[30px] bg-black rounded-md'
                    type='button'
                    onClick={() => {
                      closeCRModal()
                      setShowDelayModal('')
                      setReasonArray([])
                      setSelectedReason('')
                    }}
                  >
                    {Cancel}
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </dialog>
      <PopupModal
        isOpen={showChangeRequestModal}
        message={i18next.t('PopUpMessage.Doyouwanttocreateadelayrequest')}
        onCancel={closeChangeRequestModal}
        cancelText={i18next.t('AcceptModal.Cancel')}
        onConfirm={handleConfirmDelay}
        confirmText={i18next.t('AcceptModal.Confirm')}
      />
      <PopupBanner
        isOpen={showChangeRequestBanner}
        message={i18next.t('PopUpMessage.Changerequestissubmittedsuccessfully')}
        onCancel={closePopUpModal}
        text={poRow}
        text1={crIdResponse}
      />
    </div>
  )
}

export default MyPos
