import React, { useEffect, useState } from 'react'
import { FilterComp } from '../../screens/buyer/Dashboard'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import Loading from '../Loading'
import { ValidateAPI } from '../../utils/Utils'

const HorizontalBarCRAge = ({ vendorList, buyerList, user }) => {
  const [reqTypeData, setReqTypeData] = useState([])
  const [barload, setBarload] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [selectedBuyer, setSelectedBuyer] = useState('')
  const [selectedVendor, setSelectedVendor] = useState('')
  const { t } = useTranslation()
  const {
    DistributionbyRequestAge,
    Filter,
    Analyst,
    Vendor,
    Norelateddatafound
  } = t('horizontalbarChart')
  const fetchReqTypeData = () => {
    setBarload(true)
    axios
      .get(
        `${
          process.env.REACT_APP_API_DOMAIN
        }:8088/api/admin/get-all-counts-cr-age-filter?createdBy=${encodeURIComponent(
          user?.fullName
        )}`,ValidateAPI
      )
      .then((response) => {
        console.log('Data from reqtype: ', response?.data)
        setReqTypeData(response?.data)
        setBarload(false)
      })
      .catch((error) => {
        console.log('error while fetching reqtype: ', error)
        setBarload(false)
      })
  }

  useEffect(() => {
    fetchReqTypeData()
  }, [])

  return (
    <div className="w-[50%] flex justify-center items-center flex-col gap-4 sm:w-[100%]
     shadow-lg rounded-md border border-gray-300 p-3 max-h-[360px]">
      <p className="text-sm font-medium">{DistributionbyRequestAge}</p>
      <div className="relative flex justify-between items-center w-full">
        <div className="flex gap-3 relative ">
          {user?.roleType === "MANAGER" && (
            <div className="relative">
              <button
                onClick={() => setShowFilters(!showFilters)}
                className='flex outline-none text-sm gap-2 items-center focus:outline-none justify-center rounded-md p-2 border border-gray-300'
              >
                <span className='px-2 text-xs font-medium'>{Filter}</span>
                <span>
                  <svg
                    class='w-3 h-3 text-gray-800 dark:text-white'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='currentColor'
                    viewBox='0 0 24 24'
                  >
                    <path d='M10.8 5a3 3 0 0 0-5.6 0H4a1 1 0 1 0 0 2h1.2a3 3 0 0 0 5.6 0H20a1 1 0 1 0 0-2h-9.2ZM4 11h9.2a3 3 0 0 1 5.6 0H20a1 1 0 1 1 0 2h-1.2a3 3 0 0 1-5.6 0H4a1 1 0 1 1 0-2Zm1.2 6H4a1 1 0 1 0 0 2h1.2a3 3 0 0 0 5.6 0H20a1 1 0 1 0 0-2h-9.2a3 3 0 0 0-5.6 0Z' />
                  </svg>
                </span>
              </button>

              {showFilters && (
                <FilterComp
                  buyerUpdate={setSelectedBuyer}
                  vendorUpdate={setSelectedVendor}
                  fetchActual={fetchReqTypeData}
                  showFilter={setShowFilters}
                  filteredData={setReqTypeData}
                  graph={'requestAge'}
                  vendorList={vendorList}
                  buyerList={buyerList}
                />
              )}
            </div>
          )}
          <div className='flex gap-2'>
            {selectedBuyer && (
              <p className='border-blue-600 border border-dashed rounded-md p-2 text-xs font-medium flex gap-1 min-w-max'>
                <span className='text-gray-500'>{Analyst}</span>
                <span> {selectedBuyer}</span>
              </p>
            )}
            {selectedVendor && (
              <p className='border-blue-600 border border-dashed rounded-md p-2 text-xs font-medium flex gap-3 min-w-max'>
                <span className='text-gray-500'>{Vendor} </span>
                <span>{selectedVendor}</span>
              </p>
            )}
          </div>
        </div>
      </div>

      {!barload ? (
        reqTypeData?.length > 0 ? (
          <ResponsiveContainer width='100%' height={180}>
            <BarChart
              data={reqTypeData}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20
              }}
              layout='vertical'
            >
              <XAxis type='number' className='text-xs font-medium' />
              <YAxis
                dataKey='range'
                type='category'
                className='text-xs font-medium'
              />
              <Tooltip
                labelStyle={{ fontSize: '12px' }}
                contentStyle={{
                  background: 'white',
                  borderRadius: '8px',
                  fontSize: '12px'
                }}
              />
              <Bar
                dataKey='Count'
                barSize={20}
                fill='#413ea0'
                className='text-xs font-medium'
              />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <p>{Norelateddatafound}</p>
        )
      ) : (
        <div className='w-full min-h-[250px] justify-center items-center flex'>
          <Loading />
        </div>
      )}
    </div>
  )
}

export default HorizontalBarCRAge
