import React, { useEffect, useMemo, useState } from 'react'
import UserProfile from '../../components/UserProfile'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { formatDateFit } from './BuyerRequestDetails'
import { Filter, ValidateAPI, downloadCSV, handlePODetailsClick } from '../../utils/Utils'
import { useTranslation } from 'react-i18next'

import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues
} from '@tanstack/react-table'

import i18next from 'i18next'

import { useLocation } from 'react-router-dom'
import PopupModal from '../../components/PopupModal'
import { IndeterminateCheckbox } from './NewPoList'
import PopupBanner from '../../components/PopupBanner'

export const formatDate = (originalString) => {
  const year = originalString?.slice(0, 4)
  const month = originalString?.slice(4, 6)
  const day = originalString?.slice(6, 8)
  const date = new Date(`${year}-${month}-${day}`)
  const formattedDate = date.toISOString()?.slice(0, 10)

  return formattedDate
}
export const ViewDetail = ({ request }) => {
  const navigate = useNavigate()
  console.log('data received in viewDetail comp: ', request)
  return request?.request_type === 'URGENT' ? (
    <p
      className='hover:underline cursor-pointer text-blue-600'
      onClick={() =>
        navigate(
          `/buyer/urgent_orders/request_details/${request?.id_open_request}`,
          { state: request }
        )
      }
    >
      View Detail
    </p>
  ) : (
    <p
      className='hover:underline cursor-pointer text-blue-600'
      onClick={() =>
        navigate(
          `/buyer/hft_buyer_change_requests/request_details/${request?.id_open_request}`,
          { state: request }
        )
      }
    >
      View Detail
    </p>
  )
}

const BuyerRequest = () => {
  const [requestData, setRequestData] = useState([])
  const [loading, setLoading] = useState(false)
  const [showChangeRequestBanner, setShowChangeRequestBanner] = useState(false)
  const [poRow,setrowPO] = useState("")
  const [crIdResponse, setCrIdResponse] = useState([])
  const navigate = useNavigate()
  const { t } = useTranslation()
  const tableId = 'BuyerReqTable'; // Define tableId here
  const { HFTBuyerChangeRequests, Home, Loading1 } = t(
    'HFTBuyerChangeRequestsPage'
  )

  // Access translated strings outside useMemo
  const headers = {
    CR: HFTBuyerChangeRequests,
    PONUMBER: t('HFTBuyerChangeRequestsPage.PONUMBER'),
    SKU: t('HFTBuyerChangeRequestsPage.SKU'),
    SUPPLIERNAME: t('HFTBuyerChangeRequestsPage.SUPPLIERNAME'),
    STATUS: t('HFTBuyerChangeRequestsPage.STATUS'),
    REQUESTTYPE: t('HFTBuyerChangeRequestsPage.REQUESTTYPE'),

    REQUESTEDBY: t('HFTBuyerChangeRequestsPage.REQUESTEDBY'),
    CREATEDATE: t('HFTBuyerChangeRequestsPage.CREATEDATE'),
    UPDATEDATE: t('HFTBuyerChangeRequestsPage.UPDATEDATE'),
    CRSTATUS: t('HFTBuyerChangeRequestsPage.CRSTATUS'),
    VENDORRESPONSE: t('HFTBuyerChangeRequestsPage.VENDORRESPONSE'),
    REQSW: t('POList.REQSW'),
    ORGSW: t('POList.ORGSW'),
    CURRSW: t('POList.CURRENTSW'),
    ONTIMETARGETDate: t('HFTBuyerChangeRequestsPage.ONTIMETARGETDate'),
  }



  const columns = useMemo(
    () => [
      {
        header: headers.CR,
        accessorKey: 'id_open_request',
        cell: ({ row }) =>
          row?.original?.request_type === 'URGENT' ? (
            <p
              className='underline cursor-pointer text-blue-500'
              onClick={() =>
                navigate(
                  `/buyer/urgent_orders/request_details/${row?.original.id_open_request}`,
                  { state: row?.original }
                )
              }
            >
              {row?.original?.id_open_request}
            </p>
          ) : (
            <p
              className='underline cursor-pointer text-blue-500'
              onClick={() =>
                navigate(
                  `/buyer/hft_buyer_change_requests/request_details/${row?.original.id_open_request}`,
                  { state: row?.original }
                )
              }
            >
              {row?.original?.id_open_request}
            </p>
          ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.PONUMBER,
        accessorKey: 'po_number',
        cell: (info) => {
          return (
            <p
              className='underline cursor-pointer text-blue-500'
              onClick={() => handlePODetailsClick('buyer', info?.getValue())}
            >
              {info?.getValue()}
            </p>
          )
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.SKU,
        accessorKey: 'sku',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        },
      },
      {
        header: headers.SUPPLIERNAME,
        accessorKey: 'vendor_name',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.REQSW,
        accessorKey: 'requested_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.ORGSW,
        accessorKey: 'original_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CURRSW,
        accessorKey: 'current_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.ONTIMETARGETDate,
        accessorKey: 'on_time_target',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.VENDORRESPONSE,
        accessorKey: 'supplier_response',
        filterFn: (row, id, value) => {
          let value1 = value.toLowerCase()
          let value2 = row.getValue(id)?.toLowerCase()
          return value1.includes(value2)
        }
      },
      {
        header: headers.CRSTATUS,
        accessorKey: 'status',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.REQUESTTYPE,
        accessorKey: 'request_type',
        // filterFn: (row, id, value) => {
        //   let value1 = value.toLowerCase()
        //   let value2 = row.getValue(id)?.toLowerCase()
        //   return value1.includes(value2)

        // },

        filterFn: (row, columnId, filterValue) => {
          if (row.getValue(columnId))
            return row
              .getValue(columnId)
              .toString()
              .toLowerCase()
              .startsWith(filterValue?.toLowerCase())
        }
      },
      {
        header: headers.REQUESTEDBY,
        accessorKey: 'created_by',
        filterFn: (row, id, value) => {
          let value1 = value.toLowerCase()
          let value2 = row.getValue(id)?.toLowerCase()
          return value1.includes(value2)
        }
      },
      
      
      {
        header: headers.CREATEDATE,
        accessorKey: 'created_at',
        cell: (info) =>
          info?.getValue() !== null ? formatDateFit(info?.getValue()) : '',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.UPDATEDATE,
        accessorKey: 'updated_at',
        cell: (info) =>
          info?.getValue() !== null ? formatDateFit(info?.getValue()) : '',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      }
      // {
      //   header: "VIEW DETAILS",
      //   isPlaceholder: true,
      //   enableSorting: false,
      //   cell: (info) => <ViewDetail request={info?.row?.original} />,
      // },
    ],
    [headers, navigate]
  )

  const ChangeRequestTable = ({ tableId, tableData, columns }) => {
    console.log(tableId);
    const location = useLocation()
    const [data, setData] = useState(tableData ?? [])
    const paramname = useLocation()
    const setPathName = paramname?.pathname?.split('/')
    const [csvFileName, setFileName] = useState(setPathName[2])
    const [sorting, setSorting] = useState([])
    const [selectedArray, setSelectedArray] = useState([])
    const [showCancelOrderModal, setShowCancelOrderModal] = useState(false)
    const [globalFilter, setGlobalFilter] = useState('')
    const [columnFilters, setColumnFilters] = useState([])
    const { t } = useTranslation()
    const { Searchallcolumns, CreateChangeRequest, Page, Gotopage, Show } =
      t('BasicTable')
    const [values, setValues] = useState([])
    const [rowSelection, setRowSelection] = useState({})
    const { CancelOrder } = t('HFTBuyerPOChangeRequestDetailsPage')

    const [columnResizeMode, setColumnResizeMode] = React.useState('onChange')

    const [columnResizeDirection, setColumnResizeDirection] =
      React.useState('ltr')
    const [ClearState, setClearState] = useState(false)

    const user = JSON.parse(localStorage.getItem('userData'))

    const table = useReactTable({
      data,
      columns,
      columnResizeMode,
      columnResizeDirection,
      getCoreRowModel: getCoreRowModel(),
      state: {
        rowSelection,
        sorting,
        columnFilters,
        globalFilter
      },
      enableRowSelection: (row) => row?.original?.status === 'OPEN' && row?.original?.supplier_response==='Awaiting Response' ,
      onRowSelectionChange: setRowSelection,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getSortedRowModel: getSortedRowModel(),
      onColumnFiltersChange: setColumnFilters,
      onSortingChange: setSorting,
      onGlobalFilterChange: setGlobalFilter,
      getFacetedRowModel: getFacetedRowModel(),
      getFacetedUniqueValues: getFacetedUniqueValues(),
      getFacetedMinMaxValues: getFacetedMinMaxValues()
    })

    const requiredOutput = () => {
      const selectedRowRaw = table?.getSelectedRowModel().flatRows
      console.log('selected raw data : ', selectedRowRaw)
      const requiredData = []
      selectedRowRaw?.map((row, index) => requiredData.push(row?.original))
      setSelectedArray(requiredData)
      return requiredData
    }

    useEffect(() => {
      requiredOutput()
    }, [rowSelection])

    useEffect(() => {
      setData(tableData ?? [])

      if (
        location?.pathname?.includes('/vendor/po_change_requests') &&
        location?.state
      ) {
        setColumnFilters(location?.state)
      } else if (
        location?.pathname?.includes('/buyer/hft_buyer_change_requests') &&
        location?.state
      ) {
        setColumnFilters(location?.state)
      } else if (location?.state?.includes('dashboard')) {
        setColumnFilters([
          {
            id: 'supplier_response',
            value: 'Awaiting Response'
          },
          {
            id: 'status',
            value: 'OPEN'
          }
        ])
      }
      // console.log('columnFilters0', table)
    }, [tableData, columnFilters])
    // generate csv data
    const generateCSVData = (table) => {
      const data = table?.getFilteredRowModel()?.rows?.map((row) => {
        return row?.original
      })
      return data
    }

    const cleared = () => {
      const localStorageKey = 'all_selected_values';
      localStorage.removeItem(localStorageKey);
      setColumnFilters([]);
      setClearState(prevState => !prevState); // Toggle the state
    }


    const handleColumnResizeEnd = (header, tableId) => {
      const newWidth = header.getSize();
      const storedWidths = JSON.parse(localStorage.getItem('column-widths')) || {};
      if (!storedWidths[tableId]) {
        storedWidths[tableId] = {};
      }
      storedWidths[tableId][header.id] = newWidth;
      localStorage.setItem('column-widths', JSON.stringify(storedWidths));
    };

    const loadColumnWidths = (columns, tableId) => {
      const storedWidths = JSON.parse(localStorage.getItem('column-widths')) || {};
      if (storedWidths[tableId]) {
        columns.forEach(column => {
          if (storedWidths[tableId][column.id]) {
            column.columnDef.size = Number(storedWidths[tableId][column.id]);
          }
        });
      }
    };

    useEffect(() => {
      loadColumnWidths(table.getAllColumns(), tableId);
      // localStorage.removeItem('column-widths'); // Uncomment if you want to clear storage
    }, [table, tableId]);


    useEffect(() => {
      // Event listener to remove item from localStorage on window reload
      const handleBeforeUnload = (event) => {
        // Custom logic to determine if you want to clear localStorage on reload
        localStorage.removeItem('column-widths')
      }
      // Add event listener for beforeunload
      window.addEventListener('beforeunload', handleBeforeUnload)

      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload)
      }
    }, []) // Empty dependency array ensures this effect runs only once on mount

    const openCancelOrderModal = () => {
      setShowCancelOrderModal(true)
    }

    const closeCancelOrderModal = () => {
      setShowCancelOrderModal(false)
    }

    const handleCancelOrderRequests = (e) => {
      e?.preventDefault()
      const req_payload = []
      selectedArray?.map((item) => {
        const req_obj = {
          hftCrId: item?.id_open_request,
          createdBy: item?.created_by,
          vendorNumber: item?.vendor_number,
          po_number: item?.po_number,
          added_by_role_name:
            user?.roleName === 'SUPPLIER_ROLE' ? 'SUPPLIER' : 'BUYER',
          added_by_name:
            user?.roleName === 'SUPPLIER_ROLE'
              ? user?.supplierName
              : user?.fullName,
          comment: 'Change Request Cancelled',
          type: 'Action'
        }
        req_payload?.push(req_obj)
      })
      axios
        .post(
          `${process.env.REACT_APP_API_DOMAIN}:8088/api/cancel-change-request-list`,
          req_payload,ValidateAPI

        )
        .then((response) => {
          console.log('response after Change request:', response?.data)
          setrowPO(response?.data[0]?.po_number)
          setRowSelection({})
          if (response?.data) {
            const crIdResponse = response?.data?.map(
              (item) => item.id_open_request
            )
            console.log('crIdResponse:', crIdResponse)
            setCrIdResponse(crIdResponse)
          }
          setShowChangeRequestBanner(true)
          fetchAllRequests()
        })
        .catch((error) => {
          console.log('error while creating a CR: ', error)
          alert(
            i18next.t(
              'PopUpMessage.ErrorwhilecreatingaChangeRequestPleasetryagain'
            )
          )
        })
      closeCancelOrderModal()
    }

    const closePopUpBanner = () => {
      setShowChangeRequestBanner(false)
    }

    return (
      <div className='w-full flex justify-start items-start text-xs flex-col gap-4 max-h-screen'>
        <div className='w-full justify-between flex items-center text-xs'>
          <div className='relative flex justify-start items-center gap-3'>
            <div>
              <input
                value={globalFilter ?? ''}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className='p-1 py-2 rounded-sm border border-gray-300 outline-none'
                placeholder={Searchallcolumns}
              />
            </div>
            {selectedArray?.length > 0 && (
              <div className='flex gap-3 items-center justify-start'>
                <button
                  className='bg-[#415da1] px-4 h-[30px] rounded-md outline-none text-white min-w-max'
                  onClick={openCancelOrderModal}
                >
                  {CancelOrder}
                </button>
              </div>
            )}
            <PopupModal
              isOpen={showCancelOrderModal}
              message={i18next.t('PopUpMessage.Doyouwanttocancelorder')}
              onCancel={closeCancelOrderModal}
              cancelText={i18next.t('AcceptModal.Cancel')}
              onConfirm={handleCancelOrderRequests}
              confirmText={i18next.t('AcceptModal.Confirm')}
            />
            <PopupBanner
              isOpen={showChangeRequestBanner}
              message={'Change request cancelled successfully'}
              onCancel={closePopUpBanner}
              text={poRow}
              text1={crIdResponse}
      
            />
          </div>
          <div className='flex items-center gap-2'>
            {columnFilters.length > 0 && (
              <div>
                {console.log(columnFilters)}
                <button
                  className='font-medium text-white outline-none bg-[#415da1] w-[90px] rounded-md h-[30px]'
                  onClick={() => {
                    cleared()
                  }}
                >
                  Clear Filters
                </button>
              </div>
            )}{' '}
            {data?.length !== 0 &&
              downloadCSV(csvFileName, generateCSVData(table))}
            <button
              className='border rounded p-1 font-medium text-sm'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className='border rounded p-1 font-medium text-sm'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className='border rounded p-1 font-medium text-sm'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className='border rounded p-1 font-medium text-sm'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
            <span className='min-w-max flex items-center gap-1'>
              <div>{Page}</div>
              <strong>
                {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
              </strong>
            </span>
            <span className='min-w-max flex items-center gap-1'>
              | {Gotopage}
              <input
                type='number'
                id='pagination_input'
                name='pagination_input'
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  table.setPageIndex(page)
                }}
                className='border p-1 rounded w-16'
              />
            </span>
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50, data?.length].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {Show} {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          className={`w-full overflow-x-auto ${window?.location?.pathname?.includes('/po_list/po_record') ? ' min-h-[5cm]' : 'min-h-[14cm]'} `}
        >
          <table className='text-xs border border-gray-300 relative'>
            <thead className='sticky top-0 z-10'>
              {table?.getHeaderGroups()?.map((headerGroup, index) => (
                <tr
                  key={headerGroup?.id + index}
                  className='divide-x-[1px] bg-[#e0ecfc] divide-gray-300 border-[#ccd9e4] border'
                >
                  <th className='font-medium px-5 py-3 frozen-column'>
                    <IndeterminateCheckbox
                      checked={table?.getIsAllPageRowsSelected()}
                      indeterminate={table?.getIsSomePageRowsSelected()}
                      onChange={table?.getToggleAllPageRowsSelectedHandler()}
                    />
                  </th>
                  {headerGroup?.headers?.map((header) => (
                    <th
                      key={header?.id}
                      className='font-medium px-2 py-3 sticky top-0'
                      {...{
                        key: header.id,
                        colSpan: header.colSpan,
                        style: {
                          width: header.getSize()
                        }
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className='w-full flex flex-col items-center justify-center gap-2'
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler()
                          }}
                        >
                          <div className='flex justify-center items-center'>
                            {' '}
                            {/* Container with Flexbox */}
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            &nbsp;&nbsp;
                            <div>
                              {{
                                asc: (
                                  <svg
                                    className='w-3 h-4 text-gray-500'
                                    // className="w-4 h-5 text-gray-500" // Increased size with w-4 and h-5
                                    aria-hidden='true'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 10 14'
                                  // viewBox="0 0 14 20" // Adjusted viewBox for larger arrow
                                  >
                                    <path
                                      stroke='currentColor'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      strokeWidth='2'
                                      d='M5 13V1m0 0L1 5m4-4 4 4'
                                    // d="M1 5h12m0 0-4 4m4-4L9 1"
                                    />
                                  </svg>
                                ),
                                desc: (
                                  <svg
                                    className='w-3 h-4 text-gray-500'
                                    // className="w-4 h-5 text-gray-500" // Increased size with w-4 and h-5
                                    aria-hidden='true'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 10 14'
                                  // viewBox="0 0 14 20" // Adjusted viewBox for larger arrow
                                  >
                                    <path
                                      stroke='currentColor'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      strokeWidth='2'
                                      d='M5 1v12m0 0 4-4m-4 4L1 9'
                                    // d="M13 5H1m0 0 4 4m-4-4 3-3m0 6V1m0 0 3 3m-3-3 3 3"
                                    />
                                  </svg>
                                )
                              }[header.column.getIsSorted()] ?? (
                                  <svg
                                    className='w-3 h-4 text-gray-500'
                                    aria-hidden='true'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 16 20'
                                  >
                                    <path
                                      stroke='currentColor'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      strokeWidth='2'
                                      d='M4 6v13m0 0 3-3m-3 3-3-3m11-2V1m0 0L9 4m3-3 3 3'
                                    // d="M4 6h10m0 0-3-3m3 3-3 3m1 8H1m0 0 3-3m-3 3 3 3"
                                    />
                                  </svg>
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                      {header.column.getCanFilter() ? (
                        <div>
                          <Filter
                            tableId={tableId}
                            column={header.column}
                            table={table}
                            columnFilters={columnFilters}
                            values={values}
                            setValues={setValues}
                            header={header}
                            ClearState={ClearState}
                          />
                        </div>
                      ) : null}
                      <div
                        onDoubleClick={() => header.column.resetSize()}
                        onMouseDown={(e) => {
                          header.getResizeHandler()(e);
                          document.addEventListener('mouseup', () => handleColumnResizeEnd(header, tableId), { once: true });
                        }}
                        onTouchStart={(e) => {
                          header.getResizeHandler()(e);
                          document.addEventListener('touchend', () => handleColumnResizeEnd(header, tableId), { once: true });
                        }}
                        className={`resizer ${table.options.columnResizeDirection} ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                        style={{
                          transform: columnResizeMode === 'onEnd' && header.column.getIsResizing()
                            ? `translateX(${(table.options.columnResizeDirection === 'rtl' ? -1 : 1) * (table.getState().columnSizingInfo.deltaOffset ?? 0)}px)`
                            : '',
                        }}
                      />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table?.getRowModel().rows?.map((row, index) => {
                const rowClassName =
                  index % 2 !== 0 ? 'bg-[#ecedf2]' : 'bg-[#ffffff]'
                return (
                  <tr
                    key={row?.id + index}
                    className={`${rowClassName} text-center divide-x-[1px] divide-gray-300 border border-gray-300`}
                  >
                    <td className='px-2 py-3 frozen-column'>
                      
                      <div className='w-full flex justify-center gap-2 min-w-min'>
                        <IndeterminateCheckbox
                          checked={row.getIsSelected()}
                          indeterminate={row.getIsSomeSelected()}
                          onChange={row.getToggleSelectedHandler()}
                          row={row.original}
                          isdisabled={row?.original?.status !== 'OPEN' || row?.original?.supplier_response!=='Awaiting Response'}
                        />
                      </div>
                    </td>
                    {row?.getVisibleCells()?.map((cell) => (
                      <td
                        className='px-2 py-3'
                        {...{
                          key: cell.id,
                          style: {
                            width: cell.column.getSize()
                          }
                        }}
                      >
                        {flexRender(
                          cell?.column?.columnDef?.cell,
                          cell?.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  const fetchAllRequests = () => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}:8088/api/get-all-cr`, ValidateAPI)
      .then((response) => {
        console.log('response from FCR: ', response?.data)
        setRequestData(response?.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(`error while FRC: `, error)
        setLoading(false)
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }

  useEffect(() => {
    fetchAllRequests()
  }, [])


  return (
    <div className='w-full flex flex-col gap-6 justify-start items-start p-4'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl'>{HFTBuyerChangeRequests}</p>
        </div>
      </div>
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/buyer')}
          >
            {Home}
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span>{HFTBuyerChangeRequests}</span>
          {/* <span>{">"}</span> */}
        </p>
        {/* <p className="flex gap-1 items-center justify-center">
            <span>PO Details - {id}</span>
          </p> */}
      </div>
      {loading ? (
        <div className='w-full min-h-[300px] flex justify-center items-center'>
          <p>{Loading1}</p>
        </div>
      ) : (
        <div className='w-full overflow-x-auto'>
          <ChangeRequestTable tableId={tableId} tableData={requestData} columns={columns} />
        </div>
      )}{' '}
    </div>
  )
}

export default BuyerRequest
