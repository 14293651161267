import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getFilteredRowModel,
  getSortedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { CartState } from '../Context/Context';
import { Filter, dateformatDDMMYEAR, downloadCSV } from '../utils/Utils';

const Notification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('userData'));
  const {
    state: { notifyData, count },
    changeNotificationStaus
  } = CartState();

  const [columnFilters, setColumnFilters] = useState(
    JSON.parse(localStorage.getItem('column_filters')) ?? []
  );

  const [ClearState, setClearState] = useState(false)

  const [sorting, setSorting] = useState([]);

  const [data, setData] = useState(notifyData);

  const { t } = useTranslation();
  const {
    Notification1,
    Home,
    HFTID,
    CreatedDate,
    CreatedBy,
    Details,
    ClearFilters,
  } = t('NotificationPage');
  console.log(t('NotificationPage'));
  const changeNavigation = (type, hftID) => {
    const data =
      user?.roleName === 'BUYER_ROLE'
        ? type?.toLowerCase() === 'urgent'
          ? '/buyer/urgent_orders/request_details'
          : '/buyer/hft_buyer_change_requests/request_details'
        : type?.toLowerCase() === 'urgent'
          ? '/vendor/urgent_orders/request_details'
          : '/vendor/po_change_requests/request_details';
    return data + '/' + hftID;
  };

  // useEffect(() => { }, [notifyData, count]);

  const headers = {
    HFTID: t('NotificationPage.HFTID'),
    CreatedDate: t('NotificationPage.CreatedDate'),
    CreatedBy: t('NotificationPage.CreatedBy'),
    Details: t('NotificationPage.Details'),
    Notification1: 'Notification Type',
  };

  const columns = useMemo(
    () => [
      {
        header: headers.HFTID,
        accessorKey: 'iDOpenRequestID',
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        cell: ({ row }) => (
          <Link
            className='px-4 py-2'
            to={changeNavigation(row?.original?.type, row?.original?.iDOpenRequestID)}
            onClick={() => changeNotificationStaus(row?.original?.iDNOTIFICATION)}
          >
            {row?.original?.iDOpenRequestID}
          </Link>
        ),
        // size: 100,
      },
      {
        header: headers.CreatedDate,
        accessorKey: 'createDate',
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        // size: 100,
      },
      {
        header: headers.CreatedBy,
        accessorKey: 'cREATEDBY',
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        // size: 100,
      },
      {
        header: headers.Details,
        accessorKey: 'dESCRIPTION',
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        size: 350,
      },
      {
        header: headers.Notification1,
        accessorKey: 'notificationType',
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
        // size: 100,
      },
    ],
    [headers]
  );
  console.log(data);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
  });


  const generateCSVData = (table) => {
    const data = table?.getFilteredRowModel()?.rows?.map((row) => {
      return row?.original
    })
    // console.log(data, 'dataaaa')
    return data
  }


  const cleared = () => {
    const localStorageKey = 'all_selected_values';
    localStorage.removeItem(localStorageKey);
    setColumnFilters([])
    setClearState(prevState => !prevState); // Toggle the state
  }
  //error message 
  const [errorMessage, setErrorMessage] = useState(null); 
  // Retrieve the role from localStorage
  const role = localStorage.getItem('role');
  if (!role) return null; // Return null if no role is found

  // Function to navigate based on the user's role
  const navigateRolePage = () => {
    let path = '';
    if (role === 'buyer') {
      path = `/buyer`; 
    } else if (role === 'vendor') {
      path = `/vendor`;
    } else {
      console.log("Invalid role");
      setErrorMessage("Invalid role"); // Set error message if role is invalid
      return;
    }
    navigate(path); // Navigate to the specified path
  };

  return (
    <main className='w-full flex flex-col gap-3 p-3 h-full'>
      <div className='w-full justify-between flex items-center'>
        <div>
          <p className='text-xl font-medium'>{Notification1}</p>
        </div>
      </div>
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigateRolePage(role)}
          >
            {Home}
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span>{Notification1}</span>
        </p>
      </div>

      <div className='flex justify-end items-center gap-2'>
        {columnFilters?.length > 0 && (
          <button
            className='font-medium text-white outline-none bg-[#415da1] w-[90px] rounded-md h-[30px]'
            onClick={() => {
              cleared()
            }}
          >
            Clear Filters
          </button>
        )}
        {/* {data?.length !== 0 && downloadCSV('PO_list', generateCSVData(table))} */}
        <button
          className='border rounded p-1 font-medium text-sm'
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          className='border rounded p-1 font-medium text-sm'
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <button
          className='border rounded p-1 font-medium text-sm'
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          className='border rounded p-1 font-medium text-sm'
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
        <span className='min-w-max flex items-center gap-1'>
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <span className='min-w-max flex items-center gap-1'>
          | | Go to page
          <input
            type='number'
            id='pagination_input'
            name='pagination_input'
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className='border p-1 rounded w-16'
          />
        </span>
        <select
          className='border border-gray-300 rounded-md p-1 outline-none'
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50, data?.length].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>


      {/* <div className='w-full border border-gray-300 text-xs my-4'> */}
        {notifyData?.length !== 0 ? (
          <>
            <div className='w-full overflow-x-auto my-4' style={{height:"9.1cm"}}>
              <table className='w-full text-xs border border-gray-300 relative'>
                <thead className='sticky top-0 z-10'>
                  {table.getHeaderGroups()?.map((headerGroup) => (
                    <tr
                      key={headerGroup.id}
                      className='divide-x-[1px] bg-[#e0ecfc] divide-gray-300 border-[#ccd9e4] border'
                    >
                      {headerGroup.headers?.map((header) => (
                        <th
                          key={header.id}
                          className='font-medium px-2 py-3'
                          style={{ width: header.getSize() }}
                        >
                          {header.isPlaceholder ? null : (
                            <div
                              className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              <div className='flex justify-center items-center'>
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                &nbsp;&nbsp;
                                <div>
                                  {{
                                    asc: (
                                      <svg
                                        className='w-3 h-4 text-gray-500'
                                        aria-hidden='true'
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 10 14'
                                      >
                                        <path
                                          stroke='currentColor'
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          strokeWidth='2'
                                          d='M5 13V1m0 0L1 5m4-4 4 4'
                                        />
                                      </svg>
                                    ),
                                    desc: (
                                      <svg
                                        className='w-3 h-4 text-gray-500'
                                        aria-hidden='true'
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 10 14'
                                      >
                                        <path
                                          stroke='currentColor'
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          strokeWidth='2'
                                          d='M5 1v12m0 0 4-4m-4 4L1 9'
                                        />
                                      </svg>
                                    )
                                  }[header.column.getIsSorted()] ?? (
                                      <svg
                                        className='w-3 h-4 text-gray-500'
                                        aria-hidden='true'
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 16 20'
                                      >
                                        <path
                                          stroke='currentColor'
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          strokeWidth='2'
                                          d='M4 6v13m0 0 3-3m-3 3-3-3m11-2V1m0 0L9 4m3-3 3 3'
                                        />
                                      </svg>
                                    )}
                                </div>
                              </div>
                            </div>
                          )}
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter
                                column={header.column}
                                table={table}
                                columnFilters={columnFilters}
                                HeaderText={header.column.columnDef.header}
                                header={header}
                                ClearState={ClearState}
                              />
                            </div>
                          ) : null}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className='align-top'>
                  {table.getRowModel().rows.map((row) => {
                    const rowData = row.original;
                    const rowStatus = rowData?.status;
                    return (
                      <tr
                        key={row.id}
                        className={`${rowStatus === 'Y' ? 'bg-purple-50' : 'bg-red-200'} 
                      text-center divide-x-[1px] divide-gray-300 border border-gray-300 text-gray-600`}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className='px-4 py-2'
                            style={{ width: cell.column.getSize() }}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/* <div className='w-full overflow-x-auto min-h-[70%]'>
              <table
                className='text-xs border border-gray-300 relative'
                {...{
                  style: {
                    width: table.getCenterTotalSize(),
                  },
                }}
              >
                <thead className='sticky top-0 z-10'>
                  {table.getHeaderGroups().map((headerGroup, index) => (
                    <tr
                      key={headerGroup.id + index}
                      className='divide-x-[1px] bg-[#e0ecfc] divide-gray-300 border-[#ccd9e4] border'
                    >
                      {headerGroup.headers.map((header) => {
                        const { column } = header;
                        return (
                          <th
                            key={header?.id}
                            className={`font-medium px-2 py-3 sticky top-0 ${header.id === 'PO_SKU' || header.id === 'REQUEST_TYPE' ? 'frozen-column' : ''}`}
                            {...{
                              key: header.id,
                              colSpan: header.colSpan,
                              style: {
                                width: header.getSize(),
                              },
                            }}
                          // style={{ ...getCommonPinningStyles(column, table.getAllColumns()) }}
                          >
                            {header.isPlaceholder ? null : (
                              <div
                                className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                <div className='flex justify-center items-center'>
                                  {flexRender(header.column.columnDef.header, header.getContext())}
                                  &nbsp;&nbsp;
                                  <div>
                                    {{
                                      asc: (
                                        <svg
                                          className='w-3 h-4 text-gray-500'
                                          aria-hidden='true'
                                          xmlns='http://www.w3.org/2000/svg'
                                          fill='none'
                                          viewBox='0 0 10 14'
                                        >
                                          <path
                                            stroke='currentColor'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='2'
                                            d='M5 13V1m0 0L1 5m4-4 4 4'
                                          />
                                        </svg>
                                      ),
                                      desc: (
                                        <svg
                                          className='w-3 h-4 text-gray-500'
                                          aria-hidden='true'
                                          xmlns='http://www.w3.org/2000/svg'
                                          fill='none'
                                          viewBox='0 0 10 14'
                                        >
                                          <path
                                            stroke='currentColor'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='2'
                                            d='M5 1v12m0 0 4-4m-4 4L1 9'
                                          />
                                        </svg>
                                      ),
                                    }[header.column.getIsSorted()] ?? (
                                        <svg
                                          className='w-3 h-4 text-gray-500'
                                          aria-hidden='true'
                                          xmlns='http://www.w3.org/2000/svg'
                                          fill='none'
                                          viewBox='0 0 16 20'
                                        >
                                          <path
                                            stroke='currentColor'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='2'
                                            d='M4 6v13m0 0 3-3m-3 3-3-3m11-2V1m0 0L9 4m3-3 3 3'
                                          />
                                        </svg>
                                      )}
                                  </div>
                                </div>
                              </div>
                            )}
                            {header.column.getCanFilter() && (
                              <div>
                                <Filter
                                  column={header.column}
                                  table={table}
                                  columnFilters={columnFilters}
                                  HeaderText={header.column.columnDef.header}
                                  header={header}
                                />
                              </div>
                            )}
                          </th>
                        )
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row, index) => {
                    const rowClassName = index % 2 !== 0 ? 'bg-[#ecedf2]' : 'bg-[#ffffff]';
                    return (
                      <tr
                        key={'tr-polist' + index}
                        className={`${rowClassName} text-center divide-x-[1px] divide-gray-300 border border-gray-300 ${row.getIsSelected() ? 'bg-blue-200' : ''}`}
                      >
                        {row.getVisibleCells().map((cell, cellIndex) => {
                          const { column } = cell;
                          return (
                            <td
                              key={cell.id}
                              className={` px-2 py-3 ${cell.column.id === 'PO_SKU' || cell.column.id === 'REQUEST_TYPE' ? 'frozen-column' : ''}
               `}
                              {...{
                                key: cell.id,
                                style: {
                                  width: cell.column.getSize(),
                                },
                              }}
                            // style={{ ...getCommonPinningStyles(column, table.getAllColumns()) }}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          )
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div> */}
          </>
        ) : (
          ''
        )}
      {/* </div> */}

      {errorMessage && (
        <div className="fixed top-20 left-1/5 w-3/5 bg-red-100 text-red-700 p-4 rounded-lg shadow-md">
          {errorMessage}
        </div>
      )}
    </main>
  );
};

export default Notification;
