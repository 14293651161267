import React from 'react';
import LandingPage from '../components/LandingPage';
import SignoutHandler from '../components/SignoutHandler';

const LoginErrorPage = () => {
  return (
    <LandingPage>
    <div className="flex flex-col items-center justify-center ">
          <div className="bg-white p-6 rounded-lg "> 
            <h1 className="text-2xl font-bold mb-4">Oops! Something went wrong.</h1>
            <p className=" mb-2 font-bold text-red-500">
              {/* The Harbor Freight Systems Management Appliance has encountered an unhandled error. */}
              Session Expired
            </p>
            
            <div className="mt-4 flex items-center justify-center">
              <a href="mailto:support@example.com" className="text-blue-500 underline ml-2 cursor-pointer">
                Contact Support
              </a>
              <p className="p-2 px-3 cursor-pointer hover:text-blue-400 ml-2">
                <SignoutHandler />
              </p>
            </div>
          </div>
        </div>
   
    </LandingPage>
  );
};

export default LoginErrorPage;

