import React, { useState, useEffect } from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { CustomTooltip } from '../../screens/buyer/Dashboard'
import axios from 'axios'
import Loading from '../Loading'
import { FilterComp } from '../../screens/buyer/Dashboard'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ValidateAPI } from '../../utils/Utils'

export const colors = ['#ffbb28', '#00c49f', '#415da1', '#0088fe', '#ff7518']

const PieChartComp = ({ vendorList, buyerList, user }) => {
  const [reqStatusData, setReqStatusData] = useState([])
  const [pieload, setPieload] = useState(false)
  const [selectedBuyer, setSelectedBuyer] = useState('')
  const [selectedVendor, setSelectedVendor] = useState('')
  const [showFilters, setShowFilters] = useState(false)
  const { t } = useTranslation()
  const {
    DistributionbyRequestStatus,
    Filter,
    Norelateddatafound,
    Analyst,
    Vendor
  } = t('pieChart')

  const fetchReqStatusData = () => {
    setPieload(true)
    axios
      .get(
        `${
          process.env.REACT_APP_API_DOMAIN
        }:8088/api/admin/get-all-counts-cr-status-filter?createdBy=${encodeURIComponent(
          user?.fullName
        )}`,ValidateAPI
      )
      .then((response) => {
        console.log('Data from reqtype: ', response?.data)
        const dataArray = Object.entries(response?.data)
          .filter(([key]) => key !== 'TOTAL_CR' && key !== 'OPEN')
          .map(([status, value]) => ({ status, value }))

        console.log(dataArray)
        setReqStatusData(dataArray)
        setPieload(false)
      })
      .catch((error) => {
        console.log('error while fetching reqStatus: ', error)
        setPieload(false)
      })
  }
  const navigate = useNavigate()
  const handleBarClick = (entry) => {
    let status = entry?.payload?.status
    if(user?.userGroupName==="OVERSEAS_ROLE")
      return 
    if (status?.includes('CLOSED')) {
      navigate(`/buyer/hft_buyer_change_requests`, {
        state: [
          {
            id: 'status',
            value: status
          },
          {
            id: 'created_by',
            value: user?.fullName
          }
        ]
      })
    } else {
      status = status === 'AWAITING_RESPONSE' ? 'AWAITING RESPONSE' : status

      navigate(`/buyer/hft_buyer_change_requests`, {
        state: [
          {
            id: 'supplier_response',
            value: status
          },
          {
            id: 'created_by',
            value: user?.fullName
          }
        ]
      })
    }
  }
  useEffect(() => {
    fetchReqStatusData()
  }, [])

  return (
    <div className="w-[50%] flex justify-center items-center sm:w-[100%]
     flex-col gap-4 shadow-lg rounded-md border border-gray-300 p-3 max-h-[360px]">
      <div className="w-full flex justify-start items-center flex-col gap-1.5">
        <p className="text-sm font-medium">{DistributionbyRequestStatus}</p>
        <div className="relative flex justify-between items-center w-full">
          <div className="flex gap-3 relative ">
            {user?.roleType === "MANAGER" && (
              <div className="relative">
                <button
                  onClick={() => setShowFilters(!showFilters)}
                  className='flex outline-none text-sm gap-2 items-center focus:outline-none justify-center rounded-md p-2 border border-gray-300'
                >
                  <span className='px-2 text-xs font-medium'>{Filter}</span>
                  <span>
                    <svg
                      class='w-3 h-3 text-gray-800 dark:text-white'
                      aria-hidden='true'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='currentColor'
                      viewBox='0 0 24 24'
                    >
                      <path d='M10.8 5a3 3 0 0 0-5.6 0H4a1 1 0 1 0 0 2h1.2a3 3 0 0 0 5.6 0H20a1 1 0 1 0 0-2h-9.2ZM4 11h9.2a3 3 0 0 1 5.6 0H20a1 1 0 1 1 0 2h-1.2a3 3 0 0 1-5.6 0H4a1 1 0 1 1 0-2Zm1.2 6H4a1 1 0 1 0 0 2h1.2a3 3 0 0 0 5.6 0H20a1 1 0 1 0 0-2h-9.2a3 3 0 0 0-5.6 0Z' />
                    </svg>
                  </span>
                </button>

                {showFilters && (
                  <FilterComp
                    buyerUpdate={setSelectedBuyer}
                    vendorUpdate={setSelectedVendor}
                    fetchActual={fetchReqStatusData}
                    showFilter={setShowFilters}
                    filteredData={setReqStatusData}
                    graph={'requestStatus'}
                    vendorList={vendorList}
                    buyerList={buyerList}
                  />
                )}
              </div>
            )}
            <div className='flex gap-2'>
              {selectedBuyer && (
                <p className='border-blue-600 border border-dashed rounded-md p-2 text-xs font-medium flex gap-1 min-w-max'>
                  <span className='text-gray-500'>{Analyst}</span>
                  <span> {selectedBuyer}</span>
                </p>
              )}
              {selectedVendor && (
                <p className='border-blue-600 border border-dashed rounded-md p-2 text-xs font-medium flex gap-3 min-w-max'>
                  <span className='text-gray-500'>{Vendor} </span>
                  <span>{selectedVendor}</span>
                </p>
              )}
            </div>
          </div>
        </div>
        {!pieload ? (
          reqStatusData ? (
            <>
              <ResponsiveContainer width='90%' height={180}>
                <PieChart>
                  <Tooltip content={<CustomTooltip />} />
                  <Pie
                    data={reqStatusData}
                    innerRadius={55}
                    outerRadius={70}
                    paddingAngle={5}
                    dataKey='value'
                    onClick={(e) => handleBarClick(e)}
                  >
                    {reqStatusData?.map((entry, index) => (
                      <Cell
                        key={`cell-${entry?.status}`}
                        fill={colors[index]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <div className='w-full flex gap-5 justify-center flex-wrap'>
                {reqStatusData?.map((item, index) => (
                  <div className='flex gap-2 items-center'>
                    <div
                      className='w-[10px] h-[10px]'
                      style={{ backgroundColor: colors[index] }}
                    />
                    <p className='text-xs'>{item?.status}</p>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <p>{Norelateddatafound}</p>
          )
        ) : (
          <Loading />
        )}
      </div>
    </div>
  )
}

export default PieChartComp
