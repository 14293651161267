import React, { useEffect, useMemo, useState } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues
} from '@tanstack/react-table'
import { Filter, ValidateAPI, handlePODetailsClick } from '../../utils/Utils'
import axios from 'axios'
import Loading from '../../components/Loading'
import UserProfile from '../../components/UserProfile'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const MyTasks = () => {
  const user = JSON.parse(localStorage.getItem('userData'))
  const { requestType, vendorId } = useParams()
  const [data, setData] = useState([])
  const [sorting, setSorting] = useState([])
  const [selectedArray, setSelectedArray] = useState([])
  const [showConfirm, setShowConfirm] = useState(false)
  const [globalFilter, setGlobalFilter] = useState('')
  const [columnFilters, setColumnFilters] = useState([])
  const [loading, setLoading] = useState(false)
  const [showLegend, setShowLegend] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    MyTasks,
    Home,
    Page,
    Gotopage,
    Show,
    RequestType,
    VendorID,
    Searchallcolumns
  } = t('OpenTaskMyTask')
  const columns = useMemo(
    () => [
      {
        header: 'PO NUMBER',
        accessorKey: 'PO_NUMBER',
        cell: (info) => (
          <p
            className='hover:underline cursor-pointer'
            onClick={() => handlePODetailsClick(info?.getValue())}
          >
            {info?.getValue()}
          </p>
        )
      },
      {
        header: 'PO CREATION DATE',
        accessorKey: 'PO_CREATION_DATE',
        cell: (info) => info?.getValue()?.split('T')?.[0]
      },
      { header: 'REVISION NUMBER', accessorKey: 'REVISION_NUMBER' },
      { header: 'PO HEADER DESC', accessorKey: 'PO_HEADER_DESC' },
      { header: 'SKU', accessorKey: 'ITEM' },
      { header: 'DESCRIPTION', accessorKey: 'ITEM_DESCRIPTION' },
      { header: 'UPC', accessorKey: 'UPC' },
      { header: 'DC', accessorKey: 'DC' },
      { header: 'CURRENT QUANTITY', accessorKey: 'CURRENT_QUANTITY' },
      { header: 'REQUESTED SHIP WEEK', accessorKey: 'REQUESTED_SHIP_WEEK' },
      { header: 'ORIGINAL SHIP WEEK', accessorKey: 'ORIGINAL_SHIP_WEEK' },
      { header: 'CURRENT SHIP WEEK', accessorKey: 'CURRENT_SHIP_WEEK' },
      { header: 'CURRENCY', accessorKey: 'CURRENCY' },
      { header: 'UNIT PRICE', accessorKey: 'UNIT_PRICE' },
      { header: 'EXTENDED COST', accessorKey: 'EXTENDED_COST' },
      {
        header: 'PROMISED DATE',
        accessorKey: 'PROMISED_DATE',
        cell: (info) => info?.getValue()?.split('T')?.[0]
      },
      {
        header: 'NEED BY DATE',
        accessorKey: 'NEED_BY_DATE',
        cell: (info) => info?.getValue()?.split('T')?.[0]
      },
      { header: 'VENDOR#', accessorKey: 'VENDOR#' },
      { header: 'SUPPLIER NAME', accessorKey: 'SUPPLIER_NAME' },
      { header: 'VENDOR CONTACT', accessorKey: 'VENDOR_CONTACT' },
      { header: 'RECEIVED QUANTITY', accessorKey: 'RECEIVED_QUANTITY' },
      { header: 'SHIP TO LOCATION', accessorKey: 'SHIP_TO_LOCATION' },
      { header: 'DESTINATION SUB INV', accessorKey: 'DESTINATION_SUB_INV' },
      { header: 'FOB', accessorKey: 'FOB' },
      { header: 'MANUFACTURING PART#', accessorKey: 'MANUFACTURING_PART#' },
      { header: 'PO SHIPMENT NUMBER', accessorKey: 'PO_SHIPMENT_NUMBER' },
      {
        header: 'DESTINATION WAREHOUSE',
        accessorKey: 'DESTINATION_WAREHOUSE'
      },
      { header: 'FREIGHT TERMS', accessorKey: 'FREIGHT_TERMS' },
      { header: 'PAYMENT TERMS', accessorKey: 'PAYMENT_TERMS' },
      { header: 'SHIP VIA', accessorKey: 'SHIP_VIA' },
      { header: 'COST SHOWN', accessorKey: 'COST_SHOWN' },
      { header: 'PO OPEN QTY', accessorKey: 'PO_OPEN_QTY' },
      { header: 'NOTE TO SUPPLIER', accessorKey: 'NOTE_TO_SUPPLIER' },
      { header: 'SHIPPED QUANTITY', accessorKey: 'SHIPPED_QUANTITY' },
      { header: 'RECEIVED QUANTITY', accessorKey: 'RECEIVED_QUANTITY_R' },
      { header: 'RECORD TYPE', accessorKey: 'RECORD_TYPE' },
      {
        header: 'APPROVED DATE',
        accessorKey: 'APPROVED_DATE',
        cell: (info) => info?.getValue()?.split('T')?.[0]
      },
      {
        header: 'CLOSED DATE',
        accessorKey: 'CLOSED_DATE',
        cell: (info) => info?.getValue()?.split('T')?.[0]
      },
      {
        header: 'BUYER',
        accessorKey: 'BUYER'
      },
      {
        header: 'PO HEADER DESCRIPTION',
        accessorKey: 'PO_HEADER_DESCRIPTION'
      },
      { header: 'HTS', accessorKey: 'HTS' }
    ],
    []
  )
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
      globalFilter
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true
  })

  const fetchData = () => {
    setLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/oracle-data-by-hft-userid?hftUserId=${user?.vendorOrcaleId}`,ValidateAPI
      )
      .then((response) => {
        setData(response?.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log('Error while fetching po data :', error)
        setLoading(false)
        if(error.response.status===401 || error.response.status===403){
          navigate('/login-error')
        }
      })
  }

  // useEffect(() => {
  //     fetchData()
  // }, [])

  useEffect(() => {
    if (table.getState().columnFilters[0]?.id === 'fullName') {
      if (table.getState().sorting[0]?.id !== 'fullName') {
        table.setSorting([{ id: 'fullName', desc: false }])
      }
    }
  }, [table.getState().columnFilters[0]?.id])
  return (
    <div className='w-full flex justify-start items-start text-xs flex-col gap-4'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl'>{MyTasks}</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/buyer')}
          >
            {Home}
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span>{MyTasks}</span>
          {/* <span>{">"}</span> */}
        </p>
        {/* <p className="flex gap-1 items-center justify-center">
            <span>PO Details - {id}</span>
          </p> */}
      </div>
      <div className='w-full justify-between flex items-center text-xs'>
        <div>
          <input
            value={globalFilter ?? ''}
            onChange={(e) => setGlobalFilter(e.target.value)}
            className='p-1 py-2 rounded-sm border border-gray-300 outline-none'
            placeholder={Searchallcolumns}
          />
        </div>
        <div className='flex items-center gap-2'>
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className='border rounded p-1 font-medium text-sm'
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <span className='min-w-max flex items-center gap-1'>
            <div>{Page}</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </strong>
          </span>
          <span className='min-w-max flex items-center gap-1'>
            | {Gotopage}
            <input
              type='number'
              id='pagination_input'
              name='pagination_input'
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                table.setPageIndex(page)
              }}
              className='border p-1 rounded w-16'
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50, data?.length].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {Show} {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div></div>
      {loading ? (
        <Loading />
      ) : (
        <div className='w-full overflow-x-auto'>
          <div className='w-full flex gap-3 '></div>
          <p>
            {RequestType} {requestType}
          </p>
          <p>
            {VendorID} {vendorId}
          </p>
          <p></p>

          {/* <table className="w-full border border-gray-300">
            <thead>
              {table?.getHeaderGroups()?.map((headerGroup, index) => (
                <tr
                  key={headerGroup?.id + index}
                  className="divide-x-[1px] bg-[#e0ecfc] divide-gray-300 border-[#ccd9e4] border"
                >
                  {headerGroup?.headers?.map((header) => (
                    <th key={header?.id} className="font-medium px-2 py-3">
                      {header.isPlaceholder ? null : (
                        <div
                          className="w-full flex flex-col items-center justify-center gap-2"
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          <div>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>

                          {header.column.getCanSort() ? (
                            <div className="w-full flex justify-center items-center">
                              {{
                                asc: (
                                  <svg
                                    class="w-2 h-3 text-gray-500"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 14"
                                  >
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M5 13V1m0 0L1 5m4-4 4 4"
                                    />
                                  </svg>
                                ),
                                desc: (
                                  <svg
                                    class="w-2 h-3 text-gray-500"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 14"
                                  >
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M5 1v12m0 0 4-4m-4 4L1 9"
                                    />
                                  </svg>
                                ),
                              }[header.column.getIsSorted()] ?? (
                                <svg
                                  class="w-3 h-4 text-gray-500"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 16 20"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6v13m0 0 3-3m-3 3-3-3m11-2V1m0 0L9 4m3-3 3 3"
                                  />
                                </svg>
                              )}
                            </div>
                          ) : null}
                        </div>
                      )}
                      {header.column.getCanFilter() ? (
                        <div>
                          <Filter column={header.column} table={table} />
                        </div>
                      ) : null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table?.getRowModel().rows?.map((row, index) => (
                <tr
                  key={"tr_my_task" + row?.id}
                  className={`${
                    row?.id % 2 !== 0 ? "bg-[#ecedf2]" : "bg-[#ffffff]"
                  } text-center divide-x-[1px] divide-gray-300 border border-gray-300`}
                >
                  {row?.getVisibleCells()?.map((cell) => (
                    <td className="px-2 py-3">
                      {flexRender(
                        cell?.column?.columnDef?.cell,
                        cell?.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table> */}
        </div>
      )}
    </div>
  )
}

export default MyTasks
