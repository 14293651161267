export const inititalState = {
  notifyData: [],
  count: 0,
  updateCount:[]
}
export const NotificationReducer = (state, action) => {
  switch (action.type) {
    case 'ADDCOUNT':
      return {
        ...state,
        count: action.payload
      }

    case 'ADDLIST':
      return {
        ...state,
        notifyData: action.payload
      }
      case 'UPDATECOUNT':
        return{
          ...state,
          updateCount:action.payload
        }
  }
}
