

// src/EmailConfig.js
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import JoditEditor from 'jodit-react';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { ValidateAPI } from '../../utils/Utils';
import Cookies from 'js-cookie'

const EmailConfig = () => {
  const navigate = useNavigate()
  const [cronjobId, setCronJobID] =   useState("")
  const editor = useRef(null);
  const [content, setContent] = useState("");
  let templateEx = `<!DOCTYPE html><html><head><meta charset="UTF-8" /><title>Email Notification</title></head><body>${content}</body></html>`
  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: 'Start typings...',
      style: {
        font: {
          options: [
            'Arial, Helvetica, sans-serif',
            'Times New Roman, Times, serif',
            'Georgia, serif',
            'Courier New, Courier, monospace',
            'Custom Font' // Add your custom font here
          ]
        }
      }
    }),
    []
  );
  const [templateData, setTemplateData] = useState([])
  
  const getAllTemplate = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/email/getAllEmailTempate`,ValidateAPI)
      .then((res) => {
        if (res) {
          console.log(res);
          setTemplateData(res?.data)
        }
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      });
  }
  
  const handlecreate = () => {
    if(cronjobId==="")
   return alert("please Select Cron job Name")
    let cleanHtmlContent =templateEx
    //add border before submittting the template
    if (cleanHtmlContent.includes("<table")){
      cleanHtmlContent = cleanHtmlContent.replaceAll("<table", '<table border="1" ');
      }
      const token = Cookies.get('idcs_user_assertion')
      const role = Cookies.get('role')
    axios
      .put(
        `${process.env.REACT_APP_API_DOMAIN}:8088/email/updateEmailTemplate?cronJobId=${cronjobId}`,  cleanHtmlContent, {
          headers: {
            // 'Content-Type': 'application/json',
            'Content-Type': 'text/html',
            'Authorization': `Bearer ${token}`,
            'Role': role
          },
          
        }
          
      )
      .then((res) => {
        if (res) {
          alert("Submitted Successfully");
          setCronJobID("")
          setContent("")
          getAllTemplate()
        }
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      });
  }

  useEffect(() => {
    getAllTemplate()
  }, [])
  return (
    <div className='p-2 w-full  flex flex-col  gap-5'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl font-medium'>Email Template Configuration</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      {/* BreadCrumb */}
      <div className='w-full text-xs flex  justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/admin')}
          >
            Home
          </span>
          <span>{'>'}</span>
        </p>

        <p className='flex gap-1 items-center justify-center'>
          <span>configuration </span>
        </p>
      </div>
      <div class="w-1/4 group">
        <label
          class="block  text-sm font-medium text-gray-900 dark:text-white  "
        >
          Cron Job Name
        </label>
        <select name="cycle" required onChange={(e) => setCronJobID(e?.target?.value)}
          className='bg-gray-50  border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'>
          <option selected>Select</option>
          {
            templateData?.map((data) => {
              return (
                <option value={data?.CRON_JOB_ID}>{data?.CRON_JOB_NAME}</option>
              )
            })
          }
        </select>
      </div>
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        tabIndex={1} // tabIndex of textarea
        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
        onChange={(newContent) => { console.log(newContent) }}
      />
      <button onClick={handlecreate} className='text-center  w-1/5  border p-2 rounded-md bg-gray-200 text-black cursor-pointer border-gray-500'>Update Template</button>
      <div className="w-full overflow-x-auto min-h-[14cm]">
        <table className="text-xs border border-gray-300 relative w-full">
          <thead className="sticky top-0 z-10"
          // style={{ height: values.length>0?theadHeight1:theadHeight}}
          > <tr

            // className='divide-x-[1px] bg-[#e0ecfc] divide-gray-300 border-[#ccd9e4] border'
          >
              <th key='' className='font-medium px-2 py-3'>
                CRON JOB
              </th>
              <th key='' className='font-medium px-2 py-3'>
                EMAIL TEMPLATE
              </th>

            </tr>
          </thead>
          <tbody>
            {
              templateData?.map((data, index) => {
                return (
                  <tr
                    // className={` text-center divide-x-[1px] divide-gray-300 border border-gray-300`}
                  >
                    <td className='px-2 py-3'>
                      {data?.CRON_JOB_NAME}
                    </td>
                    <td className='px-2 py-3'>
                      < div dangerouslySetInnerHTML={{ __html: data?.EMAIL_TEMPATE }}       />
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmailConfig;
