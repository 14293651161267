import React from 'react'
import UserProfile from '../../components/UserProfile'
import VendorBarChart from '../../components/vendor/VendorBarChart'
import VendorPieChart from '../../components/vendor/VendorPieChart'
import VendorTableView from '../../components/vendor/VendorTableView'

const VendorDashboard = () => {
  return (
    <div className='w-full flex flex-col gap-4 '>
      <div className='w-full flex justify-end items-center'>
        {/* <UserProfile /> */}
      </div>
      <div className='w-full flex gap-5 justify-center flex-col lg:flex-row'>
          <VendorBarChart />
          <VendorPieChart />
        </div>
      <div className='w-full flex flex-col justify-center items-center gap-5 '>
        <div className='w-full flex gap-3 justify-center items-center min-w-max'>
          <VendorTableView />
        </div>
      </div>
    </div>
  )
}

export default VendorDashboard
