import React, { useEffect, useMemo, useState } from 'react'
import UserProfile from '../UserProfile'
import { formatDate } from '../../screens/buyer/BuyerRequest'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router'
import BasicTable from '../BasicTable'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { ValidateAPI } from '../../utils/Utils'

const CRHistory = () => {
  const { poNumber, sku } = useParams()
  const [requestData, setRequestData] = useState([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { Loading1 } = t('CRHistory')
  const navigate = useNavigate()
  const headers = {
    PONUMBER: t('CRHistory.PONUMBER'),
    CR: t('CRHistory.CR'),
    SKU: t('CRHistory.SKU'),
    STATUS: t('CRHistory.STATUS'),
    REQUESTTYPE: t('CRHistory.REQUESTTYPE'),
    REQUESTEDBY: t('CRHistory.REQUESTEDBY'),
    CREATEDATE: t('CRHistory.CREATEDATE'),
    UPDATEDATE: t('CRHistory.UPDATEDATE')
  }
 const checkRole = (row)=>{
  const user = JSON.parse(localStorage.getItem('userData'))
  const url = user?.roleName==="SUPPLIER_ROLE"?'/vendor/po_change_requests/request_details':'/buyer/hft_buyer_change_requests/request_details'
  return (
    <p
    className='underline cursor-pointer text-blue-500'
    onClick={() =>
      navigate(
        `${url}/${row?.original.id_open_request}`,
        { state: row?.original }
      )
    }
  >
    {row?.original?.id_open_request}
  </p>
  )
 }
  const columns = useMemo(
    () => [
      { 
        header: headers.CR, 
        accessorKey: 'id_open_request' ,
        cell: ({ row }) =>checkRole(row)
      
      },
      { header: headers.PONUMBER, accessorKey: 'po_number' },
      {
        header: headers.SKU,
        accessorKey: 'sku',
        cell: (info) => Number(info?.getValue())
      },

      { header: headers.STATUS, accessorKey: 'status' },
      { header: headers.REQUESTTYPE, accessorKey: 'request_type' },
      {
        header: headers.REQUESTEDBY,
        accessorKey: 'created_by',
        cell: (info) => info?.getValue() ?? 'System'
      },
      {
        header: headers.CREATEDATE,
        accessorKey: 'created_at',
        cell: (info) =>
          info?.getValue() !== null ? formatDate(info?.getValue()) : ''
      },
      {
        header: headers.UPDATEDATE,
        accessorKey: 'updated_at',
        cell: (info) =>
          info?.getValue() !== null ? formatDate(info?.getValue()) : ''
      }
    ],
    [t]
  )

  const fetchCRHistory = () => {
    setLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-crid-history?poNumber=${poNumber}&sku=${sku}`,ValidateAPI
      )
      .then((response) => {
        console.log('response from cr history: ', response?.data)
        setRequestData(response?.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error while fetching cr history: ', error)
        setLoading(false)
        if(error.response.status===401 || error.response.status===403){
          navigate('/login-error')
        }
      })
  }

  useEffect(() => {
    fetchCRHistory()
  }, [])

  // const{t} = useTranslation()
  // const{Loading1} = t("CRHistory")
  return (
    <div className='w-full flex flex-col gap-6 justify-start items-start p-4 font-pop'>
      {loading ? (
        <div className='w-full min-h-[300px] flex justify-center items-center'>
          <p>{Loading1}</p>
        </div>
      ) : (
        <div className='w-full overflow-x-auto'>
          <BasicTable tableData={requestData} columns={columns} />
        </div>
      )}
    </div>
  )
}

export default CRHistory
