import React, { useEffect, useRef, useState } from 'react'
import UserProfile from '../../components/UserProfile'
import {
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import axios from 'axios'
import BarChartComp from '../../components/buyer/BarChartComp'
import PieChartComp from '../../components/buyer/PieChartComp'
import DelayRequestChart from '../../components/buyer/DelayRequestChart'
import HorizontalBarCRAge from '../../components/buyer/HorizontalBarCRAge'
import LanguageSelector from './Language-selector'
import { useTranslation } from 'react-i18next'
import { ValidateAPI } from '../../utils/Utils'
import VendorBarChart from '../../components/vendor/VendorBarChart'
import VendorPieChart from '../../components/vendor/VendorPieChart'
import VendorTableView from '../../components/vendor/VendorTableView'
// import { t } from 'i18next';

export const FilterComp = ({
  buyerUpdate,
  vendorUpdate,
  fetchActual,
  showFilter,
  graph,
  filteredData,
  buyerList,
  vendorList
}) => {
  const [buyer, setBuyer] = useState('')
  const [vendor, setVendor] = useState('')
  const [url, setUrl] = useState('')
  const { t } = useTranslation()
  const {
    InventoryAnalyst,
    SelectanAnalyst,
    Vendor,
    SelectanVendor,
    Reset,
    ApplyFilters,
    Expedite,
    Divert,
    Defer,
    Delay,
    Urgent
  } = t('DashboardBuyer')

  const resetFilters = () => {
    buyerUpdate('')
    vendorUpdate('')
    setBuyer('')
    setVendor('')
    fetchActual()
    showFilter(false)
  }

  const fetchFilteredData = () => {
    console.log('filter api');
    if (buyer?.length > 0 || vendor?.length > 0) { // Changed from '&&' to '||'
      console.log('at least one value present');
      let url;
      if (graph === 'requestType') {
        url = `${process.env.REACT_APP_API_DOMAIN
          }:8088/api/admin/get-all-counts-cr-types-filter?vendorNumber=${vendor}&createdBy=${encodeURIComponent(
            buyer
          )}`;
      } else if (graph === 'requestStatus') {
        url = `${process.env.REACT_APP_API_DOMAIN
          }:8088/api/admin/get-all-counts-cr-status-filter?vendorNumber=${vendor}&createdBy=${encodeURIComponent(
            buyer
          )}`;
      } else if (graph === 'requestAge') {
        url = `${process.env.REACT_APP_API_DOMAIN
          }:8088/api/admin/get-all-counts-cr-age-filter?vendorNumber=${vendor}&createdBy=${encodeURIComponent(
            buyer
          )}`;
      } else if (graph === 'shipWeekDelay') {
        url = `${process.env.REACT_APP_API_DOMAIN
          }:8088/api/admin/get-all-counts-shipweekdelays-filter?vendorNumber=${vendor}&createdBy=${encodeURIComponent(
            buyer
          )}`;
      }
      console.log('url b4 request: ', url);
      axios
        .get(url, ValidateAPI)
        .then((response) => {
          if (graph === 'requestStatus') {
            const dataArray = Object.entries(response?.data)
              .filter(([key]) => key !== 'TOTAL_CR' && key !== 'OPEN')
              .map(([status, value]) => ({ status, value }));
  
            console.log(dataArray);
            filteredData(dataArray);
          } else {
            filteredData(response?.data);
          }
          showFilter(false);
        })
        .catch((err) => {
          console.log('err while filtering data: ', err);
          showFilter(false);
        });
    } else {
      alert('Please select at least one of the filter values before submitting');
    }
  };
  

  return (
    <div className='p-3 rounded-md shadow-lg flex flex-col gap-3 transition-opacity w-[200px] absolute top-10 left-0 z-10 bg-slate-600 text-white'>
      <div className='flex flex-col items-start justify-start gap-3'>
        <p className='text-xs font-medium'>{InventoryAnalyst}</p>
        <select
          name=''
          id=''
          className='border border-gray-300 rounded-md py-2 p-1 text-xs font-medium w-full text-black'
          onChange={(e) => {
            buyerUpdate(e?.target.value)
            setBuyer(e?.target?.value)
          }}
        >
          <option defaultValue className='p-1 cursor-pointer'>
            {SelectanAnalyst}
          </option>

          {buyerList?.map((buyer, index) => {
            return (
              <option
                key={buyer?.buyerName + index}
                value={buyer?.buyerName}
                className='p-1 cursor-pointer'
              >
                {buyer?.buyerFullName}
              </option>
            )
          })}
        </select>
      </div>
      <div className='flex flex-col items-start justify-start gap-3'>
        <p className='text-xs font-medium'>{Vendor}</p>
        <select
          name=''
          id=''
          className='border border-gray-300 rounded-md py-2 p-1 text-xs font-medium w-full text-black'
          onChange={(e) => {
            vendorUpdate(e?.target.value)
            setVendor(e?.target?.value)
          }}
        >
          <option selected className='p-1 cursor-pointer'>
            {SelectanVendor}
          </option>
          {vendorList?.map((vendor, index) => {
            return (
              <option
                key={'vendorOption' + vendor?.vendorName + index}
                value={vendor?.vendorOracleId}
                className='p-1 cursor-pointer'
              >
                {vendor?.vendorName}
              </option>
            )
          })}
        </select>
      </div>
      <div className='flex justify-between items-center w-full mt-4'>
        <button
          className='border border-gray-300 text-xs font-medium rounded-md p-2 focus:outline-none'
          onClick={resetFilters}
        >
          {Reset}
        </button>
        <button
          className='bg-blue-500 border border-blue-500 focus:outline-none text-xs font-medium text-white p-2 outline-none rounded-md'
          onClick={fetchFilteredData}
        >
          {ApplyFilters}
        </button>
      </div>
    </div>
  )
}

const barData = [
  {
    name: 'Expedite',
    value: 20
  },
  {
    name: 'Divert',
    value: 13
  },
  {
    name: 'Defer',
    value: 10
  },
  {
    name: 'Delay',
    value: 5
  },
  {
    name: 'Urgent',
    value: 19
  }
]

export const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload?.length) {
    const data = payload[0].payload
    return (
      <div
        style={{
          background: 'rgba(255, 255, 255, 0.8)',
          padding: '5px',
          borderRadius: '5px'
        }}
      >
        <p className='text-xs'> {`${data.status}: ${data.value}`}</p>
      </div>
    )
  }

  return null
}

const Dashboard = () => {
  const [buyerList, setBuyerList] = useState([])
  const [vendorList, setVendorList] = useState([])
  const user = JSON.parse(localStorage.getItem('userData'))
  const [searchString, setSearch] = useState("")
  const [vendorListOverSeas, setOverSeas] = useState([])
  const [open, setOpen] = useState(false)
  const [vendorOracleId, setVendorOracleId] = useState("")
  const fetchBuyerList = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-user-list-dashboard`, ValidateAPI
      )
      .then((response) => {
        console.log('buyer list: ', response?.data)
        setBuyerList(response?.data)
      })
      .catch((err) => {
        console.log('err while vendor list fethc: ', err)
      })
  }

  const fetchVendorList = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/po/VendorNameOrSKUBySearchString?searchString=`, ValidateAPI
      )
      .then((response) => {
        console.log('vendor list: ', response?.data)
        setVendorList(response?.data)
      })
      .catch((err) => {
        console.log('err while vendor list fethc: ', err)
      })
  }
  const fetchVendorListForOverseas = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/cpi-office-vendors?cpioffice=${user?.roleType}&searchByNameOrNum=${searchString}`, ValidateAPI
      )
      .then((response) => {
        setOverSeas(response?.data)
      })
      .catch((err) => {
        console.log('err while vendor list fethc: ', err)
      })
  }
  useEffect(() => {
    if (user?.userGroupName !== "OVERSEAS_ROLE") {
      fetchVendorList()
      fetchBuyerList()
    }
  }, [])
  useEffect(() => {
    const list = setTimeout(() => {
      if (user?.userGroupName === "OVERSEAS_ROLE") {
        fetchVendorListForOverseas()
      }
    }, 1000);
    return ()=> clearTimeout(list)
  }, [searchString])
  const handleSearch = (number, name) => {
    setSearch(number + " - " + name);
    setVendorOracleId(number)
    setOpen(false)
  }
  const vendorListRef = useRef(null)
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        vendorListRef.current &&
        !vendorListRef.current.contains(event.target)
      ) {
        // Clicked outside the notification, close it
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      // Cleanup: Remove the event listener when the component is unmounted
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])
  return (
    <div className='w-full flex flex-col gap-4 '>
      {/* Dashboard for all buyer and manager role */}
      {
        user?.userGroupName !== "OVERSEAS_ROLE" &&
        <div className='w-full flex flex-col justify-center items-center gap-5 '>
          <div className='w-full flex gap-5 justify-center  flex-col lg:flex-row'>
            <BarChartComp
              buyerList={buyerList}
              vendorList={vendorList}
              user={user}
            />
            <PieChartComp
              buyerList={buyerList}
              vendorList={vendorList}
              user={user}
            />
          </div>
          <div className='w-full flex gap-5 justify-center items-center flex-col lg:flex-row'>
            <HorizontalBarCRAge
              buyerList={buyerList}
              vendorList={vendorList}
              user={user}
            />
            <DelayRequestChart
              buyerList={buyerList}
              vendorList={vendorList}
              user={user}
              type="Shipweek"
            />
          </div>
        </div>
      }

      {/* Dashboard for overseas role */}
      {user?.userGroupName === "OVERSEAS_ROLE" &&
        <div className=' w-full space-y-3 '>
          <div className=' gap-3 text-sm w-full md:w-1/2 relative'>
            <div className=' border border-gray-400 rounded-sm flex items-center  ' onClick={() => setOpen(!open)}>
              <input name="search"
                type='text'
                value={searchString}
                className='outline-none p-2 w-full'
                placeholder='Please Enter Vendor Name or Number'
                onChange={(e) => setSearch(e?.target?.value?.toUpperCase())}
                onFocus={() => { setSearch(''); setVendorOracleId("") }}
              />
              <span className='mx-2'>{'|'}</span>
              <svg
                className='w-4 h-4 text-gray-800 mr-2 '
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 20 20'
              >
                <path
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                />
              </svg>
            </div>
            {
              open &&
              <div className='overflow-y-auto max-h-64 absolute z-50 bg-white  border border-gray-400 rounded-lg w-full' 
                ref={vendorListRef}
              >
                {
                  vendorListOverSeas?.length !== 0 ?
                    vendorListOverSeas?.map((data) => {
                      return (
                        <div className='p-1 hover:bg-blue-500 hover:text-white cursor-pointer'
                          onClick={() => handleSearch(data?.vendorNum, data?.vendorName)}>
                          {data?.vendorNum + " - " + data?.vendorName}
                        </div>
                      )
                    })
                    : (<h1 className='text-sm text-gray-500 text-center p-2'>No Data Found</h1>)
                }
              </div>
            }
          
          </div>
          <div className='w-full flex gap-5 justify-center flex-col lg:flex-row'>
            <VendorBarChart vendor={vendorOracleId} cpiOffice={user?.roleType} />
            <VendorPieChart vendor={vendorOracleId} cpiOffice={user?.roleType} />
          </div>
          <div className='w-full flex flex-col justify-center items-center gap-5 '>
            <div className='w-full flex gap-3 justify-center items-center min-w-max'>
              <VendorTableView vendor={vendorOracleId} cpiOffice={user?.roleType} />
            </div>
          </div>
        </div>}
    </div>
  )
}

export default Dashboard
