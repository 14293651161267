import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UserProfile from '../../components/UserProfile'
import ConfirmTable from '../../components/ConfirmTable'
import axios from 'axios'
import { formatDate } from '../buyer/BuyerRequest'
import DatePicker from 'react-datepicker'
import {
  ValidateAPI,
  getStartDate,
  handlePODetailsClick,
  reasonCodes as reason_codes,
  updateActionHistory
} from '../../utils/Utils'
import { weeksInBetween } from './ConfirmPo'
import { getWeek } from '../buyer/NewPoList'
import Loading from '../../components/Loading'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import PopupModal from '../../components/PopupModal'
import PopupBanner from '../../components/PopupBanner'
import { CartState } from '../../Context/Context'

const ReconfirmPo = () => {
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem('userData'))
  const [selectedRowArray, setSelectedRowArray] = useState([])
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [showReasonCode, setShowReasonCode] = useState(false)
  const [showConfirmOrdersModal, setShowConfirmOrdersModal] = useState(false)
  const [showRerequestChangeModal, setShowRerequestChangeModal] =
    useState(false)
  const [showChangeRequestBanner, setShowChangeRequestBanner] = useState(false)
  const [poRow, setPoRow] = useState('')
  const [crIdResponse, setCrIdResponse] = useState('')
  const [reasonArray, setReasonArray] = useState([])
  const [date, setDate] = useState(new Date())
  const [secondReason, setSecondReason] = useState('')
  const [requestSW, setRequestSW] = useState('')
  const [requestDC, setRequestDC] = useState('')
  const [comment, setComment] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [rowSelection, setRowSelection] = useState({})
  const [dc_list, setDCList] = useState(
    JSON.parse(sessionStorage.getItem('dcList'))
  )
  const { updateDataCount } = CartState()
  const tableId = 'ReConfirmTable' // Define tableId here
  const { t } = useTranslation()
  const {
    SelectDC,
    SelectedSW,
    Comment_load,
    POConfirmOrders,
    Home,
    ConfirmPOOrders,
    Submitting_load,
    RequestChange,
    ConfirmOrders,
    Loading1,
    PleaseselectReasoncodefortheselectedorders,
    ReasonCode,
    Selectareason,
    Changetype,
    Selectatype,
    DC,
    Shipweek,
    Price,
    Quantity,
    // RequestChange:"请求更改",
    Cancel,
    //reconfirmpopage

    POReconfirmOrders,
    ReconfirmPOOrders,
    //vendor Reconfirm page
    CURSW
    //dynamic table header
  } = t('ConfirmPOpage')

  const handleCRDetailsClick = (value) => {
    console.log('cr id from hcrc: ', value)
    const detailsUrl = `/vendor/po_change_requests/request_details/${value}`
    const popupWindow = window.open(
      detailsUrl,
      '_blank',
      'width=950,height=800,resizable=yes,scrollbars=yes'
    )
    if (popupWindow) {
      popupWindow.focus()
    }
  }
  const headers = {
    CR: t('ConfirmPOpage.CR'),
    PONUMBER: t('ConfirmPOpage.PONUMBER'),
    SKU: t('ConfirmPOpage.SKU'),
    DESCRIPTION: t('ConfirmPOpage.DESCRIPTION'),
    REQSW: t('ConfirmPOpage.REQSW'),
    // CURSW: t('ConfirmPOpage.CURSW'),
    CURRSW: t('POList.CURRENTSW'),
    ORGSW: t('ConfirmPOpage.ORGSW'),
    DC: t('ConfirmPOpage.DC'),
    QUANTITY: t('ConfirmPOpage.QUANTITY'),
    VENDORRESPONSE: t('ConfirmPOpage.VENDORRESPONSE'),
    CREATEDAT: t('ConfirmPOpage.CREATEDAT'),
    REQDC: t('ConfirmPOpage.REQDC'),
    ONTIMETARGET: t('ConfirmPOpage.ONTIMETARGET'),
    DELTA: t('ConfirmPOpage.DELTA'),
    STATUS: t('ConfirmPOpage.STATUS'),
    DELTA: t('ConfirmPOpage.DELTA')
  }

  const columns = useMemo(
    () => [
      {
        header: headers.CR,
        accessorKey: 'id_open_request',
        cell: (info) => (
          <p
            className='underline cursor-pointer text-blue-500'
            onClick={() => handleCRDetailsClick(info?.getValue())}
          >
            {info?.getValue()}
          </p>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.PONUMBER,
        accessorKey: 'po_number',
        cell: (info) => {
          return (
            <p
              className='underline cursor-pointer text-blue-500'
              onClick={() => handlePODetailsClick('vendor', info?.getValue())}
            >
              {info?.getValue()}
            </p>
          )
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.SKU,
        accessorKey: 'sku',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.DESCRIPTION,
        accessorKey: 'description',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      ,
      {
        header: headers.REQSW,
        accessorKey: 'requested_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CURRSW,
        accessorKey: 'current_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.ORGSW,
        accessorKey: 'original_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.QUANTITY,
        accessorKey: 'original_qty'
      },
      {
        header: headers.VENDORRESPONSE,
        accessorKey: 'supplier_response',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      { header: headers.STATUS, accessorKey: 'status' },
      {
        header: headers.ONTIMETARGET,
        accessorKey: 'on_time_target',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.DELTA,
        accessorKey: 'ship_week_delay',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.DC,
        accessorKey: 'original_dc',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      }
      // {
      //   header: "PO CREATION DATE",
      //   accessorKey: "created_at",
      //   cell: (info) => formatDate(info?.getValue()),
      // },
    ],
    [headers]
  )

  const fetchData = () => {
    setLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-supplier-reconfirm-po?vendorNumber=${user?.supplierId}`,
        ValidateAPI
      )
      .then((response) => {
        setTableData(response?.data)
        setLoading(false)
      })
      .catch((error) => {
        // console.log('Error while fetching data: ', error)
        setLoading(false)
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }

  const handleReasonChange = (selectedReason) => {
    if (!reasonArray.includes(selectedReason)) {
      setReasonArray((prevReasonArray) => [...prevReasonArray, selectedReason])
    }
  }

  const handleChangeDate = (date) => {
    setDate(date)
    setRequestSW(getWeek(date))
  }

  const closeConfirmOrdersModal = () => {
    setShowConfirmOrdersModal(false)
  }

  const openConfirmOrdersModal = () => {
    setShowConfirmOrdersModal(true)
  }
  const closePopUpModal = () => {
    // setPoRow(false)

    setShowChangeRequestBanner(false)
  }

  const handleConfirmPos = () => {
    const request_payload = []
    selectedRowArray?.map((row) => {
      const payload = {
        id_open_request: row?.id_open_request,
        vendorNumber: Number(row?.vendor_number),
        vendorName: row?.vendor_name,
        createdBy: row?.inventory_analyst,
        current_shipweek: row?.current_ship_week,
        original_ship_week: row?.original_ship_week
      }
      request_payload?.push(payload)
    })
    console.log('ReConfirm Po payload: ', request_payload)
    setSubmitting(true)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-reconfirm-po`,
        request_payload,
        ValidateAPI
      )
      .then((response) => {
        console.log('response from confirm po: ', response?.data)
        setTableData(response?.data)
        if (response?.data) {
          const crIdResponse = response.data.map((item) => item.id_open_request)
          const poRow = response.data.map((item) => item.po_number)

          console.log('crIdResponse:', crIdResponse)
          console.log('poNumber:', poRow)

          setCrIdResponse(crIdResponse)
          setPoRow(poRow)
        }
        setSelectedRowArray([])
        setSubmitting(false)
        setShowChangeRequestBanner(true)
        // alert(i18next.t('VendorPopUp.Ordersconfirmedsuccessfully'))
        updateDataCount(user?.supplierId)
        updateActionHistory('Accepted', selectedRowArray)
        
      })
      .catch((error) => {
        console.log('error while confirming PO: ', error)
        setSubmitting(false)
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
    closeConfirmOrdersModal()
  }

  const editConfirm = () => {
    const req_payload = []
    if (reasonArray?.length > 0) {
      const reasons = reasonArray.join(', ')
      if (requestSW?.length > 0 || requestDC?.length > 0) {
        selectedRowArray?.map((item) => {
          const req_obj = {
            hft_cr_id: item?.id_open_request,
            requested_dc: requestDC,
            reason_code_dc: secondReason === 'dc' ? reasons : '',
            requested_ship_week: item?.requested_ship_week ?? '',
            reason_code_ship_week: secondReason === 'sw' ? reasons : '',
            supplier_response: 'ACCEPTED',
            current_ship_week: requestSW,
            status: 'OPEN',
            followups: 1,
            vendor_selected_ship_week: requestSW,
            ship_week_delay: Math.abs(
              weeksInBetween(requestSW ?? '', requestSW)
            ),
            vendorName: item?.vendor_name,
            createdBy: item?.inventory_analyst,
            requestType: 'ReConfirm'
          }
          return req_payload?.push(req_obj)
        })
        console.log('payload before edit reconfirm: ', req_payload)
        setShowReasonCode(true)
        setSubmitting(true)
        axios
          .put(
            `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-edit-confirm-po`,
            req_payload,
            ValidateAPI
          )
          .then((response) => {
            console.log('response from editconfirm: ', response?.data)
            setRequestDC('')
            setRequestSW('')
            setSubmitting(false)
            setShowReasonCode(false)
            setSecondReason('')
            setReasonArray([])
            setShowChangeRequestBanner(true)
            updateDataCount(user?.supplierId)
            // alert(i18next.t('VendorPopUp.changesmadesuccessfully'))
            updateActionHistory('Accepted', selectedRowArray)
            fetchData()
          })
          .catch((error) => {
            // console.log('error while submitting edit confirm: ', error)
            setSubmitting(false)
            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              navigate('/login-error')
            }
          })
      } else {
        alert(i18next.t('VendorPopUp.Pleaseaddnewvaluebeforesubmitting'))
      }
    } else {
      alert(
        i18next.t(
          'VendorPopUp.PleaseselectReasonCodeChangeTypebeforesubmitting'
        )
      )
    }
  }

  const renderBlock = (reason) => {
    if (reason === 'dc') {
      return (
        <div className='flex flex-col space-y-4'>
        <label
                  htmlFor='dc delect'
                  className='text-xs font-medium'
                >
                  Please Select DC
                </label>
        <select
          className='w-full border border-gray-300 p-2 rounded-md text-xs'
          onChange={(e) => setRequestDC(e?.target?.value)}
        >
          <option disabled>{SelectDC}</option>
          {dc_list?.map((data, index) => (
            <option value={data?.DC_NAME} key={data + index}>
              {data?.DC_NAME}
            </option>
          ))}
        </select>
        </div>
      )
    } else if (reason === 'sw') {
      return (
        <div className='flex flex-col space-y-4'>
          <label
                    htmlFor='dc delect'
                    className='text-xs font-medium'
                  >
                    Please Select Ship Week
                  </label>
          <div className='flex justify-between items-center'>
            <DatePicker
              showWeekNumbers
              filterDate={(date) => date.getDay() === 1}
              selected={date}
              onChange={(date) => handleChangeDate(date)}
              className='p-1 px-2 border border-gray-300 rounded-md text-xs'
              calendarStartDay={1}
              showYearDropdown
              minDate={getStartDate(selectedRowArray[0].current_ship_week)}
            />
            {requestSW && (
              <div className='flex items-center space-x-2'>
                <p className='text-xs'>{SelectedSW}</p>
                <p className='text-xs'>
                  <span className='font-medium p-1 px-2 border border-gray-300 rounded-md text-xs'>
                    {requestSW}
                  </span>
                </p>
              </div>
            )}
          </div>

          <div className='flex items-center p-2'>
            <p className='text-xs'>{CURSW}</p>
            <p className='text-xs'>
              <span className='font-medium p-1 px-2 border border-gray-300 rounded-md text-xs'>
                {selectedRowArray[0].current_ship_week}
              </span>
            </p>
          </div>
        </div>
      )
    } else {
      return (
        <div className='w-full flex flex-col gap-2'>
          <label htmlFor='sr_comment' className='text-xs font-medium'>
            {Comment_load}
          </label>
          <textarea
            id='sr_comment'
            className='w-full border border-gray-300 p-2 rounded-md text-xs'
            placeholder={i18next.t('Typehereindetail')}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      )
    }
  }

  const closeRequestChangeModal = () => {
    setShowRerequestChangeModal(false)
  }

  const openRequestChangeModal = () => {
    setShowRerequestChangeModal(true)
  }

  const rejectPop = () => {
    setShowReasonCode(true)
    closeRequestChangeModal()
  }

  const handleRejectPos = () => {
    const request_payload = []
    const comments_payload = []
    if (!reasonArray?.length > 0)
      return alert(i18next.t('VendorPopUp.Pleaseselectareasoncode'))
    if (!comment)
      return alert(i18next.t('VendorPopUp.Pleaseaddcommentbeforesubmitting'))
    const reasons = reasonArray.join(', ')
    selectedRowArray?.map((row) => {
      const payload = {
        id_open_request: row?.id_open_request,
        vendorNumber: Number(row?.vendor_number),
        reasonCodeShipWeek: reasons
      }
      const comment_indi = {
        hftcrid: row?.id_open_request,
        po_number: row?.po_number,
        added_by_role_name: user?.roleName?.split('_')?.[0],
        added_by_name: user?.fullName,
        comment: comment,
        type: 'Comment',
        SKU:row?.sku
      }
      request_payload?.push(payload)
      comments_payload?.push(comment_indi)
    })

    console.log('Reject Po payload: ', request_payload)
    console.log('Reject comments payload: ', comments_payload)
    setSubmitting(true)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-confirm-reject-po`,
        request_payload,
        ValidateAPI
      )
      .then((response) => {
        console.log('response from reject po: ', response?.data)
        setShowReasonCode(false)
        setSelectedRowArray([])
        setReasonArray([])
        setTableData(response?.data)
        setComment('')
        axios
          .post(
            `${process.env.REACT_APP_API_DOMAIN}:8088/api/create-cr-activity`,
            comments_payload,
            ValidateAPI
          )
          .then((response) => {
            setSubmitting(false)
            console.log('response from submitting comment: ', response?.data)
            setShowChangeRequestBanner(true)
            updateDataCount(user?.supplierId)
            updateActionHistory('Rejected', selectedRowArray)
          })
          .catch((error) => {
            setSubmitting(false)
            // console.log('error from comments submission: ', error)
            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              navigate('/login-error')
            }
          })
        // alert(i18next.t('VendorPopUp.Orderschangedsuccessfully'))
      })
      .catch((error) => {
        // console.log('error while reject PO: ', error)
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }

  // const handleRejectPos = () => {
  //   const request_payload = [];
  //   if (!reasonArray?.length > 0)
  //     return alert("Please select a reason code.");
  //   const reasons = reasonArray.join(", ");
  //   console.log("date before format change: ", date);
  //   const inputDate = new Date(date);
  //   console.log("date afyter input format change: ", inputDate);
  //   const formattedDate = inputDate
  //     .toLocaleDateString("en-GB", {
  //       day: "2-digit",
  //       month: "2-digit",
  //       year: "2-digit",
  //     })
  //     .replace(/\//g, "-");
  //   console.log("date after format change: ", formattedDate);
  //   selectedRowArray?.map((row) => {
  //     const payload = {
  //       id_open_request: row?.id_open_request,
  //       vendorNumber: Number(row?.vendor_number),
  //       reasonCodeShipWeek: reasons,
  //       promise_date: formattedDate,
  //     };
  //     request_payload?.push(payload);
  //   });

  //   console.log("Reject Po payload: ", request_payload);
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-reconfirm-reject-po`,
  //       request_payload
  //     )
  //     .then((response) => {
  //       console.log("response from reject po: ", response?.data);
  //       setShowReasonCode(false);
  //       setSelectedRowArray([]);
  //       setReasonArray([]);
  //       setTableData(response?.data);
  //       alert("Orders rejected successfully!");
  //     })
  //     .catch((error) => {
  //       console.log("error while reject PO: ", error);
  //     });
  // };

  useEffect(() => {
    fetchData()
  }, [])
  useEffect(() => {
    console.log('Selection Array has been updated: ', selectedRowArray)
  }, [selectedRowArray])

  return (
    <div className='w-full flex flex-col gap-6 justify-start items-start p-4'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl'>{POReconfirmOrders}</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/vendor')}
          >
            {Home}
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span>{ReconfirmPOOrders}</span>
          {/* <span>{">"}</span> */}
        </p>
        {/* <p className="flex gap-1 items-center justify-center">
            <span>PO Details - {id}</span>
          </p> */}
      </div>
      {selectedRowArray?.length > 0 && (
        <div className='flex w-full justify-start items-center font-medium text-xs gap-6'>
          <button
            className='px-3 rounded-md bg-[#415da1] text-white h-[32px]'
            onClick={openRequestChangeModal}
          >
            {RequestChange}
          </button>
          <PopupModal
            isOpen={showRerequestChangeModal}
            message={i18next.t('PopUpMessage.Doyouwanttocreateachangerequest')}
            onCancel={closeRequestChangeModal}
            cancelText={i18next.t('AcceptModal.Cancel')}
            onConfirm={rejectPop}
            confirmText={i18next.t('AcceptModal.Confirm')}
          />
          <button
            className='px-3 rounded-md bg-[#415da1] text-white h-[32px]'
            onClick={openConfirmOrdersModal}
          >
            {ConfirmOrders}
          </button>
          <PopupModal
            isOpen={showConfirmOrdersModal}
            message={i18next.t('PopUpMessage.Doyouwanttoconfirmtheseorders')}
            onCancel={closeConfirmOrdersModal}
            cancelText={i18next.t('AcceptModal.Cancel')}
            onConfirm={handleConfirmPos}
            confirmText={i18next.t('AcceptModal.Confirm')}
          />
          {showChangeRequestBanner && (
        <PopupBanner
          isOpen={showChangeRequestBanner}
          message={'Change request is submitted successfully'}
          onCancel={closePopUpModal}
          text={poRow}
          text1={crIdResponse}
        />
      )}
        </div>
      )}
      
      {loading ? (
        <div className='w-full min-h-[300px] flex justify-center items-center'>
          <p>{Loading1}</p>
        </div>
      ) : (
        <div className='w-full overflow-x-auto'>
          <ConfirmTable
            tableId={tableId}
            tableData={tableData}
            selection={setSelectedRowArray}
            columns={columns}
            updateRowSelectionProp={setRowSelection}
            rowSelectionProp={rowSelection}
          />
        </div>
      )}
      <dialog
        open={showReasonCode}
        className='p-3 inset-0 fixed z-10 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          {submitting ? (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <Loading type={Submitting_load} />
            </div>
          ) : (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <p className='w-full text-center'>
                {PleaseselectReasoncodefortheselectedorders}
              </p>
              <div className='w-full flex flex-wrap justify-center items-center gap-3'>
                {reasonArray?.map((reason, index) => (
                  <div
                    className='rounded-lg bg-[#4472C4] flex gap-2 justify-center items-center p-2 text-xs text-white font-medium'
                    key={index}
                  >
                    <span>{reason}</span>
                    <span
                      className='cursor-pointer'
                      onClick={() =>
                        setReasonArray(reasonArray?.filter((e) => e !== reason))
                      }
                    >
                      X
                    </span>
                  </div>
                ))}
              </div>
              <div className='w-full flex flex-col gap-2'>
                <label htmlFor='change_type_dd' className='text-xs font-medium'>
                  {Changetype}
                </label>
                <select
                  onChange={(e) => setSecondReason(e?.target?.value)}
                  id='change_type_dd'
                  className='p-2 rounded-md outline-none border border-gray-300 text-xs w-full font-medium'
                  value={secondReason}
                >
                  <option value='' disabled>
                    {Selectatype}
                  </option>
                  <option value='dc'>{DC}</option>
                  <option value='sw' disabled={selectedRowArray?.length > 1}>
                    {Shipweek}
                  </option>
                  <option value='price'>{Price}</option>
                  <option value='qty'>{Quantity}</option>
                </select>
              </div>
              {secondReason?.length > 0 && (
                <div className='w-full flex flex-col items-start gap-2'>
                  <label
                    htmlFor='reason_code_select'
                    className='text-xs font-medium'
                  >
                    {ReasonCode}
                  </label>
                  <select
                    onChange={(e) => handleReasonChange(e.target.value)}
                    name='reason_code_select'
                    id='reason_code_select'
                    className='p-2 rounded-md outline-none border border-gray-300 text-xs w-full font-medium'
                    value=''
                  >
                    <option value='' disabled>
                      {Selectareason}
                    </option>
                    {reason_codes?.map((reason) => (
                      <option key={reason} value={reason}>
                        {reason}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className='w-full'>
                {secondReason?.length > 0 &&
                  reasonArray?.length > 0 &&
                  renderBlock(secondReason)}
              </div>
              <div className='w-full flex justify-center items-center gap-4 mt-4'>
                {secondReason &&
                  (secondReason === 'dc' || secondReason === 'sw' ? (
                    <button
                      className='text-xs font-medium px-2 rounded-md h-[38px] text-white bg-[#415da1] outline-none'
                      onClick={editConfirm}
                    >
                      {RequestChange}
                    </button>
                  ) : (
                    <button
                      className='text-xs font-medium px-2 rounded-md h-[38px] border border-black outline-none'
                      onClick={handleRejectPos}
                    >
                      {RequestChange}
                    </button>
                  ))}
                <button
                  className='text-xs font-medium px-2 rounded-md h-[38px] text-white bg-black outline-none'
                  onClick={() => {
                    setShowReasonCode(false)
                    setReasonArray([])
                    setRequestSW('')
                    setSecondReason('')
                  }}
                >
                  {Cancel}
                </button>
              </div>
            </div>
          )}
        </div>
      </dialog>
      <PopupBanner
        isOpen={showChangeRequestBanner}
        
        message={"Orders changed successfully"}
        onCancel={closePopUpModal}
        text={poRow}
        text1={crIdResponse}
      />
      {/* <dialog
        open={showReasonCode}
        className="p-3 inset-0 fixed z-10 bg-black bg-opacity-50 w-full min-h-screen"
      >
        <div className="w-full min-h-screen p-4 flex justify-center items-center">
          <div className="sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6">
            <p className="w-full text-center">
              Please select Reason code for rejecting orders.
            </p>
            <div className="w-full flex flex-wrap justify-center items-center gap-3">
              {reasonArray?.map((reason, index) => (
                <div
                  className="rounded-lg bg-[#4472C4] flex gap-2 justify-center items-center p-2 text-xs text-white font-medium"
                  key={index}
                >
                  <span>{reason}</span>
                  <span
                    className="cursor-pointer"
                    onClick={() =>
                      setReasonArray(reasonArray?.filter((e) => e !== reason))
                    }
                  >
                    X
                  </span>
                </div>
              ))}
            </div>
            <div className="w-full flex justify-center items-center">
              <select
                onChange={(e) => handleReasonChange(e.target.value)}
                name="reason_code_select"
                id="reason_code_select"
                className="p-2 rounded-md outline-none border border-gray-300 text-xs w-full font-medium"
                value=""
              >
                <option value="" disabled>
                  Select a reason
                </option>
                {reason_codes?.map((reason) => (
                  <option key={reason} value={reason}>
                    {reason}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full justify-between text-xs flex items-center">
              <label htmlFor="promise_date">Delayed Ship Date</label>
              <DatePicker
                showWeekNumbers
                filterDate={(date) => date.getDay() === 1}
                selected={date}
                onChange={(date) => setDate(date)}
                className="p-1 border border-gray-300 rounded-md"
                calendarStartDay={1}
                showYearDropdown
              />
            </div>
            <div className="w-full flex justify-center items-center gap-4 mt-4">
              <button
                className="text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-red-500 outline-none"
                onClick={handleRejectPos}
              >
                Delay Order
              </button>
              <button
                className="text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-black outline-none"
                onClick={() => {
                  setShowReasonCode(false);
                  setReasonArray([]);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </dialog> */}
    </div>
  )
}

export default ReconfirmPo
