import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const AdminSideBar = () => {
  const navigation = useNavigate()
  const[open,setopen] = useState(false)
  
  return (
    <div className='min-w-[220px] flex flex-col gap-5 justify-between items-start min-h-screen sticky top-0 bg-blue-100 '>
      <div className='w-full flex flex-col gap-3'>
        {/* <div className="px-4 py-2 bg-white">
          <img
            src="https://images.harborfreight.com/media/logos/harborfreight-logo.svg"
            width="100%"
            height="100%"
            alt="harbor_freight_logo"
            className="object-contain w-[165px] h-[53px]"
          />
        </div> */}
        <div className='w-full flex flex-col justify-start h-full relative items-start gap-4 border-[#E6EDFF] border-r py-5'>
          {/* <div
              key={"admin"}
              className={`w-full justify-between flex text-xs items-center cursor-pointer hover:bg-[#0059A8] group ${
                window?.location?.pathname === "/admin" && "bg-[#0059A8]"
              }`}
              onClick={() => {
                navigation("/admin");
              }}
            >
              <p
                className={`text-black p-2 pl-4 group-hover:text-white ${
                  window?.location?.pathname === "/admin" && "text-white"
                }`}
              >
                DashBoard
              </p>
            </div> */}
        
          <div
            key={'admin/vendor/vendor-contact'}
            className={`w-full justify-between flex text-xs items-center cursor-pointer hover:bg-[#0059A8] group ${
              window?.location?.pathname.includes(
                '/admin/vendor/vendor-contact'
              ) && 'bg-[#0059A8]'
            }`}
            onClick={() => {
              navigation('/admin/vendor/vendor-contact')
            }}
          >
            <p
              className={`text-black p-2 pl-4 group-hover:text-white ${
                window?.location?.pathname.includes(
                  '/admin/vendor/vendor-contact'
                ) && 'text-white'
              }`}
            >
              Vendor Contact
            </p>
          </div>
          <div
            key={'buyer/hft_buyer_change_requests'}
            className={`w-full justify-between flex text-xs items-center cursor-pointer hover:bg-[#0059A8] group ${
              window?.location?.pathname.includes('admin/hft_user') &&
              'bg-[#0059A8]'
            }`}
            onClick={() => {
              navigation('/admin/hft_user')
            }}
          >
            <p
              className={`text-black p-2 pl-4 group-hover:text-white ${
                window?.location?.pathname.includes('admin/hft_user') &&
                'text-white'
              }`}
            >
              HFT User
            </p>
          </div>
          
          <div
            key={'admin/confiuration'}
            className={`w-full justify-between  text-xs items-center cursor-pointer`}
            
          >
            <p  onClick={() => {
              // navigation('/admin/configuration')
            }}
              className={`text-black p-2 pl-4 group-hover:text-white  hover:bg-[#0059A8] `}
            >
              Configuration
            </p>
         
            <div className='ml-5'>
            <p
              className={`text-black p-2 pl-4 group-hover:text-white  hover:bg-[#0059A8]  ${
                window?.location?.pathname.includes('admin/cron-job-config') &&
                'text-white'
              }  ${
                window?.location?.pathname.includes('admin/cron-job-config') &&
                'bg-[#0059A8]'
              }`}
              onClick={() => {
                navigation('/admin/cron-job-config')
              }}
            >
              Cron Job Configuration
            </p>
            <p
              className={`text-black p-2 pl-4 group-hover:text-white  hover:bg-[#0059A8]  ${
                window?.location?.pathname.includes('admin/email-temp-config') &&
                'text-white'
              }  ${
                window?.location?.pathname.includes('admin/email-temp-config') &&
                'bg-[#0059A8]'
              }`}
              onClick={() => {
                navigation('/admin/email-temp-config')
              }}
            >
              Email Configuration
            </p>
            <p
              className={`text-black p-2 pl-4 group-hover:text-white  hover:bg-[#0059A8]  ${
                window?.location?.pathname.includes('admin/configuration') &&
                'text-white'
              }  ${
                window?.location?.pathname.includes('admin/configuration') &&
                'bg-[#0059A8]'
              }`}
              onClick={() => {
                navigation('/admin/configuration')
              }}
            >
              Identifier
            </p>
            </div>
         
          </div>
          
          <div
            key={'admin/roles'}
            className={`w-full justify-between flex text-xs items-center cursor-pointer hover:bg-[#0059A8] group ${
              window?.location?.pathname.includes('admin/roles') &&
              'bg-[#0059A8]'
            }`}
            onClick={() => {
              navigation('/admin/roles')
            }}
          >
            <p
              className={`text-black p-2 pl-4 group-hover:text-white ${
                window?.location?.pathname.includes('admin/roles') &&
                'text-white'
              }`}
            >
              Roles
            </p>
          </div>
          {/* <div
            key={"admin/groups"}
            className={`w-full justify-between flex text-xs items-center cursor-pointer hover:bg-[#0059A8] group ${
              window?.location?.pathname.includes("admin/groups") &&
              "bg-[#0059A8]"
            }`}
            onClick={() => {
              navigation("/admin/groups");
            }}
          >
            <p
              className={`text-black p-2 pl-4 group-hover:text-white ${
                window?.location?.pathname.includes("admin/groups") &&
                "text-white"
              }`}
            >
             Groups
            </p>
          </div>
          <div
            key={"admin/roleMapping"}
            className={`w-full justify-between flex text-xs items-center cursor-pointer hover:bg-[#0059A8] group ${
              window?.location?.pathname.includes("admin/roleMapping") &&
              "bg-[#0059A8]"
            }`}
            onClick={() => {
              navigation("/admin/roleMapping");
            }}
          >
            <p
              className={`text-black p-2 pl-4 group-hover:text-white ${
                window?.location?.pathname.includes("admin/roleMapping") &&
                "text-white"
              }`}
            >
             Role-Mapping
            </p>
          </div> */}
        </div>
      </div>

  
    </div>
  )
}

export default AdminSideBar
