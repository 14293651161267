import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { ValidateAPI } from '../../utils/Utils';

const CronJobConfig = () => {
  const navigate = useNavigate()
  const [editIdx, setEditIdx] = useState(-1);
  const Weekday = [
    {
      Weekday: "MON",
    },
    {
      Weekday: "TUE",
    },
    {
      Weekday: "WED",
    },
    {
      Weekday: "THU",
    },
    {
      Weekday: "FRI",
    },
    {
      Weekday: "SAT",
    },
    {
      Weekday: "SUN",
    },

  ]
  const Hour = [
    { hour: "00" }, { hour: "01" }, { hour: "02" }, { hour: "03" }, { hour: "04" }, { hour: "05" }, { hour: "06" }, { hour: "07" }, { hour: "08" },
    { hour: "09" }, { hour: "10" }, { hour: "11" }, { hour: "12" }, { hour: "13" }, { hour: "14" }, { hour: "15" }, { hour: "16" }, { hour: "17" },
    { hour: "18" }, { hour: "19" }, { hour: "20" }, { hour: "21" }, { hour: "22" }, { hour: "23" },
  ]
  const handlecreate = () => {
    const cronJObDay = cronJobData?.cycle === 'Daily' ? " " : cronJobData?.day
    const cronJobTime = cronJobData.hour + ":" + cronJobData.minute
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/email/setCronJobTime?cronJobName=${cronJobData?.cronjobName}&day=${cronJObDay}&cycle=${cronJobData?.cycle}&time=${cronJobTime}`
        , ValidateAPI
      )
      .then((res) => {
        if (res) {
          alert("Submitted Successfully");
          getAllCronJob()
        }
      })
      .catch((err) => {
        alert("Something went wrong ");
      });
  }
  const handleSave = (data) => {
    console.log("cronJobUpdateData",cronJobUpdateData);
    const time = cronJobUpdateData?.time?.split(":")
    // console.log("time,", cronJobUpdateData?.time);
    const daily = `0 */${cronJobUpdateData?.minute} * * * *`
    const weekly = `0 ${cronJobUpdateData?.minute} ${cronJobUpdateData?.hour} * * ${cronJobUpdateData?.day}`
    const cronJobTime = cronJobUpdateData?.hour + ":" + cronJobUpdateData?.minute
    console.log(cronJobTime);
    const cromnJobExp = cronJobUpdateData?.cycle === "Daily" ? daily : weekly
    console.log("cromnJobFormat", cronJobUpdateData?.cycle === "Daily" ? daily : weekly);
    axios
      .put(
        `${process.env.REACT_APP_API_DOMAIN}:8088/email/updateCronJobTime?cronJobName=${cronJobUpdateData?.cronjobName}&time=${cronJobTime}&day=${cronJobUpdateData?.day}&cycle=${cronJobUpdateData.cycle}&cronJobId=${cronJobUpdateData.id}&cronExpression=${cromnJobExp}`, {}
        , ValidateAPI
      )
      .then((res) => {
        if (res) {
          alert("Submitted Successfully");
          getAllCronJob()
        }
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      });
    setEditIdx(-1);
  };

  const handleEdit = (index, data) => {
    setEditIdx(index);
    console.log(data, data?.CRON_TIME?.split(":")[0], data?.CRON_TIME?.split(":")[1]);
    setCronUpdateData(prevState => ({
      ...prevState,
      cronjobName: data?.CRON_JOB_NAME,
      day: data?.CRON_DAY,
      time: data?.CRON_TIME,
      cycle: data?.CRON_CYCLE,
      id: data?.CRON_JOB_ID,
      hour: data?.CRON_TIME?.split(":")[0],
      minute: data?.CRON_TIME?.split(":")[1]
    }));
  };


  const [cronJobData, setCronJobData] = useState({
    cronjobName: "",
    day: "Monday",
    time: getcurrentTime(),
    cycle: "",
    hour: "00",
    minute: "00"
  })
  function getcurrentTime() {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    hours = hours < 10 ? '0' + hours : hours
    // Pad single digit minutes with a leading zero
    minutes = minutes < 10 ? '0' + minutes : minutes;
    // Format hours and minutes to a string "HH:MM"
    const currentTime = `${hours}:${minutes}`;
    return currentTime
  }
  const [cronJobUpdateData, setCronUpdateData] = useState({
    cronjobName: "",
    day: "",
    time: "",
    cycle: "",
    hour: "",
    minute: ""
  })
  const handleCronJob = (e) => {
    const { name, value } = e.target
    setCronJobData({
      ...cronJobData,
      [name]: value,
    });
  }
  const   handleUpdateCronJob = (e) => {
    const { name, value } = e.target
    console.log(name, value);
    setCronUpdateData({
      ...cronJobUpdateData,
      [name]: value,
    });
  }
  const [getdata1, setData] = useState([])
  const getAllCronJob = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/email/getAllCronJobs`, ValidateAPI)
      .then((res) => {
        if (res) {
          console.log(res);
          setData(res?.data)
        }
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      });
  }
  useEffect(() => {
    getAllCronJob()
  }, [])
  return (
    <div className='p-2 w-full  flex flex-col  gap-5'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl font-medium'>Cron Job COnfiguration</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      {/* BreadCrumb */}
      <div className='w-full text-xs flex  justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/admin')}
          >
            Home
          </span>
          <span>{'>'}</span>
        </p>

        <p className='flex gap-1 items-center justify-center'>
          <span>configuration </span>
        </p>
      </div>
      {/* <div className=" mx-auto max-w-3xl justify-center   ">
        <form className=" space-y-2" onSubmit={(e) => { e.preventDefault(); handlecreate() }}>
          <div className='flex space-x-2'>
            <div class=" w-full group">
              <label
                class="block  text-sm font-medium text-gray-900 dark:text-white  "
              >
                Cron Job Name
              </label>
              <input required
                type="text" name="cronjobName" onChange={handleCronJob}
                class="bg-gray-50  border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />

            </div>
            <div class="w-full group">
              <label
                class="block  text-sm font-medium text-gray-900 dark:text-white  "
              >
                Frequency
              </label>
              <select name="cycle" onChange={handleCronJob} required
                className='bg-gray-50  border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'>
                <option selected>Select</option>
                <option value='Daily'>Daily</option>
                <option value='Weekly'>Weekly</option>
              </select>
            </div>
          </div>
          <div className='flex space-x-2'>
            <div class=" w-full group">
              <label
                for="hour"
                class="block  text-sm font-medium text-gray-900 dark:text-white  "
              >
                Hour
              </label>
               <select name="hour" onChange={handleCronJob} required
                className='bg-gray-50  border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'>
                <option selected>Select</option>
                {
                  [...Array(24)].map((data,i)=>{
                    return(
                      <option value={i<10?"0"+i:i}>{i<10?"0"+i:i}</option>
                    )
                  })
              }
              </select>


            </div>
            <div class=" w-full group">
              <label
                for="minute"
                class="block  text-sm font-medium text-gray-900 dark:text-white  "
              >
                Minute
              </label>
               <select name="minute" onChange={handleCronJob} required
                className='bg-gray-50  border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'>
                <option selected>Select</option>
              {
                  [...Array(60)].map((data,i)=>{
                    return(
                      <option value={i<10?"0"+i:i}>{i<10?"0"+i:i}</option>
                    )
                  })
              }
              </select>


            </div>
            {
              cronJobData?.cycle === "Weekly"
              &&
              <div class=" w-full group">
                <label
                  class="block  text-sm font-medium text-gray-900 dark:text-white  "
                >
                  Day
                </label>
                <select name="day" onChange={handleCronJob}
                  className='bg-gray-50  border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'>
                  <option selected>Select</option>
                  {
                    Weekday?.map((data) => {
                      return (
                        <option value={data?.Weekday}>{data?.Weekday}</option>
                      )
                    })
                  }
                </select>
              </div>
            }
          </div>
          <div className="text-center">
            <input
              type="submit"
              value="Submit"
              className="border p-2 rounded-md bg-gray-200 text-black cursor-pointer border-gray-500"
            />
          </div>
        </form>
      </div> */}

      <div className="w-full overflow-x-auto min-h-[14cm]">
        <table className="text-xs border border-gray-300 relative w-full">
          <thead className="sticky top-0 z-10"
          // style={{ height: values.length>0?theadHeight1:theadHeight}}
          > <tr

            className='divide-x-[1px] bg-[#e0ecfc] divide-gray-300 border-[#ccd9e4] border'
          >
              <th key='' className='font-medium px-2 py-3'>
                CRON JOB
              </th>
              <th key='' className='font-medium px-2 py-3'>
                FREQUENCY
              </th>
              <th key='' className='font-medium px-2 py-3'>
                TIME
              </th>
              <th key='' className='font-medium px-2 py-3'>
                DAY
              </th>
              <th key='' className='font-medium px-2 py-3'>
                ACTION
              </th>
            </tr>
          </thead>
          <tbody>
            {
              getdata1?.map((data, index) => {
                return (
                  <tr
                      key={index}
                    className={` text-center divide-x-[1px] divide-gray-300 border border-gray-300`}
                  >
                    <td className='px-2 py-3'>
                      {/* {editIdx === index ? (
                        <input required value={cronJobUpdateData?.cronjobName}
                          type="text" name="cronjobName" onChange={handleUpdateCronJob}
                          class="bg-gray-50  border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 justify-center"
                        />
                      ) : (
                        
                      )} */}
                      {data?.CRON_JOB_NAME}
                    </td>
                    <td className='px-2 py-3'>
                      {/* {editIdx === index ? (
                        <select name="cycle" onChange={handleUpdateCronJob}
                          className='bg-gray-50  border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'>
                          <option value='Daily'>Daily</option>
                          <option value='Weekly'>Weekly</option>
                        </select>
                      ) : (
                        
                      )} */}
                      {data?.CRON_CYCLE}
                    </td>
                    <td className='px-2 py-3'>
                      {editIdx === index ? (
                        <div>
                          <div class=" w-full group">
                            <label
                              for="hour"
                              class="block  text-sm font-medium text-gray-900 dark:text-white  "
                            >
                              Hour
                            </label>
                            <select name="hour" onChange={handleUpdateCronJob} required
                              className='bg-gray-50  border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'>
                              <option selected>Select</option>
                              {
                                [...Array(24)].map((data, i) => {
                                  return (
                                    <option value={i < 10 ? "0" + i : i}key={i}>{i < 10 ? "0" + i : i}</option>
                                  )
                                })
                              }
                            </select>


                          </div>
                          <div class=" w-full group">
                            <label
                              for="minute"
                              class="block  text-sm font-medium text-gray-900 dark:text-white  "
                            >
                              Minute
                            </label>
                            <select name="minute" onChange={handleUpdateCronJob} required
                              className='bg-gray-50  border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'>
                              <option selected>Select</option>
                              {
                                [...Array(60)].map((data, i) => {
                                  return (
                                    <option value={i < 10 ? "0" + i : i} key={i}>{i < 10 ? "0" + i : i}</option>
                                  )
                                })
                              }
                            </select>


                          </div>
                        </div>
                      ) : (
                        data?.CRON_TIME
                      )}
                    </td>
                    <td className='px-2 py-3 '>
                      {editIdx === index && data?.CRON_CYCLE !== 'Daily' ? (
                        <select name="day" onChange={handleUpdateCronJob}
                          className='bg-gray-50  border border-gray-300text-gray-900 p-1'
                        >
                          <option selected>Select</option>
                          {
                            Weekday?.map((data) => {
                              return (
                                <option value={data?.Weekday}>{data?.Weekday}</option>
                              )
                            })
                          }
                        </select>
                      ) : (
                        data?.CRON_DAY ?? ""
                      )}
                    </td>
                    <td className='px-2 py-3'>
                      {editIdx === index ? (
                        <button onClick={() => handleSave(data)}>Save</button>
                      ) : (
                        <button onClick={() => handleEdit(index, data)}>Edit</button>
                      )}
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default CronJobConfig
