import React, { useEffect, useState } from 'react'
import UserProfile from '../../components/UserProfile'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { getWeek, getWeekDifferenceStatus } from './NewPoList'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ValidateAPI, handleDownload, updateActionHistory } from '../../utils/Utils'
import Comments from '../../components/Comments'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import Stepper from '../../components/Stepper'
import StepperUrgentOrder from '../../components/StepperUrgentOrder'
import PopupModal from '../../components/PopupModal'

export const formatDateFit = (timestampString) => {
  if (!timestampString) return ''
  const year = timestampString?.slice(0, 4)
  const month = timestampString?.slice(4, 6)
  const day = timestampString?.slice(6, 8)
  const hour = timestampString?.slice(8, 10)
  const minute = timestampString?.slice(10, 12)
  const second = timestampString?.slice(12, 14)

  const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`
  return formattedDate
}

const BuyerUrgentCR = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false)
  const { id } = useParams()

  const steps = ['Awaiting Response', 'Vendor Responded', 'Closed']
  const stepsCancel = [
    'Awaiting Response',
    'Cancelled',
    'Vendor Responded',
    'Closed'
  ]
  const [status, setStatus] = useState('')
  const currentStepIndex = steps?.indexOf(status)
  const currentStepIndexCancel = stepsCancel?.indexOf(status)
  const [searchWord, setSearchWord] = useState()
  const [limiter, setLimiter] = useState(10)
  const location = useLocation()
  const navigate = useNavigate()
  const [requestData, setRequestData] = useState([])
  const [commentsData, setCommentsData] = useState([])
  const [commentInput, setCommentInput] = useState('')
  const [showEdit, setShowEdit] = useState(false)
  const [requestSW, setRequestSW] = useState(requestData?.requested_ship_week)
  const [requestDC, setRequestDC] = useState(requestData?.requested_dc)
  const [date, setDate] = useState(new Date())
  const { t } = useTranslation()
  const {
    HFTBuyerPOChangeRequestDetails,
    Home,
    HFTBuyerChangeRequests,
    ChangeRequest,
    Supplier,
    supplierName,
    CreateDate,
    ReasonCode,
    VendorResponse,
    RequestType,
    LastUpdateDate,
    POLineChangeRequestDetails,
    Edit,
    SKU,
    Description,
    RequestedSW,
    CurrentConfirmSW,
    QTY,
    ORGDC,
    LastUpdated,
    CancelOrder
  } = t('BuyerUrgentCRPage')

  const fetchRequestData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-cr-by-id?idOpenRequest=${id}`,ValidateAPI
      )
      .then((response) => {
        console.log('response from request: ', response?.data)
        setRequestData(response?.data)
      })
      .catch((error) => {
        console.log(`error while fetching request data: `, error)
      })
  }

  const handleRequestUpdate = () => {
    const swStatus = getWeekDifferenceStatus(
      requestData?.original_ship_week,
      requestSW
    )
    const dcStatus =
      requestData?.original_dc !== requestDC
        ? requestDC?.length > 0
          ? 'Divert'
          : ''
        : ''
    const payload = [
      {
        hft_cr_id: requestData?.id_open_request,
        request_type: `${swStatus + ' ' + dcStatus}`,
        original_dc: requestData?.original_dc,
        requested_dc: requestDC,
        reason_code_dc: '',
        original_ship_week: requestData?.original_ship_week,
        requested_ship_week: requestSW,
        reason_code_ship_week: '',
        status: 'Awaiting Response',
        followups: 1
      }
    ]

    const confirmCreateRequest = window.confirm(
      i18next.t('PopUpMessage.Doyouwanttoupdatechangerequest')
    )
    if (confirmCreateRequest) {
      axios
        .put(
          `${process.env.REACT_APP_API_DOMAIN}:8088/api/edit-request`,
          payload,ValidateAPI
        )
        .then((response) => {
          console.log('response from update: ', response?.data)
          setShowEdit(false)
          setRequestData(response?.data?.[0])
          alert(i18next.t('PopUpMessage.ChangeRequestupdated'))
        })
        .catch((error) => {
          alert(
            i18next.t(
              'PopUpMessage.ErrorwhileupdatingChangeRequestPleasetryagain'
            )
          )

          console.log('error while updating: ', error)
        })
    }
  }

  const handleChangeDate = (date) => {
    setDate(date)
    setRequestSW(getWeek(date))
  }

  useEffect(() => {
    fetchRequestData()
    setRequestSW(requestData?.requested_ship_week)
    setRequestDC(requestData?.requested_dc)
  }, [id])

  const closeCancelOrderModal = () => {
    setShowCancelOrderModal(false)
  }

  const openCancelOrderModal = () => {
    setShowCancelOrderModal(true)
  }

  const handleCancelOrder = () => {
    const payload = {
      hftCrId: requestData?.id_open_request,
      createdBy: requestData?.created_by,
      vendorNumber: requestData?.vendor_number
    }
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/cancel-change-request`,
        payload,ValidateAPI
      )
      .then((response) => {
        console.log('response from update: ', response?.data)
        updateActionHistory('Cancelled', [requestData])
        window?.location?.reload()
        fetchRequestData()
        alert(i18next.t('PopUpMessage.ChangeRequestupdated'))
      })
      .catch((error) => {
        alert(i18next.t('PopUpMessage.ErrorwhileupdatingChange'))
        if(error.response.status===401 || error.response.status===403){
          navigate('/login-error')
        }
      })
    console.log(payload)
    closeCancelOrderModal()
  }
  useEffect(() => {
    if (requestData?.status?.toLowerCase() === 'open') {
      if (requestData?.supplier_response === 'Awaiting Response') {
        setStatus('Awaiting Response')
      } else if (
        requestData?.supplier_response === 'Accept' ||
        requestData?.supplier_response === 'ACCEPTED'
      ) {
        setStatus('Vendor Responded')
      } else if (
        requestData?.supplier_response === 'Reject' ||
        requestData?.supplier_response === 'REJECTED'
      ) {
        setStatus('Vendor Responded')
      }
    } else if (requestData?.status?.toLowerCase() === 'closed') {
      setStatus('Closed')
    } else {
      setStatus('Cancelled')
    }
  }, [requestData?.supplier_response, requestData?.status])

  return (
    <div className='w-full flex flex-col gap-6 justify-start items-start p-4'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl font-medium'>
            {HFTBuyerPOChangeRequestDetails}
          </p>
        </div>
      </div>
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/buyer')}
          >
            {Home}
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/buyer/hft_buyer_change_requests')}
          >
            {HFTBuyerChangeRequests}
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span>
            {} - {id}
          </span>
        </p>
      </div>
      {/*Stepper to show current status */}
      {/* <div className="w-full flex justify-center items-start relative">
        <div className="w-[70%] border border-gray-200 absolute top-3" />
        <div className="w-[80%] flex justify-between items-center">
          {(requestData?.status?.toLowerCase() === "cancelled"
            ? stepsCancel
            : steps
          )?.map((step, index) => (
            <div
              className="flex flex-col z-40 justify-start items-center gap-2 h-[100px]"
              key={index + step}
            >
              <div
                className={`rounded-full border-2 p-1 ${
                  (requestData?.status?.toLowerCase() === "cancelled"
                    ? currentStepIndexCancel
                    : currentStepIndex) >= index
                    ? "border-blue-600"
                    : "border-gray-300"
                } w-6 h-6 flex justify-center items-center `}
              >
                <div
                  className={`p-1.5 rounded-full ${
                    (requestData?.status?.toLowerCase() === "cancelled"
                      ? currentStepIndexCancel
                      : currentStepIndex) >= index
                      ? "bg-blue-600"
                      : "bg-gray-300"
                  }`}
                ></div>
              </div>
              <p
                className={`${
                  (requestData?.status?.toLowerCase() === "cancelled"
                    ? currentStepIndexCancel
                    : currentStepIndex) >= index
                    ? "text-blue-600"
                    : "text-gray-400"
                } text-center text-xs font-medium w-[160px]`}
              >
                {step}
              </p>
              <p
                  className={`${
                    (requestData?.status?.toLowerCase() === "cancelled"
                      ? currentStepIndexCancel
                      : currentStepIndex) >= index
                      ? "text-blue-600"
                      : "text-gray-400"
                  } text-center text-xs font-medium w-[160px]`}
                  >
                    {step==="Awaiting Response" &&formatDateFit(requestData?.created_at)}
                   { requestData?.status?.toLowerCase() === "cancelled" ?
                   index==currentStepIndexCancel &&formatDateFit(requestData?.updated_at) :
                   index==currentStepIndex &&formatDateFit(requestData?.updated_at)
                   }
                    </p>
            </div>
          ))}
        </div>
      </div> */}
      <StepperUrgentOrder
        status={status}
        crId={requestData?.id_open_request}
        iscancelled={requestData?.status?.toLowerCase() === 'cancelled'}
      />
      {requestData?.status === 'OPEN' && (
        <div className='flex '>
          <button
            className='bg-[#4472C4] text-white h-[37px] px-3 rounded-md text-sm'
            onClick={openCancelOrderModal}
          >
            {CancelOrder}
          </button>
          <PopupModal
            isOpen={showCancelOrderModal}
            message={i18next.t('PopUpMessage.Doyouwanttocancelorder')}
            onCancel={closeCancelOrderModal}
            cancelText={i18next.t('AcceptModal.Cancel')}
            onConfirm={handleCancelOrder}
            confirmText={i18next.t('AcceptModal.Confirm')}
          />
        </div>
      )}
      <div className='w-full border border-[#E6EDFF] rounded-md p-3'>
        <table className='w-full'>
          <tbody>
            <tr className='border-b border-[#E6EDFF] '>
              <td className='text-xs p-2 py-4'>
                <div className='flex flex-col gap-1'>
                  <p className='font-medium'>{Supplier}</p>
                  <p>{requestData?.vendor_number}</p>
                </div>
              </td>
              <td className='text-xs p-2 py-4'>
                <div className='flex flex-col gap-1'>
                  <p className='font-medium'>{supplierName}</p>
                  <p>{requestData?.vendor_name}</p>
                </div>
              </td>
              <td className='text-xs p-2 py-4'>
                <div className='flex flex-col gap-1'>
                  <p className='font-medium'>{CreateDate}</p>
                  <p>{formatDateFit(requestData?.created_at)}</p>
                </div>
              </td>
            </tr>

            <tr>
              {requestData?.reason_code_dc && (
                <td className='text-xs p-2 py-4'>
                  <div className='flex flex-col gap-1'>
                    <p className='font-medium'>{ReasonCode}</p>
                    <p>
                      {`${requestData?.reason_code_dc ?? ''}  ${
                        requestData?.reason_code_ship_week ?? ''
                      }`}
                    </p>
                  </div>
                </td>
              )}
              <td className='text-xs p-2 py-4'>
                <div className='flex flex-col gap-1'>
                  <p className='font-medium'>{VendorResponse}</p>
                  <p>{requestData?.supplier_response}</p>
                </div>
              </td>
              <td className='text-xs p-2 py-4'>
                <div className='flex flex-col gap-1'>
                  <p className='font-medium'>{RequestType}</p>
                  <p className='capitalize'>{requestData?.request_type}</p>
                </div>
              </td>
              <td className='text-xs p-2 py-4'>
                <div className='flex flex-col gap-1'>
                  <p className='font-medium'>{LastUpdateDate}</p>
                  <p>{formatDateFit(requestData?.updated_at)}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='w-full flex justify-start items-start mt-1 flex-col gap-4'>
        <div className='w-full flex justify-between items-center'>
          <p className='font-semibold text-xl'>{POLineChangeRequestDetails}</p>
          {requestData?.supplier_response === 'Awaiting Response' &&
            requestData?.request_type !== 'URGENT' && (
              <p
                className='hover:underline cursor-pointer text-sm font-medium'
                onClick={() => setShowEdit(!showEdit)}
              >
                {Edit}
              </p>
            )}
        </div>

        <div className='w-full p-3 border border-[#E6EDFF] rounded-md'>
          <table className='w-full'>
            <thead>
              <tr className='border-b border-[#E6EDFF]'>
                <th className='p-2 py-4 font-medium text-xs'>{SKU}</th>
                <th className='p-2 py-4 font-medium text-xs'>{Description}</th>
                <th className='p-2 py-4 font-medium text-xs'>{RequestedSW}</th>
                <th className='p-2 py-4 font-medium text-xs'>
                  {CurrentConfirmSW}{' '}
                </th>
                <th className='p-2 py-4 font-medium text-xs'>{QTY}</th>
                <th className='p-2 py-4 font-medium text-xs'>{ORGDC}</th>
                <th className='p-2 py-4 font-medium text-xs'>{LastUpdated}</th>
              </tr>
            </thead>
            <tbody>
              <tr className='text-center'>
                <td className='p-2 py-4 text-xs'>{requestData?.sku}</td>
                <td className='p-2 py-4 text-xs'>{requestData?.description}</td>
                <td className='p-2 py-4 text-xs'>
                  {requestData?.requested_ship_week}
                </td>
                <td className='p-2 py-4 text-xs'>
                  {requestData?.vendor_selected_sw}
                </td>
                <td className='p-2 py-4 text-xs'>
                  {requestData?.original_qty}
                </td>
                <td className='p-2 py-4 text-xs'>{requestData?.original_dc}</td>
                <td className='p-2 py-4 text-xs'>
                  {formatDateFit(requestData?.updated_at)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className='w-full'>
        {requestData?.id_open_request && (
          <Comments
            crId={requestData?.id_open_request}
            poNumber={requestData?.po_number}
            status ={requestData?.status==="CLOSED"}
          />
        )}
      </div>
    </div>
  )
}

export default BuyerUrgentCR
