import React, { useState } from 'react'

const DropDown = ({ initialValue, updateValue }) => {
  const [selectedOption, setSelectedOption] = useState(initialValue)
  const options = [
    {
      id: 1,
      value: 'Editable'
    },
    {
      id: 2,
      value: 'Non Editable'
    },
    {
      id: 3,
      value: 'Hide'
    }
  ]
  return (
    <select
      name=''
      id=''
      onChange={(e) => {
        updateValue(e?.target?.value)
        setSelectedOption(e?.target?.value)
      }}
      value={selectedOption}
    >
      {options?.map((option, index) => {
        return (
          <option key={index} value={option?.id}>
            {option?.value}
          </option>
        )
      })}
    </select>
  )
}

const RoleComp = () => {
  const [showCreateRoleForm, setShowCreateRoleForm] = useState(false)
  const [roleId, setRoleId] = useState()
  const [roleName, setRoleName] = useState()
  const [roleData, setRoleData] = useState({
    roleId: '',
    roleName: '',
    columnSettings: {}
  })

  const options = [
    {
      id: 1,
      value: 'Editable'
    },
    {
      id: 2,
      value: 'Non Editable'
    },
    {
      id: 3,
      value: 'Hide'
    }
  ]

  const columns = [
    'Action Needed',
    'DC',
    'Original Ship Week',
    'Current Ship Week',
    'Original Qty',
    'Current Qty',
    'HFT Request',
    'Vendor Update/Response',
    'Reason Code',
    'Messages',
    'Shipping Instructions',
    'Unit Price',
    'Extended Cost'
  ]

  const handleSelectChange = (columnName, selectedValue) => {
    setRoleData((prevData) => ({
      ...prevData,
      columnSettings: {
        ...prevData.columnSettings,
        [columnName]: selectedValue
      }
    }))
  }

  const isFormValid = () => {
    // Check if all columns have a selected value
    return columns?.every((columnName) => roleData?.columnSettings[columnName])
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (isFormValid()) {
      console.log('roleData: ', roleData)
    } else {
      console.log('Some column values are not assigned')
    }
  }

  return (
    <div className='w-full flex flex-col p-4'>
      <div className='mt-2'>
        <button
          className='text-white bg-gray-600 py-2 px-4 rounded-sm'
          onClick={() => setShowCreateRoleForm(true)}
        >
          Create Role
        </button>
      </div>
      <dialog
        open={showCreateRoleForm}
        className='p-3 inset-0 fixed z-10 bg-black bg-opacity-50 w-full min-h-screen font-pop'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          <div className='md:w-[600px] sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col h-[600px] gap-3 overflow-y-auto'>
            <form
              onSubmit={handleSubmit}
              className='w-full flex flex-col gap-4 tracking-tight'
            >
              <div className='w-full flex justify-center items-center'>
                <p className='text-lg'>Create Role</p>
              </div>
              <div className='w-full flex flex-col gap-2'>
                <label
                  htmlFor='roleId'
                  className='block text-sm font-medium text-gray-900'
                >
                  Role ID:
                </label>
                <input
                  type='text'
                  id='roleId'
                  name='roleId'
                  value={roleData.roleId}
                  onChange={(e) =>
                    setRoleData({ ...roleData, roleId: e.target.value })
                  }
                  className='bg-gray-50 border border-gray-300 text-gray-900 focus:outline-none text-sm rounded-lg block w-full p-2.5'
                />
              </div>
              <div className='w-full flex flex-col gap-2'>
                <label
                  htmlFor='roleName'
                  className='block text-sm font-medium text-gray-900'
                >
                  Role Name:
                </label>
                <input
                  type='text'
                  id='roleName'
                  name='roleName'
                  value={roleData.roleName}
                  onChange={(e) =>
                    setRoleData({ ...roleData, roleName: e.target.value })
                  }
                  className='bg-gray-50 border border-gray-300 text-gray-900 focus:outline-none text-sm rounded-lg block w-full p-2.5'
                />
              </div>
              <label className='block text-sm font-medium text-gray-900'>
                Column Permissions:
              </label>
              {columns?.map((columnName) => (
                <div key={columnName} className='flex gap-3 items-center'>
                  <label htmlFor={columnName} className='w-[50%]'>
                    {columnName}
                  </label>
                  <select
                    id={columnName}
                    name={columnName}
                    value={roleData?.columnSettings[columnName] || ''}
                    onChange={(e) =>
                      handleSelectChange(columnName, Number(e?.target?.value))
                    }
                    className='w-[50%] border border-gray-600 rounded-sm p-1'
                  >
                    <option value=''>Select Permission</option>
                    {options?.map((option) => (
                      <option key={option?.id} value={option?.id}>
                        {option?.value}
                      </option>
                    ))}
                  </select>
                </div>
              ))}

              <div className='w-full flex gap-4 justify-center items-center p-2 mt-4'>
                <button
                  type='submit'
                  className='w-[90px] h-[35px] font-medium text-sm rounded-md bg-green-400 text-white border border-green-400'
                >
                  Submit
                </button>
                <button
                  className='w-[90px] h-[35px] font-medium text-sm rounded-md border border-gray-300'
                  onClick={() => {
                    setShowCreateRoleForm(false)
                    setRoleData({
                      roleId: '',
                      roleName: '',
                      columnSettings: {}
                    })
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </dialog>
      <div className='w-full flex justify-center items-center'></div>
    </div>
  )
}

export default RoleComp
