import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'

const CommonBarChart = ({ chartData, color, type, value,checkDataType }) => {
  const navigate = useNavigate()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('userData')))
  const handleBarClick = (entry) => {
    if(user?.userGroupName==="OVERSEAS_ROLE")
      return 
    if(checkDataType==="Shipweek")
    return
    const requestType = entry?.activePayload?.[0]?.payload?.requestType
    if (window?.location?.pathname.includes('vendor')) {
      navigate(`/vendor/po_change_requests`, {
        state: [
          {
            id: 'request_type',
            value: requestType
          }
        ]
      })
    } else if (window?.location?.pathname.includes('buyer')) {
      navigate(`/buyer/hft_buyer_change_requests`, {
        state: [
          {
            id: 'request_type',
            value: requestType
          },
          {
            id: 'created_by',
            value: user?.fullName
          }
        ]
      })
    } else {
      console.log('condition failed')
    }
  }
  return (
    <div className='w-full flex'>
      <ResponsiveContainer width='100%' height={180}>
        <BarChart
          data={chartData}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5
          }}
          onClick={(e) => handleBarClick(e)}
        >
          <CartesianGrid strokeDasharray='5 5' />
          <XAxis dataKey={type} className='text-xs font-medium' />
          <YAxis className='text-xs font-medium' />
          <Tooltip
            labelStyle={{ display: 'none' }}
            contentStyle={{
              background: 'white',
              borderRadius: '8px',
              fontSize: '12px'
            }}
          />
          <Bar
            dataKey={value}
            fill={color}
            activeBar={<Rectangle fill='gray' stroke={color} />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default CommonBarChart
