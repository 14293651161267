import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import UserProfile from '../../components/UserProfile'
import Loading from '../../components/Loading'
import BasicTable from '../../components/BasicTable'
import { formatDate } from '../buyer/BuyerRequest'
import { downloadCSV, handlePODetailsClick } from '../../utils/Utils'
import { useTranslation } from 'react-i18next'
export const ViewDetail = ({ request }) => {
  const navigate = useNavigate()

  return request?.request_type === 'URGENT' ? (
    <p
      className='hover:underline cursor-pointer'
      onClick={() =>
        navigate(
          `/vendor/urgent_orders/request_details/${request?.id_open_request}`,
          { state: request }
        )
      }
    >
      View Detail
    </p>
  ) : (
    <p
      className='hover:underline cursor-pointer'
      onClick={() =>
        navigate(
          `/vendor/po_change_requests/request_details/${request?.id_open_request}`,
          { state: request }
        )
      }
    >
      View Detail
    </p>
  )
}

const VendorMyTasks = () => {
  const navigate = useNavigate()
  const [requestData, setRequestData] = useState([])
  const userData = JSON.parse(localStorage.getItem('userData'))
  const { requestType, vendorNumber } = useParams()
  const [loading, setLoading] = useState(false)
  const [selectedType, setSelectedType] = useState('')
  const tableId = 'VendorMyTasksTable'; // Define tableId here

  const request_types_list = [
    'confirm',
    'reconfirm',
    'expedite',
    'urgent',
    'defer',
    'divert'
  ]
  const { t } = useTranslation()
  const {
    MyTasks,
    Home,
    Norequestsfound
    //dynamic table
  } = t('VendorMyTaskPage')
  const headers = {
    CR: t('VendorMyTaskPage.CR'),
    PONUMBER: t('VendorMyTaskPage.PONUMBER'),
    SKU: t('VendorMyTaskPage.SKU'),
    DESCRIPTION: t('VendorMyTaskPage.DESCRIPTION'),
    STATUS: t('VendorMyTaskPage.STATUS'),
    REQUESTTYPE: t('VendorMyTaskPage.REQUESTTYPE'),
    REQUESTEDBY: t('VendorMyTaskPage.REQUESTEDBY'),
    CREATEDATE: t('VendorMyTaskPage.CREATEDATE'),
    UPDATEDATE: t('VendorMyTaskPage.UPDATEDATE')
  }
  const columns = useMemo(
    () => [
      {
        header: headers.CR,
        accessorKey: 'id_open_request',
        cell: ({ row }) => (
          <p
            className='underline cursor-pointer text-blue-500'
            onClick={() =>
              navigate(
                `/vendor/po_change_requests/request_details/${row?.original?.id_open_request}`,
                { state: row?.original }
              )
            }
          >
            {row?.original?.id_open_request}
          </p>
        )
      },
      {
        header: headers.PONUMBER,
        accessorKey: 'po_number',
        cell: (info) => {
          return (
            <p
              className='underline cursor-pointer text-blue-500'
              onClick={() => handlePODetailsClick('vendor', info?.getValue())}
            >
              {info?.getValue()}
            </p>
          )
        }
      },
      {
        header: headers.SKU,
        accessorKey: 'sku'
      },
      {
        header: headers.DESCRIPTION,
        accessorKey: 'description'
      },
      { header: headers.STATUS, accessorKey: 'supplier_response' },
      { header: headers.REQUESTTYPE, accessorKey: 'request_type' },
      { header: headers.REQUESTEDBY, accessorKey: 'created_by' },
      {
        header: headers.CREATEDATE,
        accessorKey: 'created_at',
        cell: (info) =>
          info?.getValue() !== null ? formatDate(info?.getValue()) : ''
      },
      {
        header: headers.UPDATEDATE,
        accessorKey: 'updated_at',
        cell: (info) =>
          info?.getValue() !== null ? formatDate(info?.getValue()) : ''
      }
      // {
      //   header: "VIEW DETAILS",
      //   isPlaceholder: true,
      //   enableSorting: false,
      //   cell: (info) => <ViewDetail request={info?.row?.original} />,
      // },
    ],
    [t]
  )

  const fetchRequestData = (request_type) => {
    setLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-my-tasks?vendorNumber=${userData?.supplierId}&requestType=${request_type}`
      )
      .then((response) => {
        console.log('Response from fetch my tasks: ', response?.data)
        setRequestData(response?.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error while fetching mytasks: ', error)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (userData?.roleName === undefined || userData?.roleName === null) {
      sessionStorage?.setItem('my_task_url', `/vendor/my_tasks/${requestType}`)
      navigate('/login')
    } else {
      if (requestType) {
        fetchRequestData(requestType)
      } else {
        fetchRequestData('')
      }
    }
  }, [selectedType])

  return (
    <div className='w-full flex flex-col gap-6 justify-start items-start p-4'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl'>{MyTasks}</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/vendor')}
          >
            {Home}
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span>{MyTasks}</span>
        </p>
      </div>
      {/* {!requestType && (
        <div className="w-full flex gap-3 justify-start items-center">
          <div className="flex gap-4 justify-center items-center text-xs font-medium">
                      <p>Select Request Type: </p>
                      <select value={selectedType} name="request_type_select" id="" className='border border-gray-300 rounded-md p-2' onChange={(e)=>setSelectedType(e?.target.value)}>
                          <option value="" disabled>Select a Request Type</option>
                          {request_types_list?.map((request, index) => {
                              return (
                                  <option value={request} key={"request_type_value" + index} className='capitalize'>{request}</option>
                              )
                          })}
                      </select>
          </div>
        </div>
      )} */}
      {loading ? (
        <Loading />
      ) : (
        <div className='w-full overflow-x-auto'>
          {requestData?.length > 0 ? (
            <BasicTable tableData={requestData} columns={columns} tableId={tableId}/>
          ) : (
            (requestType || selectedType) && <p>{Norequestsfound}</p>
          )}
        </div>
      )}
    </div>
  )
}

export default VendorMyTasks
