import React from 'react'
import UserProfile from '../../components/UserProfile'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useMemo } from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import BasicTable from '../../components/BasicTable'
import { ValidateAPI } from '../../utils/Utils'
export const AdminTabledateFormat = (dateInput) => {
  const year = dateInput?.slice(0, 4)
  const month = dateInput?.slice(5, 7)
  const day = dateInput?.slice(8, 10)
  const Time = dateInput?.split('T')[1]?.slice(0, 8)
  const finalDateTime = `${day}-${month}-${year} ${Time}`
  return finalDateTime
}
const HFTUser = () => {
  const navigate = useNavigate()
  const [hftUser, setHFTUSer] = useState([])
  const column = useMemo(() => [
    // { header: 'ID', accessorKey: 'IDHFTUSER_SUPPLIER_PORTAL' },
    {
      header: 'FULL NAME',
      accessorKey: 'FULLNAME'
    },
    {
      header: 'EMAIL',
      accessorKey: 'EMAIL'
    },
    {
      header: 'USERNAME',
      accessorKey: 'USERNAME'
    },
    {
      header: ' ORACLE ID',
      accessorKey: 'ORACLEUSERID'
    },
    {
      header: 'USER GROUP ROLE',
      accessorKey: 'USER_GROUP_ROLE'
    },
    {
      header: 'Status ',
      accessorKey: 'ENABLED',
      // cell: (info) => (info?.getValue() === 'Y' ? 'ENABLED' : 'DISABLED')
    },
    {
      header: 'CREATE DAT',
      accessorKey: 'CREATEDAT',
      cell: (info) =>
        info?.getValue() !== null ? AdminTabledateFormat(info?.getValue()) : ''
    },
    {
      header: 'UPDATE AT',
      accessorKey: 'UPDATEDAT',
      cell: (info) =>
        info?.getValue() !== null ? AdminTabledateFormat(info?.getValue()) : ''
    }
  ])
  const getHFTUser = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/get-all-hft-buyers`,ValidateAPI
      )
      .then((res) => {
        setHFTUSer(res?.data)
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }
  useEffect(() => {
    getHFTUser()
  }, [])
  return (
    <div className='p-2 w-full  flex flex-col  gap-5'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl font-medium'>HFT User</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      {/* BreadCrumb */}
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/admin')}
          >
            Home
          </span>
          <span>{'>'}</span>
        </p>

        <p className='flex gap-1 items-center justify-center'>
          <span>HFT User </span>
        </p>
      </div>
      
      {/* ALL HFT User TAble */}
      <BasicTable columns={column} tableData={hftUser} />
    </div>
  )
}

export default HFTUser
