import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { formatDateFit } from '../buyer/BuyerRequestDetails'
import {
  ValidateAPI,
  handleAcceptExpediteDeferRequests,
  handlePODetailsClick,
  handleRejectExpediteDeferDivertRequest,
  reasonCodesEN,
  reasonCodesMN
} from '../../utils/Utils'
import ConfirmTable from '../../components/ConfirmTable'
import { LineComp } from './Expedite'
import Loading from '../../components/Loading'
import { EditedRowState } from '../../LineContext/Context'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import PopupModal from '../../components/PopupModal'

const Defer = () => {
  const [requestData, setRequestData] = useState([])
  const user = JSON.parse(localStorage.getItem('userData'))
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [selectedRowArray, setSelectedRowArray] = useState([])
  const [reasonArray, setReasonArray] = useState([])
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [reason_codes, setReasonCode] = useState(reasonCodesEN ?? [])
  const [submitting, setSubmitting] = useState(false)
  const [rowSelection, setRowSelection] = useState({})
  const [showDeferAcceptModal, setShowDeferAcceptModal] = useState(false)
  const { t } = useTranslation()
  const tableId = 'DeferTable'; // Define tableId here
  const {
    DeferRequests,
    Home,
    Submitting_load,
    Pleaseselectreasoncodeforrejectingtheorder,
    Selectareason,
    Reject,
    Cancel
  } = t('DeferPage')

  const {
    state: { editArray },
    dispatch
  } = EditedRowState()
  const headers = {
    CR: t('DeferPage.CR'),
    PONUMBER: t('DeferPage.PONUMBER'),
    SKU: t('DeferPage.SKU'),
    REQSW: t('DeferPage.REQSW'),
    CURRENTSW: t('DeferPage.CURRENTSW'),
    ORGSW: t('ConfirmPOpage.ORGSW'),
    STATUS: t('DeferPage.STATUS'),
    REQUESTTYPE: t('DeferPage.REQUESTTYPE'),
    REQUESTEDBY: t('DeferPage.REQUESTEDBY'),
    CREATEDATE: t('DeferPage.CREATEDATE'),
    UPDATEDATE: t('DeferPage.UPDATEDATE'),
    CRSTATUS: t('DeferPage.CRSTATUS'),
    VENDORRESPONSE: t('DeferPage.VENDORRESPONSE')
  }

  // Access translated strings outside useMemo
  const columns = useMemo(
    () => [
      {
        header: headers.CR,
        accessorKey: 'id_open_request',
        cell: ({ row }) => (
          <p
            className='underline cursor-pointer text-blue-500'
            onClick={() =>
              navigate(
                `/vendor/po_change_requests/request_details/${row?.original.id_open_request}`,
                { state: row?.original }
              )
            }
          >
            {row?.original?.id_open_request}
          </p>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.PONUMBER,
        accessorKey: 'po_number',
        cell: (info) => {
          return (
            <p
              className='underline cursor-pointer text-blue-500'
              onClick={() => handlePODetailsClick('vendor', info?.getValue())}
            >
              {info?.getValue()}
            </p>
          )
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.SKU,
        accessorKey: 'sku',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.REQSW,
        accessorKey: 'requested_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.ORGSW,
        accessorKey: 'original_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CURRENTSW,
        accessorKey: 'current_ship_week',
        // cell: ({ row }) => (
        //   <LineComp
        //     row={row}
        //     requestType={'expedite'}
        //     selectTableRow={handleRowSelection}
        //     rowSelection={selectedRowArray}
        //     updateRowSelection={setRowSelection}
        //   />
        // ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.VENDORRESPONSE,
        accessorKey: 'supplier_response',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CRSTATUS,
        accessorKey: 'status',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.REQUESTEDBY,
        accessorKey: 'created_by',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CREATEDATE,
        accessorKey: 'created_at',
        cell: (info) =>
          info?.getValue() !== null ? formatDateFit(info?.getValue()) : '',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.UPDATEDATE,
        accessorKey: 'updated_at',
        cell: (info) =>
          info?.getValue() !== null ? formatDateFit(info?.getValue()) : ''
      }
    ],
    [headers]
  )

  const handleRowSelection = (rowId) => {
    console.log(`Row with ID ${rowId} is selected`)
    // Perform actions you want in the parent component
  }

  const closeDeferAcceptModal = () => {
    setShowDeferAcceptModal(false)
  }

  const openDeferAcceptModal = () => {
    setShowDeferAcceptModal(true)
  }

  const handleAcceptRequest = () => {
    setSubmitting(true)
    setShowRejectModal(true)
    handleAcceptExpediteDeferRequests(
      setRequestData,
      selectedRowArray,
      setSubmitting,
      setShowRejectModal
    )
    closeDeferAcceptModal()
  }

  const handleReasonChange = (selectedReason) => {
    if (!reasonArray.includes(selectedReason)) {
      setReasonArray((prevReasonArray) => [...prevReasonArray, selectedReason])
    }
  }

  const fetchAllRequests = () => {
    setLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-supplier-cr-defer-data?vendorNumber=${user?.supplierId}`, ValidateAPI
      )
      .then((response) => {
        console.log('response from FCR: ', response?.data)
        if (response?.data?.[0]?.Message) return setRequestData([])
        setRequestData(response?.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(`error while FRC: `, error)
        setLoading(false)
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }

  useEffect(() => {
    const handleStorage = () => {
      if (window.localStorage?.getItem('language') === 'en') {
        setReasonCode(reasonCodesEN)
      } else {
        setReasonCode(reasonCodesMN)
      }
    }

    window.addEventListener('storage', handleStorage())
    return () => window.removeEventListener('storage', handleStorage())
  }, [window.localStorage?.getItem('language')])

  useEffect(() => {
    fetchAllRequests()
    return () => {
      dispatch({
        type: 'CLEAR_LIST'
      })
    }
  }, [])

  return (
    <div className='w-full flex flex-col gap-6 justify-start items-start p-4'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl'>{DeferRequests}</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/vendor')}
          >
            {Home}
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span>{DeferRequests}</span>
        </p>
      </div>
      {selectedRowArray?.length > 0 && (
        <div className='flex w-full justify-start items-center font-medium text-xs gap-6'>
          <button
            className='px-3 rounded-md bg-[#415da1] text-white h-[32px]'
            onClick={openDeferAcceptModal}
          >
            <span>
              {selectedRowArray?.length > 1
                ? t('DeferPage.AcceptRequests')
                : t('DeferPage.AcceptRequest')}
            </span>
          </button>
          <PopupModal
            isOpen={showDeferAcceptModal}
            message={i18next.t('PopUpMessage.Doyouwanttoaccepttheserequests')}
            onCancel={closeDeferAcceptModal}
            cancelText={i18next.t('AcceptModal.Cancel')}
            onConfirm={handleAcceptRequest}
            confirmText={i18next.t('AcceptModal.Confirm')}
          />
          <button
            className='px-3 rounded-md bg-[#415da1] text-white h-[32px]'
            onClick={() => setShowRejectModal(true)}
          >
            {selectedRowArray?.length > 1
              ? t('DeferPage.RejectRequests')
              : t('DeferPage.RejectRequest')}
          </button>
        </div>
      )}
      {loading ? (
        <div className='sm:w-[400px] w-[280px] min-h-[300px] bg-white p-4 rounded-md flex flex-col gap-6'>
          <Loading type={Submitting_load} />
        </div>
      ) : (
        <div className='w-full overflow-x-auto'>
          <ConfirmTable
            tableId={tableId}
            tableData={requestData}
            columns={columns}
            selection={setSelectedRowArray}
            updateRowSelectionProp={setRowSelection}
            rowSelectionProp={rowSelection}
          />
        </div>
      )}

      <dialog
        open={showRejectModal}
        className='p-3 inset-0 fixed z-70 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          {submitting ? (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <Loading type={Submitting_load} />
            </div>
          ) : (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <div className='w-full flex justify-end items-center'>
                <button
                  className='border-2 border-gray-300 p-2 rounded-md'
                  onClick={() => {
                    setShowRejectModal(false)
                    setReasonArray([])
                  }}
                >
                  ❌
                </button>
              </div>
              <div className='w-full flex justify-center items-center mt-2 text-center'>
                <p className='text-sm font-medium'>
                  {Pleaseselectreasoncodeforrejectingtheorder}
                </p>
              </div>
              <div className='w-full flex flex-wrap justify-center items-center gap-3'>
                {reasonArray?.map((reason, index) => (
                  <div
                    className='rounded-lg bg-[#4472C4] flex gap-2 justify-center items-center p-2 text-xs text-white font-medium'
                    key={'reason' + index}
                  >
                    <span>{reason}</span>
                    <span
                      className='cursor-pointer'
                      onClick={() =>
                        setReasonArray(reasonArray?.filter((e) => e !== reason))
                      }
                    >
                      X
                    </span>
                  </div>
                ))}
              </div>
              <div className='w-full flex justify-center items-center'>
                <select
                  onChange={(e) => handleReasonChange(e.target.value)}
                  name='reason_code_select'
                  id='reason_code_select'
                  className='p-2 rounded-md outline-none border border-gray-300 text-xs w-full font-medium'
                  value=''
                >
                  <option value='' disabled>
                    {Selectareason}
                  </option>
                  {reason_codes?.map((reason) => (
                    <option key={'reason_code' + reason} value={reason}>
                      {reason}
                    </option>
                  ))}
                </select>
              </div>
              <div className='w-full flex justify-center items-center gap-4 mt-4'>
                <button
                  className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-green-600 outline-none'
                  onClick={() => {
                    handleRejectExpediteDeferDivertRequest(
                      setRequestData,
                      selectedRowArray,
                      reasonArray,
                      setShowRejectModal,
                      setReasonArray,
                      setSubmitting
                    )
                  }}
                >
                  {Reject}
                </button>
                <button
                  className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-red-500 outline-none'
                  onClick={() => {
                    setShowRejectModal(false)
                    setReasonArray([])
                  }}
                >
                  {Cancel}
                </button>
              </div>
            </div>
          )}
        </div>
      </dialog>
    </div>
  )
}

export default Defer
