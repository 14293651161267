import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  getPossibleShipWeek,
  getStartDate,
  nullOriginal,
  reasonCodes as reason_codes,
  reasonCodesEN,
  reasonCodesMN,
  updateActionHistory,
  ValidateAPI
} from '../../utils/Utils'
import { getWeek } from '../../screens/buyer/NewPoList'
import axios from 'axios'
import { weeksInBetween } from '../../screens/vendor/ConfirmPo'
import Loading from '../Loading'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import PopupModal from '../PopupModal'
import PopupBanner from '../PopupBanner'

const ExpediteActionComp = ({ requestData }) => {
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [showAcceptDatePicker, setShowAcceptDatePicker] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [date, setDate] = useState(new Date())
  const [requestSW, setRequestSW] = useState(requestData?.requested_ship_week)
  const dc_list = ['CAM', 'DAL', 'DIL', 'MOR', 'CHI']
  const [reasonArray, setReasonArray] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [showChangeRequestBanner, setShowChangeRequestBanner] = useState(false)//notifybar
  const [reason_codes, setReasonCode] = useState([])
  const user = JSON.parse(localStorage.getItem('userData'))
  const [showAcceptRequestModal, setShowAcceptRequestModal] = useState(false)
  const { t } = useTranslation()
  const {
    acceptPOChangerequest,
    ORGSW,
    ChangeSW,
    SelectedShipweek,
    DC,
    Cancel,
    ChangeDC,
    Changetheshipweek,
    SelectShipweek,
    Confirm,
    cancelReasonCode,
    Selectareason,
    Reject,
    Submitting_load
  } = t('ExpediteActionComp')

  const handleReasonChange = (selectedReason) => {
    if (!reasonArray.includes(selectedReason)) {
      setReasonArray((prevReasonArray) => [...prevReasonArray, selectedReason])
    }
  }
  const handleChangeDate = (date) => {
    setDate(date)
    setRequestSW(getWeek(date))
  }
  const closePopUpModal = () => {
    setShowChangeRequestBanner(false)
  }

  const closeAcceptRequestModal = () => {
    setShowAcceptRequestModal(false)
  }

  const openAcceptRequestModal = () => {
    setShowAcceptRequestModal(true)
  }

  useEffect(() => {
    const handleStorage = () => {
      if (window.localStorage?.getItem("language") === "en") {
        setReasonCode(reasonCodesEN)

      } else {
        setReasonCode(reasonCodesMN)

      }
    };


    window.addEventListener("storage", handleStorage());
    return () => window.removeEventListener("storage", handleStorage());
  }, [window.localStorage?.getItem("language")]);

  const handleAcceptRequest = () => {
    setShowChangeRequestBanner(true);
    // if (window.confirm("Are you sure you want to confirm?")) {
    let payload = [];
    if (requestData?.request_type !== "Divert") {
      payload = [
        {
          id_open_request: requestData?.id_open_request,
          id_po_supplier_portal: Number(requestData?.id_po_supplier_portal),
          original_dc: requestData?.original_dc,
          requested_dc: requestData?.requested_dc,
          // original_ship_week: showAcceptDatePicker
          //   ? requestSW
          //   : Number(requestData?.requested_ship_week),
          original_ship_week: Number(requestData?.original_ship_week),
          requested_ship_week: Number(requestData?.requested_ship_week),
          original_qty: requestData?.original_qty,
          requested_qty: requestData?.requested_qty,
          inventory_analyst_id: 1,
          current_ship_week: showAcceptDatePicker
            ? requestSW
            : Number(requestData?.requested_ship_week),
          vendor_selected_sw: showAcceptDatePicker
            ? requestSW
            : Number(requestData?.requested_ship_week),
          supplier_contact_id: user?.vendorContactOrcaleId,
          on_time_target: showAcceptDatePicker
            ? requestSW
            : requestData?.requested_ship_week,
          // ship_week_delay: Number(
          //   showAcceptDatePicker
          //     ? Math.abs(
          //         weeksInBetween(
          //           requestData?.requested_ship_week ??
          //             requestData?.original_ship_week,
          //           requestSW
          //         )
          //       )
          //     : 0
          // ),
          ship_week_delay: Number(
            showAcceptDatePicker
              ? Math.abs(
                weeksInBetween(
                  showAcceptDatePicker
                    ? requestSW
                    : Number(requestData?.requested_ship_week),
                  showAcceptDatePicker
                    ? requestSW
                    : requestData?.requested_ship_week
                )
              )
              : 0
          )
        }
      ]
    } else {
      payload = [
        {
          id_open_request: requestData?.id_open_request,
          id_po_supplier_portal: Number(requestData?.id_po_supplier_portal),
          original_dc: requestData?.original_dc,
          requested_dc: requestData?.requested_dc,
          // original_ship_week: showAcceptDatePicker
          //   ? requestSW
          //   : Number(requestData?.requested_ship_week),
          original_ship_week: Number(requestData?.original_ship_week),
          requested_ship_week: Number(requestData?.requested_ship_week),
          original_qty: requestData?.original_qty,
          requested_qty: requestData?.requested_qty,
          inventory_analyst_id: 1,
          current_ship_week: requestData?.current_ship_week,
          vendor_selected_sw: requestData?.vendor_selected_sw,
          supplier_contact_id: user?.vendorContactOrcaleId,
          on_time_target: requestData?.on_time_target,
          // ship_week_delay: Number(
          //   showAcceptDatePicker
          //     ? Math.abs(
          //         weeksInBetween(
          //           requestData?.requested_ship_week ??
          //             requestData?.original_ship_week,
          //           requestSW
          //         )
          //       )
          //     : 0
          // ),
          ship_week_delay: Number(
            showAcceptDatePicker
              ? Math.abs(
                weeksInBetween(
                  showAcceptDatePicker
                    ? requestSW
                    : Number(requestData?.requested_ship_week),
                  showAcceptDatePicker
                    ? requestSW
                    : requestData?.requested_ship_week
                )
              )
              : 0
          )
        }
      ]
    }
    console.log("payload before accept request: ", payload);
    setSubmitting(true);
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-accept-request`,
        payload, ValidateAPI
      )
      .then((response) => {
        console.log("response from accept request: ", response?.data);
        updateActionHistory("Accepted", [requestData]);
        setSubmitting(false);
        setShowAcceptModal(false);
        window?.location?.reload();
      })
      .catch((error) => {
        console.log("error while accepting req: ", error);
        setSubmitting(false);
      });

  };

  const handleRejectRequest = () => {
    if (!reasonArray?.length > 0)
      return alert(i18next.t('VendorPopUp.Pleaseselectareasoncode'))
    const reasons = reasonArray.join(', ')

    const payload = [
      {
        id_open_request: requestData?.id_open_request,
        id_po_supplier_portal: requestData?.id_po_supplier_portal,
        original_dc: requestData?.original_dc,
        requested_dc: requestData?.requested_dc,
        original_ship_week: requestData?.original_ship_week,
        requested_ship_week: requestData?.requested_ship_week,
        reason_code_ship_week: requestData?.request_type !== 'Divert' ? reasons : '',
        reason_code_dc: requestData?.request_type === "Divert" ? reasons : '',
        original_qty: requestData?.original_qty,
        requested_qty: requestData?.original_qty,
        reason_code_qty: '',
        inventory_analyst_id: 1,
        supplier_contact_id: user?.vendorContactOrcaleId,
        current_ship_week: requestData?.current_ship_week,
        status: 'CLOSED'
      }
    ]
    console.log('payload before reject request: ', payload)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-reject-request`,
        payload, ValidateAPI
      )
      .then((response) => {
        console.log('response from reject request: ', response?.data)
        updateActionHistory('Rejected', [requestData])
        setShowRejectModal(false)
        window?.location?.reload()
      })
      .catch((error) => {
        console.log('error while rejecting req: ', error)
      })
  }



  return (
    <div className='w-full flex justify-center items-center gap-2'>
      <div className='flex gap-2 justify-center items-center'>
        <button onClick={() => setShowAcceptModal(true)}>
          <svg
            className='w-6 h-6 text-green-600 '
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            viewBox='0 0 20 20'
          >
            <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
          </svg>
        </button>
        <button onClick={() => setShowRejectModal(true)}>
          <svg
            className='w-6 h-6 text-red-500'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            viewBox='0 0 20 20'
          >
            <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z' />
          </svg>
        </button>
      </div>
      {/*Accept Request Dialog Box */}
      <dialog
        open={showAcceptModal}
        className='p-3 inset-0 fixed z-50 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          {submitting ? (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <Loading type={Submitting_load} />
            </div>
          ) : (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <div className='w-full flex justify-end items-center'>
                <button
                  className='border-2 border-gray-300 p-2 rounded-md text-sm'
                  onClick={() => setShowAcceptModal(false)}
                >
                  ❌
                </button>
              </div>
              <div className='w-full flex justify-center items-center mt-2 text-center'>
                <p className='text-sm font-medium'>{acceptPOChangerequest}</p>
              </div>
              {requestData?.request_type &&
                (requestData.request_type.includes('Defer') ||
                  requestData.request_type.includes('Expedite') ? (
                  <div className='w-full p-3 border border-[#E6EDFF] rounded-md'>
                    <table className='w-full'>
                      <thead>
                        <tr className='border-b border-[#E6EDFF]'>
                          <th className='p-2 py-4 font-medium text-xs'>
                            CURR SW
                          </th>
                          <th className='p-2 py-4 font-medium text-xs'>
                            {ChangeSW}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {requestData?.original_ship_week &&
                          requestData?.requested_ship_week && (
                            <tr className='text-center'>
                              <td className='p-2 py-4 text-xs'>
                                {requestData.current_ship_week}
                              </td>
                              <td className='p-2 py-4 text-xs'>
                                {requestData.requested_ship_week}
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  requestData.request_type.includes('Divert') && (
                    <table className='w-full'>
                      <thead>
                        <tr className='border-b border-[#E6EDFF]'>
                          <th className='p-2 py-4 font-medium text-xs'>{DC}</th>
                          <th className='p-2 py-4 font-medium text-xs'>
                            {ChangeDC}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='text-center'>
                          <td className='p-2 py-4 text-xs'>
                            {requestData.original_dc}
                          </td>
                          <td className='p-2 py-4 text-xs'>
                            {requestData.requested_dc}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )
                ))}
              {requestData?.request_type !== 'Divert' && (
                <div className='text-xs flex gap-2'>
                  <input
                    type='checkbox'
                    name='date_change_cb'
                    id='date_change_cb'
                    value={showAcceptDatePicker}
                    onChange={(e) =>
                      setShowAcceptDatePicker(e?.target?.checked)
                    }
                  />
                  <label htmlFor='date_change_cb'>{Changetheshipweek}</label>
                </div>
              )}
              {showAcceptDatePicker && (
                <div className='flex flex-col gap-3 text-xs w-full'>
                  <div className='flex justify-between items-center w-full'>
                    <p>{SelectShipweek}</p>
                    {!requestData?.request_type?.includes('Expedite') ? (
                      <DatePicker
                        showWeekNumbers
                        filterDate={(date) => date.getDay() === 1}
                        selected={date}
                        onChange={(date) => handleChangeDate(date)}
                        className='p-1 border border-gray-300 rounded-md'
                        calendarStartDay={1}
                        minDate={
                          requestData?.current_ship_week
                            ? getStartDate(
                              getPossibleShipWeek(
                                requestData?.current_ship_week
                              )
                            )
                            : getStartDate('')
                        }
                        maxDate={getStartDate(
                          requestData?.requested_ship_week,
                          -1
                        )}
                        showYearDropdown
                      />
                    ) : (
                      <DatePicker
                        showWeekNumbers
                        filterDate={(date) => date.getDay() === 1}
                        selected={date}
                        onChange={(date) => handleChangeDate(date)}
                        className='p-1 border border-gray-300 rounded-md'
                        calendarStartDay={1}
                        minDate={getStartDate(
                          getPossibleShipWeek(requestData?.requested_ship_week)
                        )}
                        maxDate={
                          requestData?.current_ship_week
                            ? getStartDate(requestData?.current_ship_week, -1)
                            : getStartDate('')
                        }
                        showYearDropdown
                      />
                    )}
                  </div>
                  <div className='w-full flex justify-between items-center'>
                    <p>{SelectedShipweek}</p>
                    <p>{requestSW}</p>
                  </div>
                </div>
              )}
              <div className='w-full flex justify-center items-center gap-4 mt-4'>
                <button
                  className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-green-600 outline-none'
                  onClick={openAcceptRequestModal}
                >
                  {Confirm}
                </button>
                <PopupModal
                  isOpen={showAcceptRequestModal}
                  message={"Are you sure you want to confirm?"}
                  onCancel={closeAcceptRequestModal}
                  cancelText={i18next.t('AcceptModal.Cancel')}
                  onConfirm={handleAcceptRequest}
                  confirmText={i18next.t('AcceptModal.Confirm')}
                />
                <button
                  className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-red-500 outline-none'
                  onClick={() => setShowAcceptModal(false)}
                >
                  {Cancel}
                </button>
              </div>
            </div>
          )}
        </div>
      </dialog>
      {/* <PopupBanner
        isOpen={showChangeRequestBanner}
        message={i18next.t('PopUpMessage.Changerequestissubmittedsucessfully')}
        onCancel={closePopUpModal}
      // text={poRow}

      /> */}

      {/*Reject Request Dialog Box */}

      <dialog
        open={showRejectModal}
        className='p-3 inset-0 fixed z-50 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
            <div className='w-full flex justify-end items-center'>
              <button
                className='border-2 border-gray-300 p-2 rounded-md'
                onClick={() => setShowRejectModal(false)}
              >
                ❌
              </button>
            </div>
            <div className='w-full flex justify-center items-center mt-2 text-center'>
              <p className='text-sm font-medium'>{cancelReasonCode}</p>
            </div>
            <div className='w-full flex flex-wrap justify-center items-center gap-3'>
              {reasonArray?.map((reason, index) => (
                <div
                  className='rounded-lg bg-[#4472C4] flex gap-2 justify-center items-center p-2 text-xs text-white font-medium'
                  key={'reason' + index}
                >
                  <span>{reason}</span>
                  <span
                    className='cursor-pointer'
                    onClick={() =>
                      setReasonArray(reasonArray?.filter((e) => e !== reason))
                    }
                  >
                    X
                  </span>
                </div>
              ))}
            </div>
            <div className='w-full flex justify-center items-center'>
              <select
                onChange={(e) => handleReasonChange(e.target.value)}
                name='reason_code_select'
                id='reason_code_select'
                className='p-2 rounded-md outline-none border border-gray-300 text-xs w-full font-medium'
                value=''
              >
                <option value='' disabled>
                  {Selectareason}
                </option>
                {reason_codes?.map((reason) => (
                  <option key={'reason_code' + reason} value={reason}>
                    {reason}
                  </option>
                ))}
              </select>
            </div>
            <div className='w-full flex justify-center items-center gap-4 mt-4'>
              <button
                className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-green-600 outline-none'
                onClick={openAcceptRequestModal}
              >
                {Reject}
              </button>
              <PopupModal
                isOpen={showAcceptRequestModal}
                message={"Are you sure you want to Reject?"}
                onCancel={closeAcceptRequestModal}
                cancelText={i18next.t('AcceptModal.Cancel')}
                onConfirm={handleRejectRequest}
                confirmText={i18next.t('AcceptModal.Confirm')}
              />
              <button
                className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-red-500 outline-none'
                onClick={() => {
                  setShowRejectModal(false)
                  setReasonArray([])
                }}
              >
                {Cancel}
              </button>
            </div>
          </div>
        </div>
      </dialog>
    </div>
  )
}

export default ExpediteActionComp
