import React from 'react'
import { Outlet } from 'react-router-dom'
import useSessionCheck from '../utils/useSessionCheck'

const EnsureAuthenticated = () => {
  useSessionCheck()
  return <Outlet />
}

export default EnsureAuthenticated
