import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { formatDateFit } from "../buyer/BuyerRequestDetails";
import DatePicker from "react-datepicker";
import { getWeek } from "../buyer/NewPoList";
import "react-datepicker/dist/react-datepicker.css";
import { ValidateAPI, actionCompSelector, checkstatus, handleDownload, updateActionHistory } from "../../utils/Utils";
import { reasonCodesEN, reasonCodesMN } from "../../utils/Utils";
import Comments from "../../components/Comments";
import { weeksInBetween } from "./ConfirmPo";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Stepper from "../../components/Stepper";
import PopupModal from '../../components/PopupModal'

const VendorCRDetails = () => {
  const { id } = useParams();
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [reqAction, setReqAction] = useState(null);
  const [reasonArray, setReasonArray] = useState([]);
  const [showAcceptDatePicker, setShowAcceptDatePicker] = useState(false);
  const [date, setDate] = useState(new Date());
  const [showCRAcceptModal, setShowCRAcceptModal] = useState(false)
  const [showCRRejectModal, setShowCRRejectModal] = useState(false)

  const handleReasonChange = (selectedReason) => {
    if (!reasonArray.includes(selectedReason)) {
      setReasonArray((prevReasonArray) => [...prevReasonArray, selectedReason]);
    }
  };

  const steps = ["Awaiting Response", "Vendor Responded", "Closed"];
  const stepsCancel = [
    "Awaiting Response",
    "Canceled",
    "Vendor Responded",
    "Closed",
  ];
  const [requestData, setRequestData] = useState();
  const [status, setStatus] = useState("");
  const currentStepIndex = steps?.indexOf(status);
  const currentStepIndexCancel = stepsCancel?.indexOf(status);
  const navigate = useNavigate();
  const [requestSW, setRequestSW] = useState(requestData?.requested_ship_week);
  const [reason_codes, setReasonCode] = useState([])
  const [commentsData, setCommentsData] = useState([]);
  const [commentInput, setCommentInput] = useState("");
  const { t } = useTranslation()
  const {
    Home,
    Pleaseselectreasoncodeforrejectingtheorder,
    Selectareason,
    ChangeRequest,
    PO,
    DownloadPurchaseOrder,
    POHeaderDesc,
    ShippingOffice,
    InventoryAnalyst,
    CreateDate,
    VendorManager,
    ReasonCode,
    VendorResponse,
    RequestType,
    LastUpdateDate,

    Rev,
    SKU,
    Description,
    ORGSW,
    ChangeSW,
    QTY,

    ChangeDC,
    Action,

    Cancel,
    LastUpdated,
    POChangeRequestDetails, POChangeRequests, POChangeRequest,
    AreyousureyouwanttoacceptthePOhangerequest, SelectShipweek, SelctedShipWeek, Confirm,
    DC, Changetheshipweek, Reject, VendorSelectedSW, OracleSyncStatus
  } = t("HFTBuyerPOChangeRequestDetailsPage");

  const getStartDate = (weekString) => {
    console.log("value received in getstartdate: ", weekString);
    const [week, year] = weekString?.split(".").map(Number);
    const januaryFirst = new Date(year, 0, 1);
    const dayOffset = (8 - januaryFirst.getDay()) % 7;
    const calculatedDate = new Date(
      januaryFirst.getTime() +
      (week - 1) * 7 * 24 * 60 * 60 * 1000 +
      dayOffset * 24 * 60 * 60 * 1000
    );
    calculatedDate.setFullYear(year + 2000);
    console.log("Calculated Date:", calculatedDate);
    return calculatedDate;
  };

  const handleChangeDate = (date) => {
    setDate(date);
    setRequestSW(getWeek(date));
  };

  const nullOriginal = (requestedShipWeek) => {
    const [requestedWeek, requestedYear] = requestedShipWeek
      ?.split(".")
      ?.map(Number);
    const newWeek = requestedWeek + 4;
    const originalShipWeek = `${newWeek}.${requestedYear}`;
    return getStartDate(originalShipWeek);
  };

  const fetchRequestData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-cr-by-id?idOpenRequest=${id}`, ValidateAPI
      )
      .then((response) => {
        console.log("response from request: ", response?.data);
        setRequestData(response?.data);
        if (response?.data?.supplier_response === "Accept") {
          setReqAction("Accept");
        } else if (response?.data?.supplier_response === "Reject") {
          setReqAction("Reject");
        }
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      });
  };

  const closeCRAcceptModal = () => {
    setShowCRAcceptModal(false)
  }

  const openCRAcceptModal = () => {
    setShowCRAcceptModal(true)
  }

  const handleAcceptRequest = () => {
    // if (window.confirm("Are you sure you want to confirm?")) {
    const payload = [{
      id_open_request: id,
      id_po_supplier_portal: Number(requestData?.id_po_supplier_portal),
      original_dc: requestData?.original_dc,
      requested_dc: requestData?.requested_dc,
      original_ship_week: Number(requestData?.original_ship_week),
      requested_ship_week: Number(requestData?.requested_ship_week),
      original_qty: requestData?.original_qty,
      requested_qty: requestData?.requested_qty,
      inventory_analyst_id: 1,
      current_ship_week: requestData?.current_ship_week,
      vendor_selected_sw: showAcceptDatePicker
        ? requestSW
        : Number(requestData?.requested_ship_week),
      supplier_contact_id: requestData?.supplier_contact_id,
      on_time_target: showAcceptDatePicker
        ? requestSW
        : requestData?.requested_ship_week,
      ship_week_delay: Number(
        showAcceptDatePicker
          ? Math.abs(
            weeksInBetween(
              requestData?.requested_ship_week ??
              requestData?.original_ship_week,
              requestSW
            )
          )
          : 0
      ),
    }];
    console.log("payload before accept request: ", payload);
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-accept-request`,
        payload, ValidateAPI
      )
      .then((response) => {
        // console.log("response from accept request: ", response?.data);
        // updateActionHistory("Accepted", [requestData]);
        closeCRAcceptModal()
        setReqAction("Accept");
        setStatus("Vendor Accepted/Rejected");
        setRequestData(response?.data);
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      });
    // } else {
    //   // If user cancels, do nothing or handle accordingly
    // }
  };

  const closeCRRejectModal = () => {
    setShowCRRejectModal(false)
  }

  const openCRRejectModal = () => {
    setShowCRRejectModal(true)
  }

  const handleRejectRequest = () => {
    if (!reasonArray?.length > 0) return alert(i18next.t("VendorPopUp.Pleaseselectareasoncode"));
    const reasons = reasonArray.join(", ");

    const payload = [{
      id_open_request: id,
      id_po_supplier_portal: requestData?.id_po_supplier_portal,
      original_dc: requestData?.original_dc,
      requested_dc: requestData?.requested_dc,
      original_ship_week: requestData?.original_ship_week,
      requested_ship_week: requestData?.requested_ship_week,
      reason_code_ship_week: reasons,
      original_qty: requestData?.original_qty,
      requested_qty: requestData?.original_qty,
      reason_code_qty: "",
      inventory_analyst_id: 1,
      supplier_contact_id: requestData?.supplier_contact_id,
      current_ship_week: requestData?.current_ship_week,
      status: "Closed",
    }];
    console.log("payload before reject request: ", payload);
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/supplier-reject-request`,
        payload, ValidateAPI
      )
      .then((response) => {
        // console.log("response from reject request: ", response?.data);
        // updateActionHistory("Rejected", [requestData]);
        setReqAction("Reject");
        closeCRRejectModal();
        setStatus("Closed");
        setRequestData(response?.data);
      })
      .catch((error) => {
        console.log("error while rejecting req: ", error);
      });
  };

  useEffect(() => {
    const handleStorage = () => {
      if (window.localStorage?.getItem("language") === "en") {
        setReasonCode(reasonCodesEN)
      } else {
        setReasonCode(reasonCodesMN)
      }
    };

    window.addEventListener("storage", handleStorage());
    return () => window.removeEventListener("storage", handleStorage());
  }, [window.localStorage?.getItem("language")]);

  useEffect(() => {
    fetchRequestData();
  }, [id]);

  useEffect(() => {
    if (requestData?.status?.toLowerCase() === "open") {
      if (requestData?.supplier_response === "Awaiting Response") {
        setStatus("Awaiting Response");
      } else if (requestData?.supplier_response === "Accept" || requestData?.supplier_response === "ACCEPTED") {
        setStatus("Vendor Responded");
      } else if (requestData?.supplier_response === "Reject" || requestData?.supplier_response === "REJECTED") {
        setStatus("Vendor Responded");
      }
      else if (requestData?.supplier_response === "Vendor Initiated") {
        setStatus("Vendor Responded");
      }
    } else if (requestData?.status?.toLowerCase() === "closed") {
      setStatus("Closed");
    } else {
      setStatus("Canceled");
    }
  }, [requestData?.supplier_response, requestData?.status]);

  return (
    <div className="w-full flex flex-col gap-6 justify-start items-start p-4 overflow-y-auto">
      <div className="w-full justify-between flex items-center">
        <div className="">
          <p className="text-xl font-medium">{POChangeRequestDetails}</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      <div className="w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600">
        <p className="flex gap-1 items-center justify-center">
          <span
            className="hover:underline cursor-pointer"
            onClick={() => navigate("/vendor")}
          >
            {Home}
          </span>
          <span>{">"}</span>
        </p>
        <p className="flex gap-1 items-center justify-center">
          <span
            className="hover:underline cursor-pointer"
            onClick={() => navigate("/vendor/po_change_requests")}
          >
            {POChangeRequests}
          </span>
          <span>{">"}</span>
        </p>
        <p className="flex gap-1 items-center justify-center">
          <span>{ChangeRequest} - {id}</span>
        </p>
      </div>
      {/*Stepper to show current status */}
      {/* <div className="w-full flex justify-center items-start relative">
        <div className="w-[70%] border border-gray-200 absolute top-3" />
        <div className="w-[80%] flex justify-between items-center">
          {(requestData?.status?.toLowerCase() === "cancelled"
            ? stepsCancel
            : steps
          )?.map((step, index) => (
            <div
              className="flex flex-col z-50 justify-start items-center gap-2 h-[100px]"
              key={index + step}
            >
              <div
                className={`rounded-full border-2 p-1 ${
                  (requestData?.status?.toLowerCase() === "cancelled"
                    ? currentStepIndexCancel
                    : currentStepIndex) >= index
                    ? "border-blue-600"
                    : "border-gray-300"
                } w-6 h-6 flex justify-center items-center `}
              >
                <div
                  className={`p-1.5 rounded-full ${
                    (requestData?.status?.toLowerCase() === "cancelled"
                      ? currentStepIndexCancel
                      : currentStepIndex) >= index
                      ? "bg-blue-600"
                      : "bg-gray-300"
                  }`}
                ></div>
              </div>
              <p
                className={`${
                  (requestData?.status?.toLowerCase() === "cancelled"
                    ? currentStepIndexCancel
                    : currentStepIndex) >= index
                    ? "text-blue-600"
                    : "text-gray-400"
                } text-center text-xs font-medium w-[160px]`}
              >
                {step}
              </p>
              <p
                  className={`${
                    (requestData?.status?.toLowerCase() === "cancelled"
                      ? currentStepIndexCancel
                      : currentStepIndex) >= index
                      ? "text-blue-600"
                      : "text-gray-400"
                  } text-center text-xs font-medium w-[160px]`}
                  >
                    {step==="Awaiting Response" &&formatDateFit(requestData?.created_at)}
                   { requestData?.status?.toLowerCase() === "cancelled" ?
                   index==currentStepIndexCancel &&formatDateFit(requestData?.updated_at) :
                   index==currentStepIndex &&formatDateFit(requestData?.updated_at)
                   }
                    </p>
            </div>
          ))}
        </div>
      </div> */}
      {/* Stepper Component  */}
      <Stepper status={status} crId={requestData?.id_open_request} iscancelled={requestData?.status?.toLowerCase() === "cancelled"} />
      <div className="w-full border border-[#E6EDFF] rounded-md p-3">
        <table className="w-full">
          <tbody className="divide-y-[1px] divide-[#E6EDFF]">
            <tr className="text-center">
              <td className="text-xs p-2 py-4">
                <div className="flex flex-col gap-1">
                  <p className="font-medium">{PO}</p>
                  <p>{requestData?.po_number}</p>
                </div>
              </td>
              <td className="text-xs p-2 py-4">
                <div className="flex flex-col gap-1">
                  <p className="font-medium">{ReasonCode}</p>
                  {/* <p>{requestData?.request_type === "Divert" ? requestData?.reason_code_dc : requestData?.reason_code_ship_week}</p> */}
                  <p>{requestData?.reason_code_dc ? requestData?.reason_code_dc : requestData?.reason_code_ship_week}</p>
                </div>
              </td>
              <td className="text-xs p-2 py-4">
                <div className="flex flex-col gap-1">
                  <p className="font-medium">{VendorResponse}</p>
                  <p>{requestData?.supplier_response}</p>
                </div>
              </td>
              <td className="text-xs p-2 py-4">
                <div className="flex flex-col gap-1">
                  <p className="font-medium">{RequestType}</p>
                  <p className="capitalize">{requestData?.request_type}</p>
                </div>
              </td>
              <td className="text-xs p-2 py-4">
                <div className="flex flex-col gap-1">
                  <p className="font-medium">{DownloadPurchaseOrder}</p>
                  <button
                    className="outline-none w-full flex justify-center"
                    onClick={() => handleDownload(requestData?.po_number)}
                  >
                    <svg
                      className="w-5 h-5 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 16 18"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                      />
                    </svg>
                  </button>
                </div>
              </td>
              <td className="text-xs p-2 py-4">
                <div className="flex flex-col gap-1">
                  <p className="font-medium">{OracleSyncStatus}</p>
                  <p>{checkstatus(requestData?.process_flag)}</p>
                </div>
              </td>
            </tr>
            <tr className="text-center">
              <td className="text-xs p-2 py-4">
                <div className="flex flex-col gap-1">
                  <p className="font-medium">{POHeaderDesc}</p>
                  <p></p>
                </div>
              </td>
              <td className="text-xs p-2 py-4">
                <div className="flex flex-col gap-1">
                  <p className="font-medium">{ShippingOffice}</p>
                  <p></p>
                </div>
              </td>
              <td className="text-xs p-2 py-4">
                <div className="flex flex-col gap-1">
                  <p className="font-medium">{InventoryAnalyst}</p>
                  <p>{requestData?.inventory_analyst}</p>
                </div>
              </td>
              <td className="text-xs p-2 py-4">
                <div className="flex flex-col gap-1">
                  <p className="font-medium">{CreateDate}</p>
                  <p>{formatDateFit(requestData?.created_at)}</p>
                </div>
              </td>
              <td className="text-xs p-2 py-4">
                <div className="flex flex-col gap-1">
                  <p className="font-medium">{LastUpdateDate}</p>
                  <p>{formatDateFit(requestData?.updated_at)}</p>
                </div>
              </td>
              <td className="text-xs p-2 py-4">
                <div className="flex flex-col gap-1">
                  <p className="font-medium">{VendorManager}</p>
                  <p>{requestData?.vendor_manager}</p>
                </div>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
      <div className="w-full flex justify-start items-start mt-1 flex-col gap-4">
        <div>
          <p className="font-semibold text-xl">{POChangeRequest}</p>
        </div>
        <div classsName="w-full p-3 border border-[#E6EDFF] rounded-md" style={{ width: "100%" }}>
          <table className="w-full">
            <thead>
              <tr className="border-b border-[#E6EDFF]">
                <th className="p-2 py-4 font-medium text-xs">{Rev}</th>
                <th className="p-2 py-4 font-medium text-xs">{SKU}</th>
                <th className="p-2 py-4 font-medium text-xs">{Description}</th>
                <th className="p-2 py-4 font-medium text-xs">CURR SW</th>
                <th className="p-2 py-4 font-medium text-xs">ORG SW</th>
                {
                  requestData?.request_type !== "Divert" &&
                  <th className="p-2 py-4 font-medium text-xs">{ChangeSW}</th>
                }
                {requestData?.vendor_selected_sw && (
                  <th className="p-2 py-4 font-medium text-xs">
                    {VendorSelectedSW}
                  </th>
                )}
                <th className="p-2 py-4 font-medium text-xs">{QTY}</th>

                <th className="p-2 py-4 font-medium text-xs">{DC}</th>
                {requestData?.original_dc !== requestData?.requested_dc && (
                  <th className="p-2 py-4 font-medium text-xs">{ChangeDC}</th>
                )}

                <th className="p-2 py-4 font-medium text-xs">{LastUpdated}</th>
                {requestData?.request_type !== "Delay" &&
                  (
                    <th className="p-2 py-4 font-medium text-xs">{Action}</th>
                  )}
              </tr>
            </thead>
            <tbody>
              <tr className="text-center">
                <td className="p-2 py-4 text-xs">
                  {requestData?.revision_number}
                </td>
                <td className="p-2 py-4 text-xs">{requestData?.sku}</td>
                <td className="p-2 py-4 text-xs">{requestData?.description}</td>
                <td className="p-2 py-4 text-xs">
                  {requestData?.current_ship_week}
                </td>
                <td className="p-2 py-4 text-xs">{requestData?.original_ship_week}</td>
                {requestData?.request_type !== "Divert" &&
                  <td className="p-2 py-4 text-xs flex justify-center gap-1 relative">
                    {requestData?.request_type !== "Delay" ? requestData?.requested_ship_week : requestData?.current_ship_week}
                    {requestData?.requested_ship_week && (
                      <div className="bg-green-500 p-1 rounded-full absolute  top-1"></div>
                    )}
                  </td>}
                {requestData?.vendor_selected_sw && (
                  <td className="p-2 py-4 text-xs  relative  ">
                    {requestData?.vendor_selected_sw}
                  </td>
                )}
                <td className="p-2 py-4 text-xs ">
                  {requestData?.original_qty}
                </td>

                <td className="p-2 py-4 text-xs">{requestData?.original_dc}</td>
                {requestData?.original_dc !== requestData?.requested_dc && (
                  <td className="p-2 py-4 text-xs flex justify-center gap-1 relative">
                    {requestData?.requested_dc}
                    {requestData?.requested_dc && (
                      <div className="bg-green-500 p-1 rounded-full absolute  top-1"></div>
                    )}
                  </td>
                )}
                {/* <td className="p-2 py-4 text-xs">
                    {requestData?.message}
                </td> */}
                <td className="p-2 py-4 text-xs">
                  {formatDateFit(requestData?.updated_at)}
                </td>
                {requestData?.request_type !== "Delay" && (
                  <td className="p-2 py-4 text-xs">
                    {actionCompSelector(requestData)}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/*Accept Request Dialog Box */}
      <dialog
        open={showAcceptModal}
        className="p-3 inset-0 fixed z-70 bg-black bg-opacity-50 w-full min-h-screen"
      >
        <div className="w-full min-h-screen p-4 flex justify-center items-center">
          <div className="sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6">
            <div className="w-full flex justify-end items-center">
              <button
                className="border-2 border-gray-300 p-2 rounded-md text-sm"
                onClick={() => setShowAcceptModal(false)}
              >
                ❌
              </button>
            </div>
            <div className="w-full flex justify-center items-center mt-2 text-center">
              <p className="text-sm font-medium">
                {AreyousureyouwanttoacceptthePOhangerequest}
              </p>
            </div>
            {requestData?.request_type &&
              (requestData.request_type.includes("Defer") ||
                requestData.request_type.includes("Expedite") ? (
                <div className="w-full p-3 border border-[#E6EDFF] rounded-md">
                  <table className="w-full">
                    <thead>
                      <tr className="border-b border-[#E6EDFF]">
                        <th className="p-2 py-4 font-medium text-xs"></th>
                        <th className="p-2 py-4 font-medium text-xs">
                          {ChangeSW}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {requestData?.original_ship_week &&
                        requestData?.requested_ship_week && (
                          <tr className="text-center">
                            <td className="p-2 py-4 text-xs">
                              {requestData.original_ship_week}
                            </td>
                            <td className="p-2 py-4 text-xs">
                              {requestData.requested_ship_week}
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              ) : (
                requestData.request_type.includes("Divert") && (
                  <table className="w-full">
                    <thead>
                      <tr className="border-b border-[#E6EDFF]">
                        <th className="p-2 py-4 font-medium text-xs">{DC}</th>
                        <th className="p-2 py-4 font-medium text-xs">
                          {ChangeDC}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <td className="p-2 py-4 text-xs">
                          {requestData.original_dc}
                        </td>
                        <td className="p-2 py-4 text-xs">
                          {requestData.requested_dc}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )
              ))}
            {requestData?.request_type !== "Divert" && (
              <div className="text-xs flex gap-2">
                <input
                  type="checkbox"
                  name="date_change_cb"
                  id="date_change_cb"
                  value={showAcceptDatePicker}
                  onChange={(e) => setShowAcceptDatePicker(e?.target?.checked)}
                />
                <label htmlFor="date_change_cb">{Changetheshipweek}</label>
              </div>
            )}
            {showAcceptDatePicker && (
              <div className="flex flex-col gap-3 text-xs w-full">
                <div className="flex justify-between items-center w-full">
                  <p>{SelectShipweek}:</p>
                  {!requestData?.request_type?.includes("Expedite") ? (
                    <DatePicker
                      showWeekNumbers
                      filterDate={(date) => date.getDay() === 1}
                      selected={date}
                      onChange={(date) => handleChangeDate(date)}
                      className="p-1 border border-gray-300 rounded-md"
                      calendarStartDay={1}
                      minDate={
                        getStartDate(requestData?.original_ship_week) ?? ""
                      }
                      maxDate={
                        requestData?.requested_ship_week
                          ? getStartDate(requestData?.requested_ship_week)
                          : ""
                      }
                      showYearDropdown
                    />
                  ) : (
                    <DatePicker
                      showWeekNumbers
                      filterDate={(date) => date.getDay() === 1}
                      selected={date}
                      onChange={(date) => handleChangeDate(date)}
                      className="p-1 border border-gray-300 rounded-md"
                      calendarStartDay={1}
                      maxDate={getStartDate(requestData?.requested_ship_week)}
                      minDate={
                        requestData?.original_ship_week
                          ? getStartDate(requestData?.original_ship_week)
                          : nullOriginal(requestData?.requested_ship_week)
                      }
                      showYearDropdown
                    />
                  )}
                </div>
                <div className="w-full flex justify-between items-center">
                  <p>{SelctedShipWeek}</p>
                  <p>{requestSW}</p>
                </div>
              </div>
            )}
            <div className="w-full flex justify-center items-center gap-4 mt-4">
              <button
                className="text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-green-600 outline-none"
                onClick={openCRAcceptModal}
              >
                {Confirm}
              </button>
              <PopupModal
                isOpen={showCRAcceptModal}
                message={i18next.t('PopUpMessage.Doyouwanttoaccepttheserequests')}
                onCancel={closeCRAcceptModal}
                cancelText={i18next.t('AcceptModal.Cancel')}
                onConfirm={handleAcceptRequest}
                confirmText={i18next.t('AcceptModal.Confirm')}
              />
              <button
                className="text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-red-500 outline-none"
                onClick={() => setShowAcceptModal(false)}
              >
                {Cancel}
              </button>
            </div>
          </div>
        </div>
      </dialog>
      {/*Reject Request Dialog Box */}
      <dialog
        open={showRejectModal}
        className="p-3 inset-0 fixed z-70 bg-black bg-opacity-50 w-full min-h-screen"
      >
        <div className="w-full min-h-screen p-4 flex justify-center items-center">
          <div className="sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6">
            <div className="w-full flex justify-end items-center">
              <button
                className="border-2 border-gray-300 p-2 rounded-md"
                onClick={() => setShowRejectModal(false)}
              >
                ❌
              </button>
            </div>
            <div className="w-full flex justify-center items-center mt-2 text-center">
              <p className="text-sm font-medium">
                {Pleaseselectreasoncodeforrejectingtheorder}
              </p>
            </div>
            <div className="w-full flex flex-wrap justify-center items-center gap-3">
              {reasonArray?.map((reason, index) => (
                <div
                  className="rounded-lg bg-[#4472C4] flex gap-2 justify-center items-center p-2 text-xs text-white font-medium"
                  key={"reason" + index}
                >
                  <span>{reason}</span>
                  <span
                    className="cursor-pointer"
                    onClick={() =>
                      setReasonArray(reasonArray?.filter((e) => e !== reason))
                    }
                  >
                    X
                  </span>
                </div>
              ))}
            </div>
            <div className="w-full flex justify-center items-center">
              <select
                onChange={(e) => handleReasonChange(e.target.value)}
                name="reason_code_select"
                id="reason_code_select"
                className="p-2 rounded-md outline-none border border-gray-300 text-xs w-full font-medium"
                value=""
              >
                <option value="" disabled>
                  {Selectareason}
                </option>
                {reason_codes?.map((reason) => (
                  <option key={"reason_code" + reason} value={reason}>
                    {reason}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full flex justify-center items-center gap-4 mt-4">
              <button
                className="text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-green-600 outline-none"
                onClick={openCRRejectModal}
              >
                {Reject}
              </button>
              <PopupModal
                isOpen={showCRRejectModal}
                message={i18next.t('PopUpMessage.Doyouwanttorejecttheserequests')}
                onCancel={closeCRRejectModal}
                cancelText={i18next.t('AcceptModal.Cancel')}
                onConfirm={handleRejectRequest}
                confirmText={i18next.t('AcceptModal.Confirm')}
              />
              <button
                className="text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-red-500 outline-none"
                onClick={() => {
                  setShowRejectModal(false);
                  setReasonArray([]);
                }}
              >
                {Cancel}
              </button>
            </div>
          </div>
        </div>
      </dialog>
      {/*Comments Section */}
      <div className="w-full">
        <Comments
          crId={requestData?.id_open_request}
          poNumber={requestData?.po_number}
          sku={requestData?.sku}
          status={requestData?.status === "CLOSED"}
        />
      </div>
    </div>
  );
};

export default VendorCRDetails;
