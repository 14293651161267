import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import UserProfile from '../components/UserProfile'
import { useForm } from 'react-hook-form'
const CreateHFTUser = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    country: '',
    city: '',
    password: '',
    userGroup: 2,
    enabled: ''
  })

  const onSubmit = () => {
    console.log(formData)
    const payload = {
      fullName: formData.fullName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      country: formData.country,
      city: formData.city,
      password: formData.password,
      userGroup: parseInt(formData.userGroup),
      enabled: formData.enabled
    }
    // axios.post('http://localhost:8088/api/createHFTUser', payload).then((res) => {
    //     console.log(res);
    //     alert("Data Submitted");

    // }).catch((err) => {
    //     alert(err);
    // })
  }
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  return (
    <main className='p-2 w-full  space-y-5'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl font-medium'>Supplier Contact Create</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      {/* BreadCrumb */}
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/admin')}
          >
            Home
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/admin/hft_user')}
          >
            HFT User
          </span>
          <span>{'>'}</span>
        </p>

        <p className='flex gap-1 items-center justify-center'>
          <span>Create HFT User </span>
        </p>
      </div>
      <div className='mx-auto max-w-xl flex flex-col'>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit()
          }}
          className=''
        >
          {/* Full Name & email enter*/}
          <div class='w-full  md:flex  gap-5'>
            <div class=' w-full md:w-1/2 mb-5 group'>
              <label
                for='fullName'
                class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
              >
                Full Name
              </label>
              <input
                type='text'
                id='fullName'
                name='fullName'
                class='bg-gray-50 border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                onChange={handleChange}
              />
            </div>

            <div class=' w-full md:w-1/2 mb-5 group'>
              <label
                for='Email'
                class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
              >
                Email
              </label>
              <input
                type='email'
                id='Email'
                name='email'
                class='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                onChange={handleChange}
              />
            </div>
          </div>
          {/* Country Select box & Phone Number*/}
          <div class='w-full  md:flex  gap-5'>
            <div class=' w-full md:w-1/2 mb-5 group'>
              <label
                for='Country'
                class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
              >
                Country
              </label>
              <select
                id='country'
                name='country'
                class='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                onChange={handleChange}
              >
                <option selected>Choose a Country</option>
                <option value='Abbeville'>Abbeville</option>
                <option value='Acadia'>Acadia</option>
                <option value='Accomack'>Accomack</option>
                <option value='Accomack'>Accomack</option>
              </select>
            </div>
            <div class=' w-full md:w-1/2 mb-5 group'>
              <label
                for='Phone'
                class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
              >
                Phone
              </label>
              <input
                type='text'
                id='Phone'
                name='phoneNumber'
                class='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                onChange={handleChange}
              />
            </div>
          </div>
          {/* City Input Box & Password Input Box  */}
          <div class='w-full  md:flex  gap-5'>
            <div class=' w-full md:w-1/2 mb-5 group'>
              <label
                for='City'
                class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
              >
                City
              </label>
              <input
                type='text'
                id='City'
                name='city'
                class='bg-gray-50 border border-gray-300 text-gray-900vtext-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                onChange={handleChange}
              />
            </div>
            <div class=' w-full md:w-1/2 mb-5 group'>
              <label
                for='Password'
                class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
              >
                Password
              </label>
              <input
                type='password'
                id='Password'
                name='password'
                class='bg-gray-50 border border-gray-300
             text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
              '
                onChange={handleChange}
              />
            </div>
          </div>
          {/* Group Selection  & Status */}
          <div class='w-full  md:flex  gap-5'>
            <div class=' w-full md:w-1/2 mb-5 group'>
              <label
                for='Suplier Name'
                class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
              >
                Group
              </label>
              <select
                id='userGroup'
                name='userGroup'
                class='bg-gray-50 border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                onChange={handleChange}
              >
                <option selected>Choose a Group</option>
                <option value='1'>SUPPLIER</option>
                <option value='2'>HFT_ASIA_PECIFIC</option>
                <option value='3'>HFT_BUYER</option>
              </select>
            </div>
            <div className=' w-full md:w-1/2   flex items-center  gap-3'>
              <label
                for='enabled'
                class='block  text-sm font-medium text-gray-900 dark:text-white'
              >
                Status
              </label>
              <input
                type='radio'
                name='enabled'
                value='Y'
                id='enabled'
                onChange={handleChange}
                required
              />
              <label
                for='enabled'
                class='block  text-sm font-medium text-gray-900 dark:text-white'
              >
                Enable
              </label>
              <input
                type='radio'
                name='enabled'
                value='N'
                id='enabled'
                onChange={handleChange}
                required
              />
              <label
                for='enabled'
                class='block  text-sm font-medium text-gray-900 dark:text-white'
              >
                Disable{' '}
              </label>
            </div>
          </div>
          {/* Submit Button */}
          <input
            type='submit'
            value='submit'
            className='border p-2 rounded-md bg-gray-200 text-black cursor-pointer'
          />
        </form>
      </div>
    </main>
  )
}
export default CreateHFTUser
