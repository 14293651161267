import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { formatDateFit } from '../screens/buyer/BuyerRequestDetails'
import { ValidateAPI } from '../utils/Utils'

const Stepper = ({ status, crId, iscancelled }) => {
  const [steps, setSteps] = useState([
    { suppRes: 'Awaiting Response', updatedAT: '' },
    { suppRes: 'Vendor Responded', updatedAT: '' },
    { suppRes: 'Closed', updatedAT: '' }
  ])
  const [stepsCancel, setStepsCancel] = useState([
    { suppRes: 'Awaiting Response', updatedAT: '' },
    { suppRes: 'Cancelled', updatedAT: '' },
    { suppRes: 'Vendor Responded', updatedAT: '' },
    { suppRes: 'Closed', updatedAT: '' }
  ])
  let currentStepIndex = iscancelled
    ? stepsCancel?.findIndex((item) => item.suppRes === status)
    : steps?.findIndex((item) => item.suppRes === status)
  const fetchCommentsData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-all-cr-activity-by-crid?hftcrid=${crId}`,ValidateAPI
      )
      .then((response) => {
        const newArray = iscancelled ? [...stepsCancel] : [...steps]
        response?.data?.map((item) => {
          if (item?.MESSAGE?.includes('Created'))
            newArray[0] = { ...newArray[0], ...{ updatedAT: item?.CREATED_AT } }
          else if (
            item?.MESSAGE?.includes('Accepted') ||
            item?.MESSAGE?.includes('Vendor Initiated')
          ) {
            newArray[1] = { ...newArray[1], ...{ updatedAT: item?.CREATED_AT } }
          } else if (item?.MESSAGE?.includes('Rejected'))
            newArray[2] = { ...newArray[2], ...{ updatedAT: item?.CREATED_AT } }
          else if (item?.MESSAGE?.includes('Closed'))
            newArray[2] = { ...newArray[2], ...{ updatedAT: item?.CREATED_AT } }
          else if (item?.MESSAGE?.includes('Cancelled'))
            newArray[1] = { ...newArray[1], ...{ updatedAT: item?.CREATED_AT } }
          // console.log(newArray);
          iscancelled ? setStepsCancel(newArray) : setSteps(newArray)
        })
      })
      .catch((error) => {
        console.log('error while fetching comments: ', error)
      })
  }
  useEffect(() => {
    fetchCommentsData()
  }, [crId])
  return (
    <div className='w-full flex justify-center items-start relative'>
      <div className='w-[70%] border border-gray-200 absolute top-3' />
      <div className='w-[80%] flex justify-between items-center'>
        {(iscancelled ? stepsCancel : steps)?.map((step, index) => (
          <div
            className='flex flex-col z-10 justify-start items-center gap-2 h-[100px]'
            key={index + step}
          >
            <div
              className={`rounded-full border-2 p-1 ${
                currentStepIndex >= index
                  ? 'border-blue-600'
                  : 'border-gray-300'
              } w-6 h-6 flex justify-center items-center `}
            >
              <div
                className={`p-1.5 rounded-full ${
                  currentStepIndex >= index ? 'bg-blue-600' : 'bg-gray-300'
                }`}
              ></div>
            </div>
            <div
              className={`${
                currentStepIndex >= index ? 'text-blue-600' : 'text-gray-400'
              } text-center text-xs font-medium w-[160px]`}
            >
              <p>{step?.suppRes}</p>
              <p>{step?.updatedAT && formatDateFit(step?.updatedAT)}</p>
            </div>
          </div>
        ))}
      </div>
      {/* <pre>{JSON.stringify(step, null, "\t")}</pre> */}
    </div>
  )
}
export default Stepper
