import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const Register = () => {
  const navigate = useNavigate()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [role, setRole] = useState()

  // const handleRegistration = () => {
  //     if (password === confirmPassword) {
  //         const payload = {
  //             "first_name": firstName,
  //             "last_name": lastName,
  //             "email_id": email,
  //             "password": password,

  //         }
  //         const url = "https://assessment-portal-api-dev.thesketch.net/api/v1/register"
  //         axios.post(url, payload)
  //         .then((response) => {
  //             console.log("Registered successfully! ", response?.data)
  //             alert("Registration Sucessful! Please login to continue")
  //             navigate("/login")
  //         })
  //         .catch((error) => {
  //             console.log("Error while registering: ", error)
  //         })

  //     } else {
  //         alert("Passwords do not match")
  //     }
  // }

  return (
    <section class='bg-gray-50 dark:bg-gray-900 font-pop'>
      <div class='flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0'>
        <p class='flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white'>
          Harbor Freight Tools
        </p>
        <div class='w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700'>
          <div class='p-6 space-y-4 md:space-y-6 sm:p-8'>
            <h1 class='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white'>
              Create User
            </h1>
            <form
              class='space-y-4 md:space-y-6'
              onSubmit={(e) => {
                e?.preventDefault()
                // handleRegistration()
              }}
            >
              <div className='w-full flex gap-3'>
                <div className='w-1/2'>
                  <label
                    htmlFor='fName'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    First Name
                  </label>

                  <input
                    value={firstName}
                    autoComplete='off'
                    onChange={(e) => setFirstName(e?.target?.value)}
                    type='text'
                    name='first_name'
                    id='fName'
                    className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    placeholder='John'
                    required
                  />
                </div>
                <div className='w-1/2'>
                  <label
                    htmlFor='lName'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Last Name
                  </label>
                  <input
                    value={lastName}
                    autoComplete='off'
                    onChange={(e) => setLastName(e?.target?.value)}
                    type='text'
                    name='last_name'
                    id='lName'
                    className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    placeholder='Doe'
                    required
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor='email'
                  class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                >
                  Your email
                </label>
                <input
                  type='email'
                  name='email'
                  id='email'
                  class='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  placeholder='name@company.com'
                  value={email}
                  onChange={(e) => setEmail(e?.target.value)}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor='password'
                  class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                >
                  Password
                </label>
                <input
                  type='password'
                  name='password'
                  id='password'
                  placeholder='••••••••'
                  class='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  value={password}
                  onChange={(e) => setPassword(e?.target.value)}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor='confirm-password'
                  class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                >
                  Confirm password
                </label>
                <input
                  type='confirm-password'
                  name='confirm-password'
                  id='confirm-password'
                  placeholder='••••••••'
                  class='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e?.target.value)}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor='role_select'
                  class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                >
                  Role
                </label>
                <select
                  name='role_select'
                  id='role_select'
                  onChange={(e) => {
                    setRole(e?.target?.value)
                  }}
                  className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                >
                  <option value='buyer'>Buyer</option>
                  <option value='supplier'>Supplier</option>
                  <option value='asia_pacific'>Asia Pacific</option>
                  <option value='analyst'>Analyst</option>
                </select>
              </div>

              <div class='flex items-start'>
                <div class='flex items-center h-5'>
                  <input
                    id='terms'
                    aria-describedby='terms'
                    type='checkbox'
                    class='w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                    required
                  />
                </div>
                <div class='ml-3 text-sm'>
                  <label
                    htmlFor='terms'
                    class='font-light text-gray-500 dark:text-gray-300'
                  >
                    I accept the{' '}
                    <span class='font-medium text-blue-600 hover:underline dark:text-blue-500 cursor-pointer'>
                      Terms and Conditions
                    </span>
                  </label>
                </div>
              </div>
              <button
                type='submit'
                class='w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
              >
                Create an account
              </button>
              <p class='text-sm font-light text-gray-500 dark:text-gray-400 '>
                Already have an account?{' '}
                <span
                  class='font-medium text-blue-600 hover:underline dark:text-blue-500 cursor-pointer'
                  onClick={() => {
                    navigate('/login')
                  }}
                >
                  Login here
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Register
