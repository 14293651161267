import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { clearLocalStorage, deleteCookie } from '../utils/Utils'

const SessionExpired = () => {
  const navigate = useNavigate()

  useEffect(() => {
    deleteCookie('idcs_id_token')
    deleteCookie('idcs_user_assertion')
    deleteCookie('role')
    clearLocalStorage()
    setTimeout(() => {
      navigate('/login')
    }, 2000) // Wait for 2 seconds before redirecting
  }, [navigate])

  return (
    <div>
      <h1>Your session seems to have expired, logging you out...</h1>
    </div>
  )
}

export default SessionExpired
