import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import UserProfile from '../../components/UserProfile'
import { useMemo } from 'react'
import BasicTable from '../../components/BasicTable'
import { useEffect } from 'react'
import axios from 'axios'
import { formatDate } from '../buyer/BuyerRequest'

const CreateSupplier = () => {
  const navigate = useNavigate()
  const [supplierContactList, setContactList] = useState([])
  const column = useMemo(() => [
    { header: 'VENDOR ID', accessorKey: 'ID_VENDOR' },
    { header: 'VENDOR_ORACLE_ID', accessorKey: 'VENDOR_ORACLE_ID' },
    { header: 'VENDOR NAME', accessorKey: 'VENDOR_NAME' },
    {
      header: 'FULL NAME',
      accessorKey: 'FULLNAME'
    },
    {
      header: 'EMAIL',
      accessorKey: 'EMAIL'
    },
    {
      header: 'SUPPLIER NAME',
      accessorKey: 'SUPPLIERNAME'
    },
    {
      header: 'PHONE NUMBER',
      accessorKey: 'PHONENUMBER'
    },
    {
      header: 'COUNTRY',
      accessorKey: 'COUNTRY'
    },
    {
      header: 'CITY',
      accessorKey: 'CITY'
    },
    {
      header: 'VENDOR CONTACT',
      accessorKey: 'VENDOR_CONTACT'
    },
    {
      header: 'Status',
      accessorKey: 'enabled',
      cell: (info) => (info?.getValue() === 'H' ? 'ENABLED' : 'DISABLED')
    },
    {
      header: 'CREATED AT',
      accessorKey: 'CREATEDAT',
      cell: (info) =>
        info?.getValue() !== null ? formatDate(info?.getValue()) : ''
    },
    {
      header: 'UPDATED AT',
      accessorKey: 'UPDATEDAT',
      cell: (info) =>
        info?.getValue() !== null ? formatDate(info?.getValue()) : ''
    }
  ])
  const getAllVendor = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/get-all-hft-vendors`
      )
      .then((res) => {
        setContactList(res?.data)
      })
      .catch((err) => {
        alert('some issue please try again!')
      })
  }
  useEffect(() => {
    getAllVendor()
  }, [])
  return (
    <div className='p-2 w-full  flex flex-col  gap-5'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl font-medium'>HFT User</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      {/* BreadCrumb */}
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/admin')}
          >
            Home
          </span>
          <span>{'>'}</span>
        </p>

        <p className='flex gap-1 items-center justify-center'>
          <span>Create Vendor </span>
        </p>
      </div>

      {/* ALL HFT User TAble */}
      <BasicTable tableData={supplierContactList} columns={column} />
    </div>
  )
}
export default CreateSupplier
