import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { FiSend } from 'react-icons/fi'
import { ValidateAPI } from '../utils/Utils'

const MessageBox = ({ po, sku, name, onClose }) => {
  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState('')
  const [loading, setLoading] = useState(true)
  const timerRef = useRef(null)
  const latestMessageIdRef = useRef(null)

  const role = localStorage.getItem('role')

  const fetchMessages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/messages?poNumber=${po}&poSku=${sku}`,ValidateAPI
      )

      if (response.data.length > 0) {
        // Check if there are new messages since the last update
        const latestMessageId =
          response.data[response.data.length - 1].messageId
        if (latestMessageId !== latestMessageIdRef.current) {
          setMessages(response.data)
          latestMessageIdRef.current = latestMessageId
          markMessagesAsRead()
        }
      } else {
        setMessages([])
      }
      setLoading(false)
    } catch (error) {
      console.error('Error fetching messages:', error)
      setLoading(false)
    }
  }

  const markMessagesAsRead = async() => {
    // Call markAsRead API to update unread status
    console.log(ValidateAPI);
    await axios
      .put(`${process.env.REACT_APP_API_DOMAIN}:8088/api/messages/markAsRead?poNumber=${po}&poSku=${sku}&role=${role}`,{},ValidateAPI
      )
      .then(() => {
        console.log('Messages marked as read')
      })
      .catch((error) => {
        console.error('Error marking messages as read:', error)
      })
  }

  useEffect(() => {
    setLoading(true) // Set loading to true whenever po or sku changes
    fetchMessages()

    // Start polling for new messages every 10 seconds
    timerRef.current = setInterval(fetchMessages, 10000)

    return () => {
      // Clear the interval timer
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
  }, [po, sku, role]) // Dependencies include po and sku

  const handleSendMessage = async () => {
    const newMessageObject = {
      poNumber: po,
      poSku: sku,
      role: role,
      sender: name,
      messageContent: newMessage,
      timestamp: new Date().toISOString() // Add a timestamp here for immediate display
    }
    if(!newMessage)
    return alert("Please enter a message")
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/messages`,
        newMessageObject,ValidateAPI
      )
      setMessages([...messages, response.data])
      setNewMessage('')
    } catch (error) {
      console.error('Error sending message:', error)
    }
  }
  //enter to send a message
    const hanldeEnter= (e)=>{
      if(e.key==="Enter")
      {
        handleSendMessage()
      }
    }
    //close the message box while clicking outside
    const messageBox = useRef()
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (messageBox.current && !messageBox.current.contains(event.target)) {
          onClose();
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [onClose]);

    const bottomScroll = useRef()
    useEffect(() => {
      setTimeout(() => {
        bottomScroll.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }, [messages]);

  return (
    <div className='fixed z-20 right-0 top-0 w-80 h-full bg-white shadow-lg flex flex-col' ref={messageBox}>
      <div className='flex justify-between items-center p-4 border-b border-gray-200 bg-blue-100'>
        <div className='text-lg font-semibold'>
          {po}-{sku}
        </div>
        <button className='text-red-500 text-bold' onClick={onClose}>
          &#10006;
        </button>
      </div>
      <div className='flex-1 overflow-y-auto p-4'>
        {loading ? (
          <div className='flex justify-center items-center h-full'>
            <div className='animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500'></div>
          </div>
        ) : (
          messages
            .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
            .map((msg, index) => (
              <div ref={bottomScroll}
                key={index}
                className={`mb-2 p-2 rounded ${
                  msg.role === role
                    ? 'bg-blue-100 self-end text-right'
                    : 'bg-gray-100 self-start text-left'
                }`}
              >
                <div className='text-xs text-gray-500 mb-1'>
                  {msg.sender} - {new Date(msg.timestamp).toLocaleString()}
                </div>
                <div>{msg.messageContent}</div>
              </div>
            ))
        )}
      </div>
      <div className='flex p-4 border-t border-gray-200'>
        <input
          type='text'
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          className='flex-1 p-2 border border-gray-300 rounded'
          placeholder='Type your message...'
          onKeyUp={hanldeEnter}
        />
        <button
          onClick={handleSendMessage}
          className='ml-2 p-2 bg-blue-500 text-white rounded flex items-center justify-center'
        >
          <FiSend size={20} />
        </button>
      </div>
    </div>
  )
}

export default MessageBox
