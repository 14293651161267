import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCookie, setCookie, getLocalStorageItem } from '../utils/Utils'

const useSessionCheck = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const checkSession = () => {
      const tokenId = getCookie('idcs_id_token')
      const sessionToken = getCookie('idcs_user_assertion')
      const roleCookie = getCookie('role')
      const roleStorage = getLocalStorageItem('role')

      if (!tokenId || !sessionToken || !roleCookie) {
        if (!roleCookie && roleStorage) {
          // If roleCookie is absent but roleStorage is present
          setCookie('role', roleStorage)
          navigate('/refresh-login', { state: { role: roleStorage } })
        } else if (roleCookie) {
          // If roleCookie is present
          navigate('/refresh-login', { state: { role: roleCookie } })
        } else {
          // If both roleCookie and roleStorage are absent
          navigate('/session-expired')
        }
      }
    }

    checkSession()
  }, [navigate])
}

export default useSessionCheck
