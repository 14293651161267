import React, { useState } from 'react'
import Header from '../components/Header'
import RoleComp from '../components/RoleComp'
import Group from '../components/Group'
import UserGroupMap from '../components/UserGroupMap'

const Admin = () => {
  const [currentComp, setCurrentComp] = useState('role')
  return (
    <div className='w-full flex flex-col justify-start gap-3 '>
      <Header />
      <div className='w-full font-pop text-sm my-2'>
        <div className='w-full flex gap-3 justify-start items-center  bg-gray-100 p-4'>
          <button
            className={`${currentComp === 'role' && 'bg-blue-500 border-blue-500 text-white'} border border-black py-2 px-4 rounded-sm`}
            onClick={() => setCurrentComp('role')}
          >
            Role
          </button>
          <button
            className={`${currentComp === 'group' && 'bg-blue-500 border-blue-500 text-white'} border border-black py-2 px-4 rounded-sm`}
            onClick={() => setCurrentComp('group')}
          >
            Group
          </button>
          <button
            className={`${currentComp === 'userGroupMap' && 'bg-blue-500 border-blue-500 text-white'} border border-black py-2 px-4 rounded-sm`}
            onClick={() => setCurrentComp('userGroupMap')}
          >
            User Group Mapping
          </button>
        </div>
        <div className='w-full bg-white shadow-md'>
          {currentComp === 'role' && <RoleComp />}
          {currentComp === 'group' && <Group />}
          {currentComp === 'userGroupMap' && <UserGroupMap />}
        </div>
      </div>
    </div>
  )
}

export default Admin
