import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UserProfile from '../../components/UserProfile'
import { useForm } from 'react-hook-form'

const Group = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  const navigate = useNavigate()

  const onSubmit = (data) => {
    console.log(data)
  }
  return (
    <main className='p-2 w-full space-y-5'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl font-medium'>Create Group</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      {/* BreadCrumb */}
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/admin')}
          >
            Home
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/admin/groups')}
          >
            Group
          </span>
          <span>{'>'}</span>
        </p>

        <p className='flex gap-1 items-center justify-center'>
          <span>Create New Group </span>
        </p>
      </div>
      <div className='m-auto max-w-md justify-center   '>
        <form onSubmit={handleSubmit(onSubmit)} className=' space-y-3'>
          <div class=' w-full   group'>
            <label
              for='fullName'
              class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
            >
              Group Name
            </label>
            <input
              type='text'
              {...register('groupName', {
                required: 'Please Enter Group Name',
                pattern: /^[A-Za-z]+$/i
              })}
              class='bg-gray-50  border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
            />
            <p className='text-sm text-red-600'>{errors?.groupName?.message}</p>
          </div>
          <div class=' w-full   group'>
            <label
              for='fullName'
              class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
            >
              Purpose
            </label>
            <textarea
              type='text'
              {...register('purpose', {
                required: 'Please Enter Message'
              })}
              class='bg-gray-50 border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
            />
            <p className='text-sm text-red-600'>{errors?.purpose?.message}</p>
          </div>
          <div className=' w-full    flex items-center  gap-3'>
            <label
              for='enabled'
              class='block  text-sm font-medium text-gray-900 dark:text-white'
            >
              Status
            </label>
            <input
              type='radio'
              {...register('status', { required: true })}
              value='Y'
              required
            />
            <label
              for='enabled'
              class='block  text-sm font-medium text-gray-900 dark:text-white'
            >
              Enable
            </label>
            <input
              type='radio'
              {...register('status', {
                required: 'please select any one of status'
              })}
              value='N'
              required
            />
            <label
              for='enabled'
              class='block  text-sm font-medium text-gray-900 dark:text-white'
            >
              Disable{' '}
            </label>
          </div>

          <input
            type='submit'
            value='submit'
            className='border p-1 rounded-md bg-gray-200 text-black cursor-pointer'
          />
        </form>
      </div>
      {/* ALL HFT User TAble */}
      <div class='w-full border border-gray-300 text-xs'>
        <table className='w-full border border-gray-300'>
          <thead className='divide-x-[1px] bg-[#e0ecfc] divide-gray-300 border-[#ccd9e4] border'>
            <tr className='divide-x-[1px] bg-[#e0ecfc] divide-gray-300 border-[#ccd9e4] border'>
              <th className='px-4 py-2 '>
                <span className='inline-flex items-center gap-1'>
                  Sr No
                  <svg
                    class='w-4 h-4 text-gray-400 dark:text-white'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 16 20'
                  >
                    <path
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M4 6v13m0 0 3-3m-3 3-3-3m11-2V1m0 0L9 4m3-3 3 3'
                    />
                  </svg>
                </span>
              </th>
              <th className='px-4 py-2'>
                <span className='inline-flex items-center gap-1'>
                  Group Id
                  <svg
                    class='w-4 h-4 text-gray-400 dark:text-white'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 16 20'
                  >
                    <path
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M4 6v13m0 0 3-3m-3 3-3-3m11-2V1m0 0L9 4m3-3 3 3'
                    />
                  </svg>
                </span>
              </th>
              <th className='px-4 py-2'>
                <span className='inline-flex items-center gap-1'>
                  Group Name
                  <svg
                    class='w-4 h-4 text-gray-400 dark:text-white'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 16 20'
                  >
                    <path
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M4 6v13m0 0 3-3m-3 3-3-3m11-2V1m0 0L9 4m3-3 3 3'
                    />
                  </svg>
                </span>
              </th>
              <th className='px-4 py-2'>
                <span className='inline-flex items-center gap-1'>
                  Create Date
                  <svg
                    class='w-4 h-4 text-gray-400 dark:text-white'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 16 20'
                  >
                    <path
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M4 6v13m0 0 3-3m-3 3-3-3m11-2V1m0 0L9 4m3-3 3 3'
                    />
                  </svg>
                </span>
              </th>
              <th className='px-4 py-2'>
                <span className='inline-flex items-center gap-1'>
                  Last Updated
                  <svg
                    class='w-4 h-4 text-gray-400 dark:text-white'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 16 20'
                  >
                    <path
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M4 6v13m0 0 3-3m-3 3-3-3m11-2V1m0 0L9 4m3-3 3 3'
                    />
                  </svg>
                </span>
              </th>
              <th className='px-4 py-2'>
                <span className='inline-flex items-center gap-1'>
                  Status
                  <svg
                    class='w-4 h-4 text-gray-400 dark:text-white'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 16 20'
                  >
                    <path
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M4 6v13m0 0 3-3m-3 3-3-3m11-2V1m0 0L9 4m3-3 3 3'
                    />
                  </svg>
                </span>
              </th>
              <th className='px-4 py-2'>
                <span className='inline-flex items-center gap-1'>
                  View
                  <svg
                    class='w-4 h-4 text-gray-400 dark:text-white'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 16 20'
                  >
                    <path
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M4 6v13m0 0 3-3m-3 3-3-3m11-2V1m0 0L9 4m3-3 3 3'
                    />
                  </svg>
                </span>
              </th>
            </tr>
          </thead>
          <tbody className='align-top'>
            <tr
              className={`text-center divide-x-[1px] divide-gray-300 border border-gray-300 text-gray-600`}
            >
              <td className='px-4 py-2'>1</td>
              <td className='px-4 py-2'>897898</td>
              <td className='px-4 py-2'>Rahul</td>
              <td className='px-4 py-2'>10-12-2023</td>
              <td className='px-4 py-2'>10-12-2023</td>
              <td className='px-4 py-2'>Enable</td>
              <td className='px-4 py-2'>view details</td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  )
}
export default Group
