import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { formatDateFit } from '../screens/buyer/BuyerRequestDetails'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import PopupModal from './PopupModal'
import { ValidateAPI } from '../utils/Utils'

const Comments = ({ crId, poNumber,sku,status }) => {
  const user = JSON.parse(localStorage.getItem('userData'))
  const [commentsData, setCommentsData] = useState([])
  const [commentInput, setCommentInput] = useState('')
  const [showSubmitCommentModal, setShowSubmitCommentModal] = useState(false)
  const { t } = useTranslation()
  const {
    Comments,
    SubmitComment,
    Activities,
    Nocommentsaddedyet,
    Enteracommenthere
  } = t('CommentsPage')

  const fetchCommentsData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-all-cr-activity-by-crid?hftcrid=${crId}`,ValidateAPI
      )
      .then((res) => {
        console.log('comments response: ', res?.data)
        setCommentsData(res?.data)
      })

      .catch((error) => {
        console.log('error while fetching comments: ', error)
      })
  }

  const closeSubmitCommentModal = () => {
    setShowSubmitCommentModal(false)
  }

  const openSubmitCommentModal = () => {
    setShowSubmitCommentModal(true)
  }

  const handleSubmitComment = () => {
    const payload = [
      {
        hftcrid: crId,
        po_number: poNumber,
        added_by_role_name:
          user?.roleName === 'SUPPLIER_ROLE' ? 'SUPPLIER' : 'BUYER',
        added_by_name:
          user?.roleName === 'SUPPLIER_ROLE'
            ? user?.supplierName
            : user?.fullName,
        comment: commentInput,
        type: 'Comment',
        SKU:sku
      }
    ]
    console.log('comment payload: ', payload)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/create-cr-activity`,
        payload,ValidateAPI
      )
      .then((response) => {
        console.log('response from submitting comment: ', response?.data)
        alert(i18next.t('VendorPopUp.Commentaddedsuccessfully'))

        setCommentsData((prevData) => [response?.data?.[0], ...prevData])
        setCommentInput('')
      })
      .catch((error) => {
        console.log('error from comments submission: ', error)
      })
    closeSubmitCommentModal()
  }

  useEffect(() => {
    if (crId) fetchCommentsData()
  }, [crId])
  return (
    <div className='flex flex-col gap-3'>
      <div className='w-full flex justify-start items-start mt-1 flex-col gap-4'>
        <div>
          <p className='font-semibold text-xl'>{}</p>
        </div>
        <div className='w-full flex justify-between items-center gap-4'>
          <textarea
            type='text'
            className='w-[90%] outline-none border border-gray-400 p-2 rounded-md text-xs'
            placeholder={Enteracommenthere}
            value={commentInput}
            onChange={(e) => setCommentInput(e?.target?.value)}
          />
          <button
            className='w-[10%] text-xs font-medium rounded-md bg-[#0059A8] flex justify-center items-center h-[39px] text-white'
            onClick={openSubmitCommentModal}
          >
            {SubmitComment}
          </button>
          <PopupModal
            isOpen={showSubmitCommentModal}
            message={i18next.t('VendorPopUp.Doyouwanttosubmitthiscomment')}
            onCancel={closeSubmitCommentModal}
            cancelText={i18next.t('AcceptModal.Cancel')}
            onConfirm={handleSubmitComment}
            confirmText={i18next.t('AcceptModal.Confirm')}
          />
        </div>
      </div>
      <div className='w-full flex justify-start items-start mt-1 flex-col gap-4'>
        <div>
          <p className='font-semibold text-xl'>{Activities}</p>
        </div>
        <div className='w-full border-l-2 border-[#0059A8] flex flex-col items-start justify-start gap-4 text-xs p-3'>
          {commentsData?.length > 0 ? (
            commentsData?.map((comment, index) => (
              <div
                className='flex flex-col gap-3 justify-start items-start border-b border-[#E6EDFF] py-2'
                key={comment?.comment_id + index}
              >
                <p className='font-semibold text-sm'>
                  {comment?.TYPE +
                    ' Added by ' +
                    comment?.ADDED_BY_ROLE_NAME +
                    ' - ' +
                    comment?.ADDED_BY_NAME +
                    ':'}
                </p>
                <p>{comment?.MESSAGE}</p>
                <p className='-mt-1 text-gray-400'>
                  {formatDateFit(comment?.CREATED_AT)}
                </p>
              </div>
            ))
          ) : (
            <p>{Nocommentsaddedyet}</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default Comments
