import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import {
  ConfirmPOpageEN,
  ConfirmPOpageMN
} from './Language Translation/Vendor/ConfirmPoTran'
import {
  VendorSideBarMN,
  vendorSideBarEN
} from './Language Translation/Vendor/VendorSideBarTrans'
import {
  ModalAcceptEn,
  ModalAcceptMN,
  ModalRejectEN,
  ModalRejectMN
} from './Language Translation/PopUpTrans'
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: 'en',
    returnObjects: true,
    resources: {
      en: {
        translation: {
          greeting: {
            DistributionbyRequestType: 'Distribution by Request Type',
            Norelateddatafound: 'No related data found'
          },
          sidebar: {
            Overview: 'Overview',
            Open_Tasks: 'Open Tasks',
            HFTChangeRequestStatus: 'Change Request',
            POList: 'PO List',
            UrgentInquiry: 'Urgent Inquiry ',
            MyReport: 'My Report',
            Logout: 'Logout'
          },
          CRHistory: {
            Loading1: 'Loading...',
            //dynamic table
            CR: 'CR #',
            PONUMBER: 'PO NUMBER',
            SKU: 'SKU',
            STATUS: 'STATUS',
            REQUESTTYPE: 'REQUEST TYPE',
            REQUESTEDBY: 'REQUESTED BY',
            CREATEDATE: 'CREATED DATE',
            UPDATEDATE: 'UPDATED DATE'
          },
          bigbarChart: {
            DistributionbyShipweekDelay: 'Distribution by Request Type',
            Filters: 'Filter',
            Norelateddatafound: 'No related data found.',
            Analyst: 'Analyst:',
            Vendor: 'Vendor:'
          },
          horizontalbarChart: {
            DistributionbyRequestAge: 'Distribution by Request Age',
            Filters: 'Filter',
            Norelateddatafound: 'No related data found.',
            Analyst: 'Analyst:',
            Vendor: 'Vendor:'
          },
          LoginPage: {
            Signintoyouraccount: 'Sign in to your account',
            Youremail: 'Your email',
            Passwordplace: 'Password',
            Rememberme: 'Remember me',
            Forgotpassword: 'Forgot password?',
            Signin: ' Sign in',
            Donthaveanaccountyet: ' Don’t have an account yet?',
            Signup: 'Sign up',
            Rememberme: 'Remember me'
          },
          pieChart: {
            DistributionbyRequestStatus: 'Distribution by Request Status',
            Filters: 'Filter',
            Norelateddatafound: 'No related data found.',
            Analyst: 'Analyst:',
            Vendor: 'Vendor:'
          },
          barChart: {
            DistributionbyShipweekDelay: 'Distribution by Shipweek Delay',
            Filters: 'Filter',
            Norelateddatafound: 'No related data found.',
            Analyst: 'Analyst:',
            Vendor: 'Vendor:'
          },
          DashboardBuyer: {
            InventoryAnalyst: 'Inventory Analyst',
            SelectanAnalyst: 'Select an Analyst',
            Vendor: 'Vendor',
            SelectanVendor: 'Select an Vendor',
            Reset: 'Reset',
            ApplyFilters: 'Apply Filter',
            Expedite: 'Expedite',
            Divert: 'Divert',
            Defer: 'Defer',
            Delay: 'Delay',
            Urgent: 'Urgent'
          },
          OpenTaskMyTask: {
            MyTasks: 'My Tasks',
            Home: 'Home',
            Page: 'Page',
            Gotopage: 'Go to page:',
            Show: 'Show',
            RequestType: 'Request Type:',
            VendorID: 'Vendor ID:',
            Searchallcolumns: 'Search all columns...'
          },
          HFTBuyerChangeRequestsPage: {
            HFTBuyerChangeRequests: 'HFT Buyer Change Requests',
            Home: 'Home',
            Loading1: 'Loading...',
            CR: 'CR #',
            PONUMBER: 'PO NUMBER',
            SKU: 'SKU',
            SUPPLIERNAME: 'SUPPLIER NAME',
            STATUS: 'STATUS',
            REQUESTTYPE: 'REQUEST TYPE',
            REQUESTEDBY: 'REQUESTED BY',
            CREATEDATE: 'CREATED DATE',
            UPDATEDATE: 'UPDATED DATE',
            CRSTATUS: 'CR STATUS',
            VENDORRESPONSE: 'VENDOR RESPONSE',
            ONTIMETARGETDate:'ONTIME TARGET DATE'
          },
          HFTBuyerPOChangeRequestDetailsPage: {
            HFTBuyerPOChangeRequestDetails:
              'HFT Buyer PO Change Request Details',
            HFTBuyerChangeRequests: 'HFT Buyer Change Requests',
            Home: 'Home',
            ChangeRequest: 'Change Request -',
            PO: 'PO #',
            Supplier: 'Supplier #',
            supplierName: 'Supplier Name',
            DownloadPurchaseOrder: 'Download Purchase Order',
            POHeaderDesc: 'PO Header Desc',
            ShippingOffice: 'Shipping Office',
            InventoryAnalyst: 'Inventory Analyst',
            CreateDate: 'Create Date',
            VendorManager: 'Vendor Manager',
            ReasonCode: 'Reason Code',
            VendorResponse: 'Vendor Response',
            RequestType: 'Request Type',
            LastUpdateDate: 'Last Update Date',
            POLineChangeRequestDetails: 'PO Line Change Request Details',
            Edit: 'Edit',
            Rev: 'Rev #',
            SKU: 'SKU',
            Description: 'Description',
            ORGSW: 'ORG SW',
            ChangeSW: 'Change SW',
            QTY: 'QTY',
            ORGDC: 'ORG DC',
            ChangeDC: 'Change DC',
            Action: 'Action',
            Week: 'Week#:',
            Save: 'Save',
            Cancel: 'Cancel',
            LastUpdated: 'Last Updated',
            VendorAcceptedSW: 'Vendor Accepted SW',
            CancelOrder: 'Cancel Request',
            //VendorCRDetails
            POChangeRequestDetails: 'PO Change Request Details',
            POChangeRequests: 'PO Change Requests',
            POChangeRequest: 'PO Change Request',
            AreyousureyouwanttoacceptthePOhangerequest:
              'Are you sure you want to accept the PO change request?',
            Pleaseselectreasoncodeforrejectingtheorder:
              'Please select reason code for rejecting the order.',
            Selectareason: 'Select a reason',
            SelectShipweek: 'Select Shipweek',
            SelctedShipWeek: 'Selcted ShipWeek',
            Confirm: 'Confirm',
            Changetheshipweek: 'Change the shipweek',
            DC: 'DC',
            Reject: 'Reject',
            VendorSelectedSW: 'Vendor Selected SW',
            OracleSyncStatus: 'Oracle Sync Status'
          },
          POList: {
            OrgDC: 'Org DC:',
            SelectDC: 'Select DC',
            ActiveCR: 'Active CR',
            CRHistory: 'CR History',
            PastDue: 'Past Due',
            Filters: 'Filter',
            PO: 'PO #',
            NoDataFound: 'No Data Found',
            SKU: 'SKU',
            Vendor: 'Vendor #',
            UPC: 'UPC',
            DC: 'DC',
            CURSW:"CURSW",
            value1: 'value',
            InventoryAnalyst: 'Inventory Analyst',
            VendorManager: 'Vendor Manager',
            ShippingOffice: 'Shipping Office',
            SpecialSKUs: 'Special SKUs',
            Loading1: 'Loading...',
            ClearFilters: 'Clear Filters',
            Columns: 'Columns',
            ToggleAll: 'Toggle All',
            CurrentDC: 'Current DC :',
            CurrentShipWeek: 'Current ShipWeek :',
            ReasonCode: 'ReasonCode',
            Selectareason: 'Select a reason',
            // ChangeShipweek:"Change Shipweek",
            Change: 'Change DC',
            Page: 'Page',
            Gotopage: '| Go to page:',
            Show: 'Show',
            HFTCR: 'HFT CR:',
            CreateRequest: 'Create Request',
            ChangeShipweek: 'Change Shipweek',
            SelectShipDate: 'Select Ship Date',
            SelectedShipWeekValue: 'Selected ShipWeek Value:',
            Submit: 'Submit',
            Cancel: 'Cancel',
            ChangeDC: 'Change DC',
            PONUMBER: 'PO NUMBER',
            DESCRIPTION: 'DESCRIPTION',
            REVISIONNUMBER: 'REVISION NUMBER',
            REQSW: 'REQ SW',
            ORGSW: 'ORG SW',
            CURRENTSW: 'CURRENT SW',
            ONTIMETARGET: 'ONTIME TARGET',
            ONTIMETARGETDate:'ONTIME TARGET Date',
            DELTA: 'DELAY',
            UNITCOST: 'UNIT COST',
            EXTENDEDCOST: 'EXTENDED COST',
            VENDORNAME: 'VENDOR NAME',
            VENDORCONTACT: 'VENDOR CONTACT',
            SHIPPEDQUANTITY: 'SHIPPED QUANTITY',
            POHEADERDESCRIPTION: 'PO HEADER DESCRIPTION',
            POQUANTITY: 'PO QUANTITY',
            CURRSW: 'CURR SW',
            VENDORMESSAGE: 'VENDOR MESSAGE',
            BUYERMESSAGE: 'BUYER MESSAGE',
            POSKU: 'PO-SKU',
            ACTIONTYPE: 'ACTION TYPE',
            REQDC: 'REQ DC',

            REQSWDate: 'REQ SW DATE',
            ORGSWDate: 'ORG SW DATE',
            CURRSWDate: 'CURR SW DATE',
            ONTIMETARGET: 'ONTIME TARGET',
            

            // INVENTORYANALYST: "INVENTORY ANALYST",
            SHIPPINGOFFICE: 'SHIPPING OFFICE',
            VENDOR: 'VENDOR #',
            UPC: 'UPC',
            MESSAGES: 'MESSAGES',
            REASONCODE: 'REASON CODE',
            DATELASTUPDATED: 'DATE LAST UPDATED',
            VENDOR: 'VENDOR #',
            VENDORMANAGER: 'VENDOR MANAGER',
            SPECIALSKU: 'SPECIAL SKUs',
            INVENTORYANALYST: 'INVENTORY ANALYST',

            //vendor mypos

            CreateDelayRequest: 'Create Delay Request',
            DelayOrder: 'Delay Order',
            Select: 'Select',
            Submitting_load: 'Submitting...',
            //filter
            ALL: "ALL",
            NoCR: "NO CR",
            CR: "CR",
            PastDue: "Past Due"
              
            
            
          },
          POHeaderDetails: {
            Home: 'Home',
            POList: 'PO List',
            PODetails: 'PO Details',
            POHeader: 'PO Header Details',
            CreateRequest: 'Create Request',
            Cancel: 'Cancel',
            PO: 'PO #',
            Vendor: 'Vendor #',
            VendorName: 'Vendor Name',
            InventoryAnalyst: 'Inventory Analyst',
            POHeaderDesc: 'PO Header Desc',
            Shippingoffice: 'Shipping office',
            POCreateDate: 'PO Create Date',
            VendorManager: 'Vendor Manager',
            DownloadPDF: 'Download PDF',
            PO: 'PO Line Details',
            ShowEntries: 'Show Entries',
            Rev: 'Rev #',
            SKU: 'SKU',
            Description: 'Description',
            ORGSW: 'ORG SW',
            REQSW: 'REQ SW',
            NeedBy: 'Need By',
            DC: 'DC',
            UPC: 'UPC',
            QTY: 'QTY',
            Cost: 'Cost',
            ExtendedCost: 'Extended Cost',
            SpecialSKUs: 'Special SKUs',
            ShippingInstructions: 'Shipping Instructions',
            ShippedQty: 'Shipped Qty',
            PO: 'PO Line Change Request',
            ShowEntries: 'Show Entries',
            POLineChangeRequest: 'PO Line Change Request',
            ChangeSW: 'Change SW',
            ORGDC: 'ORG DC',
            ChangeDC: 'Change DC',
            POLineDetails: 'PO Line Details',
            CreateChangeRequest: 'Create Change Request',
            POHeaderDetails: 'PO Header Details',

            //dynamic tables
            //dynamic table
            REVISIONNUMBER: 'REVISION NUMBER',
            PONUMBER: 'PO NUMBER',
            SKU: 'SKU',
            DESCRIPTION: 'DESCRIPTION',
            REQSW: 'REQ SW',
            ORGSW: 'ORG SW',
            CURRENTSW: 'CURRENT SW',
            DC: 'DC',
            UPC: 'UPC',
            UNITCOST: 'UNIT COST',
            EXTENDEDCOST: 'EXTENDED COST',
            SHIPPINGOFFICE: 'SHIPPING OFFICE',
            SHIPPEDQUANTITY: 'SHIPPED QUANTITY',
            DELTA: 'DELAY',
            ONTIMETARGET: 'ONTIME TARGET',
            MESSAGE: 'MESSAGE',
            REASONCODE: 'REASON CODE',
            VENDORMANAGER: 'VENDOR MANAGER',
            VENDOR: 'VENDOR',
            INVENTORYANALYST: 'INVENTORY ANALYST',
            POHEADERDESCRIPTION: 'PO HEADER DESCRIPTION',
            VENDORNAME: 'VENDOR NAME',
            DATELASTUPDATED: 'DATE LAST UPDATED',
            ONTIMETARGETDate:'ONTIME TARGET Date'
          },
          pageNotfound404page: {
            Somethingmissing: "Something's missing.",
            Sorrypagenotfound: "Sorry, we can't find that page.",
            BacktoHomepage: 'Back to Homepage'
          },
          podetailspage: {
            Save: 'Save',
            Cancel: 'Cancel',
            Edit: 'Edit',
            POHeaderDetails: 'PO Header Details',
            CreateRequest: 'Create Request',
            Previous: 'Previous',
            Next: 'Next',
            PO: 'PO #',
            Vendor: 'Vendor #',
            VendorName: 'Vendor Name',
            OrderStatus: 'Order Status #',
            Approved: 'Approved',
            InventoryAnalyst: 'Inventory Analyst',
            POHeaderDesc: 'PO Header Desc',
            Shippingoffice: 'Shipping office',
            POCreateDate: 'PO Create Date',
            VendorManager: 'Vendor Manager',
            DownloadPDF: 'Download PDF',
            POLineDetails: 'PO Line Details',
            ShowEntries: 'Show Entries',
            Rev: 'Rev #',
            SKU: 'SKU',
            Desc: 'Desc',
            UPC: 'UPC',
            ORGSW: 'ORG SW',
            QTY: 'QTY',
            Cost: 'Cost',
            ExtendedCost: 'Extended Cost',
            SpecialSKUs: 'Special SKUs',
            ShippingInstructions: 'Shipping Instructions',
            ShippedQty: 'Shipped Qty',
            LastUpdated: 'Last Updated',
            POHeaderChangeRequest: 'PO Header Change Request',
            ChangeSW: 'Change SW',
            ChangeDC: 'Change DC',
            DC: 'DC',
            Message: 'Message',
            MOR: 'MOR',
            POLineChangeRequest: 'PO Line Change Request',
            Description: 'Description',
            POChangeRequestHistory: 'PO Change Request History'
          },

          MyReportPage: {
            MyReport: 'My Report',
            Home: 'Home',
            Buyer: 'Buyer',
            VendorName: 'Vendor Name',
            StartDate: 'Start Date*',
            EndDate: 'End Date*',
            VendorResponse: 'Vendor Response*',
            ChangeRequestType: 'Change Request Type*',
            Accept: 'Accepted',
            Divert: 'Divert',
            NoDataFound: 'No Data Found',
            Select: 'Select',
            Divert: 'Divert',
            UrgentOrder: 'Urgent Inquiry',
            Reject: 'Rejected',
            SelectBuyer: 'Select Buyer',
            SelectVendor: 'Select Vendor',
            //dynamictable
            ENTITY: 'ENTITY #',
            PONUMBER: 'PO NUMBER',
            HFTBUYER: 'HFT BUYER',
            UPC: 'UPC',
            DC: 'DC',
            //COLUMNSFOR URGENT
            NEWPOFLAG: 'NEW PO FLAG',
            SHIP_TO_DC: 'SHIP_TO_DC',
            QUANTITY: 'QUANTITY',
            UPC: 'UPC',
            VENDORNUMBER: 'VENDOR NUMBER',
            REQUESTEDSHIPWEEK: 'REQUESTED SHIP WEEK',
            REQUESTEDSHIPYEAR: 'REQUESTED SHIP YEAR',
            UNITPRICE: 'UNITPRICE',
            NEEDBYDATE: 'NEED BY DATE',
            BUYER: 'BUYER',
            LINELEVELCOMMENTS: 'LINE LEVEL COMMENTS'
          },
          CommentsPage: {
            Comments: 'Comments',
            SubmitComment: 'Submit Comment',
            Activities: 'Activities',
            Nocommentsaddedyet: 'No comments added yet.',
            Enteracommenthere: 'Enter a comment here...'
          },
          BuyerUrgentCRPage: {
            HFTBuyerPOChangeRequestDetails:
              'HFT Buyer PO Change Request Details',
            Home: 'Home',
            HFTBuyerChangeRequests: 'HFT Buyer Change Requests',
            ChangeRequest: 'Change Request',
            Supplier: 'Supplier #',
            supplierName: 'supplier Name',
            CreateDate: 'Create Date',
            ReasonCode: 'Reason Code',
            VendorResponse: 'Vendor Response',
            RequestType: 'Request Type',
            LastUpdateDate: 'Last Update Date',
            POLineChangeRequestDetails: 'PO Line Change Request Details',
            Edit: 'Edit',
            SKU: 'SKU',
            Description: 'Description',
            RequestedSW: 'Requested SW',
            CurrentConfirmSW: 'Current Confirm SW',
            QTY: 'QTY',
            ORGDC: 'ORG DC',
            LastUpdated: 'Last Updated',
            CancelOrder: 'Cancel Request'
          },
          UrgentOrderBuyerPage: {
            // ViewDetail1:"View Details"
            UrgentInquiry: 'Urgent Inquiry',
            Home: 'Home',
            BuyerUrgentInquiry: 'Buyer Urgent Inquiry',
            NoDataFound: 'No Data Found',
            SubmitOrderList: 'Submit Order List',
            SearchSupplier: 'Search Supplier',
            CR: 'CR #',
            SUPPLIERNAME: 'SUPPLIER NAME',
            DESCRIPTION: 'DESCRIPTION',
            QUANTITY: 'QUANTITY',
            SKU: 'SKU',
            UNITPRICE: 'UNIT PRICE',
            EXTENDEDPRICE: 'EXTENDED PRICE',
            DC: 'DC',
            REQSW: 'REQ SW',
            STATUS: 'STATUS',
            LASTUPDATED: 'LAST UPDATED',
            VIEWDETAILS: 'VIEW DETAILS'
          },
          UrgentOrderBuyerCompPage: {
            SKU: 'SKU',
            skuDescription: 'skuDescription',
            UPC: 'UPC',
            UnitPrice: 'Unit Price',
            Quntity: 'Quntity',
            ExtendedPrice: 'Extended Price',
            DC: 'DC',
            Message: 'Message....'
          },
          BasicTable: {
            Searchallcolumns: 'Search all columns...',
            CreateChangeRequest: 'Create Change Request',
            Page: 'Page',
            Gotopage: 'Go to page:',
            Show: 'Show'
          },
          UtilPage: {
            DownloadCSV: 'Download CSV',
            Search: 'Search'
          },
          PopUpMessage: {
            PleaseEnterInventoryAnalystName:"Please Enter Inventory Analyst Name",
            Changerequestissubmittedsuccessfully:
              'Change request is submitted successfully',
            // ChangeRequestsubmitted: 'Change Request submitted',
            Therearenochangesmadetosubmit:
              'There are no changes made to submit.',

            //urgentorder
            OrderSubmitted: 'Order Submitted',
            PleaseoutFillouttherequiredfield:
              'Please out Fill out the required field',
            Pleaseenterallrequiredvaluesbeforesubmitting:
              'Please enter all required values before submitting',

            //buyerrequesrdetailspage && buyerUrgentCR
            ChangeRequestupdated: 'Change Request updated!',
            ErrorwhileupdatingChange:
              'Error while updating Change Request. Please try again.',

            //dahsboard
            Pleaseselectboththefiltervaluesbeforesubmitting:
              'Please select both the filter values before submitting.',

            //NewPoList
            YouvealreadychangedavalueinthisPOLine:
              "You've already changed a value in this PO Line",
            Pleaseenteratleastonesearchcriteria:
              'Please enter at least one search criteria.',
            ErrorwhilecreatingaChangeRequestPleasetryagain:
              'Error while creating a Change Request. Please try again.',
            Therearenochangesmadetosubmit:
              'There are no changes made to submit.',
            Thereisnochanges: 'There is no changes',
            ChangeRequestCancelled: 'Change Request Cancelled!',
            ErrorwhileupdatingChangeRequestPleasetryagain:
              'Error while updating Change Request. Please try again.',
            Doyouwanttocancelorder: 'Do you want to cancel order',
            Doyouwanttoupdatechangerequest:
              'Do you want to update change request?',
            //newpolist
            chnagePOLine: "There's an open change request on this PO Line",
            POLinepastdue: 'PO Line is past due',
            POLinebyvendoryet: "PO Line isn't confirmed by vendor yet.",
            TheresanopenchangerequestonthisPOLine:
              "There's an open change request on this PO Line",
            Doyouwanttocreateachangerequest:
              'Do you want to create a change request?',
            //podetailsupdated
            NochangesnotedPleasetryagain: 'No changes noted. Please try again.',
            Doyouwanttoconfirmtheseorders:
              'Do you want to confirm these orders?',
            DoyouwanttoCancelChanges: 'Do you want to Cancel Changes?',
            Doyouwanttoaccepttheserequests:
              'Do you want to accept these requests',
            Doyouwanttorejecttheserequests:
              'Do you want to reject these requests',
            Doyouwanttocreateadelayrequest:
              'Do you want to create a delay request?',
            Doyouwanttorejectdelaytheseorders:
              'Do you want to reject/delay these orders?',
            ChangeRequestacceptedsuccessfully:
              'Change Request accepted successfully!',
            ErrorwhileacceptingChangeRequestPleasetryagain:
              'Error while accepting Change Request. Please try again.',
            Pleaseselectareasoncode: 'Please select a reason code',
            ChangeRequestrejectedsuccessfully:
              'Change Request rejected successfully!',
            ErrorwhilerejectingChangeRequestPleasetryagain:
              'Error while rejecting Change Request. Please try again.'
          },

          VendorSidebar: vendorSideBarEN,
          VendorMyTaskPage: {
            MyTasks: 'My Tasks',
            Home: 'Home',
            Norequestsfound: 'No requests found',

            //dynamic table
            CR: 'CR #',
            PONUMBER: 'PO NUMBER',
            SKU: 'SKU',
            DESCRIPTION: 'DESCRIPTION',
            STATUS: 'STATUS',
            REQUESTTYPE: 'REQUEST TYPE',
            REQUESTEDBY: 'REQUESTED BY',
            CREATEDATE: 'CREATED DATE',
            UPDATEDATE: 'UPDATED DATE'
          },
          VendorUrgentOrder: {
            BuyerUrgentInquiry: 'Buyer Urgent Inquiry',
            UrgentInquiry: 'Urgent Inquiry',
            Home: 'Home',
            MyTasks: 'My Tasks',

            X: 'X',
            Selectareason: 'Select a reason',
            SelctedShipWeek: 'Selcted ShipWeek',
            Changetheshipweek: 'Change the shipweek',
            AreyousureyouwanttoaccepttheOrder:
              'Are you sure you want to accept the Order',
              Pleaseselectreasoncodeforrejectingtheorder:"Please select reason code for rejecting the order",
    Reject:"Reject",
            Confirm: 'Confirm',
            Cancel: 'Cancel',
            Pleaseenterallrequiredvaluesbeforesubmitting:
              'Please enter all required values before submitting',


            //dynamic table content
            HFTCR: 'HFT CR #',
            SUPPLIERNAME: 'SUPPLIER NAME',
            SKU: 'SKU',
            SKUDESCRIPTION: 'SKU DESCRIPTION',
            QUANTITY: 'QUANTITY',
            UNITPRICE: 'UNIT PRICE',
            EXTENDEDPRICE: 'EXTENDED PRICE',
            REQUESTEDSHIPWEEK: 'REQUESTED SHIP WEEK',
            CURRENTCONFIRMSHIPWEEK: 'CURRENT CONFIRM SHIP WEEK',
            LASTUPDATED: 'LAST UPDATED',
            ACTION: 'ACTION',
            VENDORRESPONSE: 'VENDOR RESPONSE',
            CRSTATUS: 'CR STATUS'
          },
          UrgentOrder: {
            BuyerUrgentInquiry: 'Buyer Urgent Inquiry',
            UrgentOrder: 'Urgent Inquiry',
            Home: 'Home',
            MyTasks: 'My Tasks'
          },
          ConfirmPOpage: ConfirmPOpageEN,

          VendorRequestPage: {
            Loading1: 'Loading...',
            Home: 'Home',
            POChangeRequests: 'PO Change Requests',
            ViewDetail1: 'View Detail',
            CR: 'CR #',
            PONUMBER: 'PO NUMBER',
            SKU: 'SKU',
            VENDORRESPONSE: 'VENDOR RESPONSE',
            CRSTATUS: 'CR STATUS',
            STATUS: 'STATUS',
            REQUESTTYPE: 'REQUEST TYPE',
            REQUESTEDBY: 'REQUESTED BY',
            CREATEDATE: 'CREATED DATE',
            UPDATEDATE: 'UPDATED DATE'
          },
          VendorPopUp: {
            Ordersconfirmedsuccessfully: 'Orders confirmed successfully!',
            changesmadesuccessfully: 'changes made successfully.',
            Pleaseaddnewvaluebeforesubmitting:
              'Please add new value before submitting.',
            PleaseselectReasonCodeChangeTypebeforesubmitting:
              'Please select Reason Code & Change Type before submitting',
            Pleaseselectareasoncode: 'Please select a reason code.',
            Pleaseaddcommentbeforesubmitting:
              'Please add comment before submitting.',
            //mypos
            Orderschangedsuccessfully: 'Orders changed successfully!',
            ChangeRequestsubmitted: 'Change Request submitted',
            ErrorwhilecreatingrequestPleasetryagain:
              'Error while creating request. Please try again.',
            Therearenochangesmadetosubmit:
              'There are no changes made to submit.',
            Pleaseenteratleastonesearchcriteria:
              'Please enter at least one search criteria.',
            //reconfirmpos
            Ordersconfirmedsuccessfully: 'Orders confirmed successfully!',
            //urgentorderbuyer
            OrderSubmitted: 'Order Submitted',
            PleaseoutFillouttherequiredfield:
              'Please out Fill out the required field',
            //urgentorder
            someissue: 'some issue',
            errorwhileacceptingtherequest: 'error while accepting the request',
            Doyouwanttosubmitthiscomment: 'Do you want to submit this comment?',
            Commentaddedsuccessfully: 'Comment added successfully',
            //
            //newly added
            Thereisnochanges: 'There is no changes',
            confirmChangesRequest:
              'Do you want to request changes in these orders?',
            Doyouwanttocreateadelayrequest:
              'Do you want to create a delay request?',
            Doyouwanttoconfirtheseorders:
              'Do you want to confirm these orders?',
            Doyouwanttorequestchangesintheseorders:
              'Do you want to request changes in these orders?'
          },
          VendorUrgentCR: {
            POChangeRequestDetails: 'PO Change Request Details',
            Home: 'Home',
            POChangeRequests: 'PO Change Requests',
            ChangeRequest: 'Change Request',
            ReasonCode: 'Reason Code',
            VendorResponse: 'Vendor Response',
            RequestType: 'Request Type',
            CreateDate: 'Create Date',
            LastUpdateDate: 'Last Update Date',
            POChangeRequest: 'PO Change Request',
            SKU: 'SKU',
            Description: 'Description',
            RequestedSW: 'Requested SW',
            CurrentConfirmSW: 'Current Confirm SW',
            DC: 'DC',
            QTY: 'QTY',
            LastUpdated: 'Last Updated',
            Action: 'Action',
            AreyousureyouwanttoacceptthePOchangerequest:
              'Are you sure you want to accept the PO change request?',
            Changetheshipweek: 'Change the shipweek',
            SelctedShipWeek: 'Selcted ShipWeek:',
            SelectShipweek: 'Select Shipweek',
            Confirm: 'Confirm',
            Cancel: 'Cancel',
            Pleaseselectreasoncodeforrejectingtheorder:
              'Please select reason code for rejecting the order.',
            Selectareason: 'Select a reason'
          },
          NotificationPage: {
            Notification1: 'Notification',
            Home: 'Home',
            HFTID: 'HFT ID',
            CreatedDate: 'Created Date',
            CreatedBy: 'Created By',
            Details: 'Details'
          },
          userprofile: {
            Date: 'Date',
            Name: 'Name',
            Viewall: 'View all',
            Signout: 'Signout',
            Message: 'Message',
            Notifications: 'Notifications'
          },
          ConfirmTablePage: {
            CreateChangeRequest: 'Create Change Request',
            Page: 'Page',
            Gotopage: 'Go to page:',
            Show: 'Show',
            //placeholder
            Searchallcolumns: 'Search all columns...'
          },
          PaginationHeader: {
            Page: 'Page',
            Gotopage: 'Go to page',
            Show: 'Show',
            Searchallcolumns: 'Search all columns...',
            Columns: 'Columns',
            CreateChangeRequest: 'Create Change Request'
          },

          AcceptModal: {
            AreyousureyouwanttoaccepttheOrder:
              'Are you sure you want to accept the Order',
            SelectShipweek: 'Select Shipweek',
            Confirm: 'Confirm',
            Cancel: 'Cancel'
          },

          ReasonCodeList: {
            CapacityorProductionSlowDown: 'Capacity or Production Slow Down',
            EquipmentMoldMaintenance: 'Equipment/Mold Maintenance',
            LCLShipmentConsolidation: 'LCL Shipment Consolidation',
            MaterialorLaborShortage: 'Material or Labor Shortage',
            PublicHoliday: 'Public Holiday',
            QualityIssueProductChange: 'Quality Issue / Product Change',
            ForceMajeureWeatherEvents: 'Force Majeure / Weather Events',
            FreightForwarderConsolidation: 'Freight Forwarder Consolidation',
            SpaceConstraints: 'Space Constraints',
            VesselDelay: 'Vessel Delay',
            Other: 'Other'
          },
          ConfirmActionComp: {
            SelectDC: 'Select DC',
            SelectedSW: 'Selected SW :',
            Comment: 'Comment',
            PleaseselectReasoncodefortheselectedorders:
              'Please select Reason code for the selected orders.',
            Changetype: 'Change Type',
            Selectatype: 'Select a type',
            Shipweek: 'Shipweek',
            DC: 'DC',
            Price: 'Price',
            Quantity: 'Quantity',
            ReasonCode: 'Reason Code',
            Selectareason: ' Select a reason',
            RequestChange: 'Request Change',
            Cancel: 'Cancel',
            Submitting_load: 'Submitting...',
            REQSW: 'REQ SW',
            //placeholder line
            Typehereindetail: 'Type here in detail...'
          },
          ExpediteActionComp: {
            acceptPOChangerequest:
              'Are you sure you want to accept the PO change request?',
            ORGSW: 'ORG SW',
            ChangeSW: 'Change SW',
            ChangeDC: 'Change DC',
            Changetheshipweek: 'Change the shipweek',
            SelectShipweek: 'Select Shipweek:',
            SelectedShipweek: 'Selected Shipweek',
            Confirm: 'Confirm',
            Cancel: 'Cancel',
            cancelReasonCode:
              'Please select reason code for rejecting the order.',
            Selectareason: 'Select a reason',
            Reject: 'Reject',
            Cancel: 'Cancel',
            DC: 'DC',
            Submitting_load: 'Submitting...'
          },
          VendortableViewPage: {
            VendorTable: 'Open Change Request',
            RequestType: 'Request Type',
            Count: 'Count',
            Norelateddatafound: 'No related data found.'
          },
          DeferPage: {
            DeferRequests: 'Defer Requests',
            Home: 'Home',
            Submitting_load: 'Submitting_load',
            Pleaseselectreasoncodeforrejectingtheorder:
              'Please select reason code for rejecting the order',
            Selectareason: 'Select a reason',
            Reject: 'Reject',
            Cancel: 'Cancel',
            Home: 'Home',
            AcceptRequest: 'Accept Request',
            RejectRequest: 'Reject Request',
            AcceptRequests: 'Accept Requests',
            RejectRequests: 'Reject Requests',
            //dynamic table
            CR: 'CR #',
            PONUMBER: 'PO NUMBER',
            SKU: 'SKU',
            STATUS: 'STATUS',
            REQSW: 'REQ SW',
            CURRENTSW: 'CURRENT SW',
            REQUESTTYPE: 'REQUEST TYPE',
            REQUESTEDBY: 'REQUESTED BY',
            CREATEDATE: 'CREATED DATE',
            UPDATEDATE: 'UPDATED DATE',
            CRSTATUS: 'CR STATUS',
            VENDORRESPONSE: 'VENDOR RESPONSE'
          },
          DivertPage: {
            DivertRequests: 'Divert Requests',
            Submitting_Load: 'Submitting_Load',
            Pleaseselectreasoncodeforrejectingtheorder:
              'Please select reason code for rejecting the order',
            Selectareason: 'Select a reason',
            Reject: 'Reject',
            Cancel: 'Cancel',
            Home: 'Home',
            AcceptRequest: 'Accept Request',
            RejectRequest: 'Reject Request',
            AcceptRequests: 'Accept Requests',
            RejectRequests: 'Reject Requests',
            //DYNAMIC TABLE
            CR: 'CR #',
            PONUMBER: 'PO NUMBER',
            SKU: 'SKU',
            STATUS: 'STATUS',
            REQUESTTYPE: 'REQUEST TYPE',
            REQUESTEDBY: 'REQUESTED BY',
            CREATEDATE: 'CREATED DATE',
            UPDATEDATE: 'UPDATED DATE',
            CRSTATUS: 'CR STATUS',
            VENDORRESPONSE: 'VENDOR RESPONSE',
            REQSW: 'REQ SW',
            CURRENTSW: 'CURRENT SW',
            DC: 'DC',
            REQDC: 'REQ DC'
          },
          PageNotFoundPage: {
            PageNotFound: 'Page Not Found',
            GOBack: 'GO BACK'
          },
          ServerErrorPage: {
            ServerErrorMessage: 'Uhho! Something unexpected happened.',
            Contact: 'support@harborfreight.com',
            Signout: 'Signout',
            GoBack: 'Go Back'
          },
          ExpditePage: {
            ExpediteRequests: 'Expedite Requests',
            Home: 'Home',
            Submitting_load: 'Submitting...',
            Pleaseselectreasoncodeforrejectingtheorder:
              'Please select reason code for rejecting the order',
            Selectareason: 'Select a reason',
            Reject: 'Reject',
            Cancel: 'Cancel',
            AcceptRequest: 'Accept Request',
            RejectRequest: 'Reject Request',
            AcceptRequests: 'Accept Requests',
            RejectRequests: 'Reject Requests',

            //DYNAMIC TABLE
            CR: 'CR #',
            PONUMBER: 'PO NUMBER',
            SKU: 'SKU',
            STATUS: 'STATUS',
            REQUESTTYPE: 'REQUEST TYPE',
            REQUESTEDBY: 'REQUESTED BY',
            CREATEDATE: 'CREATED DATE',
            UPDATEDATE: 'UPDATED DATE',
            CRSTATUS: 'CR STATUS',
            VENDORRESPONSE: 'VENDOR RESPONSE'
          },
          IdentifiersTab: {
            PastDue: 'Past Due',
            All: 'All'
          }
        }
      },
      mn: {
        translation: {
          greeting: {
            DistributionbyRequestType: '按请求类型分布',

            Norelateddatafound: '未找到相关数据'
          },
          sidebar: {
            Overview: '概览',
            Open_Tasks: '未完成任务',
            HFTChangeRequestStatus: '高频交易高频交易变更请求状态',
            POList: '采购订单列表',
            UrgentInquiry: '紧急查询',
            MyReport: '我的报告',
            Logout: '登出'
          },
          CRHistory: {
            Loading1: '加载中...',
            //dynamic table
            CR: 'CR #',
            PONUMBER: '采购订单编号',
            SKU: 'SKU',
            STATUS: '状态',
            REQUESTTYPE: '请求类型',
            REQUESTEDBY: '请求者',
            CREATEDATE: '创建日期',
            UPDATEDATE: '更新日期'
          },
          LoadingPage: {
            Loading_load: 'Loading...'
          },

          bigbarChart: {
            DistributionbyShipweekDelay: '按交期延迟展示',
            Filters: '筛选条件',
            Norelateddatafound: '未找到相关数据。',
            Analyst: '分析师：',
            Vendor: '供应商代码:'
          },
          horizontalbarChart: {
            DistributionbyRequestAge: '按请求年龄分布',
            Filters: '筛选条件',
            Norelateddatafound: '找不到相关数据。',
            Analyst: '分析师：',
            Vendor: '供应商代码:'
          },
          pieChart: {
            DistributionbyRequestStatus: '按请求状态展示',
            Filters: '筛选条件',
            Norelateddatafound: '未找到相关数据。',
            Analyst: '分析师：',
            Vendor: '供应商代码:'
          },
          barChart: {
            DistributionbyShipweekDelay: '按交期延迟展示',
            Filters: '筛选条件',
            Norelateddatafound: '未找到相关数据。',
            Analyst: '分析师：',
            Vendor: '供应商代码:'
          },
          DashboardBuyer: {
            InventoryAnalyst: 'HFT 库存分析员',
            SelectanAnalyst: '选择HFT 库存分析员',
            Vendor: '供应商代码',
            SelectanVendor: '选择供应商',
            Reset: '重置',
            ApplyFilters: '应用筛选',
            Expedite: '提前',
            Divert: '转移',
            Defer: '推迟',
            Delay: '延迟',
            Urgent: '紧急'
          },
          OpenTaskMyTask: {
            MyTasks: '我的任务',
            Home: '主页',
            Page: '页面',
            Gotopage: '转到页:',
            Show: '显示',
            RequestType: '请求类型:',
            VendorID: '供应商代码ID:',
            Searchallcolumns: '搜索所有列...'
          },
          HFTBuyerChangeRequestsPage: {
            HFTBuyerChangeRequests: 'HFT 下单员订单变更请求',
            Home: '主页',
            Loading1: '加载中...',
            CR: '变更请求 #',
            PONUMBER: '采购订单编号',
            SKU: '上一次更新日期',
            SUPPLIERNAME: '供应商名称',
            // STATUS: "状态",
            REQUESTTYPE: '请求类型',
            REQUESTEDBY: '请求者',
            CREATEDATE: '创建日期',
            UPDATEDATE: '更新日期',
            CRSTATUS: '变更状态',
            VENDORRESPONSE: '供应商回复',
            ONTIMETARGETDate:'按时目标日期'
          },
          HFTBuyerPOChangeRequestDetailsPage: {
            HFTBuyerPOChangeRequestDetails: 'HFT 下单员订单变更请求详情',
            HFTBuyerChangeRequests: 'HFT 下单员订单变更请求',
            Home: '主页',
            ChangeRequest: '变更请求 -',
            PO: '采购订单编号 #',
            Supplier: '供应商代码 #',
            supplierName: '供应商名称',
            DownloadPurchaseOrder: '下载采购订单',
            POHeaderDesc: '采购订单描述',
            ShippingOffice: '物流管理办公室',
            InventoryAnalyst: 'HFT 库存分析员',
            CreateDate: '创建日期',
            VendorManager: '供应商管理经理',
            ReasonCode: '原因代码',
            VendorResponse: '供应商回复',
            RequestType: '请求类型',
            LastUpdateDate: '上一次更新日期',
            POLineChangeRequestDetails: '采购订单变更请求',
            Edit: '编辑',
            Rev: '版本号 #',
            SKU: '上一次更新日期',
            Description: '产品描述',
            ORGSW: '初始出运周',
            ChangeSW: '变更出运周',
            QTY: '数量',
            ORGDC: '初始目的地（',
            ChangeDC: '变更目的地',
            Action: '操作',
            Week: '周数 #',
            Save: '保存',
            Cancel: '取消',
            LastUpdated: '上一次更新',
            VendorAcceptedSW: '供应商接受的出运周',
            CancelOrder: '取消订单',
            //vendorCRdetails
            POChangeRequestDetails: '采购订单变更请求详情',
            POChangeRequests: '采购订单变更请求',
            POChangeRequest: '采购订单变更请求',
            AreyousureyouwanttoacceptthePOhangerequest:
              '您确定要接受采购订单变更请求吗？',
            Pleaseselectreasoncodeforrejectingtheorder: '请选择拒绝订单的原因',
            Selectareason: '选择一个原因',
            SelectShipweek: '选择出运周',
            SelctedShipWeek: '已选择的发货周',
            Confirm: '确认',
            Changetheshipweek: '更改发货周',
            DC: '目的地',
            Reject: '拒绝',
            VendorSelectedSW: '供应商选定的软件',
            OracleSyncStatus: '甲骨文 同步状态'
          },
          POList: {
            OrgDC: '原始目的地:',
            SelectDC: '选择目的地',
            ActiveCR: '有效变更',
            CRHistory: '变更记录',
            PastDue: '已过确认交期',

            ReasonCode: '原因代码',
            Selectareason: '选择一个原因',

            REQDC: '请求运周',
            Filters: '筛选条件',
            PO: '采购订单编号',
            NoDataFound: '未找到数据',
            SKU: '上一次更新日期',
            value1: '数值',
            Vendor: '供应商代码',
            UPC: '通用产品代码',
            DC: '目的地',
            InventoryAnalyst: 'HFT 库存分析员',
            VendorManager: '供应商管理经理',
            ShippingOffice: '物流管理办公室',
            SpecialSKUs: '特殊产品编号',
            Loading1: '加载中...',
            ClearFilters: '清除筛选条件',
            Columns: '列',
            ToggleAll: '切换全部',
            CancelOrder: '取消订单',
            CreateRequest: '创建请求',
            CurrentDC: '当前直流 ：',
            CurrentShipWeek: '当前发货周 ：',
            // ChangeShipweek:"更改出运周",
            Change: '变更',
            Page: '页',
            Gotopage: '转到页:',
            Show: '显示',
            HFTCR: '高频交易 信贷评级:',
            ChangeShipweek: '更改出运周',
            SelectShipDate: '选择发货日期',
            SelectedShipWeekValue: '已选择的发货周数值:',
            Submit: '提交',
            Cancel: '取消',
            ChangeDC: '变更目的地',
            CURRSW: '当前软件日期',
            PONUMBER: '采购订单编号',
            DESCRIPTION: '产品描述',
            REVISIONNUMBER: '变更版本编号',
            REQSW: '要求出货周次',
            ORGSW: '初始出运周',
            CURRENTSW: '当前软件',
            REQSWDate: '需求软件日期',
            ORGSWDate: '原始软件日期',
            CURRSWDate: '当前软件日期',
            ONTIMETARGETDate: '按时目标日期',

            ONTIMETARGET: '按时目标',
            DELTA: '差异',
            UNITCOST: '单价',
            EXTENDEDCOST: '总价',
            VENDORNAME: '供应商名称',
            VENDORCONTACT: '供应商联系人',
            SHIPPEDQUANTITY: '已出运数量',
            POHEADERDESCRIPTION: '采购订单描述',
            INVENTORYANALYST: 'HFT 库存分析员',
            SHIPPINGOFFICE: '物流管理办公室',
            VENDOR: '供应商代码',
            UPC: '通用产品代码',
            DC: '目的地',
            MESSAGES: '消息',
            REASONCODE: '原因代码',
            DATELASTUPDATED: '最后更新日期',
            VENDOR: '供应商代码',
            VENDORMANAGER: '供应商管理经理',
            SPECIALSKU: '特殊 上一次更新日期',
            POQUANTITY: '数量',
            INVENTORYANALYST: '库存分析员',

            VENDORMESSAGE: '供应商信息',
            BUYERMESSAGE: '买家信息',
            POSKU: '产品编号',
            ACTIONTYPE: '行动类型',

            //vendormypo

            CreateDelayRequest: '创建延迟请求',
            DelayOrder: '延迟订单',
            Select: '选择',
            Submitting_load: '提交中...',
            //filter
            
              ALL: "全部",
              NoCR: "无变更请求",
              CR: "变更请求",
              PastDue: "过期",
              CURSW:"过期"
            
            
          },
          POHeaderDetails: {
            Home: '主页',
            POList: '采购订单列表',
            PODetails: '采购订单详情',
            POHeader: '采购订单头部详情',
            CreateRequest: '创建请求',
            Cancel: '取消',
            PO: '采购订单编号',
            Vendor: '供应商代码',
            VendorName: '供应商名称',
            InventoryAnalyst: 'HFT 库存分析员',
            POHeaderDesc: '采购订单描述',
            Shippingoffice: '物流管理办公室',
            POCreateDate: '采购订单创建日期',
            VendorManager: '供应商管理经理',
            DownloadPDF: '下载 PDF 格式文档',
            PO: '采购订单编号',
            ShowEntries: '显示条目',
            Rev: '版本号',
            SKU: '上一次更新日期',
            Description: '产品描述',
            ORGSW: '初始出运周',
            REQSW: '要求出货周次',
            NeedBy: '要求到货日期',
            DC: '目的地',
            UPC: '商品编码',
            QTY: '数量',
            Cost: '成本',
            ExtendedCost: '总价',
            SpecialSKUs: '特殊产品编号',
            ShippingInstructions: '发货指示',
            ShippedQty: '已出运数量',
            // PO: "采购订单变更请求",
            // ShowEntries: "显示条目",
            POLineChangeRequest: '采购订单变更请求',
            ChangeSW: '变更出运周',
            ORGDC: '原始目的地',
            ChangeDC: '变更目的地',
            POLineDetails: '采购订单详情',
            CreateChangeRequest: '创建变更请求',
            POHeaderDetails: '采购订单描述',

            //dynamic table
            REVISIONNUMBER: '变更版本编号',
            PONUMBER: '采购订单号',
            SKU: '上一次更新日期',
            DESCRIPTION: '产品描述',
            REQSW: '要求出货周次',
            ORGSW: '初始出运周',
            CURRENTSW: '当前软件',
            DC: '目的地',
            UPC: '通用产品代码',
            UNITCOST: '单价',
            EXTENDEDCOST: '总价',
            SHIPPINGOFFICE: '物流管理办公室',
            SHIPPEDQUANTITY: '已出运数量',
            DELTA: '差异',
            ONTIMETARGET: '按时目标',
            MESSAGE: '消息',
            REASONCODE: '原因代码',
            VENDORMANAGER: '供应商管理经理',
            VENDOR: '供应商代码',
            INVENTORYANALYST: 'HFT 库存分析员',
            POHEADERDESCRIPTION: '采购订单描述',
            VENDORNAME: '供应商名称',
            DATELASTUPDATED: '上次更新日期'
          },
          MyReportPage: {
            MyReport: '我的报告',
            Home: '主页',
            Buyer: '下单员',
            VendorName: '供应商名称',
            StartDate: '开始日期*',
            EndDate: '结束日期*',
            VendorResponse: '供应商回复*',
            ChangeRequestType: '变更请求类型*',
            Accept: '接受',
            Divert: '转移',
            NoDataFound: '未找到数据',
            Select: '选择',
            Divert: '转移',
            UrgentOrder: '紧急订单',
            SelectBuyer: '选择下单员',
            SelectVendor: '选择供应商',
            Reject: '拒绝',
            //dynamictable
            ENTITY: '实体编号',
            PONUMBER: '采购订单编号',
            HFTBUYER: 'HFT下单员',
            UPC: '统一产品编码',
            DC: '目的地',
            //紧急情况下的列
            NEWPOFLAG: '新采购订单标志',
            SHIP_TO_DC: '送货至配送中心',
            QUANTITY: '数量',
            UPC: '统一产品编码',
            VENDORNUMBER: '供应商编号',
            REQUESTEDSHIPWEEK: '要求发货周',
            REQUESTEDSHIPYEAR: '要求发货年',
            UNITPRICE: '单价',
            NEEDBYDATE: '需求日期',
            BUYER: '下单员',
            LINELEVELCOMMENTS: '行级别备注'
          },
          CommentsPage: {
            Comments: '注释',
            SubmitComment: '提交注释',
            Activities: '活动',
            Nocommentsaddedyet: '尚未添加注释',
            Enteracommenthere: '在这里输入注释'
          },
          LoadingPage: {
            Loading_load: '加载中...'
          },

          PageNotFoundPage: {
            PageNotFound: '页面未找到',
            GOBack: '返回'
          },
          BuyerUrgentCRPage: {
            HFTBuyerPOChangeRequestDetails: 'HFT 下单员订单变更请求详情',
            Home: '主页',
            HFTBuyerChangeRequests: 'HFT 下单员订单变更请求',
            ChangeRequest: '变更请求',
            Supplier: '供应商 #',
            supplierName: '供应商名称',
            CreateDate: '创建日期',
            ReasonCode: '原因代码',
            VendorResponse: '供应商回复',
            RequestType: '请求类型',
            LastUpdateDate: '上一次更新日期',
            POLineChangeRequestDetails: '采购订单变更请求',
            Edit: '编辑',
            SKU: '上一次更新日期',
            Description: '产品描述',
            RequestedSW: '要求出货周次',
            CurrentConfirmSW: '当前确认出货周',
            QTY: '数量',
            ORGDC: '原始目的地',
            LastUpdated: '上一次更新',
            CancelOrder: '取消订单'
          },

          podetailspage: {
            Save: '保存',
            Cancel: '取消',
            Edit: '编辑',
            POHeaderDetails: '采购订单头详细信息',
            CreateRequest: '创建请求',
            Previous: '上一页',
            Next: '下一页',
            PO: '采购订单 #',
            Vendor: '供应商 #',
            VendorName: '供应商名称',
            OrderStatus: '订单状态 #',
            Approved: '已批准',
            InventoryAnalyst: '库存分析员',
            POHeaderDesc: '采购订单头描述',
            Shippingoffice: '发货办公室',
            POCreateDate: '采购订单创建日期',
            VendorManager: '供应商经理',
            DownloadPDF: '下载PDF',
            POLineDetails: '采购订单行详细信息',
            ShowEntries: '显示条目',
            Rev: '版本 #',
            SKU: '上一次更新日期',
            Desc: '描述',
            UPC: 'UPC',
            ORGSW: 'ORG SW',
            QTY: '数量',
            Cost: '成本',
            ExtendedCost: '扩展成本',
            SpecialSKUs: '特殊 上一次更新日期',
            ShippingInstructions: '发货说明',
            ShippedQty: '已发货数量',
            LastUpdated: '最后更新',
            POHeaderChangeRequest: '采购订单头更改请求',
            ChangeSW: '更改SW',
            ChangeDC: '更改DC',
            DC: 'DC',
            Message: '消息',
            MOR: 'MOR',
            POLineChangeRequest: '采购订单行更改请求',
            Description: '描述',
            POChangeRequestHistory: '采购订单更改请求历史记录'
          },

          pageNotfound404page: {
            Somethingmissing: '有些东西丢失了。',
            Sorrypagenotfound: '抱歉，我们找不到该页面。',
            BacktoHomepage: '返回主页'
          },
          UrgentOrderBuyerPage: {
            // ViewDetail1:"查看详情"
            UrgentInquiry: '紧急查询',
            Home: '主页',
            BuyerUrgentInquiry: '下单员紧急订单',
            NoDataFound: '未找到数据',
            SubmitOrderList: '提交订单列表',
            SearchSupplier: '搜索供应商',
            CR: '变更请求#',
            SUPPLIERNAME: '供应商名称',
            DESCRIPTION: '产品描述',
            QUANTITY: '数量',
            SKU: '上一次更新日期',
            UNITPRICE: '单价',
            EXTENDEDPRICE: '总价',
            DC: '目的地',
            REQSW: '要求出货周次',
            STATUS: '状态',
            LASTUPDATED: '上一次更新',
            VIEWDETAILS: '查看详情',

            SupplierUrgentOrders: '供应商要求的紧急订单',

            Pleaseenterallrequiredvaluesbeforesubmitting:
              '请在提交之前填写所有必填值',
            PleaseoutFillouttherequiredfield: '请填写必填字段'
          },

          LoginPage: {
            Signintoyouraccount: '登录到您的帐户',
            Youremail: '您的电子邮件',
            Passwordplace: '密码',
            Rememberme: '记住我',
            Forgotpassword: '忘记密码？',
            Signin: '登录',
            Donthaveanaccountyet: '还没有帐号？',
            Signup: '注册'
          },
          UrgentOrderBuyerCompPage: {
            SKU: '上一次更新日期',
            UPC: '统一产品编码',
            skuDescription: '产品描述',
            UnitPrice: '单价',
            Quntity: '数量',
            ExtendedPrice: '总价',
            DC: '目的地',
            Message: '消息....'
          },
          BasicTable: {
            Searchallcolumns: '搜索所有列...',
            CreateChangeRequest: '创建变更请求',
            Page: '页',
            Gotopage: '转到页:',
            Show: '显示'
          },
          UtilPage: {
            DownloadCSV: '下载 CSV 格式文档',
            Search: '搜索'
          },
          PopUpMessage: {
            PleaseEnterInventoryAnalystName: "请输入库存分析员姓名",
            Changerequestissubmittedsucessfully: '变更请求已成功提交',
            // ChangeRequestsubmitted: '更改请求已提交',
            Therearenochangesmadetosubmit: '没有变更可提交.',

            //urgentorder
            OrderSubmitted: '订单已提交',
            PleaseoutFillouttherequiredfield: '请填写必填字段',
            Pleaseenterallrequiredvaluesbeforesubmitting:
              '请在提交前填写所有必填值',

            //buyerrequestdetails && buyerurgent CR
            ChangeRequestupdated: '变更请求已更新！',
            ErrorwhileupdatingChange: '更新变更请求时发生错误。请重试。',
            //Dashboard
            Pleaseselectboththefiltervaluesbeforesubmitting:
              '请在提交之前选择两个筛选条件.',

            //newpolist
            YouvealreadychangedavalueinthisPOLine:
              '您已更改了此采购订单行中的一个值。',
            Pleaseenteratleastonesearchcriteria: '请至少输入一个搜索条件。',
            ErrorwhilecreatingaChangeRequestPleasetryagain:
              '创建变更请求时出错，请重试。',
            Therearenochangesmadetosubmit: '没有变更可提交.',
            //newly added
            Thereisnochanges: '没有变化',
            ChangeRequestCancelled: '更改请求已取消!',
            ErrorwhileupdatingChangeRequestPleasetryagain:
              '更新更改请求时出错。请重试。',
            Doyouwanttocancelorder: '您是否要取消订单？',
            Doyouwanttoupdatechangerequest: '您是否要更新更改请求？',
            chnagePOLine: '此采购订单行上有一个未关闭的更改请求',
            POLinepastdue: '采购订单行已过期',
            POLinebyvendoryet: '供应商尚未确认采购订单行。',
            TheresanopenchangerequestonthisPOLine:
              '此采购订单行上有一个未关闭的更改请求',
            Doyouwanttocreateachangerequest: '您是否要创建一个更改请求？',
            //podeatilsupdated
            NochangesnotedPleasetryagain: '未注意到任何更改。请重试。',
            //confirmmypos
            Doyouwanttoconfirmtheseorders: '您要确认这些订单吗？',

            DoyouwanttoCancelChanges: '您要取消更改吗？',
            Doyouwanttoaccepttheserequests: '您想接受这些请求吗',
            Doyouwanttorejecttheserequests: '您想拒绝这些请求吗',
            Doyouwanttocreateadelayrequest: '您想要创建延迟请求吗?',
            Doyouwanttorejectdelaytheseorders: '您想要拒绝/延迟这些订单吗？',
            ChangeRequestacceptedsuccessfully: '更改请求已成功接受！',

            ErrorwhileacceptingChangeRequestPleasetryagain:
              '接受更改请求时出错。请重试。',
            Pleaseselectareasoncode: '请选择一个原因代码',

            ErrorwhilerejectingChangeRequestPleasetryagain:
              '拒绝更改请求时出错。请重试。',
            ChangeRequestrejectedsuccessfully: '更改请求已成功拒绝！'
          },
          //   VENDOR PART MN
          VendorSidebar: VendorSideBarMN,
          VendorMyTaskPage: {
            MyTasks: '我的任务',
            Home: '主页',
            Norequestsfound: '未找到请求',

            //vendor dynamic table
            CR: '变更请求 #',
            PONUMBER: '采购订单号',
            SKU: '上一次更新日期',
            DESCRIPTION: '产品描述',
            STATUS: '状态',
            REQUESTTYPE: '请求类型',
            REQUESTEDBY: '请求者',
            CREATEDATE: '创建日期',
            UPDATEDATE: '更新日期'
          },
          VendorUrgentOrder: {
            BuyerUrgentInquiry: '供应商要求的紧急订单',
            UrgentInquiry: '紧急查询',
            Home: '主页',
            MyTasks: '我的任务',

            X: 'X',
            Selectareason: '选择一个原因',
            SelectedShipWeek: '已选出运周',
            Changetheshipweek: '更改发货周',
            AreyousureyouwanttoaccepttheOrder: '您确定要接受订单吗？',
              Pleaseselectreasoncodeforrejectingtheorder: "请选取拒绝订单的原因代码",
              Reject: "拒绝",
            Confirm: '确认',
            Cancel: '取消',
            Pleaseenterallrequiredvaluesbeforesubmitting:
              '请在提交前输入所有必填值',

            HFTCR: '频交易 变更请求 #',
            SUPPLIERNAME: '供应商名称',
            SKU: '上一次更新日期',
            SKUDESCRIPTION: '产品描述',
            QUANTITY: '数量',
            UNITPRICE: '单价',
            EXTENDEDPRICE: '总价',
            REQUESTEDSHIPWEEK: '请求发货周',
            CURRENTCONFIRMSHIPWEEK: '当前确认发货周',
            LASTUPDATED: '上一次更新',
            ACTION: '操作',
            VENDORRESPONSE: '供应商回复',
            CRSTATUS: 'CR 状态'
          },
          ConfirmPOpage: ConfirmPOpageMN,

          VendorRequestPage: {
            Loading1: '加载中...',
            Home: '主页',
            POChangeRequests: '采购订单变更请求',
            ViewDetail1: '查看详情',
            CR: '变更请求 #',
            PONUMBER: '采购订单号',
            SKU: '上一次更新日期',
            VENDORRESPONSE: '供应商回复',
            CRSTATUS: '变更状态',
            STATUS: '状态',
            REQUESTTYPE: '请求类型',
            REQUESTEDBY: '请求者',
            CREATEDATE: '创建日期',
            UPDATEDATE: '更新日期'
          },
          VendorPopUp: {
            Ordersconfirmedsuccessfully: '订单已成功确认！',
            changesmadesuccessfully: '更改成功。',
            Pleaseaddnewvaluebeforesubmitting: '提交前请添加新值。',
            PleaseselectReasonCodeChangeTypebeforesubmitting:
              '提交前请选择原因代码和更改类型',
            Pleaseselectareasoncode: '请选择原因代码。',
            Pleaseaddcommentbeforesubmitting: '提交前请添加评论。',
            //mypos
            Orderschangedsuccessfully: '订单更改成功！',
            ChangeRequestsubmitted: '更改请求已提交',
            ErrorwhilecreatingrequestPleasetryagain: '创建请求时出错，请重试。',
            Therearenochangesmadetosubmit: '没有变更可提交.',
            Pleaseenteratleastonesearchcriteria: '请至少输入一个搜索条件。',
            //reconfirmpos
            Ordersconfirmedsuccessfully: '订单已成功确认！',
            //urgentorderbuyer
            OrderSubmitted: '订单已提交',
            PleaseoutFillouttherequiredfield: '请填写必填字段',
            //urgentorder
            someissue: '一些问题',
            errorwhileacceptingtherequest: '接受请求时出错',
            Doyouwanttosubmitthiscomment: '您想提交此评论吗',
            //commentnside
            Commentaddedsuccessfully: '供应商弹出窗口',
            //newly added
            confirmChangesRequest: '您是否想要在这些订单中请求更改？',

            Doyouwanttocreateadelayrequest: '您要创建延迟请求吗？',
            //reConfirmOrderPage
            Doyouwanttoconfirtheseorders: '您想要确认这些订单吗？',

            Doyouwanttorequestchangesintheseorders:
              '您想要在这些订单中请求更改吗？'
          },
          VendorUrgentCR: {
            POChangeRequestDetails: '采购订单更改请求详情',
            Home: '主页',
            POChangeRequests: '采购订单更改请求',
            ChangeRequest: '更改请求',
            ReasonCode: '原因代码',
            VendorResponse: '供应商回复',
            RequestType: '请求类型',
            CreateDate: '创建日期',
            LastUpdateDate: '上一次更新日期',
            POChangeRequest: '采购订单更改请求',
            SKU: '上一次更新日期',
            Description: '产品描述',
            RequestedSW: '要求出货周次',
            CurrentConfirmSW: '当前确认出货周',
            DC: '目的地',
            QTY: '数量',
            LastUpdated: '上一次更新',
            Action: '操作',
            AreyousureyouwanttoacceptthePOchangerequest:
              '您确定要接受采购订单更改请求吗？',
            Changetheshipweek: '更改发货周',
            SelctedShipWeek: '选择的发货周:',
            SelectShipweek: '选择出运周',
            Confirm: '确认',
            Cancel: '取消',
            Pleaseselectreasoncodeforrejectingtheorder: '请选择拒绝订单的原因',
            Selectareason: '选择一个原因'
          },
          NotificationPage: {
            Notification1: '通知',
            Home: '主页',
            HFTID: '频交易',
            CreatedDate: '创建日期',
            CreatedBy: '创建者',
            Details: '详情'
          },

          userprofile: {
            Date: '日期',
            Name: '姓名',
            Viewall: '查看全部',
            Signout: '退出',
            Message: '消息',
            Notifications: '通知'
          },
          ConfirmTablePage: {
            CreateChangeRequest: '创建变更请求',
            Page: '页',
            Gotopage: '转到页:',
            Show: '显示',
            //placeholder
            Searchallcolumns: '搜索所有列...'
          },
          PaginationHeader: {
            Page: '页',
            Gotopage: '转到页',
            Show: '显示',
            //placeholder
            Searchallcolumns: '搜索所有列...',
            Columns: '列',
            CreateChangeRequest: '创建更改请求'
          },
          // RejectModal: ModalRejectMN,
          AcceptModal: {
            AreyousureyouwanttoaccepttheOrder: '您确定要接受订单吗？',
            SelectShipweek: '选择出运周',
            Confirm: '确认',
            Cancel: '取消'
          },

          // RejectModal: ModalAcceptMN,
          ReasonCodeList: {
            CapacityorProductionSlowDown: '产能或生产放缓',
            EquipmentMoldMaintenance: '设备/模具维护',
            LCLShipmentConsolidation: '拼箱货物整合',
            MaterialorLaborShortage: '材料或劳动力短缺',
            PublicHoliday: '公共假期',
            QualityIssueProductChange: '质量问题/产品变更',
            ForceMajeureWeatherEvents: '不可抗力/天气事件',
            FreightForwarderConsolidation: '货运代理整合',
            SpaceConstraints: '空间限制',
            VesselDelay: '船舶延迟',
            Other: '其他'
          },
          ConfirmActionComp: {
            SelectDC: '选择目的地',
            SelectedSW: '已选择的SW :',
            Comment: '注释',
            PleaseselectReasoncodefortheselectedorders:
              '请选择所选订单的原因代码。',
            Changetype: '更改类型',
            Selectatype: '选择类型',
            Shipweek: '发货周',
            DC: '目的地',
            Price: '价格',
            Quantity: '数量',
            ReasonCode: '原因代码',
            Selectareason: '选择一个原因',
            RequestChange: '请求更改',
            Cancel: '取消',
            Submitting_load: '提交中...',
            REQSW: '要求出货周次',
            //placeholder page
            Typehereindetail: '在此详细描述...'
          },
          ExpediteActionComp: {
            acceptPOChangerequest: '您确定要接受PO更改请求吗?',
            Submitting_load: '提交中...',
            ORGSW: '初始出运周',
            ChangeSW: '变更出运周',
            ChangeDC: '变更目的地',
            Changetheshipweek: '更改发货周',
            SelectShipweek: '选择出运周:',
            Confirm: '确认',
            Cancel: '取消',
            cancelReasonCode: '请选择拒绝订单的原因代码。',
            Selectareason: '选择一个原因',
            Reject: '拒绝',
            SelectedShipweek: '已选出运周',
            DC: '目的地',
            Submitting_load: '提交中...'
          },
          VendortableViewPage: {
            VendorTable: '开放变更请求',
            RequestType: '请求类型',
            Count: '数量',
            Norelateddatafound: '未找到相关数据。'
          },
          DeferPage: {
            DeferRequests: '推迟请求',
            Home: '主页',
            Submitting_load: '提交中...',
            Pleaseselectreasoncodeforrejectingtheorder: '请选择拒绝订单的原因',
            Selectareason: '选择一个原因',
            Reject: '拒绝',
            Cancel: '取消',
            AcceptRequest: '接受请求',
            RejectRequest: '拒绝请求',
            AcceptRequests: '接受请求',
            RejectRequests: '拒绝请求',

            //DYNAMIC TABLE
            CR: '变更请求#',
            PONUMBER: '采购订单编号',
            SKU: '上一次更新日期',
            STATUS: '状态',
            REQSW: '需求软件',
            CURRENTSW: '当前软件',

            REQUESTTYPE: '请求类型',
            REQUESTEDBY: '请求者',
            CREATEDATE: '创建日期',
            UPDATEDATE: '更新日期',
            CRSTATUS: '变更状态',
            VENDORRESPONSE: '供应商回复'
          },

          DivertPage: {
            DivertRequests: '转移请求',
            Submitting_Load: '提交中...',
            Pleaseselectreasoncodeforrejectingtheorder: '请选择拒绝订单的原因',
            Selectareason: '选择一个原因',
            Reject: '拒绝',
            Cancel: '取消',
            Home: '主页',
            AcceptRequest: '接受请求',
            RejectRequest: '拒绝请求',
            AcceptRequests: '接受请求',
            RejectRequests: '拒绝请求',

            //DYNAMIC TABLE
            CR: '变更请求#',
            PONUMBER: '采购订单编号',
            SKU: '上一次更新日期',
            STATUS: '状态',
            REQUESTTYPE: '请求类型',
            REQUESTEDBY: '请求者',
            CREATEDATE: '创建日期',
            UPDATEDATE: '更新日期',
            CRSTATUS: '变更状态',
            VENDORRESPONSE: '供应商回复',
            REQSW: '请求运周',
            CURRENTSW: '当前运周',
            DC: '目的地',
            REQDC: '请求运周'
          },
          ExpditePage: {
            ExpediteRequests: '加急请求',
            Home: '主页',
            Submitting_load: '提交中...',
            Pleaseselectreasoncodeforrejectingtheorder: '请选择拒绝订单的原因s',
            Selectareason: '选择一个原因',
            Reject: '拒绝',
            Cancel: '取消',
            AcceptRequest: '接受请求',
            RejectRequest: '拒绝请求',
            AcceptRequests: '接受请求',
            RejectRequests: '拒绝请求',
            //DYNAMIC TABLE
            CR: '变更请求#',
            PONUMBER: '采购订单编号',
            SKU: '上一次更新日期',
            STATUS: '状态',
            REQUESTTYPE: '请求类型',
            REQUESTEDBY: '请求者',
            CREATEDATE: '创建日期',
            UPDATEDATE: '更新日期',
            CRSTATUS: '变更状态',
            VENDORRESPONSE: '供应商回复'
          }
        }
      }
    }
  })
