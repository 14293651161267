import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

const RefreshLogin = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { role } = location.state || {}

  useEffect(() => {
    if (role) {
      const loginUrl = `${process.env.REACT_APP_ANOTHER_API_URL}/auth/oracle?role=${role}`
      setTimeout(() => {
        window.location.href = loginUrl
      }, 1000) // Display the message for 1 seconds
    } else {
      navigate('/session-expired')
    }
  }, [role, navigate])

  return (
    <div>
      <h1>Your session cookies seems to be invalid</h1>
      <p>Attempting to refresh login...</p>
    </div>
  )
}

export default RefreshLogin
