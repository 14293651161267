import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const PageNotFound = () => {
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem('userData'))
  const { t } = useTranslation()
  const { Somethingmissing, Sorrypagenotfound, BacktoHomepage } = t(
    'pageNotfound404page'
  )

  const handleNavigate = () => {
    if (user?.roleName === 'BUYER_ROLE') navigate('/buyer')
    else if (user?.roleName === 'SUPPLIER_ROLE') navigate('/vendor')
    else if (user?.roleName === 'ADMIN_ROLE') navigate('/admin')
  }

  useEffect(() => {
    if (!user?.roleName) navigate('/login')
  }, [])
  return (
    <section className='bg-white font-pop'>
      <div className='py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
        <div className='mx-auto max-w-screen-sm text-center flex flex-col gap-4 items-center'>
          <h1 className='mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-blue-600 '>
            404
          </h1>
          <p className='mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl '>
            {Somethingmissing}
          </p>
          <p className='mb-4 text-lg text-gray-500 '>{Sorrypagenotfound}</p>
          <button
            onClick={handleNavigate}
            className='flex bg-blue-600 text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4'
          >
            {BacktoHomepage}
          </button>
        </div>
      </div>
    </section>
  )
}

export default PageNotFound
