import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { formatDateFit } from '../buyer/BuyerRequestDetails'
import {
  ValidateAPI,
  getStartDate,
  handleAcceptExpediteDeferRequests,
  handlePODetailsClick,
  handleRejectExpediteDeferDivertRequest,
  reasonCodesEN,
  reasonCodesMN
} from '../../utils/Utils'
import ConfirmTable from '../../components/ConfirmTable'
import DatePicker from 'react-datepicker'
import { getWeek } from '../buyer/NewPoList'
import { EditedRowState } from '../../LineContext/Context'
import Loading from '../../components/Loading'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import PopupModal from '../../components/PopupModal'

export const LineComp = ({
  row,
  requestType,
  selectTableRow,
  rowSelection,
  updateRowSelection
}) => {
  const [showInput, setShowInput] = useState(false)
  const [value, setValue] = useState('')
  const inputRef = useRef(null)
  const [date, setDate] = useState(new Date())
  const [showValue, setShowValue] = useState(false)
  const [currentRows, setCurrentRows] = useState([])
  // const [reason_codes, setReasonCode] = useState([])
  const {
    state: { editArray },
    dispatch
  } = EditedRowState()

  const handleUpdate = (date) => {
    console.log('shipweek value: ', date)
    console.log('row data: ', row)
    const updatedRow = {
      ...row?.original,
      requested_ship_week: date,
      row_id: row?.id
    }
    console.log('row selection before: ', updatedRow)
    if (
      editArray?.some(
        (c) => c?.id_open_request === row?.original?.id_open_request
      )
    ) {
      dispatch({
        type: 'UPDATE_SW_VALUE',
        payload: {
          id: row?.original?.id_open_request,
          sw: date
        }
      })
    } else {
      dispatch({
        type: 'ADD_TO_CR_LIST',
        payload: updatedRow
      })
      const updatedRowId = row.id
      updateRowSelection((prevSelection) => ({
        ...prevSelection,
        [updatedRowId]: true
      }))
    }
    setShowValue(true)
  }

  const resetValue = () => {
    setShowInput(false)
    setValue(row?.original?.CURRENT_SW)
    setShowValue(false)
    dispatch({
      type: 'REMOVE_CR_FROM_LIST',
      payload: {
        id: row?.original?.id_open_request
      }
    })
    updateRowSelection((prevSelection) => ({
      ...prevSelection,
      [row.id]: false
    }))
  }
  const handleChangeDate = (date) => {
    setDate(date)
    setValue(getWeek(date))
    handleUpdate(getWeek(date))
    setShowInput(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowInput(false)
      }
    }
    document.body.addEventListener('click', handleClickOutside)
    return () => {
      document.body.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    setValue(row?.original?.CURRENT_SW)
  }, [row?.id])

  useEffect(() => {
    console.log('rowSelection from lineComp:', rowSelection)
    setCurrentRows(rowSelection)
  }, [rowSelection])
  return (
    <div className='w-full flex justify-center items-center p-1'>
      {showInput ? (
        <div>
          <DatePicker
            showWeekNumbers
            filterDate={(date) => date.getDay() === 1}
            selected={date}
            onChange={(date) => handleChangeDate(date)}
            className='p-1 z-20 w-full'
            showIcon
            scrollableYearDropdown
            popperClassName='react-datepicker__week-number'
            formatWeekDay={(day) => day.slice(0, 3)}
            calendarStartDay={1}
            showYearDropdown
            maxDate={
              requestType === 'expedite'
                ? getStartDate(row?.original?.current_ship_week)
                : getStartDate(row?.original?.requested_ship_week)
            }
            minDate={
              requestType === 'expedite'
                ? getStartDate(row?.original?.requested_ship_week)
                : getStartDate(row?.original?.current_ship_week)
            }
            onBlur={() => {
              setShowInput(false)
            }}
            autoFocus
          />
        </div>
      ) : (
        <div className='w-full flex flex-col items-center justify-center gap-1'>
          <p className='cursor-pointer' onClick={() => setShowInput(true)}>
            {showValue ? value : row?.original?.current_ship_week}
          </p>
          {editArray?.some(
            (c) => c?.id_open_request === row?.original?.id_open_request
          ) && (
              <div className='w-full justify-end items-center flex'>
                <button onClick={() => resetValue()}>
                  <svg
                    className='w-6 h-6 text-gray-800 dark:text-white'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='currentColor'
                    viewBox='0 0 24 24'
                  >
                    <path
                      fillRule='evenodd'
                      d='M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z'
                      clipRule='evenodd'
                    />
                  </svg>
                </button>
              </div>
            )}
        </div>
      )}
    </div>
  )
}

const Expedite = () => {
  const [requestData, setRequestData] = useState([])
  const user = JSON.parse(localStorage.getItem('userData'))
  const [loading, setLoading] = useState(false)
  const [selectedRowArray, setSelectedRowArray] = useState([])
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [reason_codes, setReasonCode] = useState(reasonCodesEN ?? [])
  const [reasonArray, setReasonArray] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [rowSelection, setRowSelection] = useState({})
  const [showExpediteAcceptModal, setShowExpediteAcceptModal] = useState(false)
  const { t } = useTranslation()
  const tableId = 'ExpediteTable'; // Define tableId here
  const {
    ExpediteRequests,

    Home,
    Submitting_load,
    Pleaseselectreasoncodeforrejectingtheorder,
    Selectareason,
    Reject,
    Cancel
  } = t('ExpditePage')
  const {
    state: { editArray },
    dispatch
  } = EditedRowState()

  const navigate = useNavigate()

  // Access translated strings outside useMemo
  const headers = {
    CR: t('ExpditePage.CR'),
    PONUMBER: t('ExpditePage.PONUMBER'),
    SKU: t('ExpditePage.SKU'),
    STATUS: t('ExpditePage.STATUS'),
    REQUESTTYPE: t('ExpditePage.REQUESTTYPE'),
    REQUESTEDBY: t('ExpditePage.REQUESTEDBY'),
    CREATEDATE: t('ExpditePage.CREATEDATE'),
    UPDATEDATE: t('ExpditePage.UPDATEDATE'),
    CRSTATUS: t('ExpditePage.CRSTATUS'),
    VENDORRESPONSE: t('ExpditePage.VENDORRESPONSE'),
    REQSW: t('POList.REQSW'),
    ORGSW: t('POList.ORGSW'),
    CURRSW: t('POList.CURRENTSW'),

  }
  // Access translated strings outside useMemo
  const columns = useMemo(
    () => [
      {
        header: headers.CR,
        accessorKey: 'id_open_request',
        cell: ({ row }) => (
          <p
            className='underline cursor-pointer text-blue-500'
            onClick={() =>
              navigate(
                `/vendor/po_change_requests/request_details/${row?.original.id_open_request}`,
                { state: row?.original }
              )
            }
          >
            {row?.original?.id_open_request}
          </p>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.PONUMBER,
        accessorKey: 'po_number',
        cell: (info) => {
          return (
            <p
              className='underline cursor-pointer text-blue-500'
              onClick={() => handlePODetailsClick('vendor', info?.getValue())}
            >
              {info?.getValue()}
            </p>
          )
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.SKU,
        accessorKey: 'sku',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.REQSW,
        accessorKey: 'requested_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.ORGSW,
        accessorKey: 'original_ship_week',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CURRSW,
        accessorKey: 'current_ship_week',
        // cell: ({ row }) => (
        //   <LineComp
        //     row={row}
        //     requestType={'expedite'}
        //     selectTableRow={handleRowSelection}
        //     rowSelection={selectedRowArray}
        //     updateRowSelection={setRowSelection}
        //   />
        // ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.VENDORRESPONSE,
        accessorKey: 'supplier_response',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CRSTATUS,
        accessorKey: 'status',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.REQUESTEDBY,
        accessorKey: 'created_by',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.CREATEDATE,
        accessorKey: 'created_at',
        cell: (info) =>
          info?.getValue() !== null ? formatDateFit(info?.getValue()) : '',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        }
      },
      {
        header: headers.UPDATEDATE,
        accessorKey: 'updated_at',
        cell: (info) =>
          info?.getValue() !== null ? formatDateFit(info?.getValue()) : ''
      }
    ],
    [headers]
  )

  const handleRowSelection = (rowId) => {
    // rowSelectionFunction(rowId)
    // Perform actions you want in the parent component
  }

  const closeExpediteAcceptModal = () => {
    setShowExpediteAcceptModal(false)
  }

  const openExpediteAcceptModal = () => {
    setShowExpediteAcceptModal(true)
  }

  const handleAcceptRequest = () => {
    setSubmitting(true)
    setShowRejectModal(true)
    handleAcceptExpediteDeferRequests(
      setRequestData,
      selectedRowArray,
      setSubmitting,
      setShowRejectModal
    )
    closeExpediteAcceptModal()
  }

  const handleReasonChange = (selectedReason) => {
    if (!reasonArray.includes(selectedReason)) {
      setReasonArray((prevReasonArray) => [...prevReasonArray, selectedReason])
    }
  }

  const fetchAllRequests = () => {
    setLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-supplier-cr-expedite-data?vendorNumber=${user?.supplierId}`, ValidateAPI
      )
      .then((response) => {
        console.log('response from FCR: ', response?.data)
        if (response?.data?.[0]?.Message) return setRequestData([])
        setRequestData(response?.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(`error while FRC: `, error)
        setLoading(false)
        if (error.response.status === 401 || error.response.status === 403) {
          navigate('/login-error')
        }
      })
  }

  useEffect(() => {
    console.log('context Array: ', editArray)
    setSelectedRowArray([...editArray])
  }, [editArray])

  useEffect(() => {
    console.log('selectedRow Array: ', selectedRowArray)
  }, [selectedRowArray])

  useEffect(() => {
    const handleStorage = () => {
      if (window.localStorage?.getItem('language') === 'en') {
        setReasonCode(reasonCodesEN)
      } else {
        setReasonCode(reasonCodesMN)
      }
    }

    window.addEventListener('storage', handleStorage())
    return () => window.removeEventListener('storage', handleStorage())
  }, [window.localStorage?.getItem('language')])

  useEffect(() => {
    fetchAllRequests()

    return () => {
      dispatch({
        type: 'CLEAR_LIST'
      })
    }
  }, [])

  return (
    <div className='w-full flex flex-col gap-6 justify-start items-start p-4'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl'>{ExpediteRequests}</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/vendor')}
          >
            {Home}
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span>{ExpediteRequests}</span>
        </p>
      </div>
      {selectedRowArray?.length > 0 && (
        <div className='flex w-full justify-start items-center font-medium text-xs gap-6'>
          <button
            className='px-3 rounded-md bg-[#415da1] text-white h-[32px]'
            onClick={openExpediteAcceptModal}
          >
            <span>
              {selectedRowArray?.length > 1
                ? t('ExpditePage.AcceptRequests')
                : t('ExpditePage.AcceptRequest')}
            </span>
          </button>
          <PopupModal
            isOpen={showExpediteAcceptModal}
            message={i18next.t('PopUpMessage.Doyouwanttoaccepttheserequests')}
            onCancel={closeExpediteAcceptModal}
            cancelText={i18next.t('AcceptModal.Cancel')}
            onConfirm={handleAcceptRequest}
            confirmText={i18next.t('AcceptModal.Confirm')}
          />
          <button
            className='px-3 rounded-md bg-[#415da1] text-white h-[32px]'
            onClick={() => setShowRejectModal(true)}
          >
            {selectedRowArray?.length > 1
              ? t('ExpditePage.RejectRequests')
              : t('ExpditePage.RejectRequest')}
          </button>
        </div>
      )}
      {loading ? (
        <div className='sm:w-[400px] w-[280px] min-h-[300px] bg-white p-4 rounded-md flex flex-col gap-6'>
          <Loading type={Submitting_load} />
        </div>
      ) : (
        <div className='w-full overflow-x-auto'>
          <ConfirmTable
            tableId={tableId}
            tableData={requestData}
            columns={columns}
            selection={setSelectedRowArray}
            updateRowSelectionProp={setRowSelection}
            rowSelectionProp={rowSelection}
          />
        </div>
      )}
      <dialog
        open={showRejectModal}
        className='p-3 inset-0 fixed z-50 bg-black bg-opacity-50 w-full min-h-screen'
      >
        <div className='w-full min-h-screen p-4 flex justify-center items-center'>
          {submitting ? (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <Loading type={Submitting_load} />
            </div>
          ) : (
            <div className='sm:w-[400px] w-[280px] bg-white p-4 rounded-md flex flex-col gap-6'>
              <div className='w-full flex justify-end items-center'>
                <button
                  className='border-2 border-gray-300 p-2 rounded-md'
                  onClick={() => {
                    setShowRejectModal(false)
                    setReasonArray([])
                  }}
                >
                  ❌
                </button>
              </div>
              <div className='w-full flex justify-center items-center mt-2 text-center'>
                <p className='text-sm font-medium'>
                  {Pleaseselectreasoncodeforrejectingtheorder}
                </p>
              </div>
              <div className='w-full flex flex-wrap justify-center items-center gap-3'>
                {reasonArray?.map((reason, index) => (
                  <div
                    className='rounded-lg bg-[#4472C4] flex gap-2 justify-center items-center p-2 text-xs text-white font-medium'
                    key={'reason' + index}
                  >
                    <span>{reason}</span>
                    <span
                      className='cursor-pointer'
                      onClick={() =>
                        setReasonArray(reasonArray?.filter((e) => e !== reason))
                      }
                    >
                      X
                    </span>
                  </div>
                ))}
              </div>
              <div className='w-full flex justify-center items-center'>
                <select
                  onChange={(e) => handleReasonChange(e.target.value)}
                  name='reason_code_select'
                  id='reason_code_select'
                  className='p-2 rounded-md outline-none border border-gray-300 text-xs w-full font-medium'
                  value=''
                >
                  <option value='' disabled>
                    {Selectareason}
                  </option>
                  {reason_codes?.map((reason) => (
                    <option key={'reason_code' + reason} value={reason}>
                      {reason}
                    </option>
                  ))}
                </select>
              </div>
              <div className='w-full flex justify-center items-center gap-4 mt-4'>
                <button
                  className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-green-600 outline-none'
                  onClick={() =>
                    handleRejectExpediteDeferDivertRequest(
                      setRequestData,
                      selectedRowArray,
                      reasonArray,
                      setShowRejectModal,
                      setReasonArray,
                      setSubmitting
                    )
                  }
                >
                  {Reject}
                </button>
                <button
                  className='text-xs font-medium w-[80px] rounded-md h-[38px] text-white bg-red-500 outline-none'
                  onClick={() => {
                    setShowRejectModal(false)
                    setReasonArray([])
                  }}
                >
                  {Cancel}
                </button>
              </div>
            </div>
          )}
        </div>
      </dialog>
    </div>
  )
}

export default Expedite
