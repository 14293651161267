import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'

const SSOLogin = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = () => {
    const payload = {
      email: email
      //   password: password,
    }
    console.log('login payload', payload)
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/HFTUserLogin`,
        payload
      )
      .then((response) => {
        console.log('login info: ', response?.data)
        localStorage?.setItem('userData', JSON.stringify(response?.data))
        if (response?.data?.roleName === 'SUPPLIER_ROLE') {
          let url = sessionStorage.getItem('my_task_url')
          console.log('url from session: ', url)
          if (url) {
            console.log('url if satisfied')
            // window.location.href = url;
            sessionStorage?.removeItem('event_url')
            navigate(url)
          } else {
            navigate('/vendor')
          }
        } else if (response?.data?.roleName === 'BUYER_ROLE') {
          navigate('/buyer')
        } else if (response?.data?.roleName === 'OTHERS_ROLES') {
          navigate('/buyer')
        }
        // const data = {
        //   "fullName": "HELEN  FRUTOS",
        //   "email": "HFRUTOS@HARBORFREIGHT.COM",
        //   "supplierName": null,
        //   "supplierId": null,
        //   "phoneNumber": null,
        //   "country": null,
        //   "city": null,
        //   "userGroupRole": "3",
        //   "userGroupName": "HFT_ASIA_PECIFIC",
        //   "roleName": "ADMIN_ROLE",
        //   "enabled": "Y",
        //   "vendorContactOrcaleId": null,
        //   "hftOracleId": "1395",
        //   "userType": "H"
        // }
        // localStorage?.setItem("userData", JSON.stringify(data));
        // updateUser(data);
        // navigate("/admin");
      })
      .catch((error) => {
        console.log('error while loggin in: ', error)
        alert(error?.response?.data)
      })
  }

  return (
    <section className='bg-gray-50 dark:bg-gray-900 font-pop'>
      <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0'>
        <p className='flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white'>
          <img
            src='https://images.harborfreight.com/media/logos/harborfreight-logo.svg'
            width='100%'
            height='100%'
            alt='harbor_freight_logo'
            className='object-contain w-[165px] h-[53px]'
          />
        </p>
        <div className='w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700'>
          <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
            {/* <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign in to your account
            </h1> */}
            <form
              className='space-y-4 md:space-y-6'
              onSubmit={(e) => {
                e.preventDefault()
                handleLogin()
              }}
            >
              <div>
                <label
                  htmlFor='email'
                  className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                >
                  Your email
                </label>
                <input
                  type='text'
                  name='email'
                  id='email'
                  className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  placeholder='johnDoe@example.com'
                  value={email}
                  onChange={(e) => setEmail(e?.target.value)}
                  required
                />
              </div>

              <button
                type='submit'
                className='w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                // onClick={handleLogin}
              >
                SSO Log In
              </button>
              <p className='text-sm font-light items-center text-gray-500 dark:text-gray-400'>
                Return to{' '}
                <span
                  className='font-medium text-blue-600 hover:underline dark:text-blue-500 cursor-pointer'
                  onClick={() => navigate('/login')}
                >
                  Log In
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SSOLogin
