import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UserProfile from '../../components/UserProfile'
import axios from 'axios'
import { CSVLink } from 'react-csv'
import BasicTable from '../../components/BasicTable'
import ReactDatePicker from 'react-datepicker'
import { ValidateAPI, downloadCSV } from '../../utils/Utils'
import { useTranslation } from 'react-i18next'
import i18next from 'react-i18next'
const MyReport = () => {
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState('')

  const [openBuyerList, setOpenBuyerList] = useState(false)
  const [buyerList, setBuyerList] = useState([])
  const [vendorList, setVendorList] = useState([])
  const [openVendorList, SetOpneVendorList] = useState(false)
  const [report, setReport] = useState([])
  const user = JSON.parse(localStorage.getItem('userData'))
  const [searchBuyer, setSearchBuyer] = useState(user?.fullName)
  // const [columns, setColumn] = useState([])
  const [userInput, setuserUInput] = useState({
    reuquestType: 'Divert',
    VendorResponce: 'ACCEPTED',
    VendorNumber: '',
    FromDate: '',
    toDate: new Date().toISOString().slice(0, 10),
    hftUserId: user?.hftOracleId
  })
  const { t } = useTranslation()
  const {
    MyReport,
    Home,
    Buyer,
    VendorName,
    StartDate,
    EndDate,
    VendorResponse,
    SelectVendor,
    SelectBuyer,
    ChangeRequestType,
    Accept,
    Divert,
    NoDataFound,
    Select,
    UrgentOrder,
    Reject
  } = t('MyReportPage')
  const handleChange = (name, value) => {
    setuserUInput((prevFields) => ({
      ...prevFields,
      [name]: value
    }))
    console.log(userInput)
  }
  useEffect(() => {
    const currentDate = new Date()
    const previousDate = new Date()
    previousDate.setDate(currentDate.getDate() - 1)
    const finalDate = previousDate.toISOString().slice(0, 10)
    setuserUInput({ ...userInput, ['FromDate']: finalDate })
  }, [])

  const reportDateFormat = (inputDate) => {
    const date = new Date(inputDate)
    // Get day, month, and year from the Date object
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // Month is zero-based
    const year = date.getFullYear()
    // Form the new date string in "ddmmyyyy" format
    const outputDateString = day + '/' + month + '/' + year
    return outputDateString
  }
  const getCurrentTime = () => {
    const currTime = new Date().toLocaleTimeString()
    return currTime
  }
  const startdateEndDateFormat = (inputDate) => {
    const tempdate = inputDate?.split('-')
    const finalDate = tempdate[2] + '-' + tempdate[1] + '-' + tempdate[0]
    return finalDate
  }
  const searchVendorORSKU = (value) => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/po/VendorNameOrSKUBySearchString?searchString=${value}`,ValidateAPI
      )
      .then((response) => {
        setVendorList(response?.data)
      })
      .catch((error) => {
        // alert(error?.response?.data);
        if(error.response.status===401 || error.response.status===403){
          navigate('/login-error')
        }
      })
  }
  const searchBuyerByIDORNAME = (value) => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/search-buyers?searchstring=${value}&roleType=`,ValidateAPI
      )
      .then((response) => {
        setBuyerList(response?.data)
      })
      .catch((error) => {
        if(error.response.status===401 || error.response.status===403){
          navigate('/login-error')
        }
      })
  }

  const FetchReport = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}:8088/api/get-report?hftUserId=${userInput?.hftUserId}&requestType=${userInput?.reuquestType}&vendorResponse=${userInput?.VendorResponce}&vendorNumber=${userInput?.VendorNumber}&fromDate=${userInput?.FromDate}&toDate=${userInput?.toDate}`,ValidateAPI
      )
      .then((response) => {
        if (response?.data?.length === 0) {
          return setReport([])
          // return alert("No Data Found")
        } else {
          setReport(response?.data)
        }
      })
      .catch((error) => {
        // alert("something issue please try again !");
        if(error.response.status===401 || error.response.status===403){
          navigate('/login-error')
        }
      })
  }
  useEffect(() => {
    FetchReport()
  }, [userInput])

  const headers = {
    ENTITY: t('MyReportPage.ENTITY'),
    PONUMBER: t('MyReportPage.PONUMBER'),
    HFTBUYER: t('MyReportPage.HFTBUYER'),
    UPC: t('MyReportPage.UPC'),
    DC: t('MyReportPage.DC'),
    NEWPOFLAG: t('MyReportPage.NEWPOFLAG'),
    SHIP_TO_DC: t('MyReportPage.SHIP_TO_DC'),
    QUANTITY: t('MyReportPage.QUANTITY'),
    VENDORNUMBER: t('MyReportPage.VENDORNUMBER'),
    REQUESTEDSHIPWEEK: t('MyReportPage.REQUESTEDSHIPWEEK'),
    REQUESTEDSHIPYEAR: t('MyReportPage.REQUESTEDSHIPYEAR'),
    UNITPRICE: t('MyReportPage.UNITPRICE'),
    NEEDBYDATE: t('MyReportPage.NEEDBYDATE'),
    BUYER: t('MyReportPage.BUYER'),
    LINELEVELCOMMENTS: t('MyReportPage.LINELEVELCOMMENTS')
  }

  const columnsForDivert = useMemo(
    () => [
      { header: headers.ENTITY, accessorKey: 'ENTITY' },
      {
        header: headers.NEWPOFLAG,
        accessorKey: "NEW_PO_FLAG",
      },
      { header: headers.PONUMBER, accessorKey: "PO_Number" },
      { header: headers.VENDORNUMBER, accessorKey: "VENDOR_NUMBER" },
      {
        header: headers.SHIP_TO_DC,
        accessorKey: "SHIP_TO_DC",
      },
      {
        header: headers.UPC,
        accessorKey: 'UPC'
      },
      {
        header: headers.DC,
        accessorKey: 'SHIP_TO_DC'
      },
      {
        header: headers.HFTBUYER,
        accessorKey: 'HFT_BUYER'
      }
    ],
    [headers]
  )
  const columnsForUrgent = useMemo(
    () => [
      { header: headers.ENTITY, accessorKey: 'ENTITY' },
      {
        header: headers.NEWPOFLAG,
        accessorKey: 'NEW_PO_FLAG'
      },
      { header: headers.PONUMBER, accessorKey: 'PO_NUMBER' },
      { header: 'VENDOR NUMBER', accessorKey: 'VENDOR_NUMBER' },
      {
        header: headers.SHIP_TO_DC,
        accessorKey: 'SHIP_TO_DC'
      },
      {
        header: headers.UPC,
        accessorKey: 'UPC'
      },
      {
        header: headers.QUANTITY,
        accessorKey: 'QUANTITY'
      },
      {
        header: headers.REQUESTEDSHIPWEEK,
        accessorKey: 'REQUESTED_SHIP_WEEK'
      },
      {
        header: headers.REQUESTEDSHIPYEAR,
        accessorKey: 'REQUESTED_SHIP_YEAR'
      },
      {
        header: headers.UNITPRICE,
        accessorKey: 'UNITPRICE'
      },
      {
        header: headers.NEEDBYDATE,
        accessorKey: 'NEED_BY_DATE',
        cell: (info) =>
          info?.getValue() !== null ? reportDateFormat(info?.getValue()) : ''
      },
      {
        header: headers.BUYER,
        accessorKey: 'HFT_BUYER'
      },
      {
        header: headers.LINELEVELCOMMENTS,
        accessorKey: 'LINE_LEVEL_COMMENTS'
      }
    ],
    [headers]
  )

  const getReport = (report) => {
    let tempData = []
    if (userInput?.reuquestType === 'Urgent') {
      tempData = report.map((obj, i) => {
        return {
          ...obj,
          NEED_BY_DATE: reportDateFormat(obj?.NEED_BY_DATE),
          UPC: obj?.UPC
        }
      })
    } else {
      tempData = report.map((obj, i) => {
        return {
          ...obj,
          PROMISE_DATE: reportDateFormat(obj?.PROMISE_DATE)
        }
      })
    }
    return tempData
  }
  useEffect(() => {
    // getVendorList()
    const getData = setTimeout(() => {
      searchVendorORSKU(searchValue)
    }, 1000)
    return () => clearTimeout(getData)
  }, [searchValue])
  useEffect(() => {
    // getBuyerList()
    const getBuyer = setTimeout(() => {
      searchBuyerByIDORNAME(searchBuyer)
    }, 1000)
    return () => clearTimeout(getBuyer)
  }, [searchBuyer])
  //Cancel all Events
  const allPopUp = React.useRef(null)
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (allPopUp.current && !allPopUp.current.contains(event.target)) {
        // Clicked outside the notification, close it
        setOpenBuyerList(false)
        SetOpneVendorList(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      // Cleanup: Remove the event listener when the component is unmounted
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])
  return (
    <main className='p-2 w-full gap-5 space-y-2'>
      <div className='w-full justify-between flex items-center'>
        <div className=''>
          <p className='text-xl font-medium'>{MyReport}</p>
        </div>
        {/* <UserProfile /> */}
      </div>
      {/* BreadCrumb */}
      <div className='w-full text-xs flex justify-start items-center gap-1 font-medium text-gray-600'>
        <p className='flex gap-1 items-center justify-center'>
          <span
            className='hover:underline cursor-pointer'
            onClick={() => navigate('/buyer')}
          >
            {Home}
          </span>
          <span>{'>'}</span>
        </p>
        <p className='flex gap-1 items-center justify-center'>
          <span>{MyReport} </span>
        </p>
      </div>
      <div className='m-auto max-w-md justify-center   '>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            FetchReport()
          }}
          className=' space-y-3'
        >
          <div className='w-full flex gap-3'>
            <div className='w-1/2 flex flex-col gap-1 relative '>
              <label
                for='buyerName'
                class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
              >
                {Buyer}
              </label>
              <input
                type='text'
                className='bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  '
                value={searchBuyer}
                placeholder={SelectBuyer}
                onChange={(e) => setSearchBuyer(e.target.value.toUpperCase())}
                onFocus={() => {
                  setSearchBuyer('')
                  handleChange('hftUserId', '')
                }}
                onClick={() => setOpenBuyerList(!openBuyerList)}
              />
              {openBuyerList && (
                <div
                  ref={allPopUp}
                  className='bg-white   mt-20 overflow-y-auto scroll-smooth  z-10  border border-gray-500 rounded-sm max-h-52 absolute  '
                >
                  {buyerList?.length !== 0 ? (
                    buyerList?.map((data) => {
                      return (
                        <div
                          className={`p-2 text-gray-500 text-xs hover:bg-[#0059A8] hover:text-white 
                  
                  `}
                          onClick={() => {
                            handleChange('hftUserId', data?.hftUserId)
                            setOpenBuyerList(false)
                            setSearchBuyer(data?.buyerName)
                          }}
                        >
                          {data?.BUYER_DETAIL}
                        </div>
                      )
                    })
                  ) : (
                    <h1 className='text-xs text-gray-500 text-center p-2'>
                      {NoDataFound}
                    </h1>
                  )}
                </div>
              )}
            </div>
            <div className='w-1/2 flex flex-col gap-1 relative '>
              <label
                for='vendorName'
                class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
              >
                {VendorName}
              </label>
              <input
                type='text'
                className='bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  '
                value={searchValue}
                placeholder={SelectVendor}
                onChange={(e) => setSearchValue(e.target.value.toUpperCase())}
                onFocus={() => {
                  setSearchValue('')
                  handleChange('VendorNumber', '')
                }}
                onClick={() => SetOpneVendorList(!openVendorList)}
              />
              {openVendorList && (
                <div
                  ref={allPopUp}
                  className='bg-white   mt-20 overflow-y-auto scroll-smooth  z-10  border border-gray-500 rounded-sm max-h-52 absolute  '
                >
                  {vendorList?.length !== 0 ? (
                    vendorList?.map((data) => {
                      return (
                        <div
                          className={`p-2 text-gray-500 text-xs hover:bg-[#0059A8] hover:text-white 
                  
                  `}
                          onClick={() => {
                            handleChange('VendorNumber', data?.vendorOracleId)
                            SetOpneVendorList(false)
                            setSearchValue(data?.vendorName)
                          }}
                        >
                          {data?.displayName}
                        </div>
                      )
                    })
                  ) : (
                    <h1 className='text-xs text-gray-500 text-center p-2'>
                      {NoDataFound}
                    </h1>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='w-full flex gap-3 '>
            <div class=' w-1/2   '>
              <label
                for='FromDate'
                class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
              >
                {StartDate}
              </label>
              <input
                type='date'
                required
                value={userInput?.FromDate}
                onChange={(e) => handleChange('FromDate', e?.target?.value)}
                class='bg-gray-50  border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              />
            </div>
            <div class=' w-1/2'>
              <label
                for='toDate'
                class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
              >
                {EndDate}
              </label>
              <input
                type='date'
                required
                value={userInput?.toDate}
                onChange={(e) => handleChange('toDate', e?.target?.value)}
                class='bg-gray-50  border border-gray-300text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              />
            </div>
          </div>

          <div className='w-full flex gap-3 '>
            <div class=' w-1/2 flex flex-col gap-1  '>
              <label
                for='vendor responce'
                class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
              >
                {VendorResponse}
              </label>
              <select
                onChange={(e) =>
                  handleChange('VendorResponce', e?.target?.value)
                }
                value={userInput?.VendorResponce}
                className='bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              >
                <option selected>Select</option>
                <option value='ACCEPTED'>{Accept} </option>
                <option value='REJECTED'>{Reject}</option>
              </select>
            </div>
            <div class=' w-1/2   '>
              <label
                for='Change Request Type'
                class='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
              >
                {ChangeRequestType}
              </label>
              <select
                onChange={(e) => handleChange('reuquestType', e?.target?.value)}
                value={userInput?.reuquestType}
                className='bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              >
                <option selected>{Select}</option>

                <option value='Divert'>{Divert}</option>
                <option value='Urgent'>{UrgentOrder}</option>
              </select>
            </div>
          </div>

          {/* <div className="text-center">
                        <input type='submit' value='Fetch Report ' className='border p-2 rounded-md bg-gray-200 text-black cursor-pointer text-sm ' />

                    </div> */}
        </form>
      </div>

      {/* <div className="my-3">
                {report?.length !== 0
                    &&
                    downloadCSV(`${report[0].ENTITY}_${startdateEndDateFormat(userInput?.FromDate)}_${startdateEndDateFormat(userInput?.toDate)}_${userInput?.reuquestType}_${getCurrentTime()}`, report)
                }
            </div> */}
      {report?.length !== 0 && (
        <BasicTable
          tableData={report}
          columns={
            userInput?.reuquestType === 'Divert'
              ? columnsForDivert
              : columnsForUrgent
          }
        />
      )}
      {report?.length === 0 && (
        <h1 className='text-center bg-gray-50 m-5 p-2'>{NoDataFound}</h1>
      )}
    </main>
  )
}
export default MyReport
