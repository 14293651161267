import React, { useEffect, useState } from 'react'
import CommonBarChart from '../CommonBarChart'
import Loading from '../Loading'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { ValidateAPI } from '../../utils/Utils'
import { useNavigate } from 'react-router'

const VendorBarChart = ({vendor,cpiOffice}) => {
  const user = JSON.parse(localStorage?.getItem('userData'))
  const [reqTypeData, setReqTypeData] = useState([])
  const [barload, setBarload] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { DistributionbyRequestType, Norelateddatafound } = t('greeting')
  const fetchReqTypeData = () => {
    const  overseasURL =`${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/get-all-counts-cr-types-vendor-filter-cpi-office?cpioffice=${cpiOffice}&vendorNumber=${vendor}`
    const vendorURL =`${process.env.REACT_APP_API_DOMAIN}:8088/api/admin/get-all-counts-cr-types-vendor-filter?vendorNumber=${user?.supplierId}`
    const URL = user?.userGroupName==="OVERSEAS_ROLE" ? overseasURL : vendorURL
    setBarload(true)
    axios
      .get(
        URL,ValidateAPI
      )
      .then((response) => {
        console.log('Data from reqtype: ', response?.data)
        setReqTypeData(response?.data)
        setBarload(false)
      })
      .catch((error) => {
        if(error.response.status===401 || error.response.status===403){
          navigate('/login-error')
        }
        setBarload(false)
      })
  }

  useEffect(() => {
    fetchReqTypeData()
  }, [vendor])
  return (
    <div className="w-[50%] flex justify-center items-center flex-col sm:w-[100%]
    gap-5 shadow-lg rounded-md border border-gray-300 p-3 max-h-[360px]">
      <p className="text-sm font-medium">{DistributionbyRequestType}</p>
      <div className="relative flex justify-between items-center w-full">
        {!barload ? (
          reqTypeData?.length > 0 ? (
            <CommonBarChart
              chartData={reqTypeData}
              color={'#8884d8'}
              type={'requestType'}
              value={'Count'}
            />
          ) : (
            <p>{Norelateddatafound}</p>
          )
        ) : (
          <div className='w-full min-h-[250px] justify-center items-center flex'>
            <Loading />
          </div>
        )}
      </div>
    </div>
  )
}

export default VendorBarChart
