export const ConfirmPOpageEN = {
  SelectDC: 'Select DC',
  SelectedSW: 'Selected SW :',
  Comment_load: 'Comment',
  POConfirmOrders: 'PO Confirm Orders',
  Home: 'Home',
  ConfirmPOOrders: 'Confirm PO Orders',
  RequestChange: 'Request Change',
  ConfirmOrders: 'Confirm Orders',
  Loading: 'Loading...',
  PleaseselectReasoncodefortheselectedorders:
    'Please select Reason code for the selected orders.',
  ReasonCode: 'Reason Code',
  Selectareason: 'Select a reason',
  Changetype: ' Change type',
  Selectatype: 'Select a type',
  DC: 'DC',
  Shipweek: 'Shipweek',
  Price: 'Price',
  Quantity: 'Quantity',
  RequestChange: 'Request Change',
  Cancel: 'Cancel',
  //reconfirmpage
  DIL: 'DIL',
  MOR: 'MOR',
  CHI: 'CHI',
  POReconfirmOrders: 'PO Reconfirm Orders',
  ReconfirmPOOrders: 'Reconfirm PO Orders',

  //dynamictable headers
  CR: 'CR #',
  PONUMBER: 'PO NUMBER',
  SKU: 'SKU',
  DESCRIPTION: 'DESCRIPTION',
  ORGSW: 'ORG SW',
  CURSW: 'CUR SW :',
  REQDC: 'REQ DC',
  QUANTITY: 'QUANTITY',
  VENDORRESPONSE: 'VENDOR RESPONSE',
  CREATEDAT: 'CREATED AT',
  STATUS: 'STATUS',
  // OnTimeTarget:"OnTimeTarget",
  Delta: 'Delta',

  //vendor table po confirmation page
  BUYER: 'BUYER',
  HTS: 'HTS',
  REQSW: 'REQ SW',
  POCREATIONDATE: 'PO CREATION DATE',
  ONTIMETARGET: 'ON TIME TARGET',
  DELTA: 'DELAY',
  Submitting: 'Submitting...',
  //placeholder
  Typehereindetail: 'Type here in detail...'
}
export const ConfirmPOpageMN = {
  SelectDC: '选择配送中心',
  SelectedSW: '已选择的软件 ：',
  Comment_load: '评论',
  POConfirmOrders: '确认采购订单',
  Home: '首页',
  ConfirmPOOrders: '确认采购订单',
  RequestChange: '请求更改',
  ConfirmOrders: '确认订单',
  Loading1: '加载中...',
  PleaseselectReasoncodefortheselectedorders: '请为所选订单选择原因代码。',
  ReasonCode: '原因代码',
  Selectareason: '选择一个原因',
  Changetype: '更改类型',
  Selectatype: '选择一个类型',
  DC: '配送中心',
  Shipweek: '发货周',
  Price: '价格',
  Quantity: '数量',
  // RequestChange:"请求更改",
  Cancel: '取消',
  //reconfirmpopage
  DIL: 'DIL',
  MOR: 'MOR',
  CHI: 'CHI',
  POReconfirmOrders: '皮哦 重新确认订单',
  ReconfirmPOOrders: '重新确认 皮哦 订单',
  //vendor Reconfirm page
  BUYER: '买家',
  HTS: '慧提艾斯',
  REQSW: '请求软件',
  POCREATIONDATE: '采购订单创建日期',
  //dynamic table header

  CR: '编号#', // CR号
  PONUMBER: '皮哦编号',
  SKU: '库存单位',
  DESCRIPTION: '描述',
  ORGSW: '原始软件',
  CURSW: '当前软件 :',
  QUANTITY: '数量',
  VENDORRESPONSE: '供应商回应',
  STATUS: '状态',
  CREATEDAT: '创建时间',
  REQDC: '原始软件',
  ONTIMETARGET: '按时目标',
  DELTA: '差额',
  Submitting_load: '提交中...',
  //placeholder
  Typehereindetail: '在此详细描述...'
}
