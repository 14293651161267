import { useEffect, useState } from "react"
import { reasonCodesEN, reasonCodesMN } from "../utils/Utils"
import { useTranslation } from "react-i18next"


const ReasonCodeList = ({ handleReasonChange, reasonArray,setReasonArray }) => {
    const { t } = useTranslation()

    const [reason_codes, setReasonCode] = useState([])
    const { ReasonCode, Selectareason } = t('ConfirmPOpage')
    useEffect(() => {
        const handleStorage = () => {
            if (window.localStorage?.getItem('language') === 'en') {
                setReasonCode(reasonCodesEN)
            } else {
                setReasonCode(reasonCodesMN)
            }
        }

        window.addEventListener('storage', handleStorage())
        return () => window.removeEventListener('storage', handleStorage())
    }, [window.localStorage?.getItem('language')])
    return (
        <div className='w-full flex flex-col items-start gap-2'>
            <div className='w-full flex flex-wrap justify-center items-center gap-1'>
                {reasonArray?.map((reason, index) => (
                    <div
                        className='rounded-lg bg-[#4472C4] flex gap-2 justify-center items-center p-1 text-[10px] text-white font-medium'
                        key={index}
                    >
                        <span>{reason}</span>
                        <span
                            className='cursor-pointer'
                          onClick={() =>
                            setReasonArray(reasonArray?.filter((e) => e !== reason))
                          }
                        >
                            X
                        </span>
                    </div>
                ))}
            </div>  
            <label
                htmlFor='reason_code_select'
                className='text-xs font-medium'
            >
                {ReasonCode}
            </label>
            <select
                onChange={(e) => handleReasonChange(e.target.value)}
                name='reason_code_select'
                id='reason_code_select'
                className='p-2 rounded-md outline-none border border-gray-300 text-xs w-full font-medium'
                value=''
            >
                <option value='' disabled>
                    {Selectareason}
                </option>
                {reason_codes?.map((reason) => (
                    <option key={reason} value={reason}>
                        {reason}
                    </option>
                ))}
            </select>
            
        </div>
    )
}
export default ReasonCodeList